import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import i18n from "i18next";
import { useLocation } from "react-router-dom";
import {
  exportAndDelete,
  signOut,
  userObjectSelector,
  isMobile,
  siteConfigSelector,
  isSharedPortfolioUserSelector,
  getAccountOwnerNameSelector,
  fetchUser,
  useHistory,
  sharedPortfolioUsersSelector,
  sharedPortfolioAccountTitleSelector,
  switchToUsersDefaultAccount,
  subAccountUserSelfLeave
} from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import AccountSettingsComponent, { accountSettingsTabs } from "components/account_settings/AccountSettingsComponent";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import MobilePaywall from "./MobilePaywall";
import useShowPaywall from "./useShowPaywall";
import SwitchAccountComponent from "components/multiuser/SwitchAccountComponent";

const isMobileDevice = isMobile();

const PaywallDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 575px;
  margin-top: 163px;
  padding: 45px 50px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const UserDetails = styled.div`
  font-size: 13px;
  margin-bottom: 6px;
  font-weight: bold;
  white-space: pre-wrap;
`;

const Desc = styled.div`
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 38px;
  white-space: pre-wrap;
`;

const BtnBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SubscribeBtn = styled(PrimaryButton)`
  display: inline-flex;
  margin-right: 16px;
`;

const ExportDelUser = styled(SecondaryButton)`
  display: inline-flex;
  margin-right: 16px;
  padding-left: 15px;
  padding-right: 15px;
`;

const SwitchAccountBtn = styled(ExportDelUser)``;

const DeleteConfirmationDialog = styled(ConfirmationDialog)`
  min-width: 680px;
  white-space: pre-wrap;
`;

const Link = styled.a`
  color: ${props => props.theme.linkColor};
`;

const BlockerPaywall = props => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userObject = useSelector(userObjectSelector);
  const siteConfig = useSelector(siteConfigSelector);
  const isSharedPortfolioAccountUser = useSelector(isSharedPortfolioUserSelector);
  const getAccountOwnerName = useSelector(getAccountOwnerNameSelector);

  const [step, setStep] = useState(0);
  const [delDownloadLoading, setDelDownloadLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoadingSubAccountCheck, setIsLoadingSubAccountCheck] = useState(false);
  const isPaywallVisible = useShowPaywall() || (props && props.forceShowPaywall === true);

  const sharedPortfolioAccountTitle = useSelector(sharedPortfolioAccountTitleSelector);
  const sharedPortfolioUsers = useSelector(sharedPortfolioUsersSelector);
  const [showSwitchDialog, setShowSwitchDialog] = useState(false);
  const [showLeaveConfirmationDialog, setShowLeaveConfirmationDialog] = useState(false);

  const showSubscriptionModal = () => {
    AccountSettingsComponent.show(history, location, accountSettingsTabs.SUBSCRIPTION);
  };

  const checkSubscription = () => {
    setIsLoadingSubAccountCheck(true);
    dispatch(
      fetchUser(
        () => {
          setIsLoadingSubAccountCheck(false);
        },
        () => {
          setIsLoadingSubAccountCheck(false);
        }
      )
    );
  };

  const exportAndDeleteUser = async () => {
    setDelDownloadLoading(true);
    await dispatch(exportAndDelete()).catch(error => {
      setErrorMessage(error.errorMessage || i18n.t("genericError"));
    });
    setDelDownloadLoading(false);
    setStep(1);
  };

  const leaveAccount = () => {
    setDelDownloadLoading(true);
    dispatch(
      subAccountUserSelfLeave(
        undefined,
        () => {
          switchToUsersDefaultAccount();
        },
        error => {
          setErrorMessage(error.errorMessage || i18n.t("genericError"));
        }
      )
    );
  };

  const onDismiss = () => {
    setStep(0);
    setErrorMessage(null);
  };

  const onSignOut = () => {
    signOut();
  };

  const siteName = siteConfig ? siteConfig.name : "";

  useEffect(() => {
    if (isPaywallVisible || errorMessage) {
      history.push({
        ...location,
        hash: ``
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorMessage) {
    return (
      <DialogOverlay onDismiss={onDismiss}>
        <PaywallDialog>
          <Title>{i18n.t("paywall.errorTitle")}</Title>
          <Desc>{i18n.t("paywall.errorDesc")}</Desc>
          <BtnBlock>
            <PrimaryButton title={i18n.t("ok")} onClick={onDismiss} />
          </BtnBlock>
        </PaywallDialog>
      </DialogOverlay>
    );
  }

  if (step === 1) {
    return (
      <DeleteConfirmationDialog
        title={i18n.t("paywall.mailSentTitle")}
        description={i18n.t("paywall.mailSentDesc").replace(/%s%/g, siteName)}
        positiveButtonTitle={i18n.t("signOut")}
        negativeButtonTitle={i18n.t("goBackLink")}
        isLoading={delDownloadLoading}
        handlePositiveButtonClick={onSignOut}
        handleNegativeButtonClick={onDismiss}
        onDismiss={onDismiss}
        canUserDismiss
      />
    );
  }

  if (!userObject) {
    return null;
  }

  if (isPaywallVisible) {
    if (isSharedPortfolioAccountUser) {
      return (
        <DialogOverlay onDismiss={onDismiss}>
          <PaywallDialog>
            <Title>{i18n.t("paywall.isSubAccount.modaltitle")}</Title>
            <UserDetails>{sharedPortfolioAccountTitle}</UserDetails>
            <Desc>
              {`${i18n.t("paywall.isSubAccount.modaldesc").replace(/%s%/g, getAccountOwnerName)}`}
              <Link href="mailto:hello@kubera.com">hello@kubera.com</Link>
            </Desc>
            <BtnBlock>
              <SubscribeBtn
                title={i18n.t("refresh")}
                onClick={checkSubscription}
                isLoading={isLoadingSubAccountCheck}
              />
              <ExportDelUser
                title={i18n.t("paywall.isSubAccount.leaveAccount")}
                onClick={e => setShowLeaveConfirmationDialog(true)}
              />
              {sharedPortfolioUsers.length > 0 && (
                <SwitchAccountBtn title={i18n.t("switchAccount")} onClick={e => setShowSwitchDialog(true)} />
              )}
            </BtnBlock>
            {showSwitchDialog && <SwitchAccountComponent onDismiss={() => setShowSwitchDialog(false)} />}
            {showLeaveConfirmationDialog && (
              <ConfirmationDialog
                title={i18n.t("deleteSubuserSelfDialog.title")}
                description={i18n.t("deleteSubuserSelfDialog.description")}
                positiveButtonTitle={i18n.t("deleteSubuserSelfDialog.action")}
                negativeButtonTitle={i18n.t("cancel")}
                handlePositiveButtonClick={leaveAccount}
                handleNegativeButtonClick={() => setShowLeaveConfirmationDialog(false)}
                canUserDismiss={delDownloadLoading === false}
                isLoading={delDownloadLoading}
                errorMessage={errorMessage}
                onDismiss={() => setShowLeaveConfirmationDialog(false)}
              />
            )}
          </PaywallDialog>
        </DialogOverlay>
      );
    }
    return (
      <DialogOverlay onDismiss={onDismiss}>
        <PaywallDialog>
          <Title>{i18n.t("paywall.modaltitle").replace(/%s%/g, siteName)}</Title>
          <UserDetails>{`${userObject.name} (${userObject.email})`}</UserDetails>
          <Desc>
            {`${i18n.t("paywall.modaldesc").replace(/%s%/g, siteName)}`}
            <Link href="mailto:hello@kubera.com">hello@kubera.com</Link>
          </Desc>
          <BtnBlock>
            <SubscribeBtn title={i18n.t("subscribe")} onClick={showSubscriptionModal} />
            {sharedPortfolioUsers.length > 0 ? (
              <SwitchAccountBtn title={i18n.t("switchAccount")} onClick={e => setShowSwitchDialog(true)} />
            ) : (
              <ExportDelUser
                title={i18n.t("paywall.downloaddel")}
                onClick={exportAndDeleteUser}
                isLoading={delDownloadLoading}
              />
            )}
          </BtnBlock>
          {showSwitchDialog && <SwitchAccountComponent onDismiss={() => setShowSwitchDialog(false)} />}
        </PaywallDialog>
      </DialogOverlay>
    );
  }

  return null;
};

export default isMobileDevice ? MobilePaywall : BlockerPaywall;
