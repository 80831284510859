import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import { ReactComponent as PacmanIcon } from "assets/images/pacman.svg";
import emptyDoughnutsBackground from "assets/images/dashboard_blank_state.png";
import { isAmcUser } from "@kubera/common";
import NetWorthGreetingComponent from "./NetWorthGreetingComponent";
import PrimaryButton from "components/button/PrimaryButton";
import DashboardComponent, { category } from "components/dashboard/DashboardComponent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
`;

const EmptyViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  z-index: 10;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Greeting = styled(NetWorthGreetingComponent)`
  flex: 1;
  font-weight: 700;
  font-size: 30px;
`;

const PacmanIconImage = styled(PacmanIcon)`
  width: 70px;
  height: 70px;

  path {
    fill: ${props => props.theme.dashboardComponentContainerCLR};
  }
`;

const EmptyViewContent = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  padding: 30px 50px 100px 50px;
  background: ${props => props.theme.chartsNotAvailableEmptyViewContainerBG};
  border: ${props => props.theme.chartsNotAvailableEmptyViewContainerBR};
  box-sizing: border-box;
`;

const EmptyContentDescription = styled.div`
  width: 40%;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  white-space: pre-line;
`;

const AddAssetsButton = styled(PrimaryButton)`
  margin-top: 25px;
  padding-left: 40px;
  padding-right: 40px;
  background: ${props => props.theme.emptyGridComponentOkBtnBG};
`;

const EmptyDoughnuts = styled.div`
  width: 100%;
  height: 1000px;
  margin-top: -90px;
  z-index: 1000;
  background-color: transparent;
  background-image: url(${emptyDoughnutsBackground});
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
`;

const ViewModeContainer = styled.div`
  position: absolute;
  margin-top: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ViewModeMessage = styled.div`
  margin-top: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
`;

const AdminGreeting = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 28px;
  letter-spacing: -0.055em;
  font-feature-settings: "ss01" on, "calt" off;
  margin-bottom: 16px;
`;

class ChartsNotAvailableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddAssetsClick = this.handleAddAssetsClick.bind(this);
  }

  handleAddAssetsClick(e) {
    this.props.history.push({
      ...this.props.location,
      pathname: DashboardComponent.getPathnameForCategory(category.ASSET)
    });
  }

  render() {
    if (this.props.isReadOnly) {
      return (
        <ViewModeContainer>
          <ViewModeMessage>{i18n.t("viewModeEmptyGrid")}</ViewModeMessage>
        </ViewModeContainer>
      );
    }

    return (
      <Container>
        <EmptyViewContainer>
          <EmptyViewContent>
            <TitleContainer>
              <Greeting />
              {this.props.isAmcUser === true ? <AdminGreeting>Hello</AdminGreeting> : <PacmanIconImage />}
            </TitleContainer>
            <EmptyContentDescription>{i18n.t("emptyChartsDescription")}</EmptyContentDescription>
            <AddAssetsButton title={i18n.t("addAssets")} onClick={this.handleAddAssetsClick} />
          </EmptyViewContent>
          <EmptyDoughnuts />
        </EmptyViewContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  isAmcUser: isAmcUser(state)
});

export default connect(mapStateToProps)(withRouter(ChartsNotAvailableComponent));
