import React from "react";
import styled from "styled-components";
import {
  chartTimeRange,
  updateUserPreferences,
  userPreferencesSelector,
  currentPortfolioSelector,
  recapChartTypes,
  recapChartOptions,
  RECAP_CATEGORY_TYPE_NETWORTH,
  checkIfAChartOptionHasNoDataToShow,
  convertCurrency,
  getColumnarGridColumnHeaderString,
  getDateInKuberaFormat,
  RECAP_CATEGORY_TYPE_ASSET,
  RECAP_CATEGORY_TYPE_DEBT,
  RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS,
  RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS,
  RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS,
  RECAP_CATEGORY_TYPE_FIAT_ASSET,
  formatNumberWithCurrency
} from "@kubera/common";
import ContextMenu from "components/contextmenu/ContextMenu";
import { useSelector, useDispatch } from "react-redux";
import menuIcon from "assets/images/menu_downarrow.svg";
import menuIconDark from "assets/images/menu_downarrow_dark.svg";
import i18n from "i18next";
import { ReactComponent as DownloadIcon } from "assets/images/download_icon.svg";
import { downloadFile } from "utilities/FileUtils";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction : column
  align-items: flex-start;
`;
const MenuContainer = styled.div`
  display : flex;
  flex : 1
  position: sticky;
  position: -webkit-sticky;
  left: 60px;
  top: 0px;
  z-index: 1000;
`;
const Menu = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;
const NonMenuContainer = styled.div`
  display: flex;
  position: relative
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;

const DropDownButton = styled.div`
  cursor: ${props => (props.isClickable === true ? "pointer" : "auto")};
  margin-right: 16px;
  background-color: transparent
  background-repeat: no-repeat;
  background-position: right;
  background-size: ${props => (props.isClickable === true ? "9px 9px" : "0px")};
  background-image: url(${props => (props.theme.mode === "default" ? menuIcon : menuIconDark)});
  padding-right: 15px;
  font-size: 14px;
  `;

const ChartTimeRangeDropDownButton = styled(DropDownButton)`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const ChartOptionsDropDownButton = styled(DropDownButton)`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;
const ChartTypeDropDownButton = styled(DropDownButton)`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const PercentageChangeButton = styled.div`
  width: 100px;
  height: 19px;
  background: ${props => (props.isPercentageChangeShown ? "#8a8a8a" : props.theme.percentageChangeButtonBG)};
  color: ${props => (props.isPercentageChangeShown ? "#ffffff" : "#8a8a8a")};  border: 1px solid #939393
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height */

  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-top : -30px
  padding : 0px 3px;
`;

const CSVDownloadButton = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  margin-top : -30px
  margin-right: 24px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const CSVDownloadIcon = styled(DownloadIcon)`
  pointer-events: none;
  path {
    fill: ${props => props.theme.svgDefaultColor};
    stroke: #8a8a8a;
    stroke-width: 0.3;
  }
`;

const ChartOptionsContextMenu = styled(ContextMenu)`
  width: 220px;
`;
const TimeRangeContextMenu = styled(ContextMenu)``;
const ChartTypeContextMenu = styled(ContextMenu)``;

// const ExportToPdfButton = styled.div`
//   font-style: normal;
//   font-weight: 500;
//   font-size: 10px;
//   line-height: 12px;
//   text-transform: uppercase;
//   font-feature-settings: "ss01" on;
//   color: #ffffff;
//   min-width: 26px;
//   height: 16px;
//   background: #ffffff;
//   border: 1px solid #8a8a8a;
//   box-sizing: border-box;
//   color: #8a8a8a;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   &:hover {
//     cursor: pointer;
//   }
//   padding: 5px;
//   position: relative;

//   &::after {
//     content: "";
//     position: absolute;
//     top: -4px;
//     width: 100%;
//     height: 19px;
//   }
// `;

const ColumnarChartMenuComponent = props => {
  const {
    selectedChartOptions,
    selectedTimeRange,
    selectedChartType,
    isPercentageChangeShown,
    recapData,
    currency,
    portfolioCurrency
  } = props;
  const dispatch = useDispatch();
  const chartOptionsContextMenuRef = React.createRef();
  const timeRangeContextMenuRef = React.createRef();
  const chartTypeContextMenuRef = React.createRef();
  const userPreferences = useSelector(userPreferencesSelector);
  const portfolio = useSelector(currentPortfolioSelector);

  const getChartTimeRangeMenuItems = () => {
    return [
      [
        { id: chartTimeRange.TODAY, label: "Today", type: "timeRange" },
        { id: chartTimeRange.DAILY, label: "Daily", type: "timeRange" },
        { id: chartTimeRange.WEEKLY, label: "Weekly", type: "timeRange" },
        { id: chartTimeRange.MONTHLY, label: "Monthly", type: "timeRange" },
        { id: chartTimeRange.QUARTERLY, label: "Quarterly", type: "timeRange" },
        { id: chartTimeRange.YEARLY, label: "Yearly", type: "timeRange" }
      ]
    ];
  };

  const getChartTypeMenuItems = () => {
    return [
      [
        { id: recapChartTypes.TOTALS, label: "Totals", type: "chartType" },
        { id: recapChartTypes.PERCENTAGE_ALLOCATION, label: "% Allocation", type: "chartType" }
      ]
    ];
  };

  const getCurrentlySelectedChartTypeText = () => {
    const menuItems = getChartTypeMenuItems();
    for (const item of menuItems[0]) {
      if (item.id === selectedChartType) {
        return item.label;
      }
    }
  };

  const getCurrentlySelectedTimeRangeText = () => {
    const menuItems = getChartTimeRangeMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === selectedTimeRange) {
        return item.label;
      }
    }
  };

  const getCurrentlySelectedChartOptionsText = () => {
    const menuItems = getChartOptionsMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === selectedChartOptions) {
        return item.label;
      }
    }
  };

  const getChartOptionsMenuItems = () => {
    let reportsMenuItems = Object.values(recapChartOptions);
    const assetsAndCurrencyIndex = reportsMenuItems.findIndex(
      item => item.id === recapChartOptions.ASSETS_AND_CURRENCY.id
    );
    // get label based on crypto report availablity
    reportsMenuItems[assetsAndCurrencyIndex].label = checkIfAChartOptionHasNoDataToShow(
      recapChartOptions.CRYPTO.id,
      chartTimeRange.TODAY,
      recapData
    )
      ? "Assets x Currency"
      : i18n.t("supportedReports.assetsAndCurrency.label");
    return [reportsMenuItems];
  };

  const checkIfTimeRangeHasNoDataToShow = (chartOption, timeRange) => {
    // console.log("data present", Object.keys(recapData[timeRange][selectedChartOptions].totals).length);
    // return Object.keys(recapData[timeRange][selectedChartOptions].totals).length <= 1;
    if (timeRange === chartTimeRange.TODAY) {
      return recapData[timeRange][chartOption].totals[RECAP_CATEGORY_TYPE_NETWORTH][0].values.length === 0;
    } else {
      return recapData[timeRange][chartOption].totals[RECAP_CATEGORY_TYPE_NETWORTH][0].values.length < 2;
    }
  };

  const getrecapDataBasedOnFilter = () => {
    //get data based on selected time range
    if (recapData) {
      let chartData = recapData[selectedTimeRange];
      chartData = chartData[selectedChartOptions][selectedChartType];
      return chartData;
    }

    return {};
  };
  let filteredCSVData = getrecapDataBasedOnFilter();

  const convertToCSVColumnHeaderRow = values => {
    let columnHeaderRow = "";
    for (const value of values) {
      columnHeaderRow =
        columnHeaderRow + "," + getColumnarGridColumnHeaderString(getDateInKuberaFormat(value.date), selectedTimeRange);
    }
    return columnHeaderRow;
  };

  const doCSVEscaping = str => {
    str = str.toString();
    if (str.includes(",") || str.includes('"')) {
      let newStr = str.replaceAll('"', '""');
      newStr = '"' + newStr + '"';
      return newStr;
    } else return str;
  };

  const convertToCSVDataRow = (name, values, isSegmentRow) => {
    const neverShowedInPercentage = [
      RECAP_CATEGORY_TYPE_ASSET,
      RECAP_CATEGORY_TYPE_DEBT,
      RECAP_CATEGORY_TYPE_NETWORTH,
      RECAP_CATEGORY_TYPE_INVESTABLE_ASSETS,
      RECAP_CATEGORY_TYPE_FIAT_ASSET,
      RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS,
      RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS,
      "Cash on hand",
      "Stocks",
      "Crypto",
      "Brokerages"
    ];

    // isSegmentRow flag is required, in case user has an asset, whose name is same as above array
    const isNeverShowedInPercentage = isSegmentRow && neverShowedInPercentage.includes(name);

    let dataRow = "";
    dataRow = dataRow + doCSVEscaping(name);
    for (const value of values) {
      let convertedValue;
      let formattedValue;
      if (isNeverShowedInPercentage) {
        convertedValue = convertCurrency(value.value, currency, portfolioCurrency);
        formattedValue = doCSVEscaping(
          formatNumberWithCurrency(convertedValue, currency, true, 2, false, false, false, false)
        );
        // formattedValue = parseFloat(convertedValue).toFixed(2);
      } else {
        if (selectedChartType === recapChartTypes.PERCENTAGE_ALLOCATION) {
          convertedValue = doCSVEscaping(
            formatNumberWithCurrency(value.value, currency, true, 2, false, false, false, false)
          );
          formattedValue = convertedValue + "%";
        } else {
          convertedValue = convertCurrency(value.value, currency, portfolioCurrency);
          formattedValue = doCSVEscaping(
            formatNumberWithCurrency(convertedValue, currency, true, 2, false, false, false, false)
          );
          // formattedValue = parseFloat(convertedValue).toFixed(2);
        }
      }
      dataRow = dataRow + "," + formattedValue;
    }
    return dataRow;
  };

  const getRecapCSVReportHeader = () => {
    let reportHeaderRow = "Recap report : ";

    const chartOptions = Object.values(recapChartOptions);
    const chartOption = chartOptions.filter(option => option.id === selectedChartOptions);
    const chartOptionLabel = chartOption.length > 0 ? chartOption[0].label : "";

    reportHeaderRow =
      reportHeaderRow +
      chartOptionLabel +
      " / " +
      getCurrentlySelectedTimeRangeText() +
      " / " +
      getCurrentlySelectedChartTypeText() +
      " (" +
      portfolioCurrency +
      ")";
    return reportHeaderRow;
  };

  const changeSegmentOrder = () => {
    let staticSegments = [RECAP_CATEGORY_TYPE_NETWORTH, "Stocks", "Crypto"];
    let topSegments = {};
    let remainingSegments = {};
    for (let [key, segment] of Object.entries(filteredCSVData)) {
      if (staticSegments.includes(key) || segment.length > 1) {
        topSegments[key] = segment;
      } else {
        remainingSegments[key] = segment;
      }
    }
    const orderedSegments = { ...topSegments, ...remainingSegments };
    return orderedSegments;
  };

  const getFilteredRecapDataInCSV = () => {
    let recapDataForCSV = filteredCSVData;
    if (
      selectedChartOptions === recapChartOptions.STOCKS_AND_SECTOR.id ||
      selectedChartOptions === recapChartOptions.CRYPTO.id
    ) {
      recapDataForCSV = changeSegmentOrder();
    }
    let isColumnHeaderRow = false;
    let isCustodianDataRow = false;
    const csvRows = [];
    const reportHeaderRow = getRecapCSVReportHeader();
    csvRows.push(reportHeaderRow);
    if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      for (let [key, segment] of Object.entries(recapDataForCSV)) {
        if (key === RECAP_CATEGORY_TYPE_NETWORTH) {
          continue;
        }

        if (
          segment &&
          segment.name &&
          segment.values &&
          segment.values.length &&
          segment.sheets &&
          segment.sheets.length
        ) {
          if (!isColumnHeaderRow) {
            const columnHeaderRow = convertToCSVColumnHeaderRow(segment.values);
            isColumnHeaderRow = true;
            csvRows.push(columnHeaderRow);
          }

          const isArchivedSegment =
            segment.name === RECAP_CATEGORY_TYPE_ARCHIVED_ASSETS || segment.name === RECAP_CATEGORY_TYPE_ARCHIVED_DEBTS
              ? true
              : false;
          const dataRow = convertToCSVDataRow(segment.name, segment.values, true);
          csvRows.push(dataRow);
          if (!isArchivedSegment) {
            csvRows.push("");
          }

          for (const sheet of segment.sheets) {
            if (sheet && sheet.name && sheet.values && sheet.values.length && sheet.sections && sheet.sections.length) {
              if (!isArchivedSegment) {
                const dataRow = convertToCSVDataRow(sheet.name, sheet.values);
                csvRows.push(dataRow);
                csvRows.push("");
              }

              for (const section of sheet.sections) {
                if (
                  section &&
                  section.name &&
                  section.values &&
                  section.values.length &&
                  section.rows &&
                  section.rows.length
                ) {
                  if (sheet.sections.length > 1) {
                    const dataRow = convertToCSVDataRow(section.name, section.values);
                    csvRows.push(dataRow);
                  } else {
                    if (!isArchivedSegment) csvRows.pop();
                  }

                  for (const row of section.rows) {
                    if (row && row.name && row.values && row.values.length) {
                      const dataRow = convertToCSVDataRow(row.name, row.values);
                      csvRows.push(dataRow);
                      isCustodianDataRow = true;
                    }
                  }
                  csvRows.push("");
                }
              }
            }
          }
        }
      }
    } else {
      for (let [key, segment] of Object.entries(recapDataForCSV)) {
        if (key === RECAP_CATEGORY_TYPE_NETWORTH && selectedChartOptions !== recapChartOptions.NETWORTH.id) {
          continue;
        }
        let isSegmentRow = true;
        if (segment && segment.length) {
          for (const row of segment) {
            if (row.name && row.values && row.values.length) {
              if (!isColumnHeaderRow) {
                const columnHeaderRow = convertToCSVColumnHeaderRow(row.values);
                isColumnHeaderRow = true;
                csvRows.push(columnHeaderRow);
              }
              const dataRow = convertToCSVDataRow(row.name, row.values, isSegmentRow);
              isSegmentRow = false;
              csvRows.push(dataRow);
              isCustodianDataRow = true;
            }
          }
          csvRows.push("");
        }
      }
    }
    if (csvRows.length && isCustodianDataRow) {
      const csvData = [...csvRows].join("\n");
      return csvData;
    }

    return null;
  };

  // const checkIfAChartOptionHasNoDataToShow = (chartOption, timeRange, recapData) => {
  //   if (
  //     chartOption === recapChartOptions.ASSETS_AND_CURRENCY.id ||
  //     chartOption === recapChartOptions.ASSET_CLASSES.id
  //   ) {
  //     const assetsRow = recapData[timeRange][chartOption].totals && recapData[timeRange][chartOption].totals.Assets;
  //     const isValuePresent = assetsRow[0].values.some(data => data.value !== 0);
  //     return !isValuePresent;
  //   } else if (
  //     chartOption === recapChartOptions.STOCKS_AND_MARKETCAP.id ||
  //     chartOption === recapChartOptions.STOCKS_AND_SECTOR.id ||
  //     chartOption === recapChartOptions.STOCKS_AND_GEOGRAPHY.id
  //   ) {
  //     const stocksRow = recapData[timeRange][chartOption].totals && recapData[timeRange][chartOption].totals.Stocks;
  //     const isValuePresent = stocksRow[0].values.some(data => data.value !== 0);
  //     return !isValuePresent;
  //   } else if (chartOption === recapChartOptions.CRYPTO.id) {
  //     const cryptoRow = recapData[timeRange][chartOption].totals && recapData[timeRange][chartOption].totals.Crypto;
  //     const isValuePresent = cryptoRow[0].values.some(data => data.value !== 0);
  //     console.log("is value present", isValuePresent, cryptoRow[0].values);
  //     return !isValuePresent;
  //   } else if (chartOption === recapChartOptions.INVESTABLE.id) {
  //     const investableRow =
  //       recapData[timeRange][chartOption].totals && recapData[timeRange][chartOption].totals["Investable Assets"];
  //     const isValuePresent = investableRow[0].values.some(data => data.value !== 0);
  //     return !isValuePresent;
  //   } else {
  //     return Object.keys(recapData[timeRange][chartOption].totals).length <= 1;
  //   }
  // };

  const handleChartTimeRangeDropDownClick = event => {
    if (timeRangeContextMenuRef.current.isVisible()) {
      timeRangeContextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    const menuItems = getChartTimeRangeMenuItems();
    for (const item of menuItems[0]) {
      if (item.id === selectedTimeRange) {
        item.selected = true;
      }
      if (checkIfTimeRangeHasNoDataToShow(selectedChartOptions, item.id)) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }
    }
    timeRangeContextMenuRef.current.show(
      menuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height,
      true,
      event.target
    );
  };

  const handleChartOptionsDropDownClick = event => {
    if (chartOptionsContextMenuRef.current.isVisible()) {
      chartOptionsContextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    const menuItems = getChartOptionsMenuItems();
    for (const item of menuItems[0]) {
      if (item.id === selectedChartOptions) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      if (checkIfAChartOptionHasNoDataToShow(item.id, selectedTimeRange, recapData)) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }
    }
    chartOptionsContextMenuRef.current.show(
      menuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height,
      true,
      event.target
    );
  };

  const handleChartTypesDropDownClick = event => {
    if (chartTypeContextMenuRef.current.isVisible()) {
      chartTypeContextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    const menuItems = getChartTypeMenuItems();
    for (const item of menuItems[0]) {
      if (item.id === selectedChartType) {
        item.selected = true;
      }
    }
    chartTypeContextMenuRef.current.show(
      menuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height,
      true,
      event.target
    );
  };

  const handleContextMenuSelection = item => {
    if (item.type === "timeRange") {
      const map = userPreferences.portfolioRecapChartTimeRangeMap;
      map[portfolio.id] = item.id;
      dispatch(updateUserPreferences({ portfolioRecapChartTimeRangeMap: map }));
    } else if (item.type === "chartType") {
      const map = userPreferences.portfolioRecapChartTypeMap;
      map[portfolio.id] = item.id;
      dispatch(updateUserPreferences({ portfolioRecapChartTypeMap: map }));
    } else {
      const map = userPreferences.portfolioRecapChartOptionsMap;
      map[portfolio.id] = item.id;
      dispatch(updateUserPreferences({ portfolioRecapChartOptionsMap: map, isRecapSelectedFromMenu: true }));
    }
  };

  const getDownloadFileName = () => {
    let reportOption = selectedChartOptions.charAt(0).toUpperCase() + selectedChartOptions.slice(1);
    const recapChartOptionsArray = Object.values(recapChartOptions);
    const filteredChartOption = recapChartOptionsArray.filter(
      recapChartOption => recapChartOption.id === selectedChartOptions
    );
    if (filteredChartOption.length) {
      reportOption = filteredChartOption[0].fileName;
    }

    const reportTimeRange = selectedTimeRange.charAt(0).toUpperCase() + selectedTimeRange.slice(1);
    const reportChartType = selectedChartType.charAt(0).toUpperCase() + selectedChartType.slice(1);

    const filename = "Recap_" + reportOption + "_" + reportTimeRange + "_" + reportChartType + ".csv";
    return filename;
  };

  const csvData = getFilteredRecapDataInCSV();
  const handleDownloadButtonClick = () => {
    const filename = getDownloadFileName();
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    downloadFile(url, filename);
  };

  const togglePercentageChange = () => {
    const map = userPreferences.portfolioRecapPercentageChangeFlagMap;
    map[portfolio.id] = !map[portfolio.id];
    dispatch(updateUserPreferences({ portfolioRecapPercentageChangeFlagMap: map }));
  };

  return (
    <Container>
      <MenuContainer>
        <Menu>
          <ChartOptionsDropDownButton
            isClickable={true}
            id="reports-dropdwn"
            onClick={event => {
              handleChartOptionsDropDownClick(event);
            }}
          >
            {getCurrentlySelectedChartOptionsText()}
            <ChartOptionsContextMenu
              ref={chartOptionsContextMenuRef}
              onSelection={handleContextMenuSelection}
              hideOnScroll={false}
              style={{ position: "absolute", left: "0px", top: "30px" }}
            ></ChartOptionsContextMenu>
          </ChartOptionsDropDownButton>

          <ChartTimeRangeDropDownButton
            isClickable={true}
            onClick={event => {
              handleChartTimeRangeDropDownClick(event);
            }}
          >
            {getCurrentlySelectedTimeRangeText()}
            <TimeRangeContextMenu
              ref={timeRangeContextMenuRef}
              onSelection={handleContextMenuSelection}
              hideOnScroll={false}
              style={{ position: "absolute", left: "auto", top: "30px" }}
            />
          </ChartTimeRangeDropDownButton>

          <ChartTypeDropDownButton
            isClickable={true}
            onClick={event => {
              handleChartTypesDropDownClick(event);
            }}
          >
            {getCurrentlySelectedChartTypeText()}
            <ChartTypeContextMenu
              ref={chartTypeContextMenuRef}
              onSelection={handleContextMenuSelection}
              hideOnScroll={false}
              style={{ position: "absolute", top: "30px", left: "auto" }}
            />
          </ChartTypeDropDownButton>
        </Menu>
      </MenuContainer>
      <NonMenuContainer>
        {!csvData === false && (
          <CSVDownloadButton onClick={handleDownloadButtonClick}>
            <CSVDownloadIcon />
          </CSVDownloadButton>
        )}
        <PercentageChangeButton
          show={true}
          onClick={() => {
            togglePercentageChange();
          }}
          isPercentageChangeShown={isPercentageChangeShown}
          id="percentage_change_btn"
        >
          {"Show Change"}
        </PercentageChangeButton>
      </NonMenuContainer>
    </Container>
  );
};
export default ColumnarChartMenuComponent;
