import * as Sentry from "@sentry/browser";

import { store, userSelector } from "../redux";
import { getAppVersion } from "./Number";

export function captureError(apiError) {
  let error = apiError;
  if (typeof apiError === "string") {
    error = new Error(apiError);
  } else if (apiError?.errorMessage) {
    error = new Error(apiError.errorMessage);
  }

  Sentry.withScope(function(scope) {
    scope.setTag("api_exception");
    scope.setExtras({
      userId: userSelector(store.getState()) ? userSelector(store.getState()).id : "",
      appVersion: getAppVersion()
    });
    Sentry.captureException(
      error,
      apiError?.errorMessage
        ? {
            extra: {
              ...apiError
            }
          }
        : undefined
    );
  });
}
