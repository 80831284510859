import React, { useState, useEffect, useCallback, Fragment } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { modalValues } from "routes";

import {
  userPreferencesSelector,
  updateUserPreferences,
  accountSubscriptionStatusSelector,
  accountPlanSelector,
  accountCurrentTsSelector,
  accountEndTsSelector,
  getSubscriptionEndDateString,
  accountRemainingDaysSelector,
  subscriptionSelector,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  isMobile,
  isSharedPortfolioUserSelector,
  getAccountOwnerNameSelector,
  maxMultiuserMemberCountSelector,
  multiuserListSelector,
  useHistory,
  isInOptinTrialSelector,
  blackRemainingOptinTrialDaysSelector,
  userSelector,
  isOptinTrialFinishedSelector
} from "@kubera/common";
import Banner from "components/Banner";
import AccountSettingsComponent, { accountSettingsTabs } from "components/account_settings/AccountSettingsComponent";

const isMobileDevice = isMobile();

const SubscribeReminderTxt = styled.span`
  font-size: ${isMobileDevice ? "14px" : "12px"};
  line-height: ${isMobileDevice ? "130%" : null};
  font-weight: ${!isMobileDevice ? "bold" : null};
  cursor: pointer;
  padding: ${isMobileDevice ? "20px 52px 20px 20px" : null};
`;

const SubscribeLink = styled.a`
  color: ${props => props.theme.linkColor};
  text-decoration: underline;
`;

const MobileSpaceContainer = styled.div`
  position: relative;
  height: 120px;
`;

const BannerContainer = isMobileDevice ? MobileSpaceContainer : Fragment;

const SubscriptionBannerComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const user = useSelector(userSelector);
  const userPreferences = useSelector(userPreferencesSelector);
  const accountCurrentTs = useSelector(accountCurrentTsSelector);
  const accountEndTs = useSelector(accountEndTsSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);
  const accountPlan = useSelector(accountPlanSelector);
  const maxMultiuserMemberCount = useSelector(maxMultiuserMemberCountSelector);
  const remainingDays = useSelector(accountRemainingDaysSelector);
  const subscription = useSelector(subscriptionSelector);
  const isSharedPortfolioAccountUser = useSelector(isSharedPortfolioUserSelector);
  const getAccountOwnerName = useSelector(getAccountOwnerNameSelector);
  const multiuserList = useSelector(multiuserListSelector);
  const isInOptinTrial = useSelector(isInOptinTrialSelector);
  const blackRemainingOptinTrialDays = useSelector(blackRemainingOptinTrialDaysSelector);
  const isOptinTrialFinished = useSelector(isOptinTrialFinishedSelector);

  const currentDate = getSubscriptionEndDateString(accountCurrentTs);
  const oneLessEndTs = getSubscriptionEndDateString(accountEndTs - 86400);
  const blackEndTs = getSubscriptionEndDateString((user?.tsExtendedTrialStart || 0) + 86400 * 13);
  const preferenceValue = isInOptinTrial
    ? (blackRemainingOptinTrialDays <= 10 && blackRemainingOptinTrialDays > 5 && 10) ||
      (blackRemainingOptinTrialDays <= 5 && blackRemainingOptinTrialDays > 2 && 5) ||
      (blackRemainingOptinTrialDays >= 1 && 2) ||
      (currentDate === blackEndTs && "tomorrow") ||
      blackRemainingOptinTrialDays
    : (remainingDays <= 10 && remainingDays > 5 && 10) ||
      (remainingDays <= 5 && remainingDays > 2 && 5) ||
      (remainingDays >= 1 && 2) ||
      (currentDate === oneLessEndTs && "tomorrow") ||
      remainingDays;

  const isPastDue = accountSubscriptionStatus === SUBSCRIPTION_STATUS.PAST_DUE;

  const [bannerVisible, setBannerVisible] = useState(false);

  const showSubscriptionEditModal = () => {
    AccountSettingsComponent.show(history, location, accountSettingsTabs.SUBSCRIPTION, modalValues.SUBSCRIPTION_EDIT);
  };

  const showSubscriptionModal = () => {
    AccountSettingsComponent.show(history, location, accountSettingsTabs.SUBSCRIPTION);
  };

  const setBannerPreference = useCallback(
    preference => {
      dispatch(
        updateUserPreferences({
          bannerPreference: preference
        })
      );
    },
    [dispatch]
  );

  const setOptinEndPreference = useCallback(
    preference => {
      dispatch(
        updateUserPreferences({
          optinEndBannerDismissed: true
        })
      );
    },
    [dispatch]
  );

  const onClose = () => {
    setBannerVisible(false);
    setBannerPreference(preferenceValue);
  };

  useEffect(() => {
    let isBannerVisible = false;

    if (isOptinTrialFinished && userPreferences.optinEndBannerDismissed !== true) {
      isBannerVisible =
        accountPlan !== SUBSCRIPTION_PLANS.YEARLY_BLACK && userPreferences.optinEndBannerDismissed !== true;
      setBannerVisible(isBannerVisible);
      return;
    }

    if (isInOptinTrial) {
      // eslint-disable-next-line no-mixed-operators
      isBannerVisible =
        accountPlan !== SUBSCRIPTION_PLANS.YEARLY_BLACK &&
        ((blackRemainingOptinTrialDays <= 10 && blackRemainingOptinTrialDays >= 1) ||
          blackRemainingOptinTrialDays === 2 ||
          blackRemainingOptinTrialDays === 1 ||
          blackRemainingOptinTrialDays <= 0);
    } else {
      // eslint-disable-next-line no-mixed-operators
      isBannerVisible =
        (remainingDays <= 10 && remainingDays >= 1) ||
        remainingDays === 2 ||
        remainingDays === 1 ||
        remainingDays <= 0 ||
        isPastDue;
    }

    const bannerVisibility = userPreferences.bannerPreference !== preferenceValue && isBannerVisible;

    setBannerVisible(bannerVisibility);
  }, [
    remainingDays,
    isPastDue,
    userPreferences.optinEndBannerDismissed,
    userPreferences.bannerPreference,
    accountSubscriptionStatus,
    preferenceValue,
    blackRemainingOptinTrialDays,
    isInOptinTrial,
    accountPlan,
    isOptinTrialFinished
  ]);

  if (
    !subscription === true ||
    // eslint-disable-next-line no-mixed-operators
    !bannerVisible ||
    // eslint-disable-next-line no-mixed-operators
    (!isInOptinTrial &&
      (!isOptinTrialFinished || (isOptinTrialFinished && userPreferences.optinEndBannerDismissed === true)) &&
      (accountSubscriptionStatus === SUBSCRIPTION_STATUS.TRIALING ||
        (accountPlan !== SUBSCRIPTION_PLANS.TRIAL && accountSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE)))
  ) {
    return null;
  }

  if (isPastDue && !isSharedPortfolioAccountUser) {
    return (
      <BannerContainer>
        <Banner onClose={onClose}>
          <SubscribeReminderTxt>
            {i18n.t("banner.subscribePastDue")}
            <SubscribeLink onClick={showSubscriptionModal}>{i18n.t("banner.cardDetailsLink")}</SubscribeLink>
          </SubscribeReminderTxt>
        </Banner>
      </BannerContainer>
    );
  }

  const subAccountStr = !isSharedPortfolioAccountUser
    ? !isInOptinTrial &&
      (!isOptinTrialFinished || (isOptinTrialFinished && userPreferences.optinEndBannerDismissed === true)) &&
      maxMultiuserMemberCount > 0 &&
      multiuserList &&
      multiuserList.length > 1
      ? `.ownerAccount`
      : ""
    : `.subAccount`;

  /***************** START_BLOCK: Black user banner ******************/
  if (isOptinTrialFinished && userPreferences.optinEndBannerDismissed !== true) {
    const bannerPrefix = !isSharedPortfolioAccountUser ? "banner_optinblack" : "banner";
    return (
      <BannerContainer>
        <Banner onClose={setOptinEndPreference}>
          <SubscribeReminderTxt>
            {!isSharedPortfolioAccountUser && (
              <SubscribeLink onClick={showSubscriptionEditModal}>
                {i18n.t("banner_optinblack.subscribeLink")}
              </SubscribeLink>
            )}
            {i18n.t(`${bannerPrefix}${subAccountStr}.subscribeExpired`).replace(/%owner%/, getAccountOwnerName)}
          </SubscribeReminderTxt>
        </Banner>
      </BannerContainer>
    );
  }

  if (isInOptinTrial) {
    const bannerPrefix = !isSharedPortfolioAccountUser ? "banner_optinblack" : "banner";
    if (blackRemainingOptinTrialDays === 1) {
      return (
        <BannerContainer>
          <Banner onClose={onClose}>
            <SubscribeReminderTxt>
              {!isSharedPortfolioAccountUser && (
                <SubscribeLink onClick={showSubscriptionEditModal}>
                  {i18n.t("banner_optinblack.subscribeLink")}
                </SubscribeLink>
              )}
              {i18n.t(`${bannerPrefix}${subAccountStr}.subscribeExpiryToday`).replace(/%owner%/, getAccountOwnerName)}
            </SubscribeReminderTxt>
          </Banner>
        </BannerContainer>
      );
    }

    if (blackRemainingOptinTrialDays <= 10 && blackRemainingOptinTrialDays >= 1) {
      const txt =
        blackRemainingOptinTrialDays !== 1
          ? `${bannerPrefix}${subAccountStr}.subscribeExpiryDays`
          : `${bannerPrefix}${subAccountStr}.subscribeExpiryDay`;
      return (
        <BannerContainer>
          <Banner onClose={onClose}>
            <SubscribeReminderTxt>
              {!isSharedPortfolioAccountUser && (
                <SubscribeLink onClick={showSubscriptionEditModal}>
                  {i18n.t("banner_optinblack.subscribeLink")}
                </SubscribeLink>
              )}
              {i18n
                .t(txt)
                .replace("%s%", blackRemainingOptinTrialDays)
                .replace(/%owner%/, getAccountOwnerName)}
            </SubscribeReminderTxt>
          </Banner>
        </BannerContainer>
      );
    }

    if (blackRemainingOptinTrialDays === 0 && accountSubscriptionStatus !== SUBSCRIPTION_STATUS.EXPIRED) {
      return (
        <BannerContainer>
          <Banner onClose={onClose}>
            <SubscribeReminderTxt>
              {!isSharedPortfolioAccountUser && (
                <SubscribeLink onClick={showSubscriptionEditModal}>
                  {i18n.t("banner_optinblack.subscribeLink")}
                </SubscribeLink>
              )}
              {i18n.t(`${bannerPrefix}${subAccountStr}.subscribeExpiryToday`).replace(/%owner%/, getAccountOwnerName)}
            </SubscribeReminderTxt>
          </Banner>
        </BannerContainer>
      );
    }
  }
  /***************** END_BLOCK: Black user banner ******************/

  if (currentDate === oneLessEndTs) {
    return (
      <BannerContainer>
        <Banner onClose={onClose}>
          <SubscribeReminderTxt>
            {!isSharedPortfolioAccountUser && (
              <SubscribeLink onClick={showSubscriptionModal}>{i18n.t("banner.subscribeLink")}</SubscribeLink>
            )}
            {i18n.t(`banner${subAccountStr}.subscribeExpiryToday`).replace(/%owner%/, getAccountOwnerName)}
          </SubscribeReminderTxt>
        </Banner>
      </BannerContainer>
    );
  }

  if (remainingDays <= 10 && remainingDays >= 1) {
    const txt =
      remainingDays !== 1 ? `banner${subAccountStr}.subscribeExpiryDays` : `banner${subAccountStr}.subscribeExpiryDay`;
    return (
      <BannerContainer>
        <Banner onClose={onClose}>
          <SubscribeReminderTxt>
            {!isSharedPortfolioAccountUser && (
              <SubscribeLink onClick={showSubscriptionModal}>{i18n.t("banner.subscribeLink")}</SubscribeLink>
            )}
            {i18n
              .t(txt)
              .replace("%s%", remainingDays)
              .replace(/%owner%/, getAccountOwnerName)}
          </SubscribeReminderTxt>
        </Banner>
      </BannerContainer>
    );
  }

  if (remainingDays === 0 && accountSubscriptionStatus !== SUBSCRIPTION_STATUS.EXPIRED) {
    return (
      <BannerContainer>
        <Banner onClose={onClose}>
          <SubscribeReminderTxt>
            {!isSharedPortfolioAccountUser && (
              <SubscribeLink onClick={showSubscriptionModal}>{i18n.t("banner.subscribeLink")}</SubscribeLink>
            )}
            {i18n.t(`banner${subAccountStr}.subscribeExpiryToday`).replace(/%owner%/, getAccountOwnerName)}
          </SubscribeReminderTxt>
        </Banner>
      </BannerContainer>
    );
  }

  if (remainingDays <= 0 && accountSubscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED) {
    return (
      <BannerContainer>
        <Banner onClose={onClose}>
          <SubscribeReminderTxt>
            {!isSharedPortfolioAccountUser && (
              <SubscribeLink onClick={showSubscriptionModal}>{i18n.t("banner.subscribeLink")}</SubscribeLink>
            )}
            {i18n.t(`banner${subAccountStr}.subscribeExpired`).replace(/%owner%/, getAccountOwnerName)}
          </SubscribeReminderTxt>
        </Banner>
      </BannerContainer>
    );
  }

  return null;
};

export default SubscriptionBannerComponent;
