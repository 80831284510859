import Amplify, { Auth } from "aws-amplify";
import { store, persistor } from "../redux/store";
import { SIGNOUT_USER, userSetPasswordUpdated, getUniqueSessionId } from "../redux/actions/AuthActions";
import { removePortfolioSessionUserId, removeLastUsedPortfolioUserId } from "../redux/reducers/AuthReducer";
import { getMonthFromDate, getUuid } from "./Number";
import { getQueryParams, isMobile, getOsDetails } from "./Location";
import { ApiClient } from "../api/ApiClient";
import { apiErrorCodes } from "../api/ApiResponse";
import Cookies from "js-cookie";
import { Routes } from "./Navigator";
import {
  siteConfigUserPoolIdSelector,
  siteConfigClientIdSelector,
  accountEndTsSelector,
  accountCurrentTsSelector,
  accountRemainingDaysSelector,
  userPreferencesSelector,
  isInOptinTrialSelector,
  userSelector,
  blackRemainingOptinTrialDaysSelector
} from "../redux/reducers/AuthReducer";
import { RECAP_DATA_STORAGE_KEY_PREFIX } from "../redux";
import { detect } from "detect-browser";

export const getSubscriptionDateString = accountEndTs => {
  const subscriptionExpiryTs = accountEndTs * 1000;
  const date = new Date(subscriptionExpiryTs);
  return `${date.getDate()} ${getMonthFromDate(date)}`;
};

export const getSubscriptionEndDateString = accountEndTs => {
  const subscriptionExpiryTs = accountEndTs * 1000;
  const date = new Date(subscriptionExpiryTs);
  return `${date.getDate()} ${getMonthFromDate(date)} ${date.getFullYear()}`;
};

export const getSubscriptionRemainingDays = (accountCurrentTs, accountEndTs) => {
  const subscriptionCurrentTs = accountCurrentTs * 1000;
  const subscriptionExpiryTs = accountEndTs * 1000;
  const dateCurrentTs = new Date(subscriptionCurrentTs);
  const dateExpiryTs = new Date(subscriptionExpiryTs);
  return Math.ceil((dateExpiryTs.getTime() - dateCurrentTs.getTime()) / 86400000);
};

export const checkIfBannerPreferenceShouldBeSetNull = () => {
  const user = userSelector(store.getState());
  const isInOptinTrial = isInOptinTrialSelector(store.getState());
  const accountEndTs = accountEndTsSelector(store.getState());
  const accountCurrentTs = accountCurrentTsSelector(store.getState());
  const remainingDays = accountRemainingDaysSelector(store.getState());
  const blackRemainingOptinTrialDays = blackRemainingOptinTrialDaysSelector(store.getState());
  const currentDate = getSubscriptionEndDateString(accountCurrentTs);
  const oneLessEndTs = getSubscriptionEndDateString(accountEndTs - 86400);
  const blackEndTs = getSubscriptionEndDateString((user?.tsExtendedTrialStart || 0) + 86400 * 13);
  const userPreferences = userPreferencesSelector(store.getState());
  const preferenceValue = isInOptinTrial
    ? (blackRemainingOptinTrialDays <= 10 && blackRemainingOptinTrialDays > 5 && 10) ||
      (blackRemainingOptinTrialDays <= 5 && blackRemainingOptinTrialDays > 2 && 5) ||
      (blackRemainingOptinTrialDays >= 1 && 2) ||
      (currentDate === blackEndTs && "tomorrow") ||
      blackRemainingOptinTrialDays
    : (remainingDays <= 10 && remainingDays > 5 && 10) ||
      (remainingDays <= 5 && remainingDays > 2 && 5) ||
      (remainingDays >= 1 && 2) ||
      (currentDate === oneLessEndTs && "tomorrow") ||
      remainingDays;
  if (userPreferences.bannerPreference !== null && userPreferences.bannerPreference !== preferenceValue) {
    return true;
  }
  return false;
};

export const getCapitalizedStr = (str, lowerCaseRest = false) => {
  if (!lowerCaseRest) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeStringWithSpaces = name => {
  const parts = name.trim().split(" ");
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    parts[i] = getCapitalizedStr(part);
  }
  return parts.join(" ");
};

export const getCurrentUser = async (onSuccess, onError) => {
  await Auth.currentAuthenticatedUser({
    bypassCache: false
  })
    .then(user => onSuccess(user))
    .catch(err => onError(err));
};

export const isAuthenticatedByThirdPartyProvider = async () => {
  const result = await Auth.currentAuthenticatedUser({
    bypassCache: false
  })
    .then(user => {
      return user.username.toLowerCase().startsWith("google");
    })
    .catch(() => {
      return true;
    });
  return result;
};

export const isUserSignedIn = async () => {
  const result = await Auth.currentSession()
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
  return result;
};

export const getAuthToken = async () => {
  const result = await Auth.currentSession()
    .then(data => {
      return "Bearer " + data.accessToken.jwtToken;
    })
    .catch(() => {
      return "";
    });
  return result;
};

export const getIdToken = async () => {
  const result = await Auth.currentSession()
    .then(data => {
      return data.idToken.jwtToken;
    })
    .catch(() => {
      return "";
    });
  return result;
};

export const getSWConsts = () => {
  return {
    SIGNOUT_USER,
    RECAP_DATA_STORAGE_KEY_PREFIX,
    STORE_PORTFOLIO_DATA: "STORE_PORTFOLIO_DATA",
    GET_CLONE_OBJ: "GET_CLONE_OBJ"
  };
};

export const signOut = async (redirectToLoginScreen = true, locationSearch = null) => {
  const performSignOut = () => {
    Cookies.set("kuberaAppLastAccessSecure", null, {
      sameSite: "Strict",
      secure: true,
      expires: 365,
      domain: ".kubera.com"
    });
    localStorage.setItem("INITIAL_GREETING_SHOWN_KEY", false);
    localStorage.removeItem("remember_me");
    sessionStorage.removeItem("selectedPortfolioIdForConnectivityCenter");

    removePortfolioSessionUserId();
    removeLastUsedPortfolioUserId();
    window.name = null;

    navigator.serviceWorker?.controller?.postMessage({
      type: SIGNOUT_USER,
      SW_CONSTS: getSWConsts()
    });

    persistor.pause();
    persistor
      .flush()
      .then(() => {
        return persistor.purge();
      })
      .then(() => {
        console.log("Redirecting to signout");
        if (redirectToLoginScreen === true) {
          var url = Routes.SIGNOUT;
          if (!locationSearch === false) {
            url += "?" + locationSearch;
          }
          window.location.href = url;
        }
      })
      .catch(error => {
        console.log("Store purge error", error);
      });
  };

  await ApiClient.signOut(getUuid())
    .then(() => {
      return Auth.signOut();
    })
    .then(() => {
      performSignOut();
    })
    .catch(error => {
      console.log("Signout error", error);
      Auth.signOut().then(() => {
        performSignOut();
      });
    });
};

export const isEmailValid = email => {
  const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
  return emailRegex.test(email);
};

export const signInWithGoogle = async () => {
  Auth.federatedSignIn({ provider: "Google" });
};

export const resetRecaptchaV2 = () => {
  if (getRecaptchaTokenType() === "v2") {
    window.grecaptcha.reset();
  }
};

export const signInWithEmailPassword = (email, password, onSuccess, onError, parentAction = "signin") => {
  const { name, version } = detect();
  const isMobileDevice = isMobile();
  const osDetails = getOsDetails();
  const getRecaptchaTokenAndSignIn = ipInfo => {
    getRecaptchaToken("sign_in")
      .then(token => {
        const getToken = () => {
          const params = getQueryParams(window.location);

          if (params?.test !== "true") {
            return token;
          }

          return isV2WidgetLoaded ? token : token + 123;
        };
        Auth.signIn(email, password, {
          parentAction: parentAction,
          recaptchaToken: getToken(),
          type: getRecaptchaTokenType(),
          clientInfo: JSON.stringify({
            browser: { name, version },
            isMobile: isMobileDevice,
            os: osDetails,
            ipInfo: ipInfo && ipInfo.payload
          })
        })
          .then(data => {
            onSuccess(data);
          })
          .catch(error => {
            if (error.code === "UserLambdaValidationException") {
              if (parentAction === "signup") {
                onError(error, true);
                return;
              }
              error.message = "Something’s wrong. Please try again after some time.";
            }
            resetRecaptchaV2();
            onError(error);
          });
      })
      .catch(error => {
        onError(error);
      });
  };
  ApiClient.getIpInfo(getUuid())
    .then(ipInfo => {
      getRecaptchaTokenAndSignIn(ipInfo);
    })
    .catch(() => {
      getRecaptchaTokenAndSignIn();
    });
};

const allowSignupPlanB = true;
export const signUpWithEmailPassword = (name, email, password, invitationId = null, onSuccess, onError) => {
  getRecaptchaToken("sign_up")
    .then(token => {
      const handleError = error => {
        resetRecaptchaV2();
        if (error.code === "NotAuthorizedException") {
          error.message = "Sign Up temporarily paused. We'll open up soon";
        } else if (error.code === "UserLambdaValidationException") {
          error.message = "Something’s wrong. Please try again after some time.";
        }
        onError(error);
      };
      if (allowSignupPlanB) {
        ApiClient.signUp(getUuid(), {
          email,
          name,
          password,
          recaptchaToken: token,
          type: getRecaptchaTokenType()
        })
          .then(({ payload }) => {
            console.log("payload", payload);
            if (payload.error === null) {
              onSuccess(payload);
            } else {
              handleError(payload.error);
            }
          })
          .catch(error => {
            handleError(error);
          });
        return;
      }

      var attributes = {
        email: email,
        name: name
      };
      if (invitationId) {
        attributes["custom:inv_token"] = invitationId;
      }

      Auth.signUp({
        username: email,
        password: password,
        attributes: attributes,
        clientMetadata: {
          parentAction: "signup",
          recaptchaToken: token,
          type: getRecaptchaTokenType()
        }
      })
        .then(data => {
          onSuccess(data);
        })
        .catch(error => {
          handleError(error);
        });
    })
    .catch(error => {
      onError(error);
    });
};

export const confirmSignin = async (user, code, mfaType) => {
  const loggedUser = await Auth.confirmSignIn(
    user, // Return object from Auth.signIn()
    code, // Confirmation code
    mfaType, // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    {
      parentAction: "signin"
    }
  ).catch(err => {
    console.warn(err);
  });

  return loggedUser;
};

export const confirmSignupPlanB = async (
  { name, email, password, verificationCode, invitationId = "" },
  onSuccess,
  onError
) => {
  getRecaptchaToken("sign_up")
    .then(recaptchaToken => {
      ApiClient.signupVerify(getUuid(), {
        name,
        email,
        password,
        verificationCode,
        ...(invitationId && { invitationId }),
        recaptchaToken,
        type: getRecaptchaTokenType()
      })
        .then(data => {
          if (data.payload.error === null) {
            onSuccess(data);
          } else {
            resetRecaptchaV2();
            onError(data.payload.error);
          }
        })
        .catch(error => {
          resetRecaptchaV2();
          onError(error);
        });
    })
    .catch(error => {
      onError(error);
    });
};

export const confirmSignup = async (email, code, onSuccess, onError) => {
  await Auth.confirmSignUp(email, code, {
    forceAliasCreation: true
  })
    .then(data => {
      onSuccess(data);
    })
    .catch(error => {
      onError(error);
    });
};

export const resendSignupCode = async (email, onSuccess, onError) => {
  await Auth.resendSignUp(email)
    .then(data => {
      onSuccess(data);
    })
    .catch(error => {
      onError(error);
    });
};

export const forgotPassword = async (
  email,
  onSuccess,
  onError,
  flag = {
    requestSessionId: false
  }
) => {
  let uniqueSessionId = null;
  if (flag.requestSessionId) {
    uniqueSessionId = await getUniqueSessionId().catch(err => {
      uniqueSessionId = null;
      console.warn(err);
    });

    if (!uniqueSessionId) {
      const error = new Error("Something’s wrong. Please try again after some time.");
      onError(error);
      return;
    }
  }
  getRecaptchaToken("forgot_password")
    .then(token => {
      Auth.forgotPassword(email, { recaptchaToken: token, uniqueSessionId, type: getRecaptchaTokenType() })
        .then(data => {
          onSuccess(data);
        })
        .catch(error => {
          if (error.code === "UserLambdaValidationException") {
            error.message = "Something’s wrong. Please try again after some time.";
          }
          resetRecaptchaV2();
          onError(error);
        });
    })
    .catch(error => {
      onError(error);
    });
};

export const forgotPasswordSubmit = async (email, code, newPassword, onSuccess, onError, clientMetadata) => {
  let isError = false;
  const data = await Auth.forgotPasswordSubmit(email, code, newPassword, clientMetadata).catch(error => {
    isError = true;
    onError(error);
  });

  if (!isError && clientMetadata?.parentAction === "setPassword") {
    await store.dispatch(userSetPasswordUpdated());
  }
  if (!isError) {
    onSuccess(data);
  }
};

export const changePassword = async (currentPassword, newPassword, onSuccess, onError) => {
  await Auth.currentAuthenticatedUser()
    .then(user => {
      return Auth.changePassword(user, currentPassword, newPassword);
    })
    .then(data => {
      onSuccess();
    })
    .catch(error => onError(error));
};

export const createPassword = async (user, newPassword, onSuccess, onError) => {
  await Auth.completeNewPassword(user, newPassword, {}, { parentAction: "newpassword" })
    .then(data => {
      onSuccess();
    })
    .catch(error => onError(error));
};

export const passwordStrengthLevels = {
  WEAK: { color: "rgba(255, 2, 0)" },
  GOOD: { color: "rgba(255, 185, 3)" },
  STRONG: { color: "rgba(2, 185, 86)" }
};

export const getPasswordStrength = pass => {
  if (!pass === true) {
    return passwordStrengthLevels.WEAK;
  }
  var score = 0;

  // award every unique letter until 5 repetitions
  var letters = {};
  for (var i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  var variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  };

  let variationCount = 0;
  for (var check in variations) {
    variationCount += variations[check] === true ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  score = parseInt(score);

  if (score > 80) {
    return passwordStrengthLevels.STRONG;
  } else if (score > 60) {
    return passwordStrengthLevels.GOOD;
  } else {
    return passwordStrengthLevels.WEAK;
  }
};

export const configureAmplify = () => {
  const userPoolId = siteConfigUserPoolIdSelector(store.getState());
  const clientId = siteConfigClientIdSelector(store.getState());

  if (isAppInWhiteLabelMode() === true) {
    const config = {
      Auth: {
        region: "us-east-1",
        userPoolId: userPoolId,
        userPoolWebClientId: clientId,
        mandatorySignIn: false
      }
    };
    try {
      Amplify.configure(config);
    } catch (e) {
      console.log(e);
    }
  } else {
    const signInCallback = process.env.REACT_APP_COGNITO_BASE_URL + process.env.REACT_APP_COGNITO_SIGNIN_CALLBACK_PATH;
    const signOutCallback =
      process.env.REACT_APP_COGNITO_BASE_URL + process.env.REACT_APP_COGNITO_SIGNOUT_CALLBACK_PATH;
    const config = {
      Auth: {
        region: "us-east-1",
        userPoolId: userPoolId,
        userPoolWebClientId: clientId,
        mandatorySignIn: false,
        storage: window.localStorage,
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN,
          scope: ["email", "openid", "profile"],
          redirectSignIn: signInCallback,
          redirectSignOut: signOutCallback,
          responseType: "code"
        }
      }
    };
    try {
      Amplify.configure(config);
    } catch (e) {
      console.log(e);
    }
  }
};

export const getCachedUser = async () => {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: false
  }).catch(err => {
    console.warn(err);
  });

  return user;
};

export const getUserAttributes = async (onSuccess = () => null, onError = () => null) => {
  const user = await getCachedUser();

  if (user) {
    return user.attributes;
  }

  return null;
};

export const updateCurrentUserAttributes = async (attributes, onSuccess, onError) => {
  const user = await getCachedUser();

  await Auth.updateUserAttributes(user, attributes)
    .then(() => onSuccess())
    .catch(err => onError(err));
};

export const verifyPhone = async phone => {
  const user = await getCachedUser();

  await Auth.updateUserAttributes(user, {
    phone_number: "+" + phone.replace(/[\s-()]/g, "")
  });

  return new Promise((resolve, reject) => {
    Auth.verifyCurrentUserAttribute("phone_number")
      .then(() => {
        resolve(phone);
      })
      .catch(err => {
        reject(err.message);
      });
  });
};

export const verifyPhoneCode = async code => {
  const response = await Auth.verifyCurrentUserAttributeSubmit("phone_number", code);

  return response;
};

export const setPreferredMFA = async preference => {
  const user = await getCachedUser();
  const setPref = await Auth.setPreferredMFA(user, preference).catch(err => {
    console.warn(err);
  });

  return setPref;
};

export const getQRStr = async () => {
  const user = await getCachedUser();
  const qrStr = await Auth.setupTOTP(user);

  return qrStr;
};

export const verifyTotpToken = async verifyCode => {
  const user = await getCachedUser();
  const verifyState = await Auth.verifyTotpToken(user, verifyCode).catch(err => {
    console.warn(err);
  });

  return verifyState;
};

export const getPreferredMFA = async () => {
  const user = await getCachedUser();
  const preferredMFA = await Auth.getPreferredMFA(user, {
    bypassCache: false
  }).catch(err => {
    console.warn(err);
  });

  return {
    default: preferredMFA,
    attrs: user.attributes
  };
};

export const getSiteWhiteLabelConfigId = () => {
  // Set Whitelabel mode
  // return "alpha";
  // return "dev";

  return window.getSiteWhiteLabelConfigId();
};

export const isAppInWhiteLabelMode = () => {
  const whiteLabelId = getSiteWhiteLabelConfigId();
  return !whiteLabelId === false;
};

export const isCustomWhiteLabelHost = () => {
  const whiteLabelConfigId = getSiteWhiteLabelConfigId();
  if (!whiteLabelConfigId === true || whiteLabelConfigId.split(".").length === 1) {
    return false;
  }
  return whiteLabelConfigId.endsWith(".kubera.com") === false;
};

const VIEW_MODE_ROUTE = "/view";

export const isAppInViewMode = () => {
  return window.location.pathname.startsWith(VIEW_MODE_ROUTE);
};

const VIEW_ONLY_TOKEN_KEY = "VIEW_ONLY_TOKEN_KEY";

export const setTokenForViewMode = () => {
  if (isAppInViewMode() === false) {
    return;
  }
  const params = getQueryParams(window.location);
  if (!params === true) {
    return;
  }
  const token = params.token || params.t;

  if (!token === false) {
    // Clear stored passcode if different token is being loaded
    const storedToken = sessionStorage.getItem(VIEW_ONLY_TOKEN_KEY);
    if (!storedToken === false && storedToken !== token) {
      setPasscodeForViewMode(null);
      setUserNameForViewMode(null);
    }

    sessionStorage.setItem(VIEW_ONLY_TOKEN_KEY, token);
  }
};

export const getTokenForViewMode = (checkSavedToken = true) => {
  const params = getQueryParams(window.location);
  if (!params === false && (!params.token === false || !params.t === false)) {
    return params.token || params.t;
  }
  const savedToken = sessionStorage.getItem(VIEW_ONLY_TOKEN_KEY);
  if (!savedToken === false) {
    return savedToken;
  }
  return null;
};

const VIEW_ONLY_PASSCODE_KEY = "VIEW_ONLY_PASSCODE_KEY";

export const setPasscodeForViewMode = passcode => {
  if (isAppInViewMode() === false) {
    return;
  }
  if (!passcode === true) {
    sessionStorage.removeItem(VIEW_ONLY_PASSCODE_KEY);
  } else {
    sessionStorage.setItem(VIEW_ONLY_PASSCODE_KEY, passcode);
  }
};

export const getPasscodeForViewMode = () => {
  if (isAppInViewMode() === false) {
    return null;
  }
  return sessionStorage.getItem(VIEW_ONLY_PASSCODE_KEY);
};

const VIEW_ONLY_USER_NAME = "VIEW_ONLY_USER_NAME";

export const setUserNameForViewMode = userName => {
  if (isAppInViewMode() === false) {
    return;
  }
  if (!userName === true) {
    sessionStorage.removeItem(VIEW_ONLY_USER_NAME);
  } else {
    sessionStorage.setItem(VIEW_ONLY_USER_NAME, userName);
  }
};

export const getUserNameForViewMode = () => {
  if (isAppInViewMode() === false) {
    return null;
  }
  return sessionStorage.getItem(VIEW_ONLY_USER_NAME);
};

export const showPasscodeScreen = () => {
  var url = Routes.PASSCODE;
  window.location.href = url + `?t=${getTokenForViewMode()}`;
};

let recaptchaV2Token = null;
let isV2WidgetLoaded = false;

window.verifyRecaptchaV2Callback = function(token) {
  recaptchaV2Token = token;
};

export const showRecaptchaV2Widget = async onCheckCallback => {
  if (!process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2) return;

  try {
    recaptchaV2Token = null;
    window.grecaptcha.render("html_element", {
      sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2,
      callback: token => {
        verifyRecaptchaV2Callback(token);
        onCheckCallback();
      }
    });
    isV2WidgetLoaded = true;
  } catch (_) {}
};

export const getRecaptchaTokenType = () => {
  return isV2WidgetLoaded ? "v2" : "v3";
};

export const getRecaptchaToken = action => {
  return new Promise((resolve, reject) => {
    if (!window.grecaptcha === true) {
      const error = new Error("reCAPTCHA not loaded");
      reject(error);
      return;
    }

    if (isV2WidgetLoaded && recaptchaV2Token) {
      resolve(recaptchaV2Token);
    } else if (isV2WidgetLoaded) {
      const error = {
        code: "UserLambdaValidationException",
        message: "Please complete the reCAPTCHA verification before submitting the form."
      };
      reject(error);
    }
    window.grecaptcha.ready(function() {
      try {
        window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: action }).then(function(token) {
          resolve(token);
        });
      } catch (error) {
        error.message = "reCAPTCHA Error";
        reject(error);
      }
    });
  });
};
