import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import {
  currentPortfolioSelector,
  formatNumberWithCurrency,
  getExchangeRate,
  getTickerUsingShortName,
  getTickerUsingId,
  isCryptoCurrency,
  calcCustodianOwnershipValue
} from "@kubera/common";

import { category } from "components/dashboard/DashboardComponent";
import AutocompleteSearchInp from "components/inputs/AutocompleteSearchInp";
import { filterDataFromPortfolioDetails } from "components/grid/GridDataModel";

const MortgageListItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 15px 13px;
  cursor: pointer;
  box-sizing: border-box;

  &.is-active {
    background: ${props => props.theme.contextMenuItemSelectedBackgroundColor};
  }
`;

const MortgageListValue = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 4px;
`;

const MortgageListName = styled.div`
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
`;

const TickerInput = styled(AutocompleteSearchInp)`
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;

  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }

  > label div:nth-child(1) {
    visibility: hidden;
    margin-right: -30px;
  }

  > label {
    height: 45px;
    border: ${props => (props.isError ? "1px solid #ff0000" : "1px solid rgba(0, 0, 0, 0.4)")};
  }

  ${props =>
    props.isCurrencySearch &&
    css`
      > div {
        height: 40px;
        border: ${props => (props.isError ? "1px solid #ff0000" : "1px solid rgba(0, 0, 0, 0.4)")};

        div {
          border-color: ${props => (props.isError ? "#ff0000" : "rgba(0, 0, 0, 0.4)")} !important;
        }
      }
    `}

  input {
    padding: 18px 14px 15px;
  }

  ul {
    max-height: 234px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: auto;
    box-sizing: border-box;
  }
`;

const MortgageInputList = ({
  index,
  isValueLoading,
  inputPlaceholder,
  inputVal,
  ticker,
  onTickerQuantityChange,
  onMortgageSelect,
  isAutofocusDisabled = true,
  isLoading = false,
  onBlur = () => null,
  disabled = false,
  onKeyDown = () => {},
  excludeIds = ""
}) => {
  const portfolio = useSelector(currentPortfolioSelector);

  const [bankDebtData, setBankDebtData] = useState({
    sheets: [],
    sections: [],
    rows: []
  });

  const getRenderText = item => {
    const portfolioValueTickerId = getTickerUsingShortName(ticker.currency).id;

    const debtLinkedRow = item || {
      value: 0,
      valueTickerId: portfolioValueTickerId
    };

    const debtExchangeRate = getExchangeRate(
      getTickerUsingId(debtLinkedRow.valueTickerId || portfolioValueTickerId).shortName,
      ticker.currency
    );

    return formatNumberWithCurrency(
      calcCustodianOwnershipValue(item.value, item.ownership) * debtExchangeRate,
      ticker.currency,
      undefined,
      isCryptoCurrency(ticker.currency) ? 4 : undefined
    );
  };

  const onMortageRenderList = item => {
    if (inputVal || isValueLoading || item.value === null) {
      return null;
    }

    return (
      <MortgageListItem key={item.id}>
        <MortgageListValue>{getRenderText(item)}</MortgageListValue>
        <MortgageListName>{item.name}</MortgageListName>
      </MortgageListItem>
    );
  };

  const handleOnKeyDown = e => {
    onKeyDown(e);
  };

  useEffect(() => {
    setBankDebtData(
      filterDataFromPortfolioDetails(
        portfolio.details,
        custodian => {
          return (
            custodian.linkType !== void 0 &&
            !excludeIds?.split(",").includes(custodian.id) &&
            (custodian.linkType === 0 || (custodian.linkType !== 0 && custodian.linkContainer !== "creditCard"))
          );
        },
        void 0,
        sheet => sheet.category === category.DEBT
      )
    );
  }, [portfolio.details, excludeIds]);

  return (
    <TickerInput
      disabled={disabled}
      inputPlaceholder={inputPlaceholder}
      searchValue={inputVal}
      options={bankDebtData.rows}
      onSearchChange={(value, exchangeRate) => {
        onTickerQuantityChange(value, index, exchangeRate);
      }}
      onRenderList={item => onMortageRenderList(item)}
      onSelect={item => {
        onMortgageSelect(item, index, ticker);
      }}
      selectedIndex={-1}
      preventCloseOnBlur={false}
      isAutofocusDisabled={isAutofocusDisabled}
      isLoading={isLoading}
      onBlur={onBlur}
      onKeyDown={handleOnKeyDown}
      isCurrencySearch
    />
  );
};

export default MortgageInputList;
