/*eslint eqeqeq: "off"*/
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { hashParams } from "routes";
import { withRouter } from "@kubera/common";
import { useTheme, dialogGridTheme } from "theme";
import {
  currentPortfolioCurrencySelector,
  getCustodianValueChartTimeRange,
  currentPortfolioSelector,
  convertCurrency,
  chartTimeRange,
  getMonthFromDate,
  updateUserPreferences,
  recapChartOptions,
  recapReportNameSelector,
  recapReportValueSelector,
  recapReportLineChartDataSelector,
  parseParams,
  getRecapChartOptionFromId,
  RECAP_CATEGORY_TYPE_ASSET,
  RECAP_CATEGORY_TYPE_DEBT,
  getContentsTabTitle,
  getComparisonReportsTabTitle,
  RECAP_CATEGORY_TYPE_NETWORTH,
  chartIdsForSelectedCustodianSelector,
  updateDashboardCharts,
  chartStyle,
  showToastTip,
  chartKeyParams,
  createChartIdUsingReportId,
  getColumnForChart,
  store,
  portfolioSavedChartsSelector,
  isAppInViewMode,
  reportPaths,
  sheetAndSectionReportNodeSelector,
  shareCapabilities,
  siteConfigSelector,
  getChartLabelFromId,
  checkIfAChartOptionHasNoDataToShow,
  recapDataSelector,
  recapReportNodeSelector,
  recapDataCurrencySelector,
  getDateInKuberaFormat,
  getSheetsAndSectionsSankeyChartDataFromRecap,
  shortFormatNumberWithCurrency
} from "@kubera/common";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ContentsTabComponent from "./ContentsTabComponent";
import ReportsTabComponent from "./ReportsTabComponent";
import i18n from "i18next";
import LineChartComponent from "components/charts/LineChartComponent";
import ChangeLabel from "components/labels/ChangeLabel";
import { ReactComponent as DownArrowIcon } from "assets/images/menu_downarrow.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import CurrencyLabel from "components/labels/CurrencyLabel";
import emptyLineChartBackground from "assets/images/details_line_chart_empty.png";
import { routes } from "routes";
import { MIN_CHART_DATA_POINTS } from "components/net_worth/NetWorthComponent";
import SankeyChartComponent from "components/charts/SankeyChartComponent";

const ChartsOverlay = styled(DialogOverlay)``;

const EmptyLineChartContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url(${emptyLineChartBackground});
  background-repeat: round;
  background-position: top;
  background-size: cover;
`;

const EmptyChartMessage = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: -25px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
`;

const NetWorthLineChart = styled(LineChartComponent)`
  height: 100%;
`;

const DetailsDialog = styled(Dialog)`
  width: ${props => `${props.width}px`};
  min-height: 633px;
  display: flex;
  flex: 1;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const DetailsContainer = styled.div`
  display: flex;
  margin: 50px 60px 50px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;
const CustodianMetaDataContainer = styled.div`
  display: flex;
  flex : 1
  flex-direction: column;
`;

const CustodianTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CustodianTitle = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
  padding: 0;
  word-break: break-all;
`;

const ReportsTabs = styled(Tabs)`
  margin-top: 34px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const ReportsTabList = styled(TabList)`
  display: ${props => (props.show ? "flex" : "none")};
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
  padding-bottom: 8px;
`;

const ReportsTab = styled(Tab)`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.35);
  padding-bottom: 8px;
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;

  &.is-selected {
    color: black;
    border-bottom: 2px solid rgba(0, 0, 0, 1);
  }
`;

const ReportsTabPanel = styled(TabPanel)`
  margin-top: -10px;
  display: none;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const ChartDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const ChartHeader = styled.div`
  display: flex;
  //margin-top: 18px;
`;

const ChartChangeContainer = styled.div`
  visibility: ${props => (!props.hidden === false ? "hidden" : "visible")};
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

const ChartChangeTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
`;

const ChartChangeLabel = styled(ChangeLabel)`
  margin-top: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;

  .child-container {
    justify-content: flex-start;
  }
`;

const SelectTimeRangeButtonContainer = styled.div`
  visibility: ${props => (!props.hidden === false ? "hidden" : "visible")};
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const SelectTimeRangeButton = styled.button`
  background-color: transparent;
  height: 16px;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  text-transform: uppercase;
  padding: 12px 6px;
`;

const SelectTimeRangeButtonIcon = styled(DownArrowIcon)`
  margin-left: 3px;
  margin-bottom: 1px;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 120px;
  margin-top: 10px;
  .chartjs-buffer {
    margin-top: -3px;
  }
`;

const ValueAndOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

const ValueContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const Value = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
`;

const ValueChangeLabel = styled(ChangeLabel)`
  margin-left: 8px;
  margin-top: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const ReportsDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddToDashboardContextMenu = styled(ContextMenu)``;

const AddToDashboardButton = styled.button`
  background-color: transparent;
  padding: 12px 6px;
  border: 0;
  outline: 0;
  margin-left: 30px;
  padding-bottom: 3px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  text-transform: uppercase;
`;

const DropDownIcon = styled(DownArrowIcon)`
  margin-left: 3px;
  margin-bottom: 1px;
`;

const GoToRecapButton = styled.button`
  background-color: transparent;
  padding: 12px 6px;
  border: 0;
  outline: 0;
  padding-bottom: 3px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  text-transform: uppercase;
`;

const SankeyChart = styled(SankeyChartComponent)`
  margin-top: 40px;
`;

const ChartsModalComponent = (props, ref) => {
  const reportId = props.reportId;
  const reportParams = parseParams(reportId);
  const reportName = useSelector(state => recapReportNameSelector(state, reportId));
  const reportCurrentValue = useSelector(state => recapReportValueSelector(state, reportId));
  const reportLineChartData = useSelector(state => recapReportLineChartDataSelector(state, reportId));
  const recapCurrency = useSelector(recapDataCurrencySelector);
  const selectedChartOptions = reportParams[hashParams.CHART_OPTION];
  const selectedTimeRange = reportParams[hashParams.CHART_TIMERANGE];
  const selectedChartType = reportParams[hashParams.CHART_TYPE];
  const reportPath = reportParams[hashParams.REPORT_PATH];
  const reportNodeId = reportParams[hashParams.REPORT_NODE_ID];
  const dispatch = useDispatch();
  const theme = useTheme();
  const dialogTheme = dialogGridTheme(theme.mode);
  const selectedChartTimeRange = useSelector(getCustodianValueChartTimeRange);
  const currentPortfolio = useSelector(currentPortfolioSelector);
  const portfolioCurrency = useSelector(currentPortfolioCurrencySelector);
  const siteConfig = useSelector(siteConfigSelector);
  const recapDisabled =
    siteConfig.capability && JSON.parse(siteConfig.capability).includes(shareCapabilities.RECAP) === false;
  const savedCharts = portfolioSavedChartsSelector(currentPortfolio);

  const reportDetailsTabs = {
    CONTENTS: "ContentsTabComponent",
    REPORTS: "ReportsTabComponent"
  };
  const [tabIndex, setTabIndex] = useState(Number(props.tabIndex || 0));
  const [currentChartDataPointHoverIndex, setCurrentChartDataPointHoverIndex] = useState(null);

  const chartContextMenuRef = React.createRef();
  const addToDashboardContextMenuRef = React.createRef();
  const handleOverlayDismiss = () => {
    DialogOverlay.dismiss(props.history, props.location);
  };

  const setTab = tabIndex => {
    setTabIndex(tabIndex);
  };

  const handleGoToRecapButtonClick = () => {
    props.history.push(`${process.env.PUBLIC_URL}${routes.REPORTS}#${reportId}`);
  };

  const handleReportNameClick = (selectedSectionName, type, selectedSectionId, selectedSheetId, category) => {
    if (recapDisabled === true) {
      return;
    }
    if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      if (type === "header") {
        const updatedReportId = `${decodeURIComponent(reportId)}&${
          hashParams.SELECTED_SECTION_ID
        }=${selectedSectionId}&${hashParams.SELECTED_SHEET_ID}=${selectedSheetId}&${
          hashParams.SELECTED_FROM_CHARTS_MODAL
        }=${true}&${hashParams.CATEGORY}=${category}`;
        dispatch(updateUserPreferences({ isRecapSelectedFromMenu: false }));
        props.history.push(`${process.env.PUBLIC_URL}${routes.REPORTS}#${updatedReportId}`);
      } else if (type === "sheetHeader") {
        const updatedReportId = `${decodeURIComponent(reportId)}&${
          hashParams.SELECTED_SECTION_ID
        }=${selectedSectionId}&${hashParams.SELECTED_SHEET_ID}=${selectedSheetId}&${
          hashParams.SELECTED_FROM_CHARTS_MODAL
        }=${true}&${hashParams.CATEGORY}=${category}&${hashParams.ROW_CATEGORY}=${type}`;
        dispatch(updateUserPreferences({ isRecapSelectedFromMenu: false }));
        props.history.push(`${process.env.PUBLIC_URL}${routes.REPORTS}#${updatedReportId}`);
      }
    } else {
      if (type === "header") {
        const updatedReportId = `${decodeURIComponent(reportId)}&${
          hashParams.SELECTED_SECTION_NAME
        }=${selectedSectionName}&${hashParams.SELECTED_FROM_CHARTS_MODAL}=${true}`;
        dispatch(updateUserPreferences({ isRecapSelectedFromMenu: false }));
        props.history.push(`${process.env.PUBLIC_URL}${routes.REPORTS}#${updatedReportId}`);
      }
    }
  };

  const getReportsTabs = () => {
    const reportTabs = [];
    const reportsTabPanel = (
      <ReportsTabComponent
        reportId={reportId}
        dialogTheme={dialogTheme}
        handleReportNameClick={handleReportNameClick}
      />
    );

    const cashOnHandToTotalAssetPanel = (
      <ReportsTabComponent
        reportId={reportId}
        dialogTheme={dialogTheme}
        shouldCompareAgainstInvestableAssets={true}
        handleReportNameClick={handleReportNameClick}
      />
    );

    const toSheetsPanel = (
      <ReportsTabComponent
        reportId={reportId}
        dialogTheme={dialogTheme}
        shouldCompareAgainstSheet={true}
        handleReportNameClick={handleReportNameClick}
      />
    );

    const toInvestableAssetsPanel = (
      <ReportsTabComponent
        reportId={reportId}
        dialogTheme={dialogTheme}
        shouldCompareAgainstInvestableAssets={true}
        handleReportNameClick={handleReportNameClick}
      />
    );

    const toTotalAssetsOrDebtspanel = (
      <ReportsTabComponent
        reportId={reportId}
        dialogTheme={dialogTheme}
        shouldCompareAgainstTotalAssetsOrDebts={true}
        handleReportNameClick={handleReportNameClick}
      />
    );
    if (
      selectedChartOptions === recapChartOptions.CASH_ON_HAND.id &&
      reportParams[hashParams.REPORT_NODE_ID] === "Cash on hand"
    ) {
      reportTabs.push({
        title: getComparisonReportsTabTitle(
          reportName,
          reportParams[hashParams.REPORT_NODE_ID],
          reportParams[hashParams.REPORT_PATH],
          selectedChartOptions,
          true,
          reportId
        ),
        key: reportDetailsTabs.REPORTS,
        panelComponent: cashOnHandToTotalAssetPanel
      });
    } else if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id) {
      if (reportPath === reportPaths.ASSETS_ROW) {
        // ad to toal assets and to sheet

        reportTabs.push({
          title: getComparisonReportsTabTitle(
            reportName,
            reportParams[hashParams.REPORT_NODE_ID],
            reportParams[hashParams.REPORT_PATH],
            selectedChartOptions,
            false,
            reportId,
            true
          ),
          key: reportDetailsTabs.REPORTS,
          panelComponent: reportsTabPanel
        });

        // check if `to sheets` tab is already present or not

        const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, true);
        const shouldAddToSheetsTab = parentNode.type && parentNode.type === "header";
        if (shouldAddToSheetsTab) {
          reportTabs.push({
            title: getComparisonReportsTabTitle(
              reportName,
              reportParams[hashParams.REPORT_NODE_ID],
              reportParams[hashParams.REPORT_PATH],
              selectedChartOptions,
              false,
              reportId,
              true,
              false,
              true
            ),
            key: reportDetailsTabs.REPORTS,
            panelComponent: toSheetsPanel
          });
        }
        reportTabs.push({
          title: getComparisonReportsTabTitle(
            reportName,
            reportParams[hashParams.REPORT_NODE_ID],
            reportParams[hashParams.REPORT_PATH],
            selectedChartOptions,
            true,
            reportId,
            true
          ),
          key: reportDetailsTabs.REPORTS,
          panelComponent: toInvestableAssetsPanel
        });

        reportTabs.push({
          title: getComparisonReportsTabTitle(
            reportName,
            reportParams[hashParams.REPORT_NODE_ID],
            reportParams[hashParams.REPORT_PATH],
            selectedChartOptions,
            false,
            reportId,
            true,
            true
          ),
          key: reportDetailsTabs.REPORTS,
          panelComponent: toTotalAssetsOrDebtspanel
        });
      } else if (reportPath === reportPaths.DEBTS_ROW) {
        reportTabs.push({
          title: getComparisonReportsTabTitle(
            reportName,
            reportParams[hashParams.REPORT_NODE_ID],
            reportParams[hashParams.REPORT_PATH],
            selectedChartOptions,
            false,
            reportId,
            true
          ),
          key: reportDetailsTabs.REPORTS,
          panelComponent: reportsTabPanel
        });
        // check if `to sheets` tab is already present or not

        const parentNode = sheetAndSectionReportNodeSelector(store.getState(), reportId, null, true);
        const shouldAddToSheetsTab = parentNode.type && parentNode.type === "header";
        if (shouldAddToSheetsTab) {
          reportTabs.push({
            title: getComparisonReportsTabTitle(
              reportName,
              reportParams[hashParams.REPORT_NODE_ID],
              reportParams[hashParams.REPORT_PATH],
              selectedChartOptions,
              false,
              reportId,
              true,
              false,
              true
            ),
            key: reportDetailsTabs.REPORTS,
            panelComponent: toSheetsPanel
          });
        }
        reportTabs.push({
          title: getComparisonReportsTabTitle(
            reportName,
            reportParams[hashParams.REPORT_NODE_ID],
            reportParams[hashParams.REPORT_PATH],
            selectedChartOptions,
            false,
            reportId,
            true,
            true
          ),
          key: reportDetailsTabs.REPORTS,
          panelComponent: toTotalAssetsOrDebtspanel
        });
      } else if (reportPath === reportPaths.ASSETS_SECTION || reportPath === reportPaths.DEBTS_SECTION) {
        reportTabs.push({
          title: getComparisonReportsTabTitle(
            reportName,
            reportParams[hashParams.REPORT_NODE_ID],
            reportParams[hashParams.REPORT_PATH],
            selectedChartOptions,
            false,
            reportId,
            true
          ),
          key: reportDetailsTabs.REPORTS,
          panelComponent: reportsTabPanel
        });
        if (reportPath === reportPaths.ASSETS_SECTION) {
          reportTabs.push({
            title: getComparisonReportsTabTitle(
              reportName,
              reportParams[hashParams.REPORT_NODE_ID],
              reportParams[hashParams.REPORT_PATH],
              selectedChartOptions,
              true,
              reportId,
              true
            ),
            key: reportDetailsTabs.REPORTS,
            panelComponent: toInvestableAssetsPanel
          });
        }

        reportTabs.push({
          title: getComparisonReportsTabTitle(
            reportName,
            reportParams[hashParams.REPORT_NODE_ID],
            reportParams[hashParams.REPORT_PATH],
            selectedChartOptions,
            false,
            reportId,
            true,
            true
          ),
          key: reportDetailsTabs.REPORTS,
          panelComponent: toTotalAssetsOrDebtspanel
        });
      } else if (reportPath === reportPaths.ASSETS_SHEET || reportPath === reportPaths.DEBTS_SHEETS) {
        if (reportPath === reportPaths.ASSETS_SHEET) {
          reportTabs.push({
            title: getComparisonReportsTabTitle(
              reportName,
              reportParams[hashParams.REPORT_NODE_ID],
              reportParams[hashParams.REPORT_PATH],
              selectedChartOptions,
              true,
              reportId,
              true
            ),
            key: reportDetailsTabs.REPORTS,
            panelComponent: toInvestableAssetsPanel
          });
        }
        reportTabs.push({
          title: getComparisonReportsTabTitle(
            reportName,
            reportParams[hashParams.REPORT_NODE_ID],
            reportParams[hashParams.REPORT_PATH],
            selectedChartOptions,
            false,
            reportId,
            true
          ),
          key: reportDetailsTabs.REPORTS,
          panelComponent: reportsTabPanel
        });
      }
    } else {
      reportTabs.push({
        title: getComparisonReportsTabTitle(
          reportName,
          reportParams[hashParams.REPORT_NODE_ID],
          reportParams[hashParams.REPORT_PATH],
          selectedChartOptions,
          false,
          reportId,
          true
        ),
        key: reportDetailsTabs.REPORTS,
        panelComponent: reportsTabPanel
      });
      if (selectedChartOptions === recapChartOptions.INVESTABLE_WITHOUT_CASH.id && reportPath) {
        reportTabs.push({
          title: getComparisonReportsTabTitle(
            reportName,
            reportParams[hashParams.REPORT_NODE_ID],
            reportParams[hashParams.REPORT_PATH],
            selectedChartOptions,
            false,
            reportId,
            true,
            false,
            false,
            true
          ),
          key: reportDetailsTabs.REPORTS,
          panelComponent: (
            <ReportsTabComponent
              reportId={reportId}
              dialogTheme={dialogTheme}
              shouldCompareAgainstInvestableAssetsWithOutCash={true}
              handleReportNameClick={handleReportNameClick}
            />
          )
        });
      }
      if (selectedChartOptions === recapChartOptions.INVESTABLE.id && reportPath) {
        reportTabs.push({
          title: getComparisonReportsTabTitle(
            reportName,
            reportParams[hashParams.REPORT_NODE_ID],
            reportParams[hashParams.REPORT_PATH],
            selectedChartOptions,
            true,
            reportId,
            true
          ),
          key: reportDetailsTabs.REPORTS,
          panelComponent: (
            <ReportsTabComponent
              reportId={reportId}
              dialogTheme={dialogTheme}
              shouldCompareAgainstInvestableAssets={true}
              handleReportNameClick={handleReportNameClick}
            />
          )
        });
      }
    }
    return reportTabs;
  };

  const getTabs = (custodianName, custodianValue) => {
    const contentsTabPanel = (
      <ContentsTabComponent
        reportId={reportId}
        dialogTheme={dialogTheme}
        handleReportNameClick={handleReportNameClick}
      />
    );
    let tabs = [];
    const showContentsTab = shouldShowContentsTab();
    const showReportsTab = shouldShowReportsTab();
    if (showContentsTab) {
      tabs.push({
        title: getContentsTabTitle(
          selectedChartOptions,
          reportParams[hashParams.REPORT_NODE_ID],
          reportParams[hashParams.REPORT_PATH],
          reportName,
          reportId,
          true
        ),
        key: reportDetailsTabs.CONTENTS,
        panelComponent: contentsTabPanel
      });
    }
    if (shouldShowInvestableAssetsBySheetsTab()) {
      tabs.push({
        title: "Investable Assets x Sheets",
        key: reportDetailsTabs.CONTENTS,
        panelComponent: (
          <ContentsTabComponent
            reportId={reportId}
            dialogTheme={dialogTheme}
            handleReportNameClick={handleReportNameClick}
            isInvestableAssetsBySheetsAndSectionsChart={true}
          />
        )
      });
    }
    if (shouldShowInvestableAssetsBySectionsTab()) {
      tabs.push({
        title: "Investable Assets x Sections",
        key: reportDetailsTabs.CONTENTS,
        panelComponent: (
          <ContentsTabComponent
            reportId={reportId}
            dialogTheme={dialogTheme}
            handleReportNameClick={handleReportNameClick}
            isInvestableAssetsBySectionsChart={true}
          />
        )
      });
    }
    if (shouldShowInvestableAssetsWithoutCashBySheetsTab()) {
      tabs.push({
        title: "Investable Assets ex Cash x Sheets",
        key: reportDetailsTabs.CONTENTS,
        panelComponent: (
          <ContentsTabComponent
            reportId={reportId}
            dialogTheme={dialogTheme}
            handleReportNameClick={handleReportNameClick}
            isInvestableAssetsWithoutCashBySheetsAndSectionsChart={true}
          />
        )
      });
    }
    if (shouldShowInvestableAssetsWithoutcashBySectionsTab()) {
      tabs.push({
        title: "Investable Assets ex Cash x Sections",
        key: reportDetailsTabs.CONTENTS,
        panelComponent: (
          <ContentsTabComponent
            reportId={reportId}
            dialogTheme={dialogTheme}
            handleReportNameClick={handleReportNameClick}
            isInvestableAssetsWithoutCashBySectionsChart={true}
          />
        )
      });
    }
    if (shouldShowAssetsBySectionsTab()) {
      tabs.push({
        title: "Assets x Sections",
        key: reportDetailsTabs.CONTENTS,
        panelComponent: (
          <ContentsTabComponent
            reportId={reportId}
            dialogTheme={dialogTheme}
            handleReportNameClick={handleReportNameClick}
            isAssetsBySectionsChart={true}
          />
        )
      });
    }
    if (showReportsTab) {
      const reportsTabs = getReportsTabs();
      tabs = [...tabs, ...reportsTabs];
    }
    return tabs;
  };

  const shouldShowContentsTab = () => {
    return selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id
      ? !reportParams[hashParams.REPORT_PATH] === true ||
          reportParams[hashParams.REPORT_PATH].endsWith("/rows") === false
      : !reportParams[hashParams.REPORT_PATH] === true;
  };

  const shouldShowReportsTab = () => {
    const reportId = reportParams[hashParams.REPORT_NODE_ID];
    const selectedRecapOption = getRecapChartOptionFromId(selectedChartOptions);
    if (
      reportId !== RECAP_CATEGORY_TYPE_ASSET &&
      reportId !== RECAP_CATEGORY_TYPE_DEBT &&
      reportId !== RECAP_CATEGORY_TYPE_NETWORTH &&
      reportId !== "Brokerages" &&
      reportId !== "Assets x Taxability"
    ) {
      if (reportId === "Stocks" || reportId === "Stocks & Funds") {
        if (
          selectedRecapOption.id === recapChartOptions.INVESTABLE.id ||
          selectedRecapOption.id === recapChartOptions.ASSET_CLASSES.id ||
          selectedRecapOption.id === recapChartOptions.INVESTABLE_WITHOUT_CASH.id
        ) {
          return true;
        }
        return false;
      }
      if (reportId === "Crypto" || reportId === "Cash on hand" || reportId === "Investable Assets") {
        return true;
      }
      if (
        reportId.toLowerCase() !== selectedRecapOption.label.toLowerCase() &&
        selectedRecapOption.id !== recapChartOptions.STOCKS_AND_SECTOR.id &&
        selectedRecapOption.id !== recapChartOptions.STOCKS_AND_GEOGRAPHY.id &&
        selectedRecapOption.id !== recapChartOptions.STOCKS_AND_MARKETCAP.id
      ) {
        return true;
      }
      return true;
    }
    return false;
  };

  const shouldShowInvestableAssetsBySheetsTab = () => {
    return (
      selectedChartOptions === recapChartOptions.INVESTABLE.id && reportParams.report_node_id === "Investable Assets"
    );
  };

  const shouldShowAssetsBySectionsTab = () => {
    if (selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id && reportParams.report_node_id === "Assets") {
      const node = recapReportNodeSelector(
        store.getState(),
        reportId,
        true,
        chartTimeRange.TODAY,
        false,
        false,
        false,
        false,
        true
      );
      const isAllSheetsHaveOneSection = node && node.sheets.every(sheet => sheet.sections.length === 1);
      // do not show assets by sections report if all sheets have only one section
      return !isAllSheetsHaveOneSection;
    }
    return false;
  };

  const shouldShowInvestableAssetsBySectionsTab = () => {
    if (
      selectedChartOptions === recapChartOptions.INVESTABLE.id &&
      reportParams.report_node_id === "Investable Assets"
    ) {
      const node = recapReportNodeSelector(store.getState(), reportId, true, chartTimeRange.TODAY, false, true);
      const isAllSheetsHaveOneSection = node && node.sheets.every(sheet => sheet.sections.length === 1);
      // do not show investable assets by sections report if all sheets have only one section
      return !isAllSheetsHaveOneSection;
    }
    return false;
  };

  const shouldShowInvestableAssetsWithoutcashBySectionsTab = () => {
    if (
      selectedChartOptions === recapChartOptions.INVESTABLE_WITHOUT_CASH.id &&
      reportParams.report_node_id === "Investable Assets ex Cash"
    ) {
      const node = recapReportNodeSelector(store.getState(), reportId, true, chartTimeRange.TODAY, false, true);
      const isAllSheetsHaveOneSection = node && node.sheets.every(sheet => sheet.sections.length === 1);
      // do not show investable assets by sections report if all sheets have only one section
      return !isAllSheetsHaveOneSection;
    } else {
      return false;
    }
  };

  const shouldShowInvestableAssetsWithoutCashBySheetsTab = () => {
    return (
      selectedChartOptions === recapChartOptions.INVESTABLE_WITHOUT_CASH.id &&
      reportParams.report_node_id === "Investable Assets ex Cash"
    );
  };

  const showReportDetails = (shouldShowContentsTab, showReportsTab, tabs) => {
    if (selectedChartOptions === recapChartOptions.NETWORTH.id && reportNodeId === RECAP_CATEGORY_TYPE_NETWORTH) {
      const recapData = recapDataSelector(store.getState());
      if (!recapData === true) {
        return null;
      }

      const sankeyChartData = getSheetsAndSectionsSankeyChartDataFromRecap(store.getState());
      return (
        <SankeyChart
          data={sankeyChartData.data}
          maxLayerDepth={sankeyChartData.maxLayerDepth}
          formatValue={value => shortFormatNumberWithCurrency(value, sankeyChartData.currency, true, true)}
          nodeAlign={"right"}
          respectDarkMode={false}
        />
      );
    }

    return (
      <ReportsTabs
        selectedTabClassName="is-selected"
        selectedTabPanelClassName="is-selected"
        selectedIndex={tabIndex}
        onSelect={setTab}
      >
        <ReportsTabList show={(shouldShowContentsTab && showReportsTab).toString()}>
          {tabs.map((tab, index) => (
            <ReportsTab data-cy={"reportsTab" + index} key={index}>
              {tab.title}
            </ReportsTab>
          ))}
        </ReportsTabList>

        {tabs.map((tab, index) => {
          return <ReportsTabPanel key={`reportsTabPanel${index}`}>{tab.panelComponent}</ReportsTabPanel>;
        })}
      </ReportsTabs>
    );
  };

  const getLineChartData = lineChartData => {
    if (!lineChartData === true || !lineChartData.chart === true) {
      return null;
    }

    const chartData = lineChartData.chart[selectedChartTimeRange];
    const chartDataPoints = chartData.data;
    if (chartDataPoints.length < MIN_CHART_DATA_POINTS) {
      return null;
    }

    const portfolioTicker = currentPortfolio.currency;
    const data = {
      labels: chartDataPoints.map((dataPoint, index) => {
        return getLabelDateString(getDateInKuberaFormat(dataPoint.date));
      }),
      datasets: [
        {
          data: chartDataPoints.map((dataPoint, index) => {
            let dataPointVal = dataPoint.value;
            return convertCurrency(dataPointVal, chartData.currency, portfolioTicker);
          })
        }
      ]
    };
    return data;
  };

  const getLabelDateString = date => {
    return `${date.getDate()} ${getMonthFromDate(date)} ${date.getFullYear()}`.toUpperCase();
  };

  const getPreviousDaysValue = chartData => {
    if (!chartData === true || !chartData.chart === true) {
      return null;
    }
    const weeklyData = chartData.chart[chartTimeRange.WEEKLY];
    if (!weeklyData === true || weeklyData.data.length < 2) {
      return null;
    }
    return convertCurrency(weeklyData.data[weeklyData.data.length - 2].value, weeklyData.currency, portfolioCurrency);
  };

  const getChartTimeRangeMenuItems = () => {
    return [
      [
        { id: chartTimeRange.WEEKLY, label: "1 Week" },
        { id: chartTimeRange.MONTHLY, label: "1 Month" },
        { id: chartTimeRange.QUARTERLY, label: "3 Months" },
        { id: chartTimeRange.YEARLY, label: "1 Year" },
        { id: chartTimeRange.YTD, label: "YTD" },
        { id: chartTimeRange.ALL, label: "All-time" }
      ]
    ];
  };

  const getCurrentlySelectedChartTimeRangeText = () => {
    const menuItems = getChartTimeRangeMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === selectedChartTimeRange) {
        return item.label;
      }
    }
  };

  const getStartValueForCustodianForSelectedTimeRange = (chartData, reportLineChartData) => {
    if (!chartData === true || !reportLineChartData.chart[selectedChartTimeRange].data[0]) {
      return null;
    }
    const timeRangeDataPoint = reportLineChartData.chart[selectedChartTimeRange];
    return convertCurrency(timeRangeDataPoint.data[0].value, timeRangeDataPoint.currency, portfolioCurrency);
  };

  const handleChartContextMenuSelection = item => {
    dispatch(
      updateUserPreferences({
        custodianValueChartTimeRange: item.id
      })
    );
  };

  const handleChartTimeRangeSelectionButtonClick = e => {
    const menuItems = getChartTimeRangeMenuItems();
    for (const item of menuItems[0]) {
      if (item.id === selectedChartTimeRange) {
        item.selected = true;
      }
    }

    const targetPosition = e.target.getBoundingClientRect();
    chartContextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height,
      false,
      e.target
    );
  };

  const addToDashboardContextMenuItems = () => {
    try {
      const chartIdsForSelectedCustodian = chartIdsForSelectedCustodianSelector(
        selectedChartOptions,
        selectedTimeRange,
        selectedChartType,
        reportPath,
        reportNodeId,
        reportId,
        shouldShowContentsTab(),
        shouldShowReportsTab(),
        shouldShowInvestableAssetsBySheetsTab(),
        shouldShowInvestableAssetsBySectionsTab(),
        shouldShowInvestableAssetsWithoutCashBySheetsTab(),
        shouldShowInvestableAssetsWithoutcashBySectionsTab(),
        shouldShowAssetsBySectionsTab()
      );
      const lineChartData = getLineChartData(reportLineChartData);
      const recapData = recapDataSelector(store.getState());

      return [
        chartIdsForSelectedCustodian.map((id, index) => {
          const chartParams = parseParams(id);
          const parsedReportParams = parseParams(chartParams.report_id);
          const isChartAlreadyPresent =
            savedCharts &&
            savedCharts.columnOrder.some(column => {
              return savedCharts.columns[column].chartIds.find(chartId => {
                const dashboardChartParams = parseParams(chartId);
                const parsedDashboardChartparams = parseParams(dashboardChartParams.report_id);
                if (
                  parsedReportParams.chart_option === recapChartOptions.ASSETS_AND_CURRENCY.id &&
                  parsedReportParams.report_node_id === "Fiat Assets" &&
                  !checkIfAChartOptionHasNoDataToShow(recapChartOptions.CRYPTO.id, selectedTimeRange, recapData.data)
                ) {
                  return (
                    dashboardChartParams.chart_style === chartParams.chart_style &&
                    dashboardChartParams.chart_content === chartParams.chart_content &&
                    parsedDashboardChartparams.report_node_id === parsedReportParams.report_node_id &&
                    dashboardChartParams.is_checked === "true"
                  );
                } else if (
                  (parsedReportParams.chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id ||
                    parsedDashboardChartparams.chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) &&
                  chartParams.chart_name !== "Assets"
                ) {
                  if (
                    chartParams.chart_name === "Assets x Class" ||
                    chartParams.chart_name === "Assets x Sheets" ||
                    chartParams.chart_name === "Assets x Taxability"
                  ) {
                    return (
                      dashboardChartParams.chart_name === chartParams.chart_name &&
                      dashboardChartParams.chart_style === chartParams.chart_style &&
                      dashboardChartParams.chart_content === chartParams.chart_content &&
                      dashboardChartParams.should_compare_with_investable_assets ===
                        chartParams.should_compare_with_investable_assets &&
                      dashboardChartParams.should_compare_with_sheet === chartParams.should_compare_with_sheet &&
                      dashboardChartParams.should_compare_with_total_assets_or_debts ===
                        chartParams.should_compare_with_total_assets_or_debts &&
                      parsedDashboardChartparams.report_node_id === parsedReportParams.report_node_id &&
                      dashboardChartParams.is_checked === "true"
                    );
                  }
                  return (
                    dashboardChartParams.chart_style === chartParams.chart_style &&
                    dashboardChartParams.chart_content === chartParams.chart_content &&
                    dashboardChartParams.should_compare_with_investable_assets ===
                      chartParams.should_compare_with_investable_assets &&
                    dashboardChartParams.should_compare_with_sheet === chartParams.should_compare_with_sheet &&
                    dashboardChartParams.should_compare_with_total_assets_or_debts ===
                      chartParams.should_compare_with_total_assets_or_debts &&
                    parsedDashboardChartparams.report_node_id === parsedReportParams.report_node_id &&
                    dashboardChartParams.is_checked === "true"
                  );
                } else {
                  return (
                    dashboardChartParams.chart_style === chartParams.chart_style &&
                    dashboardChartParams.chart_content === chartParams.chart_content &&
                    dashboardChartParams.chart_name === chartParams.chart_name &&
                    dashboardChartParams.is_checked === "true"
                  );
                }
              });
            });
          const chartStyleName =
            chartParams[hashParams.CHART_STYLE] === chartStyle.DOUGHNUT ? "Donut Chart" : "Line Chart";
          const menuItemDescription = `${chartStyleName} ${!!isChartAlreadyPresent ? "(Already added)" : ""}`;
          const chartLabel = getChartLabelFromId(id, true);
          return {
            id: id,
            label: chartLabel,
            description: menuItemDescription,
            chartStyle: chartParams[hashParams.CHART_STYLE],
            isChartAlreadyPresent: !!isChartAlreadyPresent,
            disabled: chartParams[hashParams.CHART_STYLE] === chartStyle.LINE && !lineChartData
          };
        })
      ];
    } catch (e) {}
  };

  const handleAddToDashboardDropdownClick = e => {
    if (addToDashboardContextMenuRef.current.isVisible()) {
      addToDashboardContextMenuRef.current.dismiss();
      return;
    }
    const menuItems = addToDashboardContextMenuItems();
    const targetPosition = e.target.getBoundingClientRect();
    addToDashboardContextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height,
      false,
      e.target
    );
  };

  const handleAddChartToDashboardSelection = item => {
    if (!item.isChartAlreadyPresent) {
      const chartParams = parseParams(item.id);
      const reportId = encodeURIComponent(chartParams.report_id);
      const parsedReportParams = parseParams(chartParams.report_id);
      const chartContent = chartParams.chart_content;
      const chartStyleForSelectedChart = chartParams.chart_style;
      const chartName = chartParams.chart_name;
      const column1ChartIds = [...savedCharts.columns["column1"].chartIds];
      const column2ChartIds = [...savedCharts.columns["column2"].chartIds];
      const column1ChartIndexInDashboardChart = column1ChartIds.findIndex(chartId => {
        const dashboardChartParams = parseParams(chartId);
        const parsedDashboardChartparams = parseParams(dashboardChartParams.report_id);
        if (
          (parsedReportParams.chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id ||
            parsedDashboardChartparams.chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) &&
          chartParams.chart_name !== "Assets"
        ) {
          if (
            chartParams.chart_name === "Assets x Class" ||
            chartParams.chart_name === "Assets x Sheets" ||
            chartParams.chart_name === "Assets x Taxability"
          ) {
            return (
              dashboardChartParams.chart_name === chartParams.chart_name &&
              dashboardChartParams.chart_style === chartParams.chart_style &&
              dashboardChartParams.chart_content === chartParams.chart_content &&
              dashboardChartParams.should_compare_with_investable_assets ===
                chartParams.should_compare_with_investable_assets &&
              dashboardChartParams.should_compare_with_sheet === chartParams.should_compare_with_sheet &&
              dashboardChartParams.should_compare_with_total_assets_or_debts ===
                chartParams.should_compare_with_total_assets_or_debts &&
              parsedDashboardChartparams.report_node_id === parsedReportParams.report_node_id
            );
          }
          return (
            dashboardChartParams.chart_style === chartParams.chart_style &&
            dashboardChartParams.chart_content === chartParams.chart_content &&
            dashboardChartParams.should_compare_with_investable_assets ===
              chartParams.should_compare_with_investable_assets &&
            dashboardChartParams.should_compare_with_sheet === chartParams.should_compare_with_sheet &&
            dashboardChartParams.should_compare_with_total_assets_or_debts ===
              chartParams.should_compare_with_total_assets_or_debts &&
            parsedDashboardChartparams.report_node_id === parsedReportParams.report_node_id
          );
        } else {
          return (
            chartParams.chart_style === dashboardChartParams.chart_style &&
            dashboardChartParams.chart_content === chartParams.chart_content &&
            dashboardChartParams.chart_name === chartParams.chart_name
          );
        }
      });
      const column2ChartIndexInDashboardChart = column2ChartIds.findIndex(chartId => {
        const dashboardChartParams = parseParams(chartId);
        const parsedDashboardChartparams = parseParams(dashboardChartParams.report_id);

        if (
          (parsedReportParams.chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id ||
            parsedDashboardChartparams.chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) &&
          chartParams.chart_name !== "Assets"
        ) {
          if (
            chartParams.chart_name === "Assets x Class" ||
            chartParams.chart_name === "Assets x Sheets" ||
            chartParams.chart_name === "Assets x Taxability"
          ) {
            return (
              dashboardChartParams.chart_name === chartParams.chart_name &&
              dashboardChartParams.chart_style === chartParams.chart_style &&
              dashboardChartParams.chart_content === chartParams.chart_content &&
              dashboardChartParams.should_compare_with_investable_assets ===
                chartParams.should_compare_with_investable_assets &&
              dashboardChartParams.should_compare_with_sheet === chartParams.should_compare_with_sheet &&
              dashboardChartParams.should_compare_with_total_assets_or_debts ===
                chartParams.should_compare_with_total_assets_or_debts &&
              parsedDashboardChartparams.report_node_id === parsedReportParams.report_node_id
            );
          }
          return (
            dashboardChartParams.chart_style === chartParams.chart_style &&
            dashboardChartParams.chart_content === chartParams.chart_content &&
            dashboardChartParams.should_compare_with_investable_assets ===
              chartParams.should_compare_with_investable_assets &&
            dashboardChartParams.should_compare_with_sheet === chartParams.should_compare_with_sheet &&
            dashboardChartParams.should_compare_with_total_assets_or_debts ===
              chartParams.should_compare_with_total_assets_or_debts &&
            parsedDashboardChartparams.report_node_id === parsedReportParams.report_node_id
          );
        } else {
          return (
            chartParams.chart_style === dashboardChartParams.chart_style &&
            dashboardChartParams.chart_content === chartParams.chart_content &&
            dashboardChartParams.chart_name === chartParams.chart_name
          );
        }
      });
      const column = getColumnForChart({ ...savedCharts });
      if (column1ChartIndexInDashboardChart !== -1 || column2ChartIndexInDashboardChart !== -1) {
        const chartIds = column1ChartIndexInDashboardChart !== -1 ? column1ChartIds : column2ChartIds;
        const targetColumn = column1ChartIndexInDashboardChart !== -1 ? "column1" : "column2";
        const targetIndex = chartIds.findIndex(chartId => {
          const dashboardChartParams = parseParams(chartId);
          return (
            chartParams.chart_style === dashboardChartParams.chart_style &&
            dashboardChartParams.chart_content === chartParams.chart_content &&
            dashboardChartParams.chart_name === chartParams.chart_name
          );
        });
        const chartIdInDashboardCharts = chartIds[targetIndex];
        const dashboardChartParams = parseParams(chartIdInDashboardCharts);
        const updatedChartId = createChartIdUsingReportId(
          reportId,
          chartStyleForSelectedChart,
          chartContent,
          true,
          dashboardChartParams[chartKeyParams.IS_DEFAULT_CHART],
          chartName,
          dashboardChartParams.should_be_checked_by_default,
          dashboardChartParams.should_compare_with_investable_assets,
          dashboardChartParams.should_compare_with_total_assets_or_debts,
          dashboardChartParams.should_compare_with_sheet
        );
        savedCharts.columns[targetColumn].chartIds[targetIndex] = updatedChartId;
      } else {
        savedCharts.columns[column].chartIds.push(item.id);
      }
      dispatch(updateDashboardCharts({ ...savedCharts }));
      dispatch(showToastTip("TIP", i18n.t("addChartToDashboardToast.added"), false, 3000));
    } else {
      dispatch(showToastTip("TIP", i18n.t("addChartToDashboardToast.alreadyAdded"), false, 3000));
    }
  };

  const valueForCustodian = (considerChartSelection = true, reportLineChartData, selectedCustodianValue) => {
    const currentlySelectedChartDataPoint = getCurrentlySelectedChartDataPoint(reportLineChartData);
    if (considerChartSelection === true && !currentlySelectedChartDataPoint === false) {
      const chartData = reportLineChartData.chart[selectedChartTimeRange];
      return convertCurrency(currentlySelectedChartDataPoint.value, chartData.currency, portfolioCurrency);
    } else {
      return selectedCustodianValue;
    }
  };

  const getCurrentlySelectedChartDataPoint = reportLineChartData => {
    if (currentChartDataPointHoverIndex === null) {
      return null;
    }

    const dataPoints = reportLineChartData.chart[selectedChartTimeRange].data;
    return dataPoints[currentChartDataPointHoverIndex];
  };

  const custodianName = reportName;
  const custodianValue = convertCurrency(reportCurrentValue, recapCurrency, portfolioCurrency);
  const showContentsTab = shouldShowContentsTab();
  const showReportsTab = shouldShowReportsTab();
  const tabs = getTabs(custodianName, custodianValue);
  const chartData = getLineChartData(reportLineChartData);
  const currentChartTimeRangeText = getCurrentlySelectedChartTimeRangeText();
  const value = valueForCustodian(true, reportLineChartData, custodianValue);
  const startValue = getStartValueForCustodianForSelectedTimeRange(chartData, reportLineChartData);
  const handleChartHoverSelection = index => {
    const dataPoints = reportLineChartData.chart[selectedChartTimeRange].data;
    let hoverIndex = index;

    if (index === dataPoints.length - 1) {
      hoverIndex = null;
    }
    setCurrentChartDataPointHoverIndex(hoverIndex);
  };

  const shouldShowChartChange = lineChartData => {
    if (!lineChartData === true || !currentChartDataPointHoverIndex === true) {
      return false;
    }
    const dataPoints = lineChartData.datasets[0].data;
    var currentDataPoint = null;

    for (const dataPoint of dataPoints) {
      if (!currentDataPoint === true) {
        currentDataPoint = dataPoint;
      } else if (currentDataPoint !== dataPoint) {
        return true;
      }
    }
    return false;
  };

  const shouldShowValueChange = shouldShowChartChange(chartData);

  return (
    <ChartsOverlay onDismiss={handleOverlayDismiss}>
      <DetailsDialog width={870}>
        <DetailsContainer>
          <Header>
            <CustodianMetaDataContainer>
              <CustodianTitleContainer>
                <CustodianTitle>{reportName}</CustodianTitle>
              </CustodianTitleContainer>
              <ValueAndOptionsContainer>
                <ValueContainer>
                  <Value
                    data-cy="custodianDetailTotal"
                    maxLongFormatValue={99999}
                    value={value}
                    roundDown={true}
                    currency={portfolioCurrency}
                    abbreviate={false}
                    fontSize={22}
                    height="27px"
                    useScrollAnimation
                  />
                  {shouldShowValueChange === true && (
                    <ValueChangeLabel
                      currency={portfolioCurrency}
                      startValue={startValue}
                      endValue={Math.floor(value)}
                      isAnimationAllowed={false}
                    />
                  )}
                </ValueContainer>
                {recapDisabled === false && (
                  <GoToRecapButton onClick={handleGoToRecapButtonClick}>
                    {i18n.t("gotoRecap.button.title")}
                  </GoToRecapButton>
                )}
                {!isAppInViewMode() && currentPortfolio.write === 1 && (
                  <AddToDashboardButton onClick={handleAddToDashboardDropdownClick}>
                    {"Add to Dashboard"}
                    <DropDownIcon />
                  </AddToDashboardButton>
                )}
              </ValueAndOptionsContainer>
            </CustodianMetaDataContainer>
          </Header>
          <ChartDetailsContainer>
            <ChartHeader>
              <ChartChangeContainer hidden={!chartData === true}>
                <ChartChangeTitle>{i18n.t("dayChange")}</ChartChangeTitle>
                <ChartChangeLabel
                  currency={portfolioCurrency}
                  startValue={getPreviousDaysValue(reportLineChartData)}
                  endValue={Math.floor(custodianValue)}
                  alignPosition="left"
                />
              </ChartChangeContainer>
              <ChartChangeContainer hidden={!chartData === true}>
                <ChartChangeTitle>{currentChartTimeRangeText}</ChartChangeTitle>
                <ChartChangeLabel
                  currency={portfolioCurrency}
                  startValue={startValue}
                  endValue={Math.floor(custodianValue)}
                  alignPosition="left"
                />
              </ChartChangeContainer>
              <SelectTimeRangeButtonContainer hidden={!chartData === true}>
                <SelectTimeRangeButton onClick={handleChartTimeRangeSelectionButtonClick}>
                  {currentChartTimeRangeText}
                  <SelectTimeRangeButtonIcon />
                </SelectTimeRangeButton>
              </SelectTimeRangeButtonContainer>
            </ChartHeader>
            <ChartContainer>
              {!chartData === false && (
                <NetWorthLineChart
                  data={chartData}
                  chartHeight={120}
                  onHoverOverSection={handleChartHoverSelection}
                  chartPadding={{
                    left: 0,
                    right: 0,
                    top: 20,
                    bottom: 3
                  }}
                  isWithinDetails
                  showBuffer={true}
                  id="charts_modal_chart"
                />
              )}
              {!chartData === true && (
                <EmptyLineChartContainer>
                  <EmptyChartMessage>{i18n.t("detailsChartEmpty")}</EmptyChartMessage>
                </EmptyLineChartContainer>
              )}
            </ChartContainer>
          </ChartDetailsContainer>
          <ReportsDetailsContainer>
            {showReportDetails(showContentsTab, showReportsTab, tabs, custodianName)}
          </ReportsDetailsContainer>
        </DetailsContainer>
        <ContextMenu ref={chartContextMenuRef} width={150} onSelection={handleChartContextMenuSelection} />
        <AddToDashboardContextMenu
          ref={addToDashboardContextMenuRef}
          width={250}
          onSelection={handleAddChartToDashboardSelection}
          hideOnScroll={true}
        />
      </DetailsDialog>
    </ChartsOverlay>
  );
};

export default withRouter(ChartsModalComponent);
