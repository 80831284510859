import React from "react";

import { category } from "components/dashboard/DashboardComponent";
import MobileWrapperComponent from "mobile_components/containers/MobileWrapperComponent";
import withInitialFetchHandle from "utilities/withInitialFetchHandle";

const MobileAssetsWrapperComponent = props => {
  return <MobileWrapperComponent pageCategory={category.ASSET} {...props} />;
};

export default React.memo(withInitialFetchHandle(MobileAssetsWrapperComponent));
