import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import i18n from "i18next";
import { hashParams } from "routes";
import {
  getTickerUsingId,
  irrTypes,
  irrComparisonIndexesSelector,
  updateUserPreferences,
  sanitizeIrr,
  supportedIndicesSelector,
  updateCustodianIrrType,
  supportedIndicesIrrSelector,
  irrComparisonTimePeriodSelector,
  withRouter,
  showCopiedToast
} from "@kubera/common";
import PercentageLabel from "components/labels/PercentageLabel";
import CurrencyLabel from "components/labels/CurrencyLabel";
import { ReactComponent as DownArrow } from "assets/images/menu_downarrow.svg";
import { ReactComponent as IndexComparisonIcon } from "assets/images/index_comparison_icon.svg";
import IrrSettingsDialog, { timePeriods } from "components/custodian_details/IrrSettingsDialog";
import CustodianDetailsComponent, { detailsTabs } from "./CustodianDetailsComponent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const HeaderFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 30px;
  margin-top: 15px;
  cursor: ${props => (props.isDisabled ? "auto" : "pointer")};
`;

const HeaderFieldTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DropDownArrow = styled(DownArrow)`
  margin-left: 2px;
`;

const HeaderFieldTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const IrrValue = styled(PercentageLabel)`
  margin-top: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on;
  filter: ${props => (props.isUpdating === true ? "opacity(0.3)" : "")};
`;

const EmptyIrrValue = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on;
`;

const IndexVsIcon = styled(IndexComparisonIcon)`
  circle {
    fill: ${props => props.theme.vsLabelBG};
  }
`;

const EnterOtherReturnsContainer = styled.div`
  display: flex;
  column-gap: 10px;
  margin-top: 20px;
`;

const EnterOtherReturnsButton = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
`;

const TotalCashflow = styled(CurrencyLabel)`
  margin-top: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on;
`;

const EmptyValue = styled.div`
  margin-top: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on;
`;

const HoldingsReturnDesc = styled.div`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 327px;

  u {
    cursor: pointer;
  }
`;

class HoldingsReturnsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndexes: props.irrComparisonIndexes,
      showSettingsDialog: false,
      selectedTimePeriod: props.irrComparisonTimePeriod || timePeriods.START_DATE
    };

    this.handleEnterCashflowOnClick = this.handleEnterCashflowOnClick.bind(this);
    this.handleIrrSettingsButtonClick = this.handleIrrSettingsButtonClick.bind(this);
  }

  handleEnterCashflowOnClickForce = e => {
    this.props.updateCustodianIrrType(this.props.custodianId, irrTypes.CASHFLOW, true);
  };

  handleEnterCashflowOnClick(e) {
    this.props.updateCustodianIrrType(this.props.custodianId, irrTypes.CASHFLOW);
  }

  handleEnterCostbasisOnClick(e) {
    this.props.updateCustodianIrrType(this.props.custodianId, irrTypes.COSTBASIS);
  }

  handleIrrSettingsButtonClick(e) {
    if (this.props.isReadOnly === true) {
      return;
    }
    this.setState({ showSettingsDialog: true });
  }

  getIrr(irrDetails) {
    if (!irrDetails === true || !irrDetails.all === true) {
      return null;
    }
    return sanitizeIrr(irrDetails.all.value);
  }

  getIrrDetails() {
    if (!this.props.irr === true) {
      return null;
    }

    try {
      const irrDetails = JSON.parse(this.props.irr);
      return irrDetails;
    } catch (e) {
      return null;
    }
  }

  getIndexIrrs(irrDetails) {
    var irrs = [];
    if (!irrDetails === true || !irrDetails.all.market === true) {
      return irrs;
    }

    const valueTickerShortName = getTickerUsingId(this.props.valueTickerId).shortName;
    for (const indexId of this.state.selectedIndexes) {
      const index = this.props.supportedIndexes.find(item => item.id === indexId);

      if (index.id !== valueTickerShortName) {
        irrs.push({ ...index, irr: this.getMarketIrr(indexId, irrDetails) });
      }
    }
    return irrs;
  }

  getMarketIrr(indexId, irrDetails) {
    if (this.state.selectedTimePeriod === timePeriods.START_DATE) {
      return irrDetails.all.market[indexId];
    } else if (this.state.selectedTimePeriod === timePeriods.YEAR) {
      return this.props.supportedIndexesIrr.year[indexId];
    } else if (this.state.selectedTimePeriod === timePeriods.YTD) {
      return this.props.supportedIndexesIrr.ytd[indexId];
    }
    return null;
  }

  handleIrrSettingsDialogOnDismiss = (selectedIndexes, selectedTimePeriod) => {
    this.setState({
      showSettingsDialog: false,
      selectedIndexes: selectedIndexes,
      selectedTimePeriod: selectedTimePeriod
    });

    this.props.updateUserPreferences({
      irrComparisonIndexes: selectedIndexes,
      irrComparisonTimePeriod: selectedTimePeriod
    });
  };

  handleGoToHoldings = event => {
    if (event.target.tagName.toLowerCase() === "u") {
      CustodianDetailsComponent.show(this.props.history, this.props.location, this.props.custodianId, true, undefined, {
        hash: `${hashParams.TAB_INDEX}=${detailsTabs.HOLDINGS}`
      });
    }
  };

  handleCashTotalClick = value => {
    if (!value === true) {
      return;
    }
    navigator.clipboard.writeText(value);
    this.props.showCopiedToast();
  };

  render() {
    const { className } = this.props;
    const irrDetails = this.getIrrDetails();
    const irr = this.getIrr(irrDetails);
    const indexIrrs = this.getIndexIrrs(irrDetails);
    const hasComparisonIrrs = indexIrrs.length > 0 && !irr === false;

    return (
      <Container className={className}>
        <HeaderContainer>
          <HeaderFieldContainer isDisabled={true} onClick={() => this.handleCashTotalClick(irrDetails?.all?.cashIn)}>
            <HeaderFieldTitle>{i18n.t("cashIn")}</HeaderFieldTitle>
            {!irrDetails?.all?.cashIn === true && <EmptyValue>{"--"}</EmptyValue>}
            {!irrDetails?.all?.cashIn === false && (
              <TotalCashflow
                maxLongFormatValue={99999}
                value={irrDetails?.all?.cashIn}
                roundDown={true}
                currency={this.props.currency}
                abbreviate={false}
                preventAnimation
              />
            )}
          </HeaderFieldContainer>
          <HeaderFieldContainer isDisabled={true} onClick={() => this.handleCashTotalClick(irrDetails?.all?.cashOut)}>
            <HeaderFieldTitle>{i18n.t("cashOut")}</HeaderFieldTitle>
            {!irrDetails?.all?.cashOut === true && <EmptyValue>{"--"}</EmptyValue>}
            {!irrDetails?.all?.cashOut === false && (
              <TotalCashflow
                maxLongFormatValue={99999}
                value={irrDetails?.all?.cashOut}
                roundDown={true}
                currency={this.props.currency}
                abbreviate={false}
                preventAnimation
              />
            )}
          </HeaderFieldContainer>
          <HeaderFieldContainer
            onClick={e => {
              if (!irr === true) {
                return;
              }
              this.handleIrrSettingsButtonClick(e);
            }}
            isDisabled={!irr === true || this.props.isReadOnly === true}
          >
            <HeaderFieldTitleContainer>
              <HeaderFieldTitle>{i18n.t("irr")}</HeaderFieldTitle>
              {!irr === false && this.props.isReadOnly === false && <DropDownArrow />}
            </HeaderFieldTitleContainer>
            {!irr === true && <EmptyIrrValue>{"--"}</EmptyIrrValue>}
            {!irr === false && (
              <IrrValue value={irr} isUpdating={false} darkBackground={false} updated={true} preventAnimation />
            )}
          </HeaderFieldContainer>
          {hasComparisonIrrs === true && (
            <HeaderFieldContainer isDisabled={true}>
              <IndexVsIcon />
            </HeaderFieldContainer>
          )}
          {hasComparisonIrrs === true &&
            indexIrrs.map((indexIrr, index) => (
              <HeaderFieldContainer
                key={index}
                onClick={this.handleIrrSettingsButtonClick}
                isDisabled={this.props.isReadOnly === true}
              >
                <HeaderFieldTitleContainer>
                  <HeaderFieldTitle>{indexIrr.shortName}</HeaderFieldTitle>
                  {this.props.isReadOnly === false && <DropDownArrow />}
                </HeaderFieldTitleContainer>
                {!indexIrr.irr === true && <EmptyIrrValue>{"--"}</EmptyIrrValue>}
                {!indexIrr.irr === false && (
                  <IrrValue
                    value={sanitizeIrr(indexIrr.irr)}
                    isUpdating={false}
                    darkBackground={false}
                    updated={true}
                    preventAnimation
                  />
                )}
              </HeaderFieldContainer>
            ))}
        </HeaderContainer>
        {this.props.isReadOnly === false && (
          <HoldingsReturnDesc
            dangerouslySetInnerHTML={{
              __html: i18n.t("holdingReturnsDescription")
            }}
            onClick={this.handleGoToHoldings}
          />
        )}
        {this.props.isReadOnly === false && (
          <EnterOtherReturnsContainer>
            <EnterOtherReturnsButton onClick={this.handleEnterCashflowOnClickForce}>
              {i18n.t("holdingReturnsButton")}
            </EnterOtherReturnsButton>
          </EnterOtherReturnsContainer>
        )}
        {this.state.showSettingsDialog === true && (
          <IrrSettingsDialog
            irr={irr}
            irrDetails={irrDetails}
            supportedIndexes={this.props.supportedIndexes}
            supportedIndexesIrr={this.props.supportedIndexesIrr}
            selectedTimePeriod={this.state.selectedTimePeriod}
            custodianName={this.props.custodianName}
            selectedIndexes={this.state.selectedIndexes}
            onDismiss={this.handleIrrSettingsDialogOnDismiss}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  supportedIndexes: supportedIndicesSelector(state),
  supportedIndexesIrr: supportedIndicesIrrSelector(state),
  irrComparisonIndexes: irrComparisonIndexesSelector(state),
  irrComparisonTimePeriod: irrComparisonTimePeriodSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences,
  updateCustodianIrrType: updateCustodianIrrType,
  showCopiedToast: showCopiedToast
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HoldingsReturnsComponent));
