import React, { useState, useEffect } from "react";
import styled from "styled-components";
import i18n from "locale/i18n";
import { useSelector, useDispatch } from "react-redux";

import {
  guessDateInCustodianHistoryUIFormat,
  getCapitalizedStr,
  clientRetryChargeClient,
  shortFormatNumberWithCurrency,
  getClientListCharges,
  wlPaymentMethodSelector
} from "@kubera/common";

import Spinner from "components/loader/Spinner";

const Container = styled.div`
  position: relative;
  display: table-row;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 40px;
`;

const Cell = styled.div`
  display: table-cell;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000;
  vertical-align: middle;
  width: ${({ width }) => (width ? width : null)};
  padding: 0 14px;
  white-space: nowrap;
`;

const ValueCell = styled(Cell)`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
`;

const CellAction = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

const Error = styled.span`
  color: #ff0000;
`;

const Retry = styled.span`
  color: #0074fc;
  text-decoration: underline;
  cursor: pointer;
`;

const Loader = styled(Spinner)`
  position: absolute;
  right: 15px;
  top: 3px;
  width: 20px;
  height: 20px;
  margin-top: 1px;
`;

const PaymentsList = ({
  index,
  userId,
  charge = {},
  handleAuthenticationRequired = () => {},
  handlePaymentError = () => {}
}) => {
  const dispatch = useDispatch();
  const wlPaymentMethod = useSelector(wlPaymentMethodSelector);

  const createdDateFormatted = guessDateInCustodianHistoryUIFormat(
    new Date(charge.tsCreated * 1000).toLocaleDateString()
  ).dateString;

  const [isRetryLoading, setIsRetryLoading] = useState(false);

  const isCardError =
    charge.tsModified > wlPaymentMethod.tsModified ? (charge.errorCode || "").startsWith("card_") : false;

  const handleOnActionClick = () => {
    if (charge.receiptUrl) {
      window.kuberaOpen(charge.receiptUrl, "_blank");
    }
  };

  const handleOnRetry = async () => {
    setIsRetryLoading(true);
    try {
      const retryChargeResponse = await dispatch(clientRetryChargeClient(charge.id));
      await handleAuthenticationRequired(retryChargeResponse.paymentAuth);
      await dispatch(getClientListCharges(userId));
    } catch (error) {
      setIsRetryLoading(false);
    } finally {
      setIsRetryLoading(false);
    }
  };

  useEffect(() => {
    if (index === 0) {
      handlePaymentError(isCardError);
    }
  }, [index, isCardError, handlePaymentError]);

  return (
    <Container>
      <Cell width="100px">{createdDateFormatted}</Cell>
      <Cell width="100%">
        {isRetryLoading || !charge.errorCode ? (
          getCapitalizedStr(charge.description || charge.type)
        ) : (
          <Error>
            {i18n.t("accountCardDeclined.title")}.{" "}
            {!isRetryLoading && <Retry onClick={handleOnRetry}>{i18n.t("retry")}</Retry>}
          </Error>
        )}
      </Cell>
      <ValueCell>{shortFormatNumberWithCurrency(charge.amount / 100, "USD", undefined, true)}</ValueCell>
      <Cell width="90px">
        <CellAction onClick={handleOnActionClick}>
          {charge.receiptUrl ? i18n.t("receipt") : isRetryLoading && <Loader />}
        </CellAction>
      </Cell>
    </Container>
  );
};

export default PaymentsList;
