import createWhitelistFilter from "redux-persist-transform-filter";
import {
  isCryptoLinkingService,
  SET_BACKGROUND_LINK_FAILURE_DATA,
  SET_POPULAR_PROVIDERS
} from "../actions/LinkAccountActions";

const initialState = {
  backgroundLinkFailureData: null,
  popularProviders: []
};

export const linkAccountPersistTransform = () => {
  return createWhitelistFilter("link_account", ["popularProviders"]);
};

export function linkAccountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BACKGROUND_LINK_FAILURE_DATA:
      return {
        ...state,
        backgroundLinkFailureData: action.failureData
      };
    case SET_POPULAR_PROVIDERS: {
      var newState = { ...state };
      newState.popularProviders = newState.popularProviders.filter(entry => entry.category !== action.category);
      newState.popularProviders = newState.popularProviders.concat(action.providerEntries);
      return newState;
    }
    default:
      return state;
  }
}

export const backgroundLinkFailureDataSelector = (state, custodianId) => {
  if (!state.link_account.backgroundLinkFailureData === true) {
    return null;
  }
  if (state.link_account.backgroundLinkFailureData.custodianId !== custodianId) {
    return null;
  }
  return state.link_account.backgroundLinkFailureData;
};

export const popularProvidersSelector = (state, category, linkingService) => {
  var popularProviders = [];
  var providerType = "fiat";

  if (isCryptoLinkingService(linkingService)) {
    providerType = "crypto";
  }
  if (category === "Asset") {
    for (const popularProviderDetails of state.link_account.popularProviders) {
      for (const provider of popularProviderDetails.providers) {
        if (provider.type === providerType && category === popularProviderDetails.category) {
          popularProviders.push(provider);
        }
      }
    }
  }

  popularProviders.sort((a, b) => a.index - b.index);
  return popularProviders;
};
