import React from "react";
import styled from "styled-components";
import { getSortKeyBetween, withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  getDefaultScenario,
  getUuid,
  MAX_SCENARIO_COUNT,
  isAppInViewMode,
  createPlanningScenario,
  updatePlanningScenario,
  deletePlanningScenario,
  currentPortfolioSelector,
  getKuberaDateString
} from "@kubera/common";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CurrencyLabel from "components/labels/CurrencyLabel";
import ScenarioRulesComponent from "./ScenarioRulesComponent";
import { ReactComponent as PlusIcon } from "assets/images/plus.svg";
import menuIcon from "assets/images/menu_downarrow.svg";
import menuIconDark from "assets/images/menu_downarrow_dark.svg";
import ContextMenu, { contextMenuItemType } from "components/contextmenu/ContextMenu";
import EditableLabel from "components/inputs/EditableLabel";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import i18n from "i18next";

const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
`;

const ScenarioTabs = styled(Tabs)`
  margin-top: 14px;
  margin-bottom: 14px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const ScenarioTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
`;

const ScenarioTabHeaderContainer = styled.div`
  display: flex;
`;

const ScenarioTabHeader = styled(Tab)`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  margin-right: 25px;
  border: 0;
  outline: 0;
  z-index: 100;
  opacity: 0.35;

  &.is-selected {
    opacity: 1;
  }
`;

const TabHeader = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 5px;
  border-left: ${props => `4px solid ${props.color}`};
  cursor: pointer;
`;

const TabTitleContainer = styled.div`
  display: flex;
`;

const TabHeaderTitle = styled(EditableLabel)`
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  cursor: pointer;
`;

const ScenarioContextMenuButton = styled.div`
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};
  width: 19px;
  min-width: 19px;
  margin-top: 2px;
  height: 19px;
  border-radius: 2px;
  margin-left: 3px;
  margin-top: -2px;
  color: red;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0px 0px;
  background-image: url(${props => (props.theme.mode === "default" ? menuIcon : menuIconDark)});

  ${TabHeader}:hover & {
    background-size: 9px 9px;
  }

  &:hover {
    background-color: ${props => props.theme.contextMenuButtonBackgroundColor};
  }

  background-size: ${props => (props.isMenuVisible ? "9px 9px" : "")};
  background-color: ${props => (props.isMenuVisible ? props.theme.contextMenuButtonBackgroundColor : "transparent")};
`;

const TabHeaderTotal = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on;
`;

const AddScenarioButton = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  visibility: ${props => (props.isHidden ? "hidden" : "visible")};

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const ScenarioTabPanel = styled(TabPanel)`
  margin-top: 14px;
  display: none;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const AddScenarioIcon = styled(PlusIcon)`
  width: 12px;
  height: 12px;

  path {
    fill: #a4a4a4;
  }
`;

class ScenariosComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isScenarioMenuVisible: false, showDeleteScenarioDialog: false };

    this.handleAddScenarioClick = this.handleAddScenarioClick.bind(this);
    this.handleScenarioContextMenuClick = this.handleScenarioContextMenuClick.bind(this);
    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleScenarioNameUpdate = this.handleScenarioNameUpdate.bind(this);
    this.handleContextMenuDismiss = this.handleContextMenuDismiss.bind(this);
    this.hideDeleteScenarioDialog = this.hideDeleteScenarioDialog.bind(this);
    this.executeDeleteScenario = this.executeDeleteScenario.bind(this);

    this.contextMenuRef = React.createRef();
    this.contextMenuForScenarioIndex = null;

    this.scenarioTitleRefs = [];
  }

  handleAddScenarioClick(e) {
    const scenarioCount = this.props.planningData.length;
    const newScenario = this.props.getDefaultScenario(scenarioCount);
    const sortKeyBefore = this.props.planningData[scenarioCount - 1].scenario.sortKey;
    const sortKeyAfter = null;
    const sortKeyForScenario = getSortKeyBetween(sortKeyBefore, sortKeyAfter);
    newScenario.sortKey = sortKeyForScenario;
    newScenario.rule = newScenario.rule.map(rule => {
      return {
        ...rule,
        scenarioId: newScenario.id,
        disabled: 0
      };
    });
    this.props.createPlanningScenario(newScenario);
    this.props.onScenarioChange(scenarioCount);
  }

  handleScenarioContextMenuClick(event, index) {
    if (this.contextMenuRef.current.isVisible() === true) {
      this.contextMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    var menuItems = [[contextMenuItemType.RENAME]];
    if (this.props.planningData.length < MAX_SCENARIO_COUNT) {
      menuItems[0].push(contextMenuItemType.DUPLICATE);
    }
    if (this.props.planningData.length > 1) {
      menuItems.push([contextMenuItemType.DELETE_SCENARIO]);
    }

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height + 2,
      true,
      event.target
    );
    this.contextMenuForScenarioIndex = index;

    this.setState({ isScenarioMenuVisible: true, contextMenuIndex: index });
  }

  hideDeleteScenarioDialog() {
    this.setState({ showDeleteScenarioDialog: false });
  }

  executeDeleteScenario() {
    this.props.deletePlanningScenario(this.props.planningData[this.state.contextMenuIndex].scenario.id);
    this.props.onScenarioChange(this.state.contextMenuIndex === 0 ? 0 : this.state.contextMenuIndex - 1);
    this.hideDeleteScenarioDialog();
  }

  handleContextMenuSelection(item) {
    if (item.id === contextMenuItemType.DELETE_SCENARIO.id) {
      this.setState({ showDeleteScenarioDialog: true });
    } else if (item.id === contextMenuItemType.RENAME.id) {
      this.scenarioTitleRefs[this.contextMenuForScenarioIndex].edit();
    } else if (item.id === contextMenuItemType.DUPLICATE.id) {
      const newScenarioIndex =
        this.props.planningData.findIndex(
          item => item.scenario.id === this.props.planningData[this.contextMenuForScenarioIndex].scenario.id
        ) + 1;
      const newScenario = JSON.parse(
        JSON.stringify(this.props.planningData[this.contextMenuForScenarioIndex].scenario)
      );
      const sortKeyBefore = this.props.planningData[this.contextMenuForScenarioIndex].scenario.sortKey || null;
      const sortKeyAfter =
        this.contextMenuForScenarioIndex + 1 === this.props.planningData.length
          ? null
          : this.props.planningData[this.contextMenuForScenarioIndex + 1].scenario.sortKey;
      const sortKeyForScenario = getSortKeyBetween(sortKeyBefore, sortKeyAfter);
      newScenario.sortKey = sortKeyForScenario;
      newScenario.name = `Copy of ${newScenario.name}`;
      newScenario.id = getUuid();
      for (const rule of newScenario.rule) {
        rule.id = getUuid();
        rule.scenarioId = newScenario.id;
      }
      this.props.createPlanningScenario(newScenario, newScenarioIndex);
      this.props.onScenarioChange(newScenarioIndex);
    }

    this.contextMenuForScenarioIndex = null;
    this.setState({ isScenarioMenuVisible: false });
  }

  handleContextMenuDismiss() {
    this.setState({ isScenarioMenuVisible: false });
  }

  handleScenarioNameUpdate(index, value) {
    const updatedScenario = this.props.planningData[index].scenario;
    updatedScenario.name = value;

    this.props.updatePlanningScenario(updatedScenario);
  }

  getTabs() {
    return this.props.planningData.map((dataForScenario, index) => {
      const panel = (
        <ScenarioRulesComponent
          scenario={dataForScenario.scenario}
          scenarios={this.props.planningData.map(item => item.scenario)}
        />
      );
      return {
        dataForScenario: dataForScenario,
        key: index,
        panelComponent: this.props.hideRules === true ? null : panel
      };
    });
  }

  getValue(dataForScenario) {
    const dataPoints = dataForScenario.data;
    if (!dataPoints === true || dataPoints.length === 0) {
      return 0;
    }

    if (!this.props.showValueForDate === false) {
      const valueDataPoint = dataPoints.find(item => item.date === getKuberaDateString(this.props.showValueForDate));
      if (valueDataPoint) {
        return valueDataPoint.networth;
      }
    }
    return dataPoints[dataPoints.length - 1].networth;
  }

  isReadOnly() {
    return isAppInViewMode() === true || this.props.currentPortfolio.write === 0;
  }

  render() {
    const tabs = this.getTabs();
    return (
      <Container className={this.props.className}>
        <ContentContainer>
          <ScenarioTabs
            selectedTabClassName="is-selected"
            selectedTabPanelClassName="is-selected"
            selectedIndex={this.props.selectedScenarioIndex}
            onSelect={this.props.onScenarioChange}
          >
            <ScenarioTabList>
              {tabs.map((tab, index) => (
                <ScenarioTabHeader data-cy={"detailsTab" + index} key={index}>
                  <ScenarioTabHeaderContainer>
                    <TabHeader color={this.props.colorPallete[index].color}>
                      <TabTitleContainer>
                        <TabHeaderTitle
                          type="text"
                          value={tab.dataForScenario.scenario.name}
                          ref={element => {
                            this.scenarioTitleRefs[index] = element;
                          }}
                          onChange={value => this.handleScenarioNameUpdate(index, value)}
                        />
                        <ScenarioContextMenuButton
                          isHidden={index !== this.props.selectedScenarioIndex || this.isReadOnly() === true}
                          isMenuVisible={this.state.isScenarioMenuVisible}
                          onClick={e => this.handleScenarioContextMenuClick(e, index)}
                        />
                      </TabTitleContainer>
                      <TabHeaderTotal
                        currency={tab.dataForScenario.currency}
                        value={this.getValue(tab.dataForScenario)}
                        maxLongFormatValue={10000}
                      />
                    </TabHeader>
                  </ScenarioTabHeaderContainer>
                </ScenarioTabHeader>
              ))}
              {this.props.disableAddScenario !== true &&
                this.props.planningData.length < MAX_SCENARIO_COUNT &&
                this.isReadOnly() === false && (
                  <AddScenarioButton onClick={this.handleAddScenarioClick}>
                    <AddScenarioIcon />
                  </AddScenarioButton>
                )}
            </ScenarioTabList>
            {tabs.map((tab, index) => (
              <ScenarioTabPanel key={`scenarioTabPanel${index}`}>{tab.panelComponent}</ScenarioTabPanel>
            ))}
          </ScenarioTabs>
          <ContextMenu
            dataPrivate
            ref={this.contextMenuRef}
            width={223}
            onSelection={this.handleContextMenuSelection}
            onDismiss={this.handleContextMenuDismiss}
          />
        </ContentContainer>
        {this.state.showDeleteScenarioDialog && (
          <ConfirmationDialog
            title={i18n.t("deleteScenario.title")}
            description={i18n.t("beneficiary.areYouSure")}
            positiveButtonTitle={i18n.t("delete")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.hideDeleteScenarioDialog}
            handlePositiveButtonClick={this.executeDeleteScenario}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  getDefaultScenario: getDefaultScenario.bind(state),
  currentPortfolio: currentPortfolioSelector(state)
});

const mapDispatchToProps = {
  updatePlanningScenario: updatePlanningScenario,
  deletePlanningScenario: deletePlanningScenario,
  createPlanningScenario: createPlanningScenario
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScenariosComponent));
