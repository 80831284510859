import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import i18n from "i18next";
import { userPreferencesSelector, isAppInViewMode, isAppInWhiteLabelMode } from "@kubera/common";

import { filterDataFromPortfolioDetails } from "components/grid/GridDataModel";
import { category } from "components/dashboard/DashboardComponent";

const isInViewMode = isAppInViewMode();
const isWhiteLabelSite = isAppInWhiteLabelMode();

const Container = styled.div`
  position: relative;
  z-index: 1;
  padding: 0 17px 0 20px;
  min-width: 222px;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.headsUpDesktopBannerCLR};
`;

const Desc = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: ${props => props.theme.headsUpDesktopBannerCLR};

  a {
    color: ${props => props.theme.headsUpDesktopBannerCLR};
  }
`;

const HeadsUpDesktopBanner = ({ portfolio }) => {
  const userPreferences = useSelector(userPreferencesSelector);

  if (!portfolio) {
    return null;
  }

  const assets = filterDataFromPortfolioDetails(
    portfolio.details,
    row => !!row.value,
    void 0,
    sheet => sheet.category === category.ASSET
  );

  const showBanner =
    !isInViewMode &&
    !isWhiteLabelSite &&
    (!userPreferences || !userPreferences.isHeadsUpShown) &&
    assets.rows.length !== 0;

  if (showBanner) {
    return (
      <Container>
        <Title>{i18n.t("headsUpMsg.appDownloadTitle")}</Title>
        <Desc
          dangerouslySetInnerHTML={{
            __html: i18n.t("headsUpMsg.appDownloadDesc")
          }}
        ></Desc>
      </Container>
    );
  }

  return null;
};

export default HeadsUpDesktopBanner;
