import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import PrimaryButton from "components/button/PrimaryButton";
import {
  accountPlanSelector,
  getUserAPIKeys,
  createUserAPIKey,
  showToastTip,
  deleteUserAPIKey,
  isAppInWhiteLabelMode,
  isAppInViewMode
} from "@kubera/common";
import NewKeyDialog from "./APIAccessDialogs/NewKeyDialog";
import Loader from "components/loader/Loader";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import DownloadKeyDialog from "./APIAccessDialogs/DownloadKeyDialog";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const Header = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  margin-bottom: 26px;
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  a {
    color: black;
  }
`;

const GenerateKeyButton = styled(PrimaryButton)`
  width: 196px;
  height: 44px;
`;

const APIKeyContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 40px;
`;

const DeleteButton = styled.a`
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  text-align: left;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor} !important;
  margin-left: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

const APIKeyContainerHeader = styled.div`
  margin-top: ${props => (!props.isFirst ? "14px" : "0")};
`;

const Subtext = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  font-feature-settings: "ss01" on;
  color: black;
  opacity: 0.5;
  margin-top: -2px;
`;

const MyLoader = styled(Loader)`
  height: 150px;
`;

class APIAccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isGenerateKeyLoading: false,
      apiKeys: [],
      error: null,
      fetchingKeys: false,
      newKey: null,
      keyToDelete: null,
      showNewKeyDialog: false,
      showDeleteKeyDialog: false,
      showDownloadKeyDialog: false
    };
    this.createAPIKey = this.createAPIKey.bind(this);
    this.showErrorToast = this.showErrorToast.bind(this);
    this.deleteAPIKey = this.deleteAPIKey.bind(this);
    this.createAPIKey = this.createAPIKey.bind(this);
    this.handleDialogDismiss = this.handleDialogDismiss.bind(this);
    this.handleGenerateKeyPress = this.handleGenerateKeyPress.bind(this);
  }

  showErrorToast(error) {
    this.props.showToastTip("TIP", `${i18n.t("genericExceptionTitle")}`, null, 5000);
  }

  componentDidMount() {
    this.getAPIKeys();
  }

  getAPIKeys() {
    this.setState({ fetchingKeys: true });
    getUserAPIKeys()
      .then(data => {
        const sortedData = data.sort((a, b) => a.tsCreated - b.tsCreated);
        this.setState({ apiKeys: sortedData });
      })
      .catch(this.showErrorToast)
      .finally(() => this.setState({ fetchingKeys: false }));
  }

  isEligible() {
    return !isAppInWhiteLabelMode() && !isAppInViewMode();
  }

  createAPIKey(payload) {
    this.setState({ isGenerateKeyLoading: true });
    createUserAPIKey(payload)
      .then(data =>
        this.setState({ apiKeys: [...this.state.apiKeys, data], newKey: data, showDownloadKeyDialog: true })
      )
      .catch(this.showErrorToast)
      .finally(() => this.setState({ isGenerateKeyLoading: false }));
  }

  handleDeletePress(key) {
    this.setState({ showDeleteKeyDialog: true, keyToDelete: key });
  }

  handleGenerateKeyPress() {
    this.setState({ showNewKeyDialog: true });
  }

  handleDialogDismiss() {
    this.setState({ showDeleteKeyDialog: false, showNewKeyDialog: false, showDownloadKeyDialog: false });
  }

  deleteAPIKey() {
    deleteUserAPIKey(this.state.keyToDelete)
      .then(() => {
        this.getAPIKeys();
        this.setState({ showDeleteKeyDialog: false, keyToDelete: null });
      })
      .catch(this.showErrorToast);
  }

  render() {
    return (
      <Container className={this.props.className}>
        <Header>{i18n.t("accountSettings.apiAccessTitle")}</Header>
        <Desc
          dangerouslySetInnerHTML={{
            __html: i18n.t("accountSetting.apiAccessDescTop")
          }}
        />
        {!this.state.fetchingKeys ? (
          <APIKeyContainer>
            {this.isEligible() && this.state.apiKeys.length === 0 && (
              <GenerateKeyButton
                title={i18n.t("generateAPIKey")}
                data-cy="generateAPIKeyButton"
                onClick={this.handleGenerateKeyPress}
                isLoading={this.state.isGenerateKeyLoading}
              />
            )}

            {this.state.apiKeys.map((key, index) => (
              <APIKeyContainerHeader key={key.id} isFirst={index === 0}>
                <Desc>
                  <strong data-private>
                    {key.name}: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxx{key.keyMask}
                  </strong>
                  <DeleteButton onClick={() => this.handleDeletePress(key.id)}>{i18n.t("DELETE")}</DeleteButton>
                </Desc>
                <Subtext>{`${i18n.t(key.readonly ? "readOnlyAPIAccess" : "readWriteAPIAccess")}${
                  key.allowedIp ? `・${i18n.t("IP Address")}: ${key.allowedIp}` : ""
                }`}</Subtext>
              </APIKeyContainerHeader>
            ))}
          </APIKeyContainer>
        ) : (
          <MyLoader />
        )}
        <Desc
          dangerouslySetInnerHTML={{
            __html: i18n.t("accountSetting.apiAccessDescBottom")
          }}
        />
        {this.state.showNewKeyDialog && (
          <NewKeyDialog
            onDismiss={this.handleDialogDismiss}
            onSave={this.createAPIKey}
            defaultName={`API Key ${this.state.apiKeys.length + 1}`}
          />
        )}
        {this.state.showDeleteKeyDialog && (
          <ConfirmationDialog
            title={i18n.t("deleteAPIKey")}
            description={i18n.t("sure")}
            positiveButtonTitle={i18n.t("delete")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleDialogDismiss}
            handlePositiveButtonClick={this.deleteAPIKey}
          />
        )}
        {this.state.showDownloadKeyDialog && (
          <DownloadKeyDialog onDismiss={this.handleDialogDismiss} data={this.state.newKey} />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  accountPlan: accountPlanSelector(state)
});

const mapDispatchToProps = {
  showToastTip: showToastTip
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(APIAccess);
