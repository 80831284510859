import React from "react";
import styled from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import i18n from "i18next";
import CurrencyLabel from "components/labels/CurrencyLabel";
import ChangeLabel from "components/labels/ChangeLabel";
import ContextMenu from "components/contextmenu/ContextMenu";
import { getValueChangeContributionsForNetworth, store, convertCurrency, chartTimeRange } from "@kubera/common";
import { category } from "components/dashboard/DashboardComponent";
import ValueChangeTable from "components/table/ValueChangeTable";
import { ReactComponent as DownArrowIcon } from "assets/images/menu_downarrow.svg";

export const changeMode = {
  INVESTABLE: "investable",
  NETWORTH: "networth"
};

const ChangeDialog = styled(Dialog)`
  width: 627px;
  min-height: 633px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 44px 50px 80px 50px;
  width: 100%;
`;

const CurrentTotal = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.015em;
  font-feature-settings: "ss01" on, "calt" off;
`;

const SelectTimeRangeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
`;

const SelectTimeRangeButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  padding-bottom: 3px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  text-transform: uppercase;
`;

const SelectTimeRangeButtonIcon = styled(DownArrowIcon)`
  margin-left: 3px;
  margin-bottom: 1px;
`;

const TotalChange = styled(ChangeLabel)`
  margin-top: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  .child-container {
    justify-content: flex-start;
  }
`;

const TabValueChange = styled(ChangeLabel)`
  margin-top: 16px;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;
  .child-container {
    justify-content: flex-start;
  }
`;

const ChangeTabs = styled(Tabs)`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const ChangeTabList = styled(TabList)`
  display: ${props => (props.showtabselector === 1 ? "flex" : "none")};
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
  padding-bottom: 8px;
`;

const ChangeTab = styled(Tab)`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.35);
  padding-bottom: 8px;
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;

  &.is-selected {
    color: black;
    border-bottom: 2px solid rgba(0, 0, 0, 1);
  }
`;

const ChangeTabPanel = styled(TabPanel)`
  margin-top: -10px;
  display: none;
  border-top: ${props => (props.showtabselector === 1 ? "2px solid rgba(0, 0, 0, 0.1)" : "")};
  box-sizing: border-box;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

class NetWorthTotalChangeDialog extends React.Component {
  constructor(props) {
    super(props);

    const selectedIndex = 0;
    this.state = { tabIndex: selectedIndex, currentTimeRange: props.currentTimeRange };

    this.tabs = this.getTabs();

    this.handleTimeRangeSelectionButtonClick = this.handleTimeRangeSelectionButtonClick.bind(this);
    this.handleTimeRangeMenuSelection = this.handleTimeRangeMenuSelection.bind(this);
    this.contextMenuRef = React.createRef();

    this.setTab = this.setTab.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.currentTimeRange !== prevState.currentTimeRange) {
      this.tabs = this.getTabs();
    }
  }

  handleOverlayDismiss() {
    if (this.props.onDismiss) {
      this.props.onDismiss();
    }
  }

  setTab(tabIndex) {
    this.setState({
      tabIndex
    });
  }

  getTabs() {
    var tabs = [];

    if (this.props.mode === changeMode.NETWORTH) {
      tabs.push({
        title: i18n.t("assetsComponent.title"),
        category: category.ASSET,
        panelComponent: this.getTabPanel(this.props.mode, category.ASSET)
      });

      tabs.push({
        title: i18n.t("debtsComponent.title"),
        category: category.DEBT,
        panelComponent: this.getTabPanel(this.props.mode, category.DEBT)
      });
    } else if (this.props.mode === changeMode.INVESTABLE) {
      tabs.push({
        title: i18n.t("assetsComponent.title"),
        category: category.ASSET,
        panelComponent: this.getTabPanel(this.props.mode, category.ASSET)
      });
    }
    return tabs;
  }

  getTimeRangeMenuItems() {
    return [
      [
        { id: chartTimeRange.WEEKLY, label: "1 Week" },
        { id: chartTimeRange.MONTHLY, label: "1 Month" },
        { id: chartTimeRange.QUARTERLY, label: "3 Months" },
        { id: chartTimeRange.YEARLY, label: "1 Year" },
        { id: chartTimeRange.YTD, label: "YTD" },
        { id: chartTimeRange.ALL, label: "All-time" }
      ]
    ];
  }

  handleTimeRangeSelectionButtonClick(event) {
    const targetPosition = event.target.getBoundingClientRect();
    const menuItems = this.getTimeRangeMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === this.state.currentTimeRange) {
        item.selected = true;
      }
    }

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.right + targetPosition.width,
      targetPosition.top + targetPosition.height,
      false,
      event.target
    );
  }

  getCurrentlySelectedTimeRangeText() {
    const menuItems = this.getTimeRangeMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === this.state.currentTimeRange) {
        return item.label;
      }
    }
  }

  handleTimeRangeMenuSelection(item) {
    this.setState({ currentLastDataPointIndex: null }, () => {
      this.setState({
        isHoveredOver: false
      });
    });

    this.setState({ currentTimeRange: item.id });
  }

  getTabPanel(mode, tabCategory) {
    const currency = this.props.currency;
    const startDataPointCurrency = this.props.startDataPointCurrency;
    // We need to create a deep copy so that changes made to the data point don't get saved
    // to original grid object

    const allTimeRangeEdgeDataPoints = this.props.allTimeRangeEdgeDataPoints;
    const currTimeRangeEdgeDataPoints = allTimeRangeEdgeDataPoints[this.state.currentTimeRange];

    const startDataPoint = JSON.parse(JSON.stringify(currTimeRangeEdgeDataPoints.start));
    const startValueCustodians = tabCategory === category.DEBT ? startDataPoint.debt : startDataPoint.asset;
    const endDataPoint = JSON.parse(JSON.stringify(currTimeRangeEdgeDataPoints.end));
    const endValueCustodians = tabCategory === category.DEBT ? endDataPoint.debt : endDataPoint.asset;

    var valueChangeContributions = [];
    var tabTotalStartValue = 0;
    var tabTotalEndValue = 0;

    if (mode === changeMode.NETWORTH) {
      valueChangeContributions = getValueChangeContributionsForNetworth(
        store.getState(),
        currency,
        startDataPointCurrency,
        startValueCustodians,
        endValueCustodians
      );
      tabTotalStartValue = tabCategory === category.DEBT ? startDataPoint.debtTotal : startDataPoint.assetTotal;
      tabTotalEndValue = tabCategory === category.DEBT ? endDataPoint.debtTotal : endDataPoint.assetTotal;

      if (startDataPointCurrency !== this.props.currency) {
        tabTotalStartValue = convertCurrency(tabTotalStartValue, startDataPointCurrency, this.props.currency);
        tabTotalEndValue = convertCurrency(tabTotalEndValue, startDataPointCurrency, this.props.currency);
      }
    } else if (mode === changeMode.INVESTABLE) {
      valueChangeContributions = getValueChangeContributionsForNetworth(
        store.getState(),
        currency,
        startDataPointCurrency,
        startValueCustodians,
        endValueCustodians,
        true
      );
    }

    return (
      <PanelContainer>
        {mode !== changeMode.INVESTABLE && (
          <TabValueChange
            currency={currency}
            isAnimationAllowed={false}
            startValue={tabTotalStartValue}
            endValue={tabTotalEndValue}
            disableShortFormat={true}
            hidePercentage={true}
            disableColor={tabCategory === category.DEBT}
          />
        )}
        <ValueChangeTable
          rows={valueChangeContributions}
          currency={currency}
          disableColor={tabCategory === category.DEBT}
          disableRoundDown={true}
        />
      </PanelContainer>
    );
  }

  render() {
    const { currency, total, totalAllValues, mode } = this.props;
    const tabs = this.tabs;
    const showtabselector = mode !== changeMode.INVESTABLE ? 1 : 0;
    const currentTimeRangeText = this.getCurrentlySelectedTimeRangeText();
    const currentTimeRange = this.state.currentTimeRange;

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <ChangeDialog className={this.props.className}>
          <Container>
            <CurrentTotal
              currency={currency}
              value={total}
              maxLongFormatValue={99999}
              abbreviate={false}
              roundDown={true}
            />
            <SelectTimeRangeContainer>
              <SelectTimeRangeButton onClick={this.handleTimeRangeSelectionButtonClick}>
                {currentTimeRangeText}
                <SelectTimeRangeButtonIcon />
              </SelectTimeRangeButton>
            </SelectTimeRangeContainer>
            <ContextMenu width={132} ref={this.contextMenuRef} onSelection={this.handleTimeRangeMenuSelection} />
            <TotalChange
              currency={currency}
              isAnimationAllowed={false}
              startValue={totalAllValues[currentTimeRange].startValue}
              endValue={totalAllValues[currentTimeRange].endValue}
              disableShortFormat={true}
            />
            <ChangeTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={this.state.tabIndex}
              onSelect={this.setTab}
            >
              <ChangeTabList showtabselector={showtabselector}>
                {tabs.map((tab, index) => (
                  <ChangeTab data-cy={"changeTab" + index} key={index}>
                    {tab.title}
                  </ChangeTab>
                ))}
              </ChangeTabList>
              {tabs.map((tab, index) => (
                <ChangeTabPanel key={index} showtabselector={showtabselector}>
                  {tab.panelComponent}
                </ChangeTabPanel>
              ))}
            </ChangeTabs>
          </Container>
        </ChangeDialog>
      </DialogOverlay>
    );
  }
}

export default NetWorthTotalChangeDialog;
