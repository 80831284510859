import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { routes, hashParams, queryParams } from "routes";
import { store, accountLinkingService, custodianSheetSelector, custodianSelector } from "@kubera/common";
import saltEdgeLogo from "assets/images/saltedge_logo.png";
import yodleeLogo from "assets/images/yodlee_logo.svg";
import flinksLogo from "assets/images/flinks_logo.png";
import QRCode from "qrcode.react";
import { linkAccountMode } from "components/link_account/LinkAccountComponent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const QrInstructionsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: -20px -30px 0px -30px;
  padding: 40px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-style: normal;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const QrProviderName = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const QrProviderSteps = styled.div`
  margin-top: 12px;
  white-space: pre-wrap;
`;

const QrCodeContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 15px;
`;

const QrCodeImage = styled(QRCode)`
  margin-right: 15px;
`;

const QrCodeDescription = styled.div`
  max-width: 190px;
  margin-top: -5px;
`;

const QrProviderLogo = styled.div`
  width: 80px;
  height: 27px;
  margin-left: -30px;
  margin-top: 10px;
  margin-bottom: -20px;
  background-color: transparent;
  background-image: url(${props => props.logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
`;

class QrLinkAccountComponent extends React.Component {
  getLinkingUrlForSelectedProvider() {
    const custodian = custodianSelector(store.getState(), this.props.custodianId);
    const custodianSheet = custodianSheetSelector(store.getState(), this.props.custodianId);

    var url = `${window.location.protocol}//${window.location.host}${routes.MOBILENAV}?${queryParams.PORTFOLIO_ID}=${this.props.portfolioId}#${hashParams.MODAL}=link_account&link_category=${this.props.category}&link_mode=${this.props.mode}&link_service=${this.props.linkType}&link_provider=${this.props.linkProviderId}&link_inline=1`;
    if (custodian && custodianSheet) {
      url += `&${hashParams.SECTION_ID}=${custodian.sectionId}&${hashParams.SHEET_ID}=${custodianSheet.id}`;
    }
    if (this.props.mode !== linkAccountMode.LINK) {
      url += `&${hashParams.ID}=${this.props.custodianId}`;
    }
    console.log(url);
    return url;
  }

  render() {
    return (
      <Container>
        <QrInstructionsContainer>
          <QrProviderName>{this.props.linkProviderName}</QrProviderName>
          <QrProviderSteps
            dangerouslySetInnerHTML={{
              __html: i18n.t("linkAccount.qrSteps").replace("%s1%", this.props.linkProviderName)
            }}
          />
          <QrCodeContainer>
            <QrCodeImage
              value={this.getLinkingUrlForSelectedProvider()}
              size={120}
              level={"L"}
              includeMargin={false}
              renderAs={"svg"}
            />
            <QrCodeDescription
              dangerouslySetInnerHTML={{
                __html: i18n.t("linkAccount.qrDescription").replace("%s1%", this.props.linkProviderName)
              }}
            />
          </QrCodeContainer>
        </QrInstructionsContainer>
        {(this.props.linkType === accountLinkingService.SALTEDGE ||
          this.props.linkType === accountLinkingService.SALTEDGE_EU) && (
          <QrProviderLogo logo={saltEdgeLogo} onClick={() => window.kuberaOpen("https://www.saltedge.com/")} />
        )}
        {this.props.linkType === accountLinkingService.YODLEE && (
          <QrProviderLogo logo={yodleeLogo} onClick={() => window.kuberaOpen("https://www.yodlee.com/")} />
        )}
        {(this.props.linkType === accountLinkingService.FLINKS_BANKING ||
          this.props.linkType === accountLinkingService.FLINKS_INVESTMENT) && (
          <QrProviderLogo logo={flinksLogo} onClick={() => window.kuberaOpen("https://www.flinks.com/")} />
        )}
      </Container>
    );
  }
}

export default QrLinkAccountComponent;
