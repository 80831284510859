import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import TableComponent from "components/table/TableComponent";
import { TableData, TableColumnData, TableRowData } from "components/table/TableDataModel";
import {
  shortFormatNumberWithCurrency,
  getPastDateString,
  getLastSeenDateString,
  archiveWlClient,
  deleteWlClient,
  setWlClientContext,
  wlSiteDefaultCurrencySelector,
  wlUserStatus,
  isEmailValid,
  showToastTip,
  toastType,
  siteConfigSelector,
  wlSetupType,
  ApiClient,
  canAmcUserManageOtherUsers,
  wlManagerSelector,
  store,
  wlManagersSelector,
  assignManagerToClient,
  isWlAdvisorModeOn,
  userTypes,
  updateUserPreferences,
  userPreferencesSelector
} from "@kubera/common";
import { contextMenuItemType, getAssignWlkClient } from "components/contextmenu/ContextMenu";
import { routes, queryParams } from "routes";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import InviteClientDialogComponent from "components/white_label/InviteClientDialogComponent";
import ShareROLinkComponent from "components/multiuser/ShareROLinkComponent";
import { copyToClipboard } from "utilities/FileUtils";
import { downloadFile } from "utilities/FileUtils";
import AssignClientToAdvisorDialog from "components/white_label/AssignClientToAdvisorDialog";

import MultiuserManagementComponent from "./multiuser/MultiuserManagementComponent";
import AdminBillingModal from "./billing/AdminBillingModal";

const ClentPortfolioTabName = "kubera_client_portfolio_tab";

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const ClientTable = styled(TableComponent)`
  width: 100%;
`;

const ClientNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ClientNameActionButton = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-feature-settings: "ss01" on, "calt" off;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
`;

const ClientDetailsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ClientCellName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

const ClientDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.5);
`;

const EmptyTableContainer = styled.div`
  padding: 18px 20px 0px 50px;
`;

const InviteClientButton = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
`;

const NoSearchResultsDescription = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const CreatedByName = styled.div`
  width: 100%;
`;

class ActiveClientsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showDeleteDialog: false, userToDelete: null, showAssignAdvisorDialogForClient: null };

    this.handleContextMenuSelection = this.handleContextMenuSelection.bind(this);
    this.handleDeleteConfirmation = this.handleDeleteConfirmation.bind(this);
    this.handleDeleteCancellation = this.handleDeleteCancellation.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handleClientActionButtonClick = this.handleClientActionButtonClick.bind(this);
    this.handleAdvisorSelectionOnDismiss = this.handleAdvisorSelectionOnDismiss.bind(this);
    this.handleAdvisorSelectionForClient = this.handleAdvisorSelectionForClient.bind(this);
  }

  handleContextMenuSelection(item, row) {
    if (item.id === contextMenuItemType.REMOVE_ACCESS.id) {
      this.props.archiveWlClient(row.rowObject);
    } else if (item.id === contextMenuItemType.ARCHIVE_CLIENT.id) {
      this.props.archiveWlClient(row.rowObject);
    } else if (item.id === contextMenuItemType.DELETE.id) {
      this.setState({ showDeleteDialog: true, userToDelete: row.rowObject });
    } else if (
      item.id === contextMenuItemType.RE_INVITE_CLIENT.id ||
      item.id === contextMenuItemType.CLIENT_CHANGE_EMAIL.id ||
      item.id === contextMenuItemType.INVITE.id ||
      item.id === contextMenuItemType.RO_CLIENT_CHANGE_EMAIL.id ||
      item.id === contextMenuItemType.RESET_VIEW_LINK.id
    ) {
      InviteClientDialogComponent.show(this.props.history, this.props.location, row.rowObject.userId);
    } else if (item.id === contextMenuItemType.COPY_VIEW_LINK.id) {
      const viewUrl = ShareROLinkComponent.getViewUrl(row.rowObject.share.id);

      copyToClipboard(viewUrl);
      this.props.showToastTip(
        toastType.TIP,
        i18n.t("linkCopied"),
        undefined,
        5000,
        undefined,
        undefined,
        undefined,
        true,
        undefined,
        true
      );
    } else if (item.id === contextMenuItemType.WLK_CLIENT_DOWNLOAD_DATA.id) {
      this.props.setWlClientContext(row.rowObject);
      this.props.showToastTip(
        toastType.TIP,
        i18n.t("downloadingWlkClientData"),
        undefined,
        5000,
        undefined,
        undefined,
        undefined,
        true,
        undefined,
        true
      );

      ApiClient.getWlClientDataDownloadUrl(row.rowObject.userId, row.rowObject.currentPortfolio.portfolioId)
        .then(url => {
          downloadFile(url, row.rowObject.currentPortfolio.name);
        })
        .catch(apiError => {});
    } else if (item.id === contextMenuItemType.WLK_ASSIGN_CLIENT.id) {
      this.setState({ showAssignAdvisorDialogForClient: row.rowObject });
    } else if (item.id === contextMenuItemType.MANAGE_CLIENT_USERS.id) {
      MultiuserManagementComponent.show(this.props.history, this.props.location, row.rowObject.userId);
    } else if (item.id === contextMenuItemType.WLK_ADMIN_BILLING.id) {
      AdminBillingModal.show(
        this.props.history,
        this.props.location,
        row.rowObject.userId,
        row.rowObject.currentPortfolio.portfolioId
      );
    }
  }

  handleRowClick(e, row) {
    this.props.setWlClientContext(row.rowObject);

    const url = `${routes.ASSETS}?${queryParams.PORTFOLIO_ID}=${row.rowObject.currentPortfolio.portfolioId}`;
    const portfolioWindow = window.kuberaOpen(url, ClentPortfolioTabName);
    portfolioWindow.wlClientContext = row.rowObject;
  }

  handleSortChange(columnSortKey, decreasingOrder) {
    this.props.updateUserPreferences({
      wlkActiveClientsSortKey: columnSortKey,
      wlkActiveClientsSortDecreasingOrder: decreasingOrder
    });
  }

  handleDeleteCancellation() {
    this.setState({ userToDelete: null, showDeleteDialog: false });
  }

  handleDeleteConfirmation() {
    this.setState({ showDeleteDialog: false });
    this.props.deleteWlClient(this.state.userToDelete);
  }

  handleClientActionButtonClick(e, client) {
    e.stopPropagation();

    if (
      this.props.siteConfig.type !== wlSetupType.ADMIN_WR_CLIENT_RO &&
      this.props.siteConfig.showBilling === 1 &&
      client.status === wlUserStatus.ACCEPTED
    ) {
      AdminBillingModal.show(
        this.props.history,
        this.props.location,
        client.userId,
        client.currentPortfolio.portfolioId
      );

      return;
    }

    InviteClientDialogComponent.show(this.props.history, this.props.location, client.userId);
  }

  getPortfolioName(client) {
    var name =
      client.portfolio.length > 1 ? `${client.clientName} (${client.currentPortfolio.name})` : client.clientName;

    if (client.status === wlUserStatus.INVITED) {
      name = `${name} (${i18n.t("invited")})`;
    }
    return name;
  }

  showActionButton(client) {
    if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
      return !client.share === true || !client.share.tsLastAccess === true;
    }
    return (
      client.status === wlUserStatus.ADDED ||
      client.status === wlUserStatus.INVITED ||
      client.status === wlUserStatus.ACCEPTED
    );
  }

  getActionButton(client) {
    if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
      return !client.share === true ? i18n.t("inviteClient") : i18n.t("reInviteClient");
    }

    if (
      this.props.siteConfig.showBilling === 1 &&
      client.status === wlUserStatus.ACCEPTED &&
      client.type !== userTypes.SUB_USER &&
      this.props.siteConfig.stripeConnectedAccount &&
      !(client.paymentMethod && client.paymentMethod.paymentMethodId)
    ) {
      return i18n.t("addPaymentMethod");
    } else if (client.status === wlUserStatus.ADDED) {
      return i18n.t("inviteClient");
    } else if (client.status === wlUserStatus.INVITED) {
      return i18n.t("reInviteClient");
    }
  }

  getClientDescription(client) {
    var description = "";

    if (
      isEmailValid(client.email) &&
      !(client.email.indexOf("client-8efb") !== -1 && client.email.endsWith("@kubera.com"))
    ) {
      const subUserLength = client.subUser ? client.subUser.length : 0;
      description += `${client.email} ${subUserLength ? `+${subUserLength}` : ""}`;
    }
    return description;
  }

  getTableData() {
    const nameColumn = new TableColumnData(
      i18n.t("client"),
      i18n.t("client"),
      client => {
        return this.getPortfolioName(client);
      },
      client => {
        return (
          <ClientNameContainer>
            <ClientDetailsContainer>
              <ClientCellName>{this.getPortfolioName(client)}</ClientCellName>
              <ClientDescription>{this.getClientDescription(client)}</ClientDescription>
            </ClientDetailsContainer>
            {this.showActionButton(client) && (
              <ClientNameActionButton onClick={e => this.handleClientActionButtonClick(e, client)}>
                {this.getActionButton(client)}
              </ClientNameActionButton>
            )}
          </ClientNameContainer>
        );
      },
      "string"
    );

    const aumColumn = new TableColumnData(
      i18n.t("aum"),
      i18n.t("aum"),
      client => {
        return client.portfolio.length > 0 ? client.currentPortfolio.aumTotal : 0;
      },
      client => {
        return shortFormatNumberWithCurrency(
          client.portfolio.length > 0 ? client.currentPortfolio.aumTotal : 0,
          this.props.wlDefaultCurrency,
          true,
          true
        );
      }
    );

    const investableColumn = new TableColumnData(
      i18n.t("investable"),
      i18n.t("investable"),
      client => {
        return client.portfolio.length > 0 ? client.currentPortfolio.investibleTotal : 0;
      },
      client => {
        return shortFormatNumberWithCurrency(
          client.portfolio.length > 0 ? client.currentPortfolio.investibleTotal : 0,
          this.props.wlDefaultCurrency,
          true,
          true
        );
      }
    );

    const netWorthColumn = new TableColumnData(
      i18n.t("networth"),
      i18n.t("networth"),
      client => {
        return client.portfolio.length > 0 ? client.currentPortfolio.value : 0;
      },
      client => {
        return shortFormatNumberWithCurrency(
          client.portfolio.length > 0 ? client.currentPortfolio.value : 0,
          this.props.wlDefaultCurrency,
          true,
          true
        );
      }
    );

    const lastSeenColumn = new TableColumnData(
      i18n.t("lastSeen"),
      i18n.t("lastSeen"),
      client => {
        if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
          return !client.share === false ? client.share.tsLastAccess : undefined;
        }
        return Math.max.apply(null, [client.tsLastAccess, ...client.subUser.map(subUser => subUser.tsLastAccess)]);
      },
      (client, columnValue) => {
        return !columnValue === true ? "--" : getLastSeenDateString(columnValue * 1000);
      }
    );

    const createdByColumn = new TableColumnData(
      i18n.t("createdBy"),
      i18n.t("createdBy"),
      client => {
        if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
          return !client.share === false
            ? (wlManagerSelector(store.getState(), client.share.invitedUserId) || {}).name
            : undefined;
        }
        return (wlManagerSelector(store.getState(), client.invitedUserId) || {}).name;
      },
      (client, columnValue) => {
        if (columnValue) {
          return <CreatedByName title={columnValue}>{columnValue}</CreatedByName>;
        }

        return null;
      },
      "string"
    );

    const joinedColumn = new TableColumnData(
      i18n.t("created"),
      i18n.t("created"),
      client => {
        return client.tsCreated;
      },
      client => {
        return getPastDateString(new Date(client.tsCreated * 1000));
      }
    );

    let columns = [nameColumn, aumColumn, investableColumn, netWorthColumn, lastSeenColumn, joinedColumn];

    if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
      const lastUpdatedColumn = new TableColumnData(
        i18n.t("updated"),
        i18n.t("updated"),
        client => {
          return client.tsLastUpdate;
        },
        (client, columnValue) => {
          return !columnValue === true ? "--" : getLastSeenDateString(columnValue * 1000);
        }
      );

      columns = [nameColumn, aumColumn, lastSeenColumn, lastUpdatedColumn, joinedColumn];
    }

    if (!this.props.isWlAdvisorModeOn) {
      columns.splice(columns.length - 1, 0, createdByColumn);
    }

    const clients = this.props.clients;
    const isStripeAccountConnected =
      this.props.siteConfig.stripeConnectedAccount && this.props.siteConfig.stripeConnectedAccount.url;
    var rows = [];

    for (const client of clients) {
      if (!client.portfolio === false) {
        for (const portfolio of client.portfolio) {
          var rowClient = { ...client };
          rowClient.currentPortfolio = portfolio;

          const manager =
            this.props.isWlAdvisorModeOn && this.props.canWlUserManageOtherUsers
              ? wlManagerSelector(store.getState(), client.managerUserId, true)
              : null;
          rowClient.manager = manager;

          const manageClientUserOption =
            this.props.siteConfig.type !== wlSetupType.ADMIN_WR_CLIENT_RO
              ? [
                  [
                    ...(this.props.siteConfig.showBilling === 1 && isStripeAccountConnected
                      ? [contextMenuItemType.WLK_ADMIN_BILLING]
                      : []),
                    contextMenuItemType.MANAGE_CLIENT_USERS
                  ]
                ]
              : [];
          rows.push(
            new TableRowData(rowClient, row => {
              if (!row.rowObject.share === false) {
                const items = [...manageClientUserOption];
                if (!manager === false) {
                  items.push([getAssignWlkClient(manager)]);
                }
                items.push([contextMenuItemType.COPY_VIEW_LINK]);
                items.push([contextMenuItemType.WLK_CLIENT_DOWNLOAD_DATA]);
                items.push([
                  contextMenuItemType.RO_CLIENT_CHANGE_EMAIL,
                  contextMenuItemType.RESET_VIEW_LINK,
                  contextMenuItemType.REMOVE_ACCESS
                ]);

                return items;
              } else if (row.rowObject.status !== wlUserStatus.ACCEPTED) {
                const items = [...manageClientUserOption];
                if (!manager === false) {
                  items.push([getAssignWlkClient(manager)]);
                }
                items.push([contextMenuItemType.WLK_CLIENT_DOWNLOAD_DATA]);

                if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO && !row.rowObject.share === true) {
                  items.push([contextMenuItemType.ARCHIVE_CLIENT]);
                }

                return items;
              }

              const items = [];
              if (!manager === false) {
                items.push([getAssignWlkClient(manager)]);
              }
              items.push(...manageClientUserOption, [contextMenuItemType.WLK_CLIENT_DOWNLOAD_DATA]);

              if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO && !row.rowObject.share === true) {
                items.push([contextMenuItemType.ARCHIVE_CLIENT]);
              }

              return items;
            })
          );
        }
      }
    }

    const sortKey = this.props.userPreferences.wlkActiveClientsSortKey || joinedColumn.sortKey;
    const decreasingOrder = this.props.userPreferences.wlkActiveClientsSortDecreasingOrder;
    return new TableData(columns, rows, sortKey, decreasingOrder);
  }

  handleAdvisorSelectionOnDismiss() {
    this.setState({ showAssignAdvisorDialogForClient: null });
  }

  handleAdvisorSelectionForClient(manager) {
    this.props.assignManagerToClient(this.state.showAssignAdvisorDialogForClient.userId, manager.userId);
    this.handleAdvisorSelectionOnDismiss();
  }

  render() {
    const clients = this.props.clients;
    if (!clients === true || clients.length === 0) {
      if (!this.props.searchText === false) {
        return (
          <Container>
            <EmptyTableContainer>
              <NoSearchResultsDescription>{i18n.t("emptyClientSearchResults")}</NoSearchResultsDescription>
            </EmptyTableContainer>
          </Container>
        );
      }

      return (
        <Container>
          <EmptyTableContainer>
            <InviteClientButton onClick={this.props.onInviteButtonClick}>
              {i18n.t("createNewClient")}
            </InviteClientButton>
          </EmptyTableContainer>
        </Container>
      );
    }

    const tableData = this.getTableData();
    const showDeleteDialog = this.state.showDeleteDialog;
    return (
      <Container>
        <ClientTable
          data={tableData}
          onContextMenuSelection={this.handleContextMenuSelection}
          onRowClick={this.handleRowClick}
          onSortChange={this.handleSortChange}
        />
        {showDeleteDialog === true && (
          <ConfirmationDialog
            title={i18n.t("deleteUserDialog.title")}
            description={i18n.t("sure")}
            positiveButtonTitle={i18n.t("delete")}
            negativeButtonTitle={i18n.t("cancel")}
            handlePositiveButtonClick={this.handleDeleteConfirmation}
            handleNegativeButtonClick={this.handleDeleteCancellation}
          />
        )}
        {!this.state.showAssignAdvisorDialogForClient === false && (
          <AssignClientToAdvisorDialog
            managers={this.props.wlManagers}
            client={this.state.showAssignAdvisorDialogForClient}
            selectedManager={this.state.showAssignAdvisorDialogForClient.manager}
            onDismiss={this.handleAdvisorSelectionOnDismiss}
            onAdvisorSelection={this.handleAdvisorSelectionForClient}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  wlDefaultCurrency: wlSiteDefaultCurrencySelector(state),
  siteConfig: siteConfigSelector(state),
  canWlUserManageOtherUsers: canAmcUserManageOtherUsers(state),
  wlManagers: wlManagersSelector(state),
  isWlAdvisorModeOn: isWlAdvisorModeOn(state),
  userPreferences: userPreferencesSelector(state)
});

const mapDispatchToProps = {
  archiveWlClient: archiveWlClient,
  deleteWlClient: deleteWlClient,
  setWlClientContext: setWlClientContext,
  showToastTip: showToastTip,
  assignManagerToClient: assignManagerToClient,
  updateUserPreferences: updateUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActiveClientsComponent));
