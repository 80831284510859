import styled from "styled-components";

import slideAnimation from "utilities/slideAnimation";
import ScrollCounter from "components/scroll_counter/ScrollCounter";

export const Container = styled.div`
  position: relative;
  background: ${props => props.theme.mobileBackground};
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  animation: ${slideAnimation};
`;

export const CategoryTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 7px;
  margin-left: 20px;
  color: ${props => props.theme.mobileTxtColor};
  font-feature-settings: "ss01" on, "calt" off;
`;

export const CategoryTotal = styled(ScrollCounter)`
  font-weight: bold;
  font-size: 36px;
  letter-spacing: -2px;
  margin-bottom: 12px;
  padding: 0 20px;
  color: ${props => props.theme.mobileTxtColor};
  font-feature-settings: "ss01" on, "calt" off;
  line-height: 32px;
  height: 32px;
  width: 100%;
  overflow-x: auto;
`;

export const Sheets = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 0 10px;
  margin-bottom: 16px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${props => `1px solid ${props.theme.mobileSeperatorColor}`};
`;
