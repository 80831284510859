import React from "react";
import styled from "styled-components";
import {
  parseNumberStringToFloat,
  getCharacterForDecimal,
  formatNumberWithKuberaNumberFormatSettings
} from "@kubera/common";
import { cellMode } from "components/grid/GridCell";

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const Input = styled.input`
  background-color: ${props => (props.disabled === true ? "rgba(0, 0, 0, 0.05)" : "#ffffff")};
`;

class EditablePercentageCell extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    var input = e.target.value.trim();
    input = input.replace("%", "");
    e.target.value = input;
    const parsedInput = input === getCharacterForDecimal() ? 0 : parseNumberStringToFloat(input);
    if (!input === false && input !== getCharacterForDecimal() && !parsedInput === true && parsedInput !== 0) {
      return;
    }

    const decimalIndex = input.indexOf(getCharacterForDecimal());
    if (!input === false && input.length - (decimalIndex + 1) > 4) {
      return;
    }
    this.props.onChange(e);
  }

  getDisplayString(value) {
    if (this.props.hasUserNotSetATargetValue) {
      if (this.props.mode === cellMode.EDIT) {
        return "";
      }
      if (!value || value === "") {
        return "";
      }
      return this.props.shouldShowApproximateSymbol
        ? `~${formatNumberWithKuberaNumberFormatSettings(value) + "%"}`
        : formatNumberWithKuberaNumberFormatSettings(value) + "%";
    } else {
      if (value === 0) {
        return value + "%";
      }
      if (value && typeof value === "string" && value.indexOf(getCharacterForDecimal()) === 0) {
        if (this.props.mode === cellMode.SELECT) {
          return `0${value}%`;
        } else if (this.props.mode === cellMode.EDIT) {
          return `0${value}`;
        }
      }
      if (!value || value === "") {
        return "";
      }
      if (this.props.mode === cellMode.SELECT) {
        return this.props.shouldShowApproximateSymbol
          ? `~${formatNumberWithKuberaNumberFormatSettings(value) + "%"}`
          : formatNumberWithKuberaNumberFormatSettings(value) + "%";
      }
      return formatNumberWithKuberaNumberFormatSettings(value);
    }
  }

  render() {
    const { className, value, disabled, ...other } = this.props;
    const displayValue = this.getDisplayString(this.props.value);
    return (
      <Container>
        <Input
          {...other}
          ref={this.inputRef}
          className={className}
          type="text"
          value={displayValue}
          size="1"
          spellCheck="false"
          autoComplete="off"
          onChange={this.handleInputChange}
          data-1p-ignore
        />
      </Container>
    );
  }
}

export default EditablePercentageCell;
