import React from "react";
import styled from "styled-components";
import GridCell from "components/grid/GridCell";
import getPrefixForDataCy from "components/grid/GridHelpers";

const Footer = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
  background-color: ${props => props.theme.gridSectionFooterBackgroundColor};
  color: ${props => props.theme.gridSectionFooterColor};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  margin-left: -1px;
  margin-right: -1px;
  border-top: 1px solid ${props => props.theme.gridSectionBorderColor};

  @media print {
    font-size: 10px !important;
  }
`;

class GridFooterRow extends React.Component {
  constructor(props) {
    super(props);

    this.dataCyIdPrefix = getPrefixForDataCy();
  }

  render() {
    return (
      <Footer>
        {this.props.columns.map((column, index) => (
          <GridCell
            dataCyId={this.dataCyIdPrefix + "H" + this.props.sheetIndex + "S" + this.props.sectionIndex + "T0C" + index}
            key={index}
            isFooter={true}
            cellType={column.type}
            cell={this.props.row.cells[index]}
            cellIndex={index}
            cellIndexToStretch={this.props.row.cellIndexToStretch}
            isLastCell={index === this.props.row.cells.length - 1}
            isLastRow={this.props.isLastRow}
            onCellUpdate={this.handleCellUpdate}
            onMoveToNextCell={this.props.onMoveToNextCell}
            onAddNewRow={this.handleAddNewRow}
            onAddNewClick={this.props.onAddNewClick}
            userPreferences={this.props.userPreferences}
            category={this.props.category}
          />
        ))}
      </Footer>
    );
  }
}

export default GridFooterRow;
