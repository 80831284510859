import React, { useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import {
  userSelector,
  userEmailSelector,
  setupPaymentIntent,
  SUBSCRIPTION_ERROR,
  fetchUser,
  guessDateInCustodianHistoryUIFormat,
  wlUpdateSubscriptionCard,
  wlUpdateUnverifiedSubscriptionCard,
  SUBSCRIPTION_ERROR_CODES,
  isMobile,
  userNameSelector,
  wlPaymentMethodStatus
} from "@kubera/common";

import PrimaryButton from "components/button/PrimaryButton";

import AccountPaymentElement from "./AccountPaymentElement";
import AccountDeclinedCardModal from "./AccountDeclinedCardModal";
import SecondaryButton from "components/button/SecondaryButton";

const isMobileDevice = isMobile();

const Container = styled.div`
  margin-bottom: 20px;
`;

const InputCardContainer = styled(AccountPaymentElement)`
  width: ${isMobileDevice ? "100%" : "528px"};
  margin-bottom: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;

const SubscribeBtn = styled(PrimaryButton)`
  width: 176px;
  height: 44px;
  background: ${props => (isMobileDevice ? props.theme.mobileColorBtn : null)};
`;

const CancelBtn = styled(SecondaryButton)`
  min-width: 137px;
  height: 44px;
`;

const RecurringChargeBlk = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  column-gap: 41px;
  color: ${props => (isMobileDevice ? props.theme.mobileTxtColor : null)};
`;

const RecurringChargeGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const RecurringChargeTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on, "calt" off;
  color: ${isMobileDevice ? null : "rgba(0, 0, 0, 0.5)"};
`;

const RecurringChargeValue = styled.div`
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on, "calt" off;
  color: ${isMobileDevice ? null : "#000"};
`;

const FormContainer = styled.form``;

const AccountCardPayment = ({
  updateMode = false,
  setIsSubmitted = () => null,
  captureError = () => null,
  handleTurnOffUpdateMode = () => null
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const user = useSelector(userSelector);
  const wlRecurringChargeSchedule = user.wl.recurringChargeSchedule || {};
  const userEmail = useSelector(userEmailSelector);
  const userName = useSelector(userNameSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState(void 0);
  const [isDeclinedError, setIsDeclinedError] = useState(false);

  const recurringScheduleDate = wlRecurringChargeSchedule.tsNextDate
    ? guessDateInCustodianHistoryUIFormat(wlRecurringChargeSchedule.tsNextDate).dateString
    : null;

  const options = useMemo(
    () => ({
      defaultValues: {
        billingDetails: { email: userEmail, name: userName }
      },
      style: {
        base: {
          color: "#32325d",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }
    }),
    [userEmail, userName]
  );

  const onSubscriptionChange = useCallback(
    async paymentMethodId => {
      let isDeclinedSubscription;
      const callbackWhenDoneFetch = () => {
        if (isDeclinedSubscription) {
          setIsLoading(false);
        }
        setIsSubmitted(true);
      };
      const onPaymentError = err => {
        setIsLoading(false);
        if (err.errorCode === SUBSCRIPTION_ERROR_CODES.DECLINED) {
          setIsDeclinedError(true);
          isDeclinedSubscription = true;
        }
      };
      await dispatch(wlUpdateSubscriptionCard(paymentMethodId)).catch(onPaymentError);
      dispatch(fetchUser(callbackWhenDoneFetch, callbackWhenDoneFetch));
    },
    [dispatch, setIsSubmitted]
  );

  const onUnverifiedSubscriptionChange = useCallback(
    async (setupIntentId, paymentMethodId) => {
      let isDeclinedSubscription;
      const callbackWhenDoneFetch = () => {
        if (isDeclinedSubscription) {
          setIsLoading(false);
        }
        setIsSubmitted(true);
      };
      const onPaymentError = err => {
        setIsLoading(false);
        if (err.errorCode === SUBSCRIPTION_ERROR_CODES.DECLINED) {
          setIsDeclinedError(true);
          isDeclinedSubscription = true;
        }
      };
      await dispatch(wlUpdateUnverifiedSubscriptionCard(setupIntentId, paymentMethodId)).catch(onPaymentError);
      dispatch(fetchUser(callbackWhenDoneFetch, callbackWhenDoneFetch));
    },
    [dispatch, setIsSubmitted]
  );

  const handleSubscribeAction = async e => {
    e.preventDefault();
    setIsLoading(true);
    const paymentIntent = await dispatch(setupPaymentIntent("monthly")).catch(() => {
      setIsLoading(false);
    });

    if (paymentIntent) {
      elements.submit();
      stripe
        .confirmSetup({
          elements,
          redirect: "if_required",
          clientSecret: paymentIntent.payload.clientSecret,
          confirmParams: {
            payment_method_data: {
              billing_details: { email: userEmail }
            }
          }
        })
        .then(result => {
          if (result.error) {
            captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_ERROR, result.error);
            setPaymentErrorMsg(result.error.message);
            setIsLoading(false);
            setIsDeclinedError(true);
          } else if (result.setupIntent.status === "succeeded") {
            onSubscriptionChange(result.setupIntent.payment_method);
          } else if (
            result.setupIntent.status === "requires_action" &&
            result.setupIntent.next_action?.type === wlPaymentMethodStatus.VERIFY_WITH_MICRODEPOSITS
          ) {
            onUnverifiedSubscriptionChange(result.setupIntent.id, result.setupIntent.payment_method);
          } else {
            captureError(SUBSCRIPTION_ERROR.CONFIRMSETUP_STATUSERROR, result.setupIntent.last_setup_error);
            setPaymentErrorMsg(result.setupIntent.last_setup_error);
            setIsLoading(false);
            setIsDeclinedError(true);
          }
        });
    }
  };

  const onDeclinedModalDismiss = () => {
    setPaymentErrorMsg(void 0);
    setIsDeclinedError(false);
  };

  return (
    <Container>
      <FormContainer onSubmit={handleSubscribeAction}>
        {wlRecurringChargeSchedule && wlRecurringChargeSchedule.amount && (
          <RecurringChargeBlk>
            <RecurringChargeGroup>
              <RecurringChargeTitle>{i18n.t("plan")}</RecurringChargeTitle>
              <RecurringChargeValue>
                {`$${wlRecurringChargeSchedule.amount / 100}/${wlRecurringChargeSchedule.type}`}
              </RecurringChargeValue>
            </RecurringChargeGroup>
            <RecurringChargeGroup>
              <RecurringChargeTitle>{i18n.t("clientBilling.nextBillingDate")}</RecurringChargeTitle>
              <RecurringChargeValue>{recurringScheduleDate}</RecurringChargeValue>
            </RecurringChargeGroup>
          </RecurringChargeBlk>
        )}
        <InputCardContainer options={options} />
        <ButtonContainer>
          <SubscribeBtn
            title={i18n.t("clientBilling.addPaymentMethodBtn")}
            data-cy="addPaymentMethodBtn"
            onClick={handleSubscribeAction}
            isLoading={isLoading}
            isDisabled={isLoading}
          />
          {updateMode && (
            <CancelBtn
              title={i18n.t("cancel")}
              data-cy="cancelPaymentMethodBtn"
              onClick={handleTurnOffUpdateMode}
              isDisabled={isLoading}
            />
          )}
        </ButtonContainer>
      </FormContainer>
      <AccountDeclinedCardModal isOpen={isDeclinedError} message={paymentErrorMsg} onDismiss={onDeclinedModalDismiss} />
    </Container>
  );
};

export default AccountCardPayment;
