import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { currentThemeMode, setThemeMode, isMobile } from "@kubera/common";
import { isWithinTimeRange, getLocalTimeStamp } from "utilities/Time.js";
import { ConsiderDefaultThemeContext } from "utilities/Contexts";
import { getNonDashboardRoutes } from "routes";

const isMobileDevice = isMobile();

const defaultTheme = {
  mode: "default",
  lightgray: "rgba(0, 0, 0, 0.5)",
  primaryButtonBackgroundColor: "#000",
  primaryButtonLoadingBackgroundColor: "rgba(128, 128, 128)",
  primaryButtonDestructiveBackgroundColor: "#A50000",
  primaryButtonDestructiveDisabledBackgroundColor: "rgba(165, 0, 0, 0.5);",
  primaryButtonColor: "#FFF",
  primaryButtonDisabledColor: "rgba(191, 191, 191)",
  primaryButtonDestructiveColor: "rgba(255, 255, 255)",
  primaryButtonDestructiveDisabledColor: "rgba(255, 255, 255, 0.7)",
  errorCLR: "#ff0000",
  desktopBackgroundScrollbarTrack: "#fcfcfc",
  desktopBackgroundScrollbarThumb: "lightgray",
  desktopBackgroundScrollbarThumbHover: "#555",
  backgroundHelpMenuButton: "#ffffff",
  borderColorHelpMenuButton: "rgba(0, 0, 0, 0.1)",
  colorHelpMenuButton: "#000000",
  focusBackgroundColor: "rgba(196, 196, 196, 0.4)",
  focusOutlineColor: "#419BF9",
  popoverBackgroundColor: "rgba(255, 255, 255, 0.8)",
  chartRearrangementBackgroundColor: "rgba(255, 255, 255, 0.9)",
  sidebarBackground: "rgba(0, 0, 0, 0.07)",
  sidebarColor: "#000000",
  sidebarSubTitleColor: "rgba(0, 0, 0, 0.5)",
  sidebarDisabledColor: "rgba(0, 0, 0, 0.4)",
  sidebarSectionSepartorColor: "#DBDBDB",
  dashboardBackgroundColor: "#FCFCFC",
  dashboardHeaderBackgroundColor: "#FFFFFF",
  dashboardHeaderColor: "#000000",
  gridRowHoverColor: "rgba(0, 0, 0, 0.02)",
  gridGreenColorForLightBackground: "#00A707",
  gridGreenColorForDarkBackground: isMobileDevice ? "#00FF0A" : "#00D708",
  gridRedColorForLightBackground: "#FF0000",
  gridRedColorForDarkBackground: isMobileDevice ? "#FF5757" : "#FF6464",
  gridUnupdatedGreenColorForLightBackground: "#00A707",
  gridUnupdatedRedColorForDarkBackground: "#FF0000",
  gridAddNewButtonColor: "rgba(255, 255, 255, 0.8)",
  gridTitleColor: "#000000",
  gridSheetTitleColor: "rgba(0, 0, 0, 0.35)",
  gridSheetTitleSelectedColor: "#000000",
  gridSheetTotalColor: "rgba(0, 0, 0, 0.4)",
  gridSheetTotalSelectedColor: "rgba(0, 0, 0, 0.7)",
  gridSectionBorderColor: "rgba(0, 0, 0, 0.1)",
  gridSectionTitleColor: "#000000",
  gridSectionCollapsedDividerColor: "rgba(0, 0, 0, 0.1)",
  gridEntryListBackgroundColor: "#FFFFFF",
  gridRowUpdatedBackgroundColor: "#FFFFFF",
  gridRowUnUpdatedBackgroundColor: "rgba(0, 0, 0, 0.05)",
  gridSectionHeaderBackgroundColor: "#FFFFFF",
  gridSectionHeaderColor: "rgba(0, 0, 0, 0.5)",
  gridSectionFooterBackgroundColor: "rgba(0, 0, 0, 0.6)",
  gridSectionAddActionColor: "rgba(0, 0, 0, 0.6)",
  gridSectionFooterColor: "#FFFFFF",
  gridCellBackgroundColor: "#FFFFFF",
  gridCellColor: "#000000",
  gridCellColorUnupdated: "rgba(0, 0, 0, 0.7)",
  placeholderColor: "rgba(0, 0, 0, 0.3)",
  gridCellPlaceholderColor: "rgba(0, 0, 0, 0.3)",
  gridCellSelectedOutlineColor: "#1D74F6",
  gridCellSelectedBackgroundColor: "transparent",
  gridCellHintColor: "rgba(0, 0, 0, 0.3)",
  gridCellSecondaryColor: "#808080",
  contextMenuButtonColor: "background: rgba(0, 0, 0, 0.4)",
  contextMenuButtonBackgroundColor: "rgba(0, 0, 0, 0.1)",
  contextMenuBackgroundColor: "#FFFFFF",
  contextMenuItemLabelColor: "#000000",
  contextMenuItemDisabledLabelColor: "rgba(0, 0, 0, 0.4)",
  contextMenuItemDescriptionColor: "rgba(0, 0, 0, 0.4)",
  contextMenuItemBackgroundColor: "#FFFFFF",
  contextMenuItemSelectedBackgroundColor: "rgba(196, 196, 196, 0.4)",
  contextMenuSectionSeparatorColor: "rgba(0, 0, 0, 0.1)",
  toastBackgroundColor: "rgba(0, 0, 0, 0.7)",
  toastForegroundColor: "#FFFFFF",
  searchInputPlaceholderColor: "rgba(0, 0, 0, 0.3)",
  checkboxSelectedColor: "#0094FF",
  checkboxUnselectedColor: "white",
  checkboxDisabledColor: "#B1B1B1",
  checkboxBorderColor: "rgba(0, 0, 0, 0.3)",
  documentsButtonColor: "rgba(0, 0, 0, 0.7)",
  dialogBorderColor: "rgba(0, 0, 0, 0.3)",
  documentCellSelectedOutlineColor: "#1D74F6",
  linkColor: "#0074FC",
  txtColorSecondaryBtn: "#000000",
  borderColorSecondaryBtn: "#000000",
  backgroundSecondaryBtn: "#FFFFFF",
  mobileBackground: "#F6F6F6",
  mobileBackgroundLight: "#FFF",
  mobileBackgroundSidebar: "#EDEDED",
  mobileBackgroundRow: "#FFFFFF",
  mobileBackgroundSection: "#E7E7E7",
  backgroundDetailsOverlay: "rgba(255, 255, 255, 0.8)",
  mobileBackgroundDetailsDialog: "#FFFFFF",
  mobileBackgroundHomeCategory: "#FFFFFF",
  mobileBackgroundOverlay: "#FFF",
  mobileBackgroundStaleRow: "#F1F1F1",
  mobileBackgroundIOSBanner: "#292929",
  mobileBackgroundOnTap: "#E7E7E7",
  mobileSignInBackground: "#F6F6F6",
  mobileTxtColor: "#000000",
  mobileTxtColorLight: "rgba(0, 0, 0, 0.35)",
  mobileTxtColorSemiLight: "rgba(0, 0, 0, 0.6)",
  mobileTxtHeaderColorSemiLight: "rgba(0, 0, 0, 0.6)",
  mobileTxtColorGrey: "rgba(36, 36, 36, 0.5)",
  mobileTxtColorHomeSwitchArrows: "#000000",
  mobileTxtColorChangeTitle: "rgba(0, 0, 0, 0.7)",
  mobileTxtColorSectionTotal: "rgba(0, 0, 0, 0.8)",
  mobileTxtColorSectionCollapseIcon: "#737373",
  mobileTxtColorSection: "#000",
  mobileTxtColorCustodianDetails: "#000000",
  mobileTxtColorCustodianDetailsLinkedIcon: "rgba(0, 0, 0, 0.3)",
  mobileTxtColorHistoryBackBtn: "rgba(0, 0, 0, 0.7)",
  mobileRefreshButtonIconColor: "rgba(0, 0, 0, 0.7)",
  desktopRefreshButtonIconColor: "#000",
  mobileTxtColorOverlay: "#000",
  mobileTxtColorChangeArrow: "rgba(0, 0, 0, 0.3)",
  mobileTxtColorIOS: "#FFF",
  mobileTxtColorIOSClose: "rgba(255, 255, 255, 0.4)",
  mobileTxtColorDetailsActualValue: "rgba(51, 51, 51, 0.5)",
  mobileTxtColorKuberaHearts: "rgba(0, 0, 0, 0.8)",
  mobileColorWB: "#FFF",
  mobileColorBW: "#000",
  mobileColorBtn: "#000",
  subscriptionOptionsBgColor: "#FFF",
  chartBgColor: "rgba(133, 92, 248, 0.16)",
  chartBgOverlapColor: "#d5c9f7",
  networthChartBgOverlapColor: "#dbd0fe",
  holdingsColor: "#1D74F6",
  mobileSeperatorColor: "rgba(0, 0, 0, 0.1)",
  mobileBorderSeperatorColor: "rgba(0, 0, 0, 0.1)",
  mobileSidebarSeperator: "rgba(0, 0, 0, 0.2)",
  mobileGridTotalUpdatingTxt: "rgba(0, 0, 0, 0.4)",
  dialogContentContainerBG: "linear-gradient(0deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)), #FFFFFF",
  treeChartLabelBG: "#fcfcfc",
  dialogContentContainerBR: "1px solid rgba(0, 0, 0, 0.3)",
  dialogContentContainerBS: "0 0 20px 0 rgba(0, 0, 0, 0.25)",
  accountCancelledCardCardContainerBG: "#e3e3e3",
  accountCancelledCardCardContainerBR: "1px solid #e1e1e1",
  accountCancelledChangeActionCLR: "rgba(0, 0, 0, 0.5)",
  accountCardChangeSubscribeBtnCLR: "#fff",
  accountCardChangeSubscribeBtnBG: "#000",
  accountCardElementCardContainerBG: "#fff",
  accountCardElementCardContainerBR: "1px solid rgba(0, 0, 0, 0.4)",
  dashboardComponentContainerCLR: "#000",
  selectTimeRangeButtonCLR: "#000",
  beneficiaryComponentGrayBoxBG: "#FFF",
  beneficiaryComponentGrayBoxBGVTA: "rgba(0, 0, 0, 0.05)",
  beneficiaryComponentContainerBG: "#FFF",
  irrCellLabelCLR: "rgba(0, 0, 0, 0.5)",
  irrCellValueCLR: "#000",
  totalChangeTitleCLR: "rgba(0, 0, 0, 0.7)",
  badgeBorderCLR: "#FFF",
  badgeBorderHoverCLR: "#e7e7e7",
  changeLabelCLR: "#000",
  cellCurrencyValueCLR: "rgba(0, 0, 0, 0.4)",
  contextMenuContainer: "#000",
  netWorthContainerBG: "#FFF",
  emptyChartMessageCLR: "rgba(0, 0, 0, 0.4)",
  noDocumentsContainerBG: "#FFF",
  noDocumentsContainerBR: "1px solid #000",
  emptySafeDepositComponentHintCLR: "rgba(0, 0, 0, 0.5)",
  emptyGridComponentOkBtnBG: "#000",
  emptyGridViewTitleBG: "rgba(0, 0, 0, 0.05)",
  emptyGridViewTitleBR: "1px solid rgba(0, 0, 0, 0.2)",
  emptyGridViewTitleBGHover: "rgba(0, 0, 0, 0.1)",
  emptyGridViewTitleColor: "#00000099",
  chartsNotAvailableEmptyViewContainerBG: "#FFF",
  emptyLineChartContainerBR: "solid 1px #e6e6e6",
  emptyDashboardChartBG: "rgba(255, 255, 255, 0.5)",
  recapDefaultMessageContainerBG: "#FFF",
  emptyInsuranceGridBG: "#FFF",
  emptyInsuranceGridBR: "1px solid #000000",
  modifyBeneficiaryContainerBoxBG: "#FFF",
  modifyBeneficiaryContainerBoxBR: "1px solid rgba(0, 0, 0, 0.3)",
  documentsDetailsCommandAreaBG: "rgba(252, 252, 252, 0.97)",
  accountSubscribedCardBG: "#e3e3e3",
  accountSubscribedCardBR: "1px solid #d8d8d8",
  accountSubscribedCardChangeActionCLR: "rgba(0, 0, 0, 0.5)",
  beneficiarySubTitleCLR: "#000",
  textCellDescriptionCLR: "rgba(0, 0, 0, 0.4)",
  textCellLinkedHoldingsCount: "rgba(0, 0, 0, 0.3)",
  linkedIconEquityContainerBG: "rgba(0, 0, 0, 0.05)",
  linkedIconEquityContainerCLR: "rgba(36, 36, 36, 0.6)",
  beneficiaryComponentContainerBR: "1px solid rgba(0, 0, 0, 0.1)",
  sidebarDescCLR: "rgba(0, 0, 0, 0.6)",
  netWorthContainerBR: "1px solid rgba(0, 0, 0, 0.1)",
  spinnerCLR: "#000",
  spinnerBRTop: "#000",
  polylineStroke: "#000",
  columnanChartSecondaryValueCLR: "rgba(0, 0, 0, 0.5)",
  errorIconComponentCLR: "rgb(255, 168, 0)",
  svgDefaultColor: "#000",
  popularProvidersOverlayBG: "rgba(255, 255, 255, 0.7)",
  percentageChangeButtonBG: "#FFF",
  vsLabelBG: "#fcfcfc",
  notesBR: "1px solid rgba(0, 0, 0, 0.1)",
  recapDefaultMessageContainerBR: "1px solid #000",
  sideBarBadgeBR: "#EDEDED",
  sectionMoveBackgroundColor: "#FFF",
  sectionMoveTitleWrapperBG: "#f3f3f3",
  sectionMoveFixedListBG: "rgba(255, 255, 255, 0.9)",
  emptyLineChartTitleCLR: "#AAA",
  emptyDoughnutsContainerBR: "1px solid rgba(0, 0, 0, 0.1)",
  chartsNotAvailableEmptyViewContainerBR: "1px solid #000000",
  toolTipBG: "#000",
  toolTipDescCLR: "#FFF",
  toolTipBtnCLR: "#e5e5e5",
  toolTipArrowBorder: "1px solid rgba(0, 0, 0, 0.2)",
  cardContainerBG: "#e3e3e3",
  cardContainerBR: "1px solid #e1e1e1",
  documentsComponentMode: "default",
  headsUpDesktopBannerCLR: "rgba(0, 0, 0, 0.7)",
  kuberaHeartsDesktopBannerCLR: "rgba(0, 0, 0, 0.7)",
  sidebarInfoCLR: "rgba(0, 0, 0, 0.7)",
  horizontalLoaderBG: "rgba(0, 0, 0, 0.1)",
  topLoaderBG: "rgba(0, 0, 0, 0.1)",
  topLoaderColor: "rgba(0, 0, 0, 0.5)",
  detailsDialogGridRowBG: "#FFF",
  scorecardTitleColor: "rgba(48, 48, 48, 0.9)",
  scorecardChangeTitleColor: "rgba(0, 0, 0, 0.5)",
  mobileNavContainerBg: "#FFF",
  reportDoughnutPolyLine: "#000",
  chartCellSvgIconColor: "##959595",
  documentHeaderIconColor: "#7E7E7E",
  recapReportChartIcon: "#959595",
  recapReportChartIconDisabled: "#EBEBEB",
  barChartLabelColor: "#000000",
  lineChartZeroLineColor: "rgba(0,0,0,0.3)",
  mobileAdjustedNetworthColor: "#000000",
  linkVisitedColor: "#000000",
  adjustedNetWorthColor: "rgba(0, 0, 0, 0.5)",
  unfundedCardCloseIconColor: "rgba(0, 0, 0, 0.4)",
  toggleSwitchDisabledHandleColor: "#E2E2E2",
  sidebarBillingInfoCLR: "rgba(0, 0, 0, 0.8)",
  nftDiscountOrTrialMessageColor: "rgba(0, 0, 0, 0.7)",
  barChartSecondaryColor: "rgba(128,0,255,0.4)",
  userImagePlaceholderBG: "#000000",
  userImagePlaceholderColor: "#FFFFFF"
};

const darkTheme = {
  ...defaultTheme,
  mode: "dark",
  lightgray: "#EEE",
  dashboardBackgroundColor: "#151515",
  desktopBackgroundScrollbarTrack: "#151515",
  desktopBackgroundScrollbarThumb: "#555",
  desktopBackgroundScrollbarThumbHover: "#fcfcfc",
  backgroundHelpMenuButton: "#202020",
  borderColorHelpMenuButton: "rgba(0, 0, 0, 0.3)",
  colorHelpMenuButton: "rgba(255, 255, 255, 0.5)",
  gridGreenColorForLightBackground: isMobileDevice ? "#00FF0A" : "#00D708",
  gridRedColorForLightBackground: "#FF4E4E",
  gridUnupdatedGreenColorForLightBackground: isMobileDevice ? "#00FF0A" : "#00D708",
  gridUnupdatedRedColorForDarkBackground: "#FF5757",
  primaryButtonBackgroundColor: "#585858",
  errorCLR: "#E55F5F",
  backgroundSecondaryBtn: "#1A1A1A",
  txtColorSecondaryBtn: "#FFF",
  borderColorSecondaryBtn: "#555555",
  mobileBackground: "#0C0C0C",
  mobileBackgroundLight: "#1E1E1E",
  mobileBackgroundSidebar: "#1E1E1E",
  mobileBackgroundRow: "#161616",
  mobileBackgroundSection: "#242424",
  backgroundDetailsOverlay: "rgba(0, 0, 0, 0.8)",
  popoverBackgroundColor: "rgba(0, 0, 0, 0.8)",
  chartRearrangementBackgroundColor: "rgba(0, 0, 0, 0.8)",
  mobileBackgroundDetailsDialog: "#CECECE",
  mobileBackgroundHomeCategory: "#161616",
  mobileBackgroundOverlay: "#CECECE",
  mobileBackgroundStaleRow: "#1C1C1C",
  mobileBackgroundIOSBanner: "#CECECE",
  mobileBackgroundOnTap: "#2b2b2b",
  mobileTxtColor: "rgba(255, 255, 255, 0.9)",
  mobileTxtColorLight: "rgba(255, 255, 255, 0.4)",
  mobileTxtColorSemiLight: "rgba(255, 255, 255, 0.8)",
  mobileTxtHeaderColorSemiLight: "rgba(255, 255, 255, 0.5)",
  mobileTxtColorGrey: "rgba(255, 255, 255, 0.5)",
  mobileTxtColorHomeSwitchArrows: "rgba(255, 255, 255, 0.5)",
  mobileTxtColorChangeTitle: "rgba(255, 255, 255, 0.5)",
  mobileTxtColorSection: "rgba(255, 255, 255, 0.9)",
  mobileTxtColorCustodianDetailsLinkedIcon: "rgba(0, 0, 0, 0.3)",
  mobileTxtColorSectionTotal: "rgba(255, 255, 255, 0.8)",
  mobileTxtColorSectionCollapseIcon: "rgba(255, 255, 255, 0.5)",
  mobileTxtColorHistoryBackBtn: "rgba(255, 255, 255, 0.8)",
  mobileRefreshButtonIconColor: "rgba(255, 255, 255, 0.7)",
  desktopRefreshButtonIconColor: "#FFF",
  mobileTxtColorOverlay: "rgba(0, 0, 0, 0.9)",
  mobileTxtColorChangeArrow: "rgba(255, 255, 255, 0.5)",
  mobileTxtColorIOS: "#000",
  mobileTxtColorIOSClose: "rgba(0, 0, 0, 0.4)",
  mobileTxtColorKuberaHearts: "rgba(255, 255, 255, 0.8)",
  mobileColorWB: "#000",
  mobileColorBW: "#FFF",
  mobileColorBtn: "#676767",
  subscriptionOptionsBgColor: "rgba(255, 255, 255, 0.8)",
  chartBgColor: "rgba(125, 71, 221, 0.8)",
  chartBgOverlapColor: "#7844d4",
  holdingsColor: "#7CA9FF",
  mobileSeperatorColor: "#282828",
  mobileSidebarSeperator: "rgba(255, 255, 255, 0.2)",
  mobileGridTotalUpdatingTxt: "#6D6D6D",
  dialogContentContainerBG: "#DFDFDF",
  treeChartLabelBG: "#DFDFDF",
  dialogContentContainerBR: "1px solid rgba(0, 0, 0, 0.3)",
  dialogContentContainerBS: "0px 0px 20px rgba(0, 0, 0, 0.25)",
  accountCancelledCardCardContainerBG: "#C9C9C9",
  accountCancelledCardCardContainerBR: "1px solid rgba(0, 0, 0, 0.05)",
  accountCancelledChangeActionCLR: "rgba(0, 0, 0, 0.5)",
  accountCardChangeSubscribeBtnCLR: "#fff",
  accountCardChangeSubscribeBtnBG: isMobileDevice ? "#676767" : "#000",
  accountCardElementCardContainerBG: "#fff",
  accountCardElementCardContainerBR: "1px solid rgba(0, 0, 0, 0.4)",
  dashboardComponentContainerCLR: "#EEE",
  selectTimeRangeButtonCLR: "#EEE",
  gridTitleColor: "#EEE",
  sidebarColor: "#EEE",
  sidebarSubTitleColor: "#EEE",
  dashboardHeaderColor: "#EEE",
  gridSheetTitleSelectedColor: "rgba(255, 255, 255, 0.9)",
  gridSectionTitleColor: "#EEE",
  gridCellColor: "#EEE",
  beneficiaryComponentGrayBoxBG: "#2D2D2D",
  beneficiaryComponentGrayBoxBGVTA: "#222222",
  beneficiaryComponentContainerBG: "#1A1A1A",
  gridRowUpdatedBackgroundColor: "#1A1A1A",
  gridRowUnUpdatedBackgroundColor: "#1E1E1E",
  gridSectionHeaderBackgroundColor: "#1A1A1A",
  gridSectionBorderColor: "#242424",
  sidebarBackground: "#0C0C0C",
  sidebarSectionSepartorColor: "#242424",
  gridEntryListBackgroundColor: "#242424",
  gridSectionHeaderColor: "rgba(255, 255, 255, 0.5)",
  gridSectionAddActionColor: "rgba(255, 255, 255, 0.5)",
  sortIconComponentCLR: "rgba(255, 255, 255, 0.5)",
  irrCellLabelCLR: "rgba(255, 255, 255, 0.5)",
  irrCellValueCLR: "#FFF",
  totalChangeTitleCLR: "#EEE",
  gridSheetTitleColor: "rgba(255, 255, 255, 0.4)",
  gridSheetTotalSelectedColor: "rgba(255, 255, 255, 0.9)",
  gridSheetTotalColor: "rgba(255, 255, 255, 0.4)",
  dialogContentContainerCLR: "#000",
  badgeBorderCLR: "#1A1A1A",
  badgeBorderHoverCLR: "#5e5e5e",
  cellCurrencyValueCLR: "rgba(255, 255, 255, 0.5)",
  gridCellPlaceholderColor: "rgba(255, 255, 255, 0.3)",
  placeholderColor: "rgba(255, 255, 255, 0.3)",
  netWorthContainerBG: "#1A1A1A",
  emptyChartMessageCLR: "#EEE",
  noDocumentsContainerBG: "#1A1A1A",
  noDocumentsContainerBR: "1px solid #313131",
  emptySafeDepositComponentHintCLR: "#EEE",
  emptyGridComponentOkBtnBG: "#585858",
  emptyGridViewTitleBG: "#252525",
  emptyGridViewTitleBR: "1px solid #313131",
  emptyGridViewTitleColor: "#EEE",
  chartsNotAvailableEmptyViewContainerBG: "#1A1A1A",
  emptyLineChartContainerBR: "1px solid #313131",
  emptyDashboardChartBG: "rgba(26, 26, 26, 0.5)",
  recapDefaultMessageContainerBG: "#1A1A1A",
  emptyInsuranceGridBG: "#1A1A1A",
  emptyInsuranceGridBR: "1px solid #313131",
  modifyBeneficiaryContainerBoxBG: "#1A1A1A",
  modifyBeneficiaryContainerBoxBR: "1px solid #313131",
  documentsDetailsCommandAreaBG: "#DFDFDF",
  initialLoaderBG: "#151515",
  accountSubscribedCardBG: "#474747",
  accountSubscribedCardBR: "1px solid rgba(0, 0, 0, 0.05)",
  accountSubscribedCardCLR: "#EEE",
  accountSubscribedCardChangeActionCLR: "rgba(255, 255, 255, 0.5)",
  checkboxBG: "#000",
  changeLabelCLR: "#FFF",
  beneficiarySubTitleCLR: "#949494",
  textCellDescriptionCLR: "#949494",
  textCellLinkedHoldingsCount: "rgba(255, 255, 255, 0.3)",
  linkedIconEquityContainerBG: "rgba(255, 255, 255, 0.05)",
  linkedIconEquityContainerCLR: "rgba(255, 255, 255, 0.5)",
  gridSectionFooterBackgroundColor: "#323232",
  contextMenuButtonBackgroundColor: "rgba(255, 255, 255, 0.1)",
  linkColor: "#549DF2",
  checkboxBorderColor: "#313131",
  beneficiaryComponentContainerBR: "1px solid #313131",
  sidebarDescCLR: "rgba(255, 255, 255, 0.6)",
  netWorthContainerBR: "1px solid #313131",
  spinnerCLR: "rgba(255, 255, 255, 0.8)",
  polylineStroke: "#EEE",
  columnanChartSecondaryValueCLR: "#949494",
  svgDefaultColor: "#EEE",
  gridCellColorUnupdated: "#EEE",
  popularProvidersOverlayBG: "rgba(223, 223, 223, 0.7)",
  spinnerBR: "rgba(255, 255, 255, 0.4)",
  spinnerBRTop: "#FFF",
  percentageChangeButtonBG: "transparent",
  providerLogoBR: "1px solid #4B4B4B",
  vsLabelBG: "#DFDFDF",
  notesBR: "1px solid #D6D6D6",
  recapDefaultMessageContainerBR: "1px solid #313131",
  sideBarBadgeBR: "#0C0C0C",
  documentsButtonColor: "#EEE",
  sectionMoveBackgroundColor: "#000",
  sectionMoveTitleWrapperBG: "#151515",
  sectionMoveFixedListBG: "rgba(0, 0, 0, 0.9)",
  emptyLineChartTitleCLR: "#4A4A4A",
  emptyDoughnutsContainerBR: "1px solid #313131",
  chartsNotAvailableEmptyViewContainerBR: "1px solid #313131",
  toolTipBG: "#E8E8E8",
  toolTipDescCLR: "#000",
  toolTipBtnCLR: "#000",
  toolTipArrowBorder: "1px solid rgba(232, 232, 232, 1)",
  documentsComponentMode: "dark",
  headsUpDesktopBannerCLR: "rgba(255, 255, 255, 0.7)",
  kuberaHeartsDesktopBannerCLR: "rgba(255, 255, 255, 0.7)",
  sidebarInfoCLR: "rgba(255, 255, 255, 0.7)",
  gridSectionCollapsedDividerColor: "#313131",
  horizontalLoaderBG: "rgba(255, 255, 255, 0.2)",
  topLoaderBG: "rgba(255, 255, 255, 0.1)",
  topLoaderColor: "rgba(255, 255, 255)",
  scorecardTitleColor: "#EEE",
  scorecardChangeTitleColor: "#EEE",
  mobileNavContainerBg: "#EEE",
  contextMenuButtonColor: "#EEE",
  reportDoughnutPolyLine: "#000",
  chartCellSvgIconColor: "##959595",
  documentHeaderIconColor: "rgba(238, 238, 238, 0.5)",
  recapReportChartIcon: "#8A8A8A",
  recapReportChartIconDisabled: "#434343",
  barChartLabelColor: "#EEE",
  lineChartZeroLineColor: "rgba(238,238,238,0.3)",
  mobileAdjustedNetworthColor: "#EEE",
  linkVisitedColor: "#EEE",
  adjustedNetWorthColor: "#EEE",
  unfundedCardCloseIconColor: "#EEE",
  sidebarBillingInfoCLR: "rgba(255, 255, 255, 0.8)",
  nftDiscountOrTrialMessageColor: isMobileDevice
    ? "rgba(255, 255, 255, 0.9)"
    : defaultTheme.nftDiscountOrTrialMessageColor,
  barChartSecondaryColor: "rgba(128,0,255,0.4)",
  userImagePlaceholderBG: "#EEE",
  userImagePlaceholderColor: "#000000"
};

export default defaultTheme;

export const dialogOverlayTheme = (mode = "default") => ({
  gridGreenColorForLightBackground: defaultTheme.gridGreenColorForLightBackground,
  gridGreenColorForDarkBackground: defaultTheme.gridGreenColorForDarkBackground,
  gridRedColorForLightBackground: defaultTheme.gridRedColorForLightBackground,
  gridRedColorForDarkBackground: defaultTheme.gridRedColorForDarkBackground,
  gridUnupdatedGreenColorForLightBackground: defaultTheme.gridUnupdatedGreenColorForLightBackground,
  gridUnupdatedRedColorForDarkBackground: defaultTheme.gridUnupdatedRedColorForDarkBackground,
  changeLabelCLR: defaultTheme.changeLabelCLR,
  primaryButtonBackgroundColor: defaultTheme.primaryButtonBackgroundColor,
  txtColorSecondaryBtn: "#000",
  borderColorSecondaryBtn: mode === "default" ? defaultTheme.borderColorSecondaryBtn : "#7A7A7A",
  backgroundSecondaryBtn: mode === "default" ? defaultTheme.backgroundSecondaryBtn : "#CFCFCF",
  spinnerBR: mode === "default" ? defaultTheme.spinnerBR : "#1C1C1C",
  spinnerBRTop: mode === "default" ? defaultTheme.spinnerBRTop : "rgba(230, 230, 230, 0.7)",
  cardContainerBG: mode === "default" ? defaultTheme.cardContainerBG : "#C9C9C9",
  cardContainerBR: mode === "default" ? defaultTheme.cardContainerBR : "1px solid rgba(0, 0, 0, 0.05)",
  documentsButtonColor: "rgba(0, 0, 0, 0.7)",
  documentsComponentMode: "default",
  noDocumentsContainerBG: null,
  noDocumentsContainerBR: null,
  emptySafeDepositComponentHintCLR: defaultTheme.emptySafeDepositComponentHintCLR,
  linkColor: "#0074FC",
  emptyGridViewTitleBG: "rgba(0, 0, 0, 0.05)",
  emptyGridViewTitleBR: "1px solid rgba(0, 0, 0, 0.2)",
  svgDefaultColor: defaultTheme.svgDefaultColor,
  emptyGridViewTitleColor: defaultTheme.emptyGridViewTitleColor
});

export const dialogGridTheme = (mode = "default") => ({
  gridRowUpdatedBackgroundColor: defaultTheme.gridRowUpdatedBackgroundColor,
  gridRowUnUpdatedBackgroundColor: defaultTheme.gridRowUnUpdatedBackgroundColor,
  gridSectionHeaderBackgroundColor: defaultTheme.gridSectionHeaderBackgroundColor,
  gridSectionBorderColor: mode === "default" ? defaultTheme.gridSectionBorderColor : "#D6D6D6",
  gridEntryListBackgroundColor: mode === "default" ? defaultTheme.gridEntryListBackgroundColor : "#D6D6D6",
  addHistoryBtnWrapperBG: mode === "default" ? defaultTheme.addHistoryBtnWrapperBG : "#EEE"
});

// Time Range Settings
const rangeStart = { hrs: 17, mins: 0, secs: 0, ms: 0 };
const rangeEnd = { hrs: 7, mins: 0, secs: 0, ms: 0 };

const calcStsCurrentWithinTimeVals = () => {
  let after5Today = isWithinTimeRange(rangeStart, { hrs: 23, mins: 59, secs: 59, ms: 999 }, 0, 0);
  let before7Today = isWithinTimeRange({ hrs: 0, mins: 0, secs: 0, ms: 0 }, rangeEnd, 0, 0);
  const isWithinTime = after5Today === true || before7Today === true;

  return {
    isWithinTime,
    after5Today,
    before7Today
  };
};
export const useTheme = () => {
  const defaultThemeContext = useContext(ConsiderDefaultThemeContext);

  const mode = useSelector(currentThemeMode);

  const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
  const [spTheme, setSpTheme] = useState(darkThemeMq.matches ? darkTheme : defaultTheme);
  const [stsTheme, setStsTheme] = useState(calcStsCurrentWithinTimeVals().isWithinTime ? darkTheme : defaultTheme);

  useEffect(() => {
    const onThemeMediaChangeHandle = event => {
      if (mode === "sp") {
        setSpTheme(event.matches ? darkTheme : defaultTheme);
      }
    };

    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", onThemeMediaChangeHandle);

    return () => {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", onThemeMediaChangeHandle);
    };
  }, [mode]);

  useEffect(() => {
    if (mode !== "sts") {
      return () => null;
    }

    let timeoutId = null;

    const handleIsWithin = () => {
      const { isWithinTime, after5Today, before7Today } = calcStsCurrentWithinTimeVals();
      const callTimeout =
        after5Today === true
          ? getLocalTimeStamp(rangeEnd, 1)
          : before7Today === true
          ? getLocalTimeStamp(rangeEnd, 0)
          : getLocalTimeStamp(rangeStart, 0);

      if (isWithinTime) {
        setStsTheme(darkTheme);
      } else {
        setStsTheme(defaultTheme);
      }

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        handleIsWithin();
      }, callTimeout - Date.now());
    };

    handleIsWithin();

    document.addEventListener("visibilitychange", handleIsWithin);

    return () => {
      clearTimeout(timeoutId);
      document.addEventListener("visibilitychange", handleIsWithin);
    };
  }, [mode]);

  if (defaultThemeContext) {
    return defaultTheme;
  }

  if (getNonDashboardRoutes().includes(window.location.pathname) === true) {
    return defaultTheme;
  }

  switch (true) {
    case mode === "default":
      return defaultTheme;
    case mode === "dark":
      return darkTheme;
    case mode === "sts":
      return stsTheme;
    case mode === "sp":
      return spTheme;
    default:
      return defaultTheme;
  }
};

export const useDarkModeActive = () => {
  return useTheme().mode === "dark";
};

const ThemeSwitchDiv = styled.div``;

export const ThemeSwitchComponent = ({ className, children, mode, onClick, ...props }) => {
  const dispatch = useDispatch();

  const switchMode = () => {
    dispatch(setThemeMode(mode));

    if (onClick) {
      onClick();
    }
  };

  return (
    <ThemeSwitchDiv className={className} {...props} onClick={switchMode}>
      {children}
    </ThemeSwitchDiv>
  );
};

export { ScrollBarHorizontal, ScrollBarVertical } from "./scrollbar.js";
