import createWhitelistFilter from "redux-persist-transform-filter";
import {
  FETCH_ARCHIVED_PORTFOLIO,
  FETCH_ARCHIVED_PORTFOLIO_SUCCESS,
  FETCH_ARCHIVED_PORTFOLIO_ERROR,
  RESET_ARCHIVED_PORTFOLIO,
  DELETE_ARCHIVED_CUSTODIAN
} from "../actions/ArchivedPortfolioActions";

const initialState = {
  portfolioId: null,
  details: null,
  fetchDetailsPending: false,
  fetchDetailsError: false
};

export const archivedPortfolioPersistTransform = () => {
  return createWhitelistFilter("archived_portfolio", []);
};

export function archivedPortfolioReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ARCHIVED_PORTFOLIO:
      return {
        ...state,
        portfolioId: action.portfolioId,
        details: null,
        fetchDetailsPending: true,
        fetchDetailsError: null
      };
    case FETCH_ARCHIVED_PORTFOLIO_SUCCESS:
      return {
        ...state,
        fetchDetailsPending: false,
        details: action.details,
        fetchDetailsError: null
      };
    case FETCH_ARCHIVED_PORTFOLIO_ERROR:
      return {
        ...state,
        fetchDetailsPending: false,
        details: null,
        fetchDetailsError: action.error
      };
    case RESET_ARCHIVED_PORTFOLIO:
      return initialState;
    case DELETE_ARCHIVED_CUSTODIAN: {
      const newState = { ...state };
      const newCustodians = newState.details.custodian.filter(custodian => custodian.id !== action.custodianId);
      newState.details.custodian = newCustodians;
      return newState;
    }
    default:
      return state;
  }
}

export const archivedCustodianSelector = state => {
  const details = state.archived_portfolio.details;
  if (details) {
    return details.custodian;
  }
  return null;
};

export const archivedPortfolioDetailsSelector = state => {
  return state.archived_portfolio.details;
};

export const archivedPortfolioStateSelector = state => state.archived_portfolio;
