import React from "react";
import styled from "styled-components";
import { ReactComponent as DropDownIcon } from "assets/images/expandable_indicator.svg";
import ContextMenu from "components/contextmenu/ContextMenu";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  padding: 13px;
  cursor: ${props => (!props.disabled === true ? "pointer" : "")};
  pointer-events: ${props => (!props.disabled === true ? "" : "none")};
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  user-select: none;
`;

const SelectedItem = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  cursor: pointer;
  pointer-events: none;
`;

const DropDownIndicator = styled(DropDownIcon)`
  width: 8px;
  height: 8px
  margin-left: 3px;
  margin-top: 2px;
  transform: rotate(0deg);
  pointer-events: none;
  color : #999999;
`;

class DropDown extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelection = this.handleSelection.bind(this);
    this.handleDropDownClick = this.handleDropDownClick.bind(this);

    this.dropDownContextMenuRef = React.createRef();
    this.isFocus = React.createRef(false);
  }

  handleSelection(item) {
    if (this.props.onSelection) {
      this.props.onSelection(item);
    }
  }

  handleDropDownClick(event, user) {
    if (this.isFocus.current) return;
    if (this.dropDownContextMenuRef.current.isVisible() === true) {
      this.dropDownContextMenuRef.current.dismiss();
      return;
    }

    const targetPosition = event.target.getBoundingClientRect();

    for (const item of this.props.items) {
      item.disablePadding = true;
    }
    const menuItems = [this.props.items];

    this.dropDownContextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height - 1,
      false,
      event.target
    );
    setTimeout(() => {
      this.isFocus.current = false;
    }, 500);
  }

  handleFocus = event => {
    this.handleDropDownClick(event);
    this.isFocus.current = true;
  };

  handleDismiss = () => {
    this.isFocus.current = false;
  };

  getSelectedItem() {
    return this.props.items.find(item => item.selected === true);
  }

  render() {
    const {
      className,
      disabled,
      items,
      onSelection,
      width,
      selectedItemStyle,
      hideIndicatorForDisabled,
      ...other
    } = this.props;
    const selectedItem = this.getSelectedItem();

    return (
      <Container
        {...other}
        className={className}
        disabled={disabled}
        onClick={this.handleDropDownClick}
        onFocus={this.handleFocus}
      >
        <SelectedItem style={!selectedItemStyle === false ? selectedItemStyle : {}}>{selectedItem.label}</SelectedItem>
        {(!hideIndicatorForDisabled === true || disabled === false) && <DropDownIndicator />}
        <ContextMenu
          ref={this.dropDownContextMenuRef}
          width={width || 320}
          onSelection={this.handleSelection}
          onDismiss={this.handleDismiss}
        />
      </Container>
    );
  }
}

export default DropDown;
