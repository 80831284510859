import React from "react";
import i18n from "i18next";
import {
  portfolioSelector,
  formatNumberWithCurrency,
  portfolioTotalForCategory,
  portfolioNetWorth,
  currentPortfolioSelector,
  convertCurrency
} from "@kubera/common";
import styled from "styled-components";
import { ReactComponent as OpenLink } from "assets/images/open_link.svg";
import { useSelector } from "react-redux";
import { category } from "components/dashboard/DashboardComponent";
import { queryParams } from "routes";
import CustodianOwnershipComponent from "./CustodianOwnershipComponent";

const Container = styled.div`
  padding: 25px 0 0;
  display: flex;
  flex: 1
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

const Totals = styled.div`
  display: flex;
  margin-top: 20px;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 35px;
  font-weight: 400;
  font-size: 20px;
`;

const TotalTitle = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
`;

const Total = styled.div`
  margin-top: 2px;
  font-feature-settings: "ss01" on;
`;

const OpenPortfolio = styled.div`
  display: flex;
  align-items: center;
`;

const OpenPortfolioButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
  color: ${props => props.theme.linkColor};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
`;

const OpenPortfolioIcon = styled(OpenLink)`
  margin-left: 7px;
`;

const OriginalCurrencyValue = styled.div`
  margin-top: 1px;
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  font-feature-settings: "ss01" on;
`;

const OwnershipComponent = styled(CustodianOwnershipComponent)`
  padding-top: "25px";
`;

const LinkedPortfolioComponent = props => {
  const portfolio = useSelector(state => portfolioSelector(state, props.portfolioId));
  const networth = useSelector(state => portfolioNetWorth(state, portfolio));
  const assetsTotal = useSelector(state => portfolioTotalForCategory(state, portfolio, category.ASSET));
  const debtsTotal = useSelector(state => portfolioTotalForCategory(state, portfolio, category.DEBT));
  const currentPortfolio = useSelector(currentPortfolioSelector);
  const shouldShowOriginalCurrencyValue = currentPortfolio.currency !== portfolio.currency;
  return (
    <Container>
      <Name>{portfolio.name}</Name>
      <Totals>
        <TotalContainer>
          <TotalTitle>{i18n.t("networth")}</TotalTitle>
          <Total>
            {formatNumberWithCurrency(
              Math.kuberaFloor(convertCurrency(networth, portfolio.currency, currentPortfolio.currency)),
              currentPortfolio.currency
            )}
          </Total>
          {networth !== 0 && shouldShowOriginalCurrencyValue && (
            <OriginalCurrencyValue>
              {formatNumberWithCurrency(Math.kuberaFloor(networth), portfolio.currency, false, 2, true)}
            </OriginalCurrencyValue>
          )}
        </TotalContainer>
        <TotalContainer>
          <TotalTitle>{i18n.t("assets")}</TotalTitle>
          <Total>
            {formatNumberWithCurrency(
              Math.kuberaFloor(convertCurrency(assetsTotal, portfolio.currency, currentPortfolio.currency)),
              currentPortfolio.currency
            )}
          </Total>
          {assetsTotal !== 0 && shouldShowOriginalCurrencyValue && (
            <OriginalCurrencyValue>
              {formatNumberWithCurrency(Math.kuberaFloor(assetsTotal), portfolio.currency, false, 2, true)}
            </OriginalCurrencyValue>
          )}
        </TotalContainer>
        <TotalContainer>
          <TotalTitle>{i18n.t("debts")}</TotalTitle>
          <Total>
            {formatNumberWithCurrency(
              Math.kuberaFloor(convertCurrency(debtsTotal, portfolio.currency, currentPortfolio.currency)),
              currentPortfolio.currency
            )}
          </Total>
          {debtsTotal !== 0 && shouldShowOriginalCurrencyValue && (
            <OriginalCurrencyValue>
              {formatNumberWithCurrency(Math.kuberaFloor(debtsTotal), portfolio.currency, false, 2, true)}
            </OriginalCurrencyValue>
          )}
        </TotalContainer>
      </Totals>
      <OwnershipComponent
        details={props.details}
        custodianId={props.detailsInfo.id}
        detailsInfo={props.detailsInfo}
        ownershipValue={props.detailsInfo.ownership}
        isReadOnly={props.isReadOnly}
        category={props.category}
        chartStartDate={props.chartStartDate}
      />
      <OpenPortfolio
        onClick={e =>
          window.kuberaOpen(
            `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryParams.PORTFOLIO_ID}=${portfolio.id}`
          )
        }
      >
        <OpenPortfolioButton>{i18n.t("openPortfolio")}</OpenPortfolioButton>
        <OpenPortfolioIcon />
      </OpenPortfolio>
    </Container>
  );
};

export default LinkedPortfolioComponent;
