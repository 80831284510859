import React, { useState, useRef, useEffect } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  currentPortfolioSelector,
  getCurrentCustodianFromCustodianId,
  insertTickerCustodianInSection,
  formatNumberWithCurrency,
  getVehicleDetails,
  linkAndGetVehicleDetails,
  accountLinkingService,
  getUuid,
  insertCustodianAtEndOfSection,
  updateCustodian,
  insertSheet,
  utilityStatus,
  getAccountLinkingService,
  getTickerUsingShortName,
  getExchangeRate,
  getTickerUsingId,
  getExchangeRateDetails,
  getSortKeyBetween,
  tickerSubTypes,
  useHistory
} from "@kubera/common";

import { getEmptySheet, filterDataFromPortfolioDetails } from "components/grid/GridDataModel";

import AutocompleteSearchInp from "components/inputs/AutocompleteSearchInp";
// import TextInput from "components/inputs/TextInput";
import PrimaryButton from "components/button/PrimaryButton";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import { category } from "components/dashboard/DashboardComponent";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

import EditableTicker from "./EditableTicker";

const Container = styled.div`
  height: 100%;
  padding-bottom: 61px;
  box-sizing: border-box;
`;

const SearchForm = styled.form`
  width: 100%;
`;

const SearchInp = styled(AutocompleteSearchInp)`
  margin-bottom: 20px;

  div div:nth-child(1) {
    visibility: hidden;
    margin-right: -18px;
  }
`;

const AddBtn = styled.div`
  min-width: 91px;
  height: 35px;
  cursor: pointer;
  font-size: 10px;
  line-height: 110%;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: ${props =>
    props.isLoading === true || props.isDisabled === true
      ? props.theme.primaryButtonLoadingBackgroundColor
      : props.theme.primaryButtonBackgroundColor};
  color: ${props =>
    props.isDisabled === true ? props.theme.primaryButtonDisabledColor : props.theme.primaryButtonColor};
`;

const TickersForm = styled.form`
  position: relative;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 60px;

  &::after {
    display: ${props => (props.disabled === true ? "block" : "none")}
    background-color: ${props => props.theme.popularProvidersOverlayBG};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
  }
`;

const TickerFormFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const SearchMoreTxt = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
  margin-top: 2px;
`;

const SearchMoreWrapper = styled.div`
  position: relative;
  display: flex;
`;

const SearchMoreError = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 102px;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.errorCLR};
  margin-top: -11px;
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  color: ${props => props.theme.errorCLR};
  margin-bottom: 16px;
`;

const DoneBtn = styled(PrimaryButton)`
  width: 124px;
  min-width: 124px;
  height: 44px;
  margin-bottom: 20px;
`;

const CarsConnectComponent = props => {
  const { searchInputPlaceholder = "" } = props;

  const portfolio = useSelector(currentPortfolioSelector);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const formRef = useRef();
  const searchInpRef = useRef();

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false); // eslint-disable-line
  const [searchText, setSearchText] = useState("");
  const [isSearchNotFound, setIsSearchNotFound] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [selectedTickers, setSelectedTickers] = useState([]);
  const [currentCustodian, setCurrentCustodian] = useState(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [tickersWithNoValue, setTickersWithNoValue] = useState([]);

  const custodian = getCurrentCustodianFromCustodianId(props.custodianId);

  const getSearchInput = () => {
    if (searchInpRef.current && searchInpRef.current.querySelector("input")) {
      return searchInpRef.current.querySelector("input");
    }

    return {
      onFocus: () => null,
      onBlur: () => null
    };
  };

  const onSearchChange = (value = "") => {
    setSearchText(value.trim());
  };

  const onRenderList = item => {
    return null;
  };

  const onSelect = async selected => {
    if (isSearchLoading || (searchText ? searchText.length < 17 : true)) {
      return;
    }

    setIsSearchLoading(true);
    const response = await dispatch(getVehicleDetails(searchText)).catch(err => {
      setIsSearchLoading(false);
      setIsSearchNotFound(true);
    });

    if (response && response.length > 0) {
      response.forEach(async (eachVehicle, index) => {
        const details = await dispatch(linkAndGetVehicleDetails(portfolio.id, eachVehicle));

        if (index === response.length - 1) {
          setSearchText("");
          setIsSearchLoading(false);
        }

        setSelectedTickers([
          ...selectedTickers,
          {
            ...eachVehicle,
            ...details[0]
          }
        ]);
      });
    } else {
      setIsSearchLoading(false);
      setIsSearchNotFound(true);
    }
  };

  const searchFormSubmit = e => {
    e.preventDefault();

    onSelect();
  };

  const removeNoValTickerFromIndex = index => {
    const tempTickersWithNoValue = [...tickersWithNoValue];
    const matchingTickerIndex = tempTickersWithNoValue.findIndex(
      ticker => ticker.placeId === selectedTickers[index].placeId
    );
    tempTickersWithNoValue.splice(matchingTickerIndex, 1);
    setTickersWithNoValue(tempTickersWithNoValue);
  };

  const removeTickerFromIndex = index => {
    const tempSelectedTickers = [...selectedTickers];

    if (!tempSelectedTickers[index].tickerBalanceFound) {
      removeNoValTickerFromIndex(index);
    }

    tempSelectedTickers.splice(index, 1);
    setSelectedTickers(tempSelectedTickers);
  };

  const onRenderSelectedTicker = (actualTicker, index) => {
    const { make = "", model = "", id = index } = actualTicker;
    const carName = (make + " " + model).trim();
    actualTicker.address = `${carName}, ${actualTicker.vin}`;
    return (
      <EditableTicker
        key={id}
        ticker={actualTicker}
        index={index}
        portfolio={portfolio}
        removeTickerFromIndex={removeTickerFromIndex}
        isValueLoading={isSearchLoading}
        onTickerQuantityChange={onTickerQuantityChange}
        onMortgageSelect={onMortgageSelect}
        isEditMode={props.isEditMode}
        removeNoValTickerFromIndex={removeNoValTickerFromIndex}
        isCar={true}
      />
    );
  };

  const onTickerSubmit = e => {
    if (e) {
      e.preventDefault();
    }

    if (isSearchLoading) {
      return;
    }

    setIsSubmitted(true);
    let custodianId = (props.nextValues && props.nextValues.custodianId) || currentCustodian.id;
    let sortKey = props.nextValues && props.nextValues.sortKey;
    let nextValues = void 0;

    if (formRef.current.checkValidity()) {
      selectedTickers.forEach(async ticker => {
        const { make = "", model = "" } = ticker;
        const carName = (make + " " + model).trim();

        let relatedId = null;
        const tickerInputvalue = ticker.inputVal ? parseFloat(ticker.inputVal.replace(/[^\d.]/g, ""), 10) : 0;
        if (!ticker.relatedId && tickerInputvalue) {
          const debtSheets = filterDataFromPortfolioDetails(
            portfolio.details,
            row => !row.name && !row.value,
            void 0,
            sheet => sheet.category === category.DEBT
          );

          let debtLoanSection = filterDataFromPortfolioDetails(
            {
              sheet: debtSheets.sheets,
              section: debtSheets.sections,
              custodian: debtSheets.rows
            },
            row => !row.name && !row.value,
            void 0,
            sheet => sheet.name === "Loans"
          );

          if (debtLoanSection.sheets.length === 0) {
            const secondItemSortKey = getSortKeyBetween(
              debtSheets.sheets[0] ? debtSheets.sheets[0].sortKey : null,
              debtSheets.sheets[1] ? debtSheets.sheets[1].sortKey : null
            );
            const newSheet = getEmptySheet("Loans", secondItemSortKey, portfolio.currency, []);
            await dispatch(insertSheet(category.DEBT, newSheet));

            debtLoanSection = filterDataFromPortfolioDetails(
              portfolio.details,
              row => !row.name && !row.value,
              void 0,
              sheet => sheet.category === category.DEBT && sheet.name === "Loans"
            );
          }

          relatedId = (debtLoanSection.rows || []).length > 0 ? debtLoanSection.rows[0].id : getUuid();
          dispatch(insertCustodianAtEndOfSection(portfolio.id, debtLoanSection.sections[0].id, relatedId));

          const tickerId = getTickerUsingShortName(portfolio.currency).id;
          const portfolioValueTickerId = getTickerUsingShortName(ticker.currency).id;
          dispatch(
            updateCustodian(
              true,
              relatedId,
              {
                name: i18n.t("loan.debtTitle").replace("%s%", carName),
                value: tickerInputvalue,
                valueTickerId: ticker.tickerDetails?.tickerId || tickerId,
                isCompleted: 1,
                valueExchangeRate:
                  ticker.tickerDetails?.exchangeRate ||
                  getExchangeRateDetails(
                    tickerId,
                    getExchangeRate(getTickerUsingId(tickerId || portfolioValueTickerId).shortName, portfolio.currency)
                  ),
                past: 0
              },
              true
            )
          );
        }

        const newCustodian = {
          linkType: ticker.accountName && ticker.balance ? accountLinkingService.CARS : 0,
          name: carName,
          value: ticker.balance ? ticker.balance : 0,
          valueTickerId: ticker.accountName ? 150 : null,
          linkProviderAccountId: ticker.providerAccountId || null,
          linkAccountId: ticker.id || null,
          linkAccountMask: "",
          linkAccountName: ticker.accountName || null,
          linkAccountContainer: ticker.accountName ? "other" : null,
          linkProviderName: ticker.providerName || null,
          linkProviderId: ticker.providerName || null,
          relatedId: ticker.relatedId || relatedId,
          type: 1,
          subType: tickerSubTypes.CARS
        };
        nextValues = dispatch(
          insertTickerCustodianInSection(
            portfolio.id,
            currentCustodian.sectionId,
            custodianId,
            newCustodian,
            sortKey,
            custodian => {
              if (!custodian.id) {
                dispatch(
                  utilityStatus({
                    errorMessage: "Failed to link custodian",
                    ...newCustodian,
                    linkType: getAccountLinkingService(ticker.accountName ? accountLinkingService.CARS : 0)
                  })
                );
              }
            }
          )
        );

        custodianId = nextValues.custodianId;
        sortKey = nextValues.sortKey;
      });

      if (e) {
        DialogOverlay.forceDismiss(history, location);
      } else {
        props.setNextValues(nextValues);
      }
    } else {
      setErrorMessage(i18n.t(" "));
      props.onConfirmCancel();
    }
  };

  const onAddMoreClick = () => {
    getSearchInput().focus();
  };

  const onSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const onSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const saveConfirmModal = () => {
    onTickerSubmit();

    if (formRef.current && formRef.current.checkValidity()) {
      props.onConfirmSave();
    }
    setIsConfirmModalVisible(false);
  };

  const closeConfirmModal = () => {
    props.onConfirmCancel();
    setIsConfirmModalVisible(false);
  };

  const getRenderText = (item, ticker) => {
    const portfolioValueTickerId = getTickerUsingShortName(ticker.currency).id;

    const debtLinkedRow = item || {
      value: 0,
      valueTickerId: portfolioValueTickerId
    };

    const debtExchangeRate = getExchangeRate(
      getTickerUsingId(debtLinkedRow.valueTickerId || portfolioValueTickerId).shortName,
      ticker.currency
    );

    return `${formatNumberWithCurrency(item.value * debtExchangeRate, ticker.currency)} (${item.name})`;
  };

  const onMortgageSelect = (item, index, ticker) => {
    if (!item) {
      return;
    }

    const tempSelectedTickers = [...selectedTickers];

    tempSelectedTickers[index].inputVal = getRenderText(item, ticker);
    tempSelectedTickers[index].relatedId = item.id;
    setSelectedTickers(tempSelectedTickers);
  };

  const onTickerQuantityChange = (value, index, tickerDetails) => {
    const tempSelectedTickers = [...selectedTickers];

    tempSelectedTickers[index].inputVal = value;
    tempSelectedTickers[index].relatedId = null;
    tempSelectedTickers[index].tickerDetails = tickerDetails;
    setSelectedTickers(tempSelectedTickers);
  };

  useEffect(() => {
    if (custodian) {
      setCurrentCustodian(custodian);
    } else {
      DialogOverlay.forceDismiss(history, location);
    }
  }, [custodian, history, location]);

  useEffect(() => {
    setIsSearchNotFound(false);
    setIsSubmitted(false);
    setErrorMessage("");
  }, [searchText, selectedTickers]);

  const { carsSelectedTab, carsClickedTab, clickedTab, onConfirmSave } = props;
  useEffect(() => {
    if ((clickedTab !== 1 || carsSelectedTab !== carsClickedTab) && formRef.current) {
      setIsConfirmModalVisible(true);
    } else {
      onConfirmSave();
    }
  }, [clickedTab, carsSelectedTab, carsClickedTab, onConfirmSave]);

  if (currentCustodian) {
    const isAddBtnDisabled = isSearchLoading || (searchText ? searchText.length < 17 : true);

    return (
      <Container className={props.className}>
        <SearchMoreWrapper>
          <SearchForm ref={searchInpRef} onSubmit={searchFormSubmit}>
            <SearchInp
              inputPlaceholder={searchInputPlaceholder}
              searchValue={searchText}
              options={[]}
              onSearchChange={onSearchChange}
              onRenderList={onRenderList}
              onSelect={onSelect}
              isLoading={isSearchLoading}
              onFocus={onSearchFocus}
              onBlur={onSearchBlur}
            />
          </SearchForm>
          {isSearchNotFound && <SearchMoreError>{i18n.t("nothingFound")}</SearchMoreError>}
          <AddBtn data-cy="addBtn" onClick={onSelect} isDisabled={isAddBtnDisabled}>
            {i18n.t("add")}
          </AddBtn>
        </SearchMoreWrapper>
        {selectedTickers.length > 0 && (
          <TickersForm
            ref={formRef}
            onSubmit={onTickerSubmit}
            disabled={isSearchFocused}
            noValidate
            data-exitconfirm={i18n.t("linkAccount.modalCarsTitle")}
          >
            {selectedTickers.map(onRenderSelectedTicker)}
            <SearchMoreTxt onClick={onAddMoreClick}>{i18n.t("linkAccount.addMoreTickers")}</SearchMoreTxt>
            <TickerFormFooter>
              <ErrorMessage>{errorMessage}</ErrorMessage>
              <DoneBtn title={i18n.t("done")} data-cy="doneBtn" onClick={() => null} isDisabled={isSearchLoading} />
            </TickerFormFooter>
          </TickersForm>
        )}
        {isConfirmModalVisible && (
          <ConfirmationDialog
            title={i18n.t("linkAccount.saveAddTitle").replace("%s%", i18n.t("linkAccount.modalCarsTitle"))}
            description={i18n.t("linkAccount.saveStocksAddDesc").replace("%s%", i18n.t("linkAccount.modalCarsTitle"))}
            positiveButtonTitle={i18n.t("save")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={closeConfirmModal}
            handlePositiveButtonClick={saveConfirmModal}
          />
        )}
      </Container>
    );
  }

  return null;
};

export default CarsConnectComponent;
