import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";
import { hashParams, modalValues } from "routes";
import { useTheme } from "theme";
import { useLocation } from "react-router-dom";
import {
  portfolioNetWorth,
  currentPortfolioSelector,
  currentPortfolioNetWorthDataSelector,
  convertCurrency,
  getSymbolForTickerUsingShortName,
  shortFormatNumberWithCurrency,
  getNetWorthChartStartDateForPortfolio,
  getTickerUsingId,
  getNetWorthChartTimeRangeForPortfolio,
  getMonthFromDate,
  chartTimeRange,
  userPreferencesSelector,
  updateUserPreferences,
  getHashParams,
  doughnutDataForPortfolioSelector,
  getDateInKuberaFormat,
  useHistory
} from "@kubera/common";

import ScrollCounter from "components/scroll_counter/ScrollCounter";
import ChangeLabel from "components/labels/ChangeLabel";
import LineChartComponent from "components/charts/LineChartComponent";
import ClickableLink from "components/labels/DelayLink";
import NetWorthTotalChangeDialog, { changeMode } from "components/net_worth/MobileNetWorthTotalChangeDialog";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import slideAnimation from "utilities/slideAnimation";

import { ReactComponent as EmptyChartBody } from "assets/images/empty_chart_body.svg";
import { ReactComponent as EmptyChartBodyDark } from "assets/images/empty_chart_body_dark.svg";
import { ReactComponent as RightArrow } from "assets/images/right_arrow_change.svg";

const FixedContainer = styled.div`
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 16px;
  background: ${props => props.theme.mobileBackground};
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  animation: ${slideAnimation};
`;

const NetworthChange = styled(ChangeLabel)`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.015em;
`;

const LineChart = styled(LineChartComponent)`
  flex: 1;
  z-index: 1;
  contain: layout;
`;

const EmptyLineChart = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 154px;
  opacity: 30%;

  &::before {
    content: " ";
    position: absolute;
    bottom: -20px;
    left: 0;
    height: 60px;
    width: 100%;
    background: ${props => props.theme.chartBgColor};
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: -60px;
    left: 0;
    height: 60px;
    width: 100%;
    background: ${props => props.theme.mobileBackground};
  }

  & svg {
    position: relative;
    top: -100px;
  }
`;

const EmptyLineChartTxt = styled.div`
  position: absolute;
  top: 112px;
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.theme.mobileTxtColor};
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  width: 100%;
`;

const TimeRangeContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px 20px;
  background: ${props => (props.isChartHavingOverlap ? props.theme.chartBgOverlapColor : props.theme.chartBgColor)};
  height: 64px;
  font-feature-settings: "ss01" on, "calt" off;
  opacity: ${props => (props.hasChartData ? "100%" : "30%")};
  z-index: 1;
`;

const TimeRange = styled.div`
  color: ${props => (props.isSelected ? props.theme.mobileTxtColor : props.theme.mobileTxtColorLight)};
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
`;

const CategoryTabsBlk = styled.div`
  position: relative;
  display: block;
  height: 125px;
  text-decoration: none;
  user-select: none;
  padding: 0 20px;
  margin-bottom: ${props => (props.isLast === true ? "10px" : "42px")};
  visibility: ${props => (props.isHidden === true ? "hidden" : "visible")};
`;

const CategoryTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 6px;
  color: ${props => props.theme.mobileTxtColorSectionTotal};
  font-feature-settings: "ss01" on, "calt" off;
`;

const CategoryTotal = styled(ScrollCounter)`
  display: block;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -2px;
  margin-bottom: 6px;
  color: ${props => props.theme.mobileTxtColor};
  font-feature-settings: "ss01" on, "calt" off;
  height: 44px;
`;

const TotalChangeWapper = styled(ClickableLink)`
  display: inline-block;
  padding: 6px 5px;
  margin-bottom: ${props => (!props.isLastRow ? "59px" : null)};
`;

const ChangeWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const CategoryTimeRange = styled.div`
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: ${props => props.theme.mobileTxtColorChangeTitle};
  margin-bottom: 6px;
`;

const RightArrowOpenChange = styled(RightArrow)`
  margin-left: 6px;

  path {
    fill: ${props => props.theme.mobileTxtColorChangeArrow};
  }
`;

const LineChartParent = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
`;

const LineChartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  max-height: 375px;
  justify-content: flex-end;
  flex-direction: column;
`;

const timeRangeMenuItems = [
  { id: chartTimeRange.WEEKLY, label: "1 Week" },
  { id: chartTimeRange.MONTHLY, label: "1 Month" },
  { id: chartTimeRange.YEARLY, label: "1 Year" },
  { id: chartTimeRange.YTD, label: "YTD" },
  { id: chartTimeRange.ALL, label: "All-time" }
];

const MobileNetWorthComponent = ({ direction }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const userPreferences = useSelector(userPreferencesSelector);
  const netWorth = useSelector(portfolioNetWorth);
  const networthData = useSelector(currentPortfolioNetWorthDataSelector);
  const portfolio = useSelector(currentPortfolioSelector);
  const netWorthChartStartDate = useSelector(getNetWorthChartStartDateForPortfolio);
  const selectedTimeRange = useSelector(getNetWorthChartTimeRangeForPortfolio);
  const doughnutData = useSelector(doughnutDataForPortfolioSelector);
  const theme = useTheme();

  const [currentLastDataPointIndex, setCurrentLastDataPointIndex] = useState(null);

  const hasNetworthData = networthData && networthData.data;

  const shouldShowInvestableData = useMemo(() => {
    for (const data of doughnutData) {
      if (data.isInvestableData === true) {
        return true;
      }
    }
    return false;
  }, [doughnutData]);

  const getNetWorth = () => {
    if (!hasNetworthData) {
      return "";
    }

    return convertCurrency(
      getCurrentLastDataPoint().value,
      getTickerUsingId(networthData.data[selectedTimeRange].tickerId).shortName,
      portfolio.currency
    );
  };

  const getInvestableTotal = () => {
    if (!hasNetworthData) {
      return "";
    }

    const netWorthData = networthData.data[selectedTimeRange];
    const netWorthTicker = getTickerUsingId(netWorthData.tickerId).shortName;
    const portfolioTicker = portfolio.currency;
    const total = getCurrentLastDataPoint().investibleTotal;
    return convertCurrency(total, netWorthTicker, portfolioTicker);
  };

  const getDataPoints = () => {
    if (!hasNetworthData) {
      return [];
    }

    const netWorthData = networthData.data[selectedTimeRange];
    const netWorthDataPoints = netWorthData.netWorth.filter(dataPoint => {
      return new Date(dataPoint.date).getTime() >= new Date(netWorthChartStartDate).setUTCHours(0, 0, 0, 0);
    });

    if (netWorthDataPoints.length === 0) {
      return [];
    }

    return netWorthDataPoints;
  };

  const getCurrentLastDataPoint = () => {
    const indexToConsider = currentLastDataPointIndex === null ? dataPoints.length - 1 : currentLastDataPointIndex;
    return dataPoints[indexToConsider] || {};
  };

  const getRangeValuesForAttribute = key => {
    const netWorthDataPoints = dataPoints;
    if (netWorthDataPoints.length === 0) {
      return {
        startValue: 0,
        endValue: 0
      };
    }
    const netWorthDataForTimeRange = networthData.data[selectedTimeRange];
    const netWorthTicker = getTickerUsingId(netWorthDataForTimeRange.tickerId).shortName;
    const portfolioTicker = portfolio.currency;
    const netWorthValue = netWorthDataPoints[0] && netWorthDataPoints[0][key];
    const startValue = convertCurrency(netWorthValue, netWorthTicker, portfolioTicker);
    const endValue = convertCurrency(getCurrentLastDataPoint()[key], netWorthTicker, portfolioTicker);

    return {
      startValue: startValue,
      endValue: endValue
    };
  };

  const isDataEmpty = () => {
    if (!hasNetworthData) {
      return true;
    }
    return dataPoints.length < 2;
  };

  const getLabelDateString = date => {
    return `${date.getDate()} ${getMonthFromDate(date)} ${date.getFullYear()}`.toUpperCase();
  };

  const getLineChartData = () => {
    if (isDataEmpty() === true) {
      return null;
    }

    const netWorthData = networthData.data[selectedTimeRange];
    const netWorthDataPoints = dataPoints;
    const portfolioTicker = portfolio.currency;
    //const firstDataPoint = netWorthDataPoints[0];

    let data = {
      labels: netWorthDataPoints.map((dataPoint, index) => {
        const dateString = getLabelDateString(getDateInKuberaFormat(dataPoint.date));
        var label = dateString;
        return label;
      }),
      datasets: [
        {
          data: netWorthDataPoints.map(dataPoint =>
            convertCurrency(dataPoint.value, getTickerUsingId(netWorthData.tickerId).shortName, portfolioTicker)
          )
        }
      ]
    };

    if (shouldShowInvestableData) {
      data.datasets.push({
        data: netWorthDataPoints.map(dataPoint =>
          convertCurrency(dataPoint.investibleTotal, getTickerUsingId(netWorthData.tickerId).shortName, portfolioTicker)
        )
      });
    }

    return data;
  };

  const setDataPointIndex = index => {
    setCurrentLastDataPointIndex(index);
  };

  const clearDataPointIndex = () => {
    setCurrentLastDataPointIndex(null);
  };

  const handleTotalChangeClick = () => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.WORTH_CHANGE}&${hashParams.INTERVAL}=${changeMode.NETWORTH}`
    });
  };

  const handleInvestableTotalChangeClick = () => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.WORTH_CHANGE}&${hashParams.INTERVAL}=${changeMode.INVESTABLE}`
    });
  };

  const getNetWorthCurrency = () => {
    return networthData.data[selectedTimeRange].currency;
  };

  const getCurrentlySelectedTimeRangeText = () => {
    for (const item of timeRangeMenuItems) {
      if (item.id === selectedTimeRange) {
        return item.label;
      }
    }
  };

  const handleTotalChangeDialogDismiss = () => {
    DialogOverlay.forceDismiss(history, location);
  };

  const dataPoints = getDataPoints();
  const netWorthChange = getRangeValuesForAttribute("value");
  const investableChange = getRangeValuesForAttribute("investibleTotal");
  const lineChartData = getLineChartData();
  const netWorthTotal = getNetWorth();
  const investableTotal = getInvestableTotal();
  const currentTimeRangeText = getCurrentlySelectedTimeRangeText();
  const hasChartData = dataPoints && dataPoints.length > 0;

  const handleTimeRangeMenuSelection = item => {
    if (!hasChartData) {
      return;
    }
    clearDataPointIndex();

    const map = userPreferences.portfolioNetWorthChartTimeRangeMap;
    map[portfolio.id] = item.id;
    dispatch(updateUserPreferences({ portfolioNetWorthChartTimeRangeMap: map }));
  };

  const urlHashParams = getHashParams(location);
  const modalValue = urlHashParams[hashParams.MODAL];
  const showTotalChangeDialogForInterval = urlHashParams[hashParams.INTERVAL] || changeMode.NETWORTH;

  const shouldShowInvestable = shouldShowInvestableData && investableTotal !== 0;

  const renderNetWorthLineChart = () => {
    if (isDataEmpty()) {
      return (
        <EmptyLineChart>
          {theme.mode === "dark" ? <EmptyChartBodyDark /> : <EmptyChartBody />}
          <EmptyLineChartTxt>{i18n.t("mobileHomeComponent.emptyChart")}</EmptyLineChartTxt>
        </EmptyLineChart>
      );
    } else {
      if (hasChartData) {
        return (
          <LineChart
            data={lineChartData}
            adjustPageScroll={true}
            chartPadding={{
              left: 0,
              right: 0,
              top: 30,
              bottom: 0
            }}
            onHoverOverSection={setDataPointIndex}
          />
        );
      }
      return (
        <EmptyLineChart>
          {theme.mode === "dark" ? <EmptyChartBodyDark /> : <EmptyChartBody />}
          <EmptyLineChartTxt>{i18n.t("mobileHomeComponent.emptyChart")}</EmptyLineChartTxt>
        </EmptyLineChart>
      );
    }
  };

  return (
    <FixedContainer>
      <Container direction={direction}>
        <CategoryTabsBlk>
          <CategoryTitle>{i18n.t("networth")}</CategoryTitle>
          <CategoryTotal
            displayValue={`${getSymbolForTickerUsingShortName(portfolio.currency)}${shortFormatNumberWithCurrency(
              netWorthTotal || 0,
              portfolio.currency
            )}`}
            preventAnimation={currentLastDataPointIndex}
          />
          <TotalChangeWapper onClick={handleTotalChangeClick} direction="left">
            <CategoryTimeRange>{currentTimeRangeText}</CategoryTimeRange>
            <ChangeWrapper>
              <NetworthChange
                currency={portfolio.currency}
                startValue={netWorthChange.startValue}
                endValue={netWorthChange.endValue}
                alignPosition="left"
                isAnimationAllowed={false}
              />
              <RightArrowOpenChange />
            </ChangeWrapper>
          </TotalChangeWapper>
        </CategoryTabsBlk>
        <CategoryTabsBlk isHidden={shouldShowInvestable === false} isLast={true}>
          <CategoryTitle>{i18n.t("investable")}</CategoryTitle>
          <CategoryTotal
            displayValue={`${getSymbolForTickerUsingShortName(portfolio.currency)}${shortFormatNumberWithCurrency(
              investableTotal || 0,
              portfolio.currency
            )}`}
            preventAnimation={currentLastDataPointIndex}
          />
          <TotalChangeWapper onClick={handleInvestableTotalChangeClick} isLastRow direction="left">
            <CategoryTimeRange>{currentTimeRangeText}</CategoryTimeRange>
            <ChangeWrapper>
              <NetworthChange
                currency={portfolio.currency}
                startValue={investableChange.startValue}
                endValue={investableChange.endValue}
                alignPosition="left"
                isAnimationAllowed={false}
              />
              <RightArrowOpenChange />
            </ChangeWrapper>
          </TotalChangeWapper>
        </CategoryTabsBlk>
        <LineChartParent>
          <LineChartContainer hasInvestable={investableTotal > 0}>
            {renderNetWorthLineChart()}
            <TimeRangeContainer
              hasChartData={hasChartData}
              isChartHavingOverlap={lineChartData && lineChartData.datasets && lineChartData.datasets.length > 1}
            >
              {timeRangeMenuItems.map(eachRange => (
                <TimeRange
                  key={eachRange.id}
                  onClick={() => handleTimeRangeMenuSelection(eachRange)}
                  isSelected={hasChartData && eachRange.id === selectedTimeRange}
                >
                  {eachRange.label}
                </TimeRange>
              ))}
            </TimeRangeContainer>
            {modalValue === modalValues.WORTH_CHANGE && dataPoints.length > 0 && (
              <NetWorthTotalChangeDialog
                mode={showTotalChangeDialogForInterval}
                currency={portfolio.currency}
                total={showTotalChangeDialogForInterval === changeMode.INVESTABLE ? investableTotal : netWorth}
                totalStartValue={
                  showTotalChangeDialogForInterval === changeMode.INVESTABLE
                    ? investableChange.startValue
                    : netWorthChange.startValue
                }
                totalEndValue={
                  showTotalChangeDialogForInterval === changeMode.INVESTABLE
                    ? investableChange.endValue
                    : netWorthChange.endValue
                }
                startDataPointCurrency={getNetWorthCurrency()}
                startDataPoint={dataPoints[0]}
                endDataPoint={dataPoints[dataPoints.length - 1]}
                chartTimeRangeText={currentTimeRangeText}
                onDismiss={handleTotalChangeDialogDismiss}
                direction={direction}
              />
            )}
          </LineChartContainer>
        </LineChartParent>
      </Container>
    </FixedContainer>
  );
};

export default React.memo(MobileNetWorthComponent);
