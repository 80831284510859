import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { hashParams, modalValues } from "routes";
import i18n from "i18next";
import { useTheme, dialogGridTheme } from "theme";
import {
  archivedCustodianSelector,
  fetchArchivedPortfolio,
  archivedPortfolioStateSelector,
  resetArchivedPortfolioAction,
  currentPortfolioSelector,
  getTickerUsingId,
  getTickersForIds,
  UNKNOWN_TICKER_SHORT_NAME,
  useHistory
} from "@kubera/common";

import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import Loader from "components/loader/Loader";
import ArchivedCustodiansComponent from "components/archived/ArchivedCustodiansComponent";
import { category as CATEGORY } from "components/dashboard/DashboardComponent";

const DetailsDialog = styled(Dialog)`
  width: 1007px;
  min-height: 600px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-top: 80px;
`;

const Container = styled.div`
  margin: 50px 60px 50px 60px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  font-feature-settings: "ss01" on;
`;

const Custodians = styled(ArchivedCustodiansComponent)`
  margin-top: 10px;
  border-bottom: 1px solid ${props => props.dialogTheme.gridSectionBorderColor};
`;

const ArchivedComponent = ({ category = CATEGORY.ASSET }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const dialogTheme = dialogGridTheme(theme.mode);

  const custodians = useSelector(archivedCustodianSelector);
  const portfolio = useSelector(currentPortfolioSelector);
  const archivedPortfolioState = useSelector(archivedPortfolioStateSelector);

  const [isUnknownTickersHandled, setIsUnknownTickersHandled] = useState(false);

  const isLoading = archivedPortfolioState.fetchDetailsPending || !isUnknownTickersHandled;
  const details = archivedPortfolioState.details;
  const error = archivedPortfolioState.fetchDetailsError;

  const fetchArchivedPortfolioIfNeeded = useCallback(async () => {
    if (archivedPortfolioState.details === null) {
      await dispatch(fetchArchivedPortfolio(portfolio.id, category)).catch(() => {});
    }

    return;
  }, [archivedPortfolioState.details, category, dispatch, portfolio.id]);

  const fetchArchivedPortfolioUnknownTickers = useCallback(async () => {
    if (!custodians) {
      return;
    }

    const unknownTickersList = [];

    for (const [, custodian] of custodians.entries()) {
      const tickerShortName = getTickerUsingId(custodian.valueTickerId).shortName;

      if (tickerShortName === UNKNOWN_TICKER_SHORT_NAME && custodian.valueTickerId) {
        unknownTickersList.push(custodian.valueTickerId);
      }
    }

    if (unknownTickersList.length > 0) {
      await dispatch(getTickersForIds(unknownTickersList)).catch(() => {});
    }

    setIsUnknownTickersHandled(true);

    return;
  }, [custodians, dispatch]);

  const handleOverlayDismiss = () => {
    setTimeout(() => {
      dispatch(resetArchivedPortfolioAction());
    }, 100);

    DialogOverlay.dismiss(history, location);
  };

  const handleLoaderRefreshClick = async e => {
    await fetchArchivedPortfolioIfNeeded();
  };

  useEffect(() => {
    const onMount = async () => {
      await fetchArchivedPortfolioIfNeeded();
      await fetchArchivedPortfolioUnknownTickers();
    };

    onMount();
  }, [fetchArchivedPortfolioIfNeeded, fetchArchivedPortfolioUnknownTickers]);

  return (
    <DialogOverlay onDismiss={handleOverlayDismiss}>
      <DetailsDialog>
        <Container>
          <Title>{i18n.t("archivedComponent.title")}</Title>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {error !== null && <Loader errorMessage={error.errorMessage} onRefresh={handleLoaderRefreshClick} />}
              {details !== null && (
                <Custodians
                  custodians={custodians}
                  portfolio={portfolio}
                  category={category}
                  dialogTheme={dialogTheme}
                />
              )}
            </>
          )}
        </Container>
      </DetailsDialog>
    </DialogOverlay>
  );
};

ArchivedComponent.show = (history, location) => {
  history.push({
    ...location,
    hash: `${hashParams.MODAL}=${modalValues.ARCHIVED}`
  });
};

export default ArchivedComponent;
