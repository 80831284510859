import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";

import {
  getSubscriptionEndDateString,
  accountCardDetailsSelector,
  accountPlanSelector,
  accountEndTsSelector,
  cancelSubscription,
  accountSubscriptionStatusSelector,
  fetchUser,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
  isMobile,
  userPriceOptionSelector
} from "@kubera/common";

import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";
import Spinner from "components/loader/Spinner";

import MobileAccountSubscribedCard from "./MobileAccountSubscribedCard";
import NFTDiscountMessage from "./NFTDiscountMessage";

const isMobileDevice = isMobile();

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  width: 300px;
  height: 188px;
  background: ${props => props.theme.accountSubscribedCardBG};
  padding: 20px;
  border: ${props => props.theme.accountSubscribedCardBR};
  box-sizing: border-box;
  border-radius: 16px;
  cursor: pointer;
`;

const CardDetails = styled.div`
  font-size: 14px;
  color: ${props => props.theme.accountSubscribedCardCLR};
`;

const CardName = styled.div`
  font-family: Roboto Mono;
  font-weight: bold;
  margin-bottom: 4px;
  text-transform: uppercase;
`;
const AccountType = styled(CardName)`
  height: 100%;
  white-space: pre-wrap;
  font-size: 13px;
  color: ${props => props.theme.accountSubscribedCardCLR};
`;

const CardInfo = styled.div`
  font-family: Roboto Mono;
  font-weight: bold;
  margin-bottom: 2px;
  text-transform: uppercase;
`;

const CardPlan = styled.div`
  font-family: Roboto Mono;
  font-size: 12px;
  text-transform: uppercase;
`;

const CardPlanDue = styled.div`
  font-family: Roboto Mono;
  font-size: 12px;
  text-transform: uppercase;
  color: #ff3333;
`;

const UpdateLink = styled.div`
  display: inline-block;
  text-decoration: underline;
  color: #2d73f9;
  cursor: pointer;
`;

const ChangeAction = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 10px;
  cursor: pointer;
  color: ${props => props.theme.accountSubscribedCardChangeActionCLR};
  text-transform: uppercase;
`;

const Footer = styled.div`
  position: absolute;
  top: calc(100% + 110px);
  display: flex;
`;

const CancelSubscriptionAction = styled.div`
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.8);
`;

const CancelSubscriptionDialog = styled(Dialog)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 680px;
  margin-top: 164px;
  padding: 50px;
  box-sizing: border-box;
`;

const CancelModalTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CancelModalMsg = styled.div`
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 30px;
  white-space: pre-wrap;
`;

const CancelBtnBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CancelSubscriptionBtn = styled(PrimaryButton)`
  display: inline-flex;
  margin-right: 16px;
  background: #a50000;
`;

const CancelGoBackBtn = styled(SecondaryButton)`
  display: inline-flex;
`;

const Loader = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
`;

const AuthLoader = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

const AddCardBlock = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  text-decoration: underline;
  margin-bottom: 5px;
  cursor: pointer;
`;

const AccountSubscribedCard = ({
  setUpdateMode = () => null,
  setUpdateCardMode = () => null,
  isAuthenticating = false
}) => {
  const dispatch = useDispatch();

  const accountCardDetails = useSelector(accountCardDetailsSelector);
  const accountPlan = useSelector(accountPlanSelector);
  const accountEndTs = useSelector(accountEndTsSelector);
  const accountSubscriptionStatus = useSelector(accountSubscriptionStatusSelector);
  const userPrice = useSelector(userPriceOptionSelector);
  const endDate = getSubscriptionEndDateString(accountEndTs);

  const plan = [SUBSCRIPTION_PLANS.MONTHLY].includes(accountPlan)
    ? i18n.t("accountSubscribed.plan1").replace("%s%", userPrice[accountPlan])
    : i18n.t("accountSubscribed.plan2").replace("%s%", userPrice[accountPlan]);

  const [isCancelModalVisible, setCancelModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cancelModalMsg =
    accountSubscriptionStatus === SUBSCRIPTION_STATUS.PAST_DUE
      ? i18n.t("accountSubscribed.cancelWarn")
      : i18n.t("accountSubscribed.cancelWarnAfter").replace("%s%", endDate);
  const cancelSubscriptionBtn =
    accountSubscriptionStatus === SUBSCRIPTION_STATUS.PAST_DUE
      ? i18n.t("ok")
      : i18n.t("accountSubscribed.cancelSubscription");

  const onCancelModalActionClick = () => {
    setCancelModalVisible(true);
  };

  const onDismissCancel = () => {
    setCancelModalVisible(false);
  };

  const handleCancelClick = useCallback(async () => {
    setIsLoading(true);
    await dispatch(cancelSubscription());
    dispatch(fetchUser());
  }, [dispatch]);

  const onUpdateCardAction = () => {
    setUpdateCardMode(true);
  };

  const onChangeAction = () => {
    setUpdateMode(true);
  };

  if (accountCardDetails.cardBrand) {
    return (
      <>
        <Container onClick={onChangeAction}>
          <AccountType>{i18n.t(`accountSettings.accountType_${accountPlan}`)}</AccountType>
          <CardDetails>
            {!accountCardDetails.cardLast4 === false ? (
              <>
                <CardName>{accountCardDetails.cardBrand}</CardName>
                <CardInfo>
                  **** {accountCardDetails.cardLast4} • Exp {accountCardDetails.cardExpiryMonth}/
                  {accountCardDetails.cardExpiryYear}
                </CardInfo>
              </>
            ) : (
              <AddCardBlock onClick={onChangeAction}>Add Card</AddCardBlock>
            )}
            {accountSubscriptionStatus === SUBSCRIPTION_STATUS.TRIALING ? (
              <CardPlan>
                {i18n.t("accountSubscribed.startsOn").replace("%s%", plan)}
                {endDate}
              </CardPlan>
            ) : accountSubscriptionStatus !== SUBSCRIPTION_STATUS.PAST_DUE ? (
              <CardPlan>
                {i18n.t("accountSubscribed.renewsOn").replace("%s%", plan)}
                {endDate}
              </CardPlan>
            ) : (
              <CardPlanDue>
                {i18n.t("accountSubscribed.cardError").replace("%s%", plan)}
                <br />
                {i18n.t("accountSubscribed.retryTomorrowMsg")}{" "}
                <UpdateLink onClick={onUpdateCardAction}>{i18n.t("accountSubscribed.updateCardLink")}</UpdateLink>
              </CardPlanDue>
            )}
          </CardDetails>
          <ChangeAction onClick={onChangeAction}>{i18n.t("change")}</ChangeAction>
          {isAuthenticating && (
            <Loader>
              <AuthLoader />
            </Loader>
          )}
        </Container>
        <Footer>
          <CancelSubscriptionAction onClick={onCancelModalActionClick}>
            {i18n.t("accountSubscribed.cancelSubscription")}
          </CancelSubscriptionAction>
        </Footer>
        {isCancelModalVisible && (
          <DialogOverlay onDismiss={onDismissCancel}>
            <CancelSubscriptionDialog>
              <CancelModalTitle>{i18n.t("accountSubscribed.cancelSubscription")}?</CancelModalTitle>
              <CancelModalMsg
                dangerouslySetInnerHTML={{
                  __html: cancelModalMsg
                }}
              />
              <CancelBtnBlock>
                <CancelSubscriptionBtn
                  title={cancelSubscriptionBtn}
                  onClick={handleCancelClick}
                  isLoading={isLoading}
                />
                <CancelGoBackBtn
                  title={i18n.t("accountSubscribed.goBackBtn")}
                  onClick={onDismissCancel}
                  isDisabled={isLoading}
                />
              </CancelBtnBlock>
            </CancelSubscriptionDialog>
          </DialogOverlay>
        )}
        <NFTDiscountMessage isSubscribed accountPlan={accountPlan} />
      </>
    );
  }

  return null;
};

export default isMobileDevice ? MobileAccountSubscribedCard : AccountSubscribedCard;
