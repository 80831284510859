import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import {
  formatDateForLastLogin,
  wlClientSelector,
  wlClientAndSubUserSelector,
  deleteWlSubUser,
  updateWlClientInvite,
  archiveWlClient,
  wlUserStatus,
  siteConfigSelector
} from "@kubera/common";
import Loader from "components/loader/Loader";
import { ReactComponent as DeleteIcon } from "assets/images/delete_user_icon.svg";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { hashParams, modalValues } from "routes";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import TextInput from "components/inputs/TextInput";
import PrimaryButton from "components/button/PrimaryButton";
import SecondaryButton from "components/button/SecondaryButton";

import InviteClientDialogComponent from "../InviteClientDialogComponent";

const MultiClientUsersDialog = styled(Dialog)`
  position: relative;
  width: 612px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  min-height: 451px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 60px 60px 67px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const ListLoader = styled(Loader)`
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
  margin-bottom: 18px;
`;

const FieldTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const NameField = styled(TextInput)`
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const MemberListContainer = styled.div`
  margin-bottom: 30px;
  min-height: 250px;
`;

const MemberItem = styled.div`
  display: flex;
  padding: 10px 15px 10px 15px;
  font-style: normal;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: ${({ lastItem }) => (!lastItem ? "0" : null)};
`;

const MemberDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MemberName = styled.div`
  font-weight: 400;
  font-size: 11px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.5);
`;

const MemberEmail = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  margin-top: 2px;
`;

const MemberLastSeen = styled.div`
  margin-top: 3px;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.5);
`;

const MemberActions = styled.div`
  display: flex;
  align-items: center;
`;

const ChangeEmail = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
`;

const ReInviteButton = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  text-decoration-line: underline;
  text-transform: uppercase;
  font-feature-settings: "ss01" on, "calt" off;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  margin-right: 15px;
`;

const DeleteUserButton = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AddUserButton = styled.div`
  padding: 15px;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: "ss01" on;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const DeleteUserConfirmationDialog = styled(ConfirmationDialog)`
  min-width: 655px;
`;

const ArchiveClientDialog = styled(DeleteUserConfirmationDialog)``;

const SomethingWentWrong = styled(DeleteUserConfirmationDialog)``;

const ActionContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const SaveButton = styled(PrimaryButton)`
  min-width: 130px;
  height: 44px;
  padding: 10px;
`;

const CancelButton = styled(SecondaryButton)`
  margin-left: 18px;
  min-width: 134px;
  height: 44px;
  padding: 10px;
`;

const ArchiveClientAction = styled.div`
  font-size: 14px;
  line-height: 130%;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
`;

class MultiuserManagementComponent extends React.Component {
  static show = (history, location, userId = null) => {
    var hash = `${hashParams.MODAL}=${modalValues.WL_MANAGE_CLIENT}`;
    if (!userId === false) {
      hash += `&${hashParams.ID}=${userId}`;
    }

    history.push({
      ...location,
      hash: hash
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      showAddUserDialog: false,
      reInvitedMultiuserIds: [],
      showUpgradeDialog: false,
      showDeleteSubuserConfirmation: false,
      isDeletingUser: false,
      isArchivingClient: false,
      deleteUserErrorMessage: null,
      userToDelete: null,
      showDeleteSelfConfirmation: false,
      isArchiveClientDialogOpen: false,
      name: !props.client === false ? props.client.clientName : "",
      nameErrorMessage: null,
      reInviteUserDialog: false,
      subUserId: null,
      newUserDialog: false,
      clientChangeDialog: false,
      isSomethingWentWrongDialogOpen: false,
      isSaveButtonLoading: false,
      inviteDialog: false
    };
  }

  get isManageChangeDialogOpen() {
    return (
      this.state.inviteDialog === true ||
      this.state.reInviteUserDialog === true ||
      this.state.newUserDialog === true ||
      this.state.clientChangeDialog === true
    );
  }

  handleDeleteUserClick = (e, user) => {
    this.setState({ showDeleteSubuserConfirmation: true, userToDelete: user });
  };

  handleReInviteClick = (e, user) => {
    const reInvitedMultiuserIds = this.state.reInvitedMultiuserIds;
    reInvitedMultiuserIds.push(user.id);

    this.setState({ reInvitedMultiuserIds: reInvitedMultiuserIds });
    this.setState({
      reInviteUserDialog: true,
      subUserId: user.userId
    });
  };

  handleAddUserClick = e => {
    if (this.orderedUserList.length === 0) {
      this.setState({
        inviteDialog: true
      });
    } else {
      this.setState({
        newUserDialog: true
      });
    }
  };

  handleArchiveClient = async () => {
    this.setState({
      isArchiveClientDialogOpen: true
    });
  };

  handleDeleteUserConfirmationDismiss = () => {
    this.setState({ showDeleteSelfConfirmation: false, showDeleteSubuserConfirmation: false, userToDelete: null });
  };

  handleDeleteUserConfirmationClick = async e => {
    this.setState({ isDeletingUser: true });

    await this.props.deleteWlSubUser(this.props.client, this.state.userToDelete).catch(error => {
      this.setState({ isDeletingUser: false, deleteUserErrorMessage: error.errorMessage });
    });

    this.setState({ showDeleteSubuserConfirmation: false, isDeletingUser: false });
  };

  handleClientArchiveConfirmationClick = async e => {
    this.setState({ isArchivingClient: true });

    await this.props.archiveWlClient(this.props.client).catch(error => {
      this.setState({ isArchivingClient: false, deleteUserErrorMessage: error.errorMessage });
    });

    DialogOverlay.forceDismiss(this.props.history, this.props.location);
  };

  handleClientArchiveDismiss = () => {
    this.setState({ isArchiveClientDialogOpen: false });
  };

  handleOverlayDismiss = () => {
    DialogOverlay.forceDismiss(this.props.history, this.props.location);
  };

  handleNameInputChange = e => {
    this.setState({ name: e.target.value, nameErrorMessage: null });
  };

  handleChangeEmail = e => {
    this.setState({
      clientChangeDialog: true
    });
  };

  handleInviteDialogDismiss = () => {
    this.setState({
      reInviteUserDialog: false,
      newUserDialog: false,
      clientChangeDialog: false,
      subUserId: null,
      inviteDialog: false
    });
  };

  handleSaveButtonClick = () => {
    this.setState({
      isSaveButtonLoading: true
    });
    this.props.updateWlClientInvite(
      this.state.name,
      this.props.client.email,
      this.props.client.email,
      "",
      false,
      !this.props.client === false && !this.props.client.share === false ? this.props.client.share.passcode : "",
      user => {
        this.setState({
          isSaveButtonLoading: false
        });
        DialogOverlay.forceDismiss(this.props.history, this.props.location);
      },
      apiError => {
        this.setState({
          isSomethingWentWrongDialogOpen: true,
          isSaveButtonLoading: false
        });
      }
    );
  };

  handleSomethingWrongDismiss = () => {
    this.setState({
      isSomethingWentWrongDialogOpen: false
    });
  };

  handleSomethingWrongPositiveButtonClick = () => {
    this.handleSomethingWrongDismiss();
    this.handleSaveButtonClick();
  };

  get orderedUserList() {
    if (!this.props.userList === true) {
      return [];
    }
    const owner = this.props.userList.find(item => {
      return !item.parentUserId === true;
    });
    const subAccounts = this.props.userList
      .filter(item => !item.parentUserId === false)
      .sort((a, b) => a.tsInvite - b.tsInvite);
    return [...(owner ? [owner] : []), ...subAccounts];
  }

  getFormattedEmail(user) {
    if (user.status === wlUserStatus.ACCEPTED) {
      return user.email;
    }
    if (user.status !== wlUserStatus.ADDED) {
      return `${user.email} (${i18n.t("invited")})`;
    }
    return `${user.email} (${i18n.t("notInvited")})`;
  }

  getUserLastSeenString(user) {
    if (user.status === wlUserStatus.ACCEPTED) {
      return `${i18n.t("lastLogin")}: ${formatDateForLastLogin(user.tsLastAccess)}`;
    }
    return `${i18n.t("invitedOn")}: ${formatDateForLastLogin(user.tsReInvite || user.tsInvite)}`;
  }

  shouldShowAddButton() {
    if (this.props.client.subUser.length < this.props.siteConfig.subUserLimit) {
      return true;
    }
    return false;
  }

  render() {
    if (this.props.isPending === true && !this.props.userList === true) {
      return <ListLoader />;
    }

    const name = this.state.name;
    const nameErrorMessage = this.state.nameErrorMessage;

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <MultiClientUsersDialog>
          <Container>
            <Title>{i18n.t("accountSettings.wlkMultiuserManagementTabTitle")}</Title>
            <FieldTitle>{i18n.t("clientsName")}</FieldTitle>
            <NameField
              placeholder={i18n.t("fullname")}
              value={name}
              errorMessage={nameErrorMessage}
              onChange={this.handleNameInputChange}
            />
            <FieldTitle>{i18n.t("accountSettings.wlkManageUsersTabTitle")}</FieldTitle>
            <MemberListContainer>
              {this.orderedUserList.map((user, index) => {
                return (
                  <MemberItem key={index} lastItem={this.props.siteConfig.subUserLimit === index}>
                    <MemberDetails>
                      <MemberEmail>{this.getFormattedEmail(user)}</MemberEmail>
                      <MemberName>
                        {user.name}
                        {index === 0 && ` • ${i18n.t("primaryUser")}`}
                      </MemberName>
                      <MemberLastSeen>{this.getUserLastSeenString(user)}</MemberLastSeen>
                    </MemberDetails>
                    <MemberActions>
                      {!user.parentUserId === true ? (
                        <ChangeEmail onClick={this.handleChangeEmail}>{i18n.t("changeEmail")}</ChangeEmail>
                      ) : user.status === wlUserStatus.ADDED || !!user.tsArchive ? (
                        <ReInviteButton onClick={e => this.handleReInviteClick(e, user)}>
                          {i18n.t("invite")}
                        </ReInviteButton>
                      ) : user.status === wlUserStatus.INVITED ? (
                        this.state.reInvitedMultiuserIds.includes(user.id) === false && (
                          <ReInviteButton onClick={e => this.handleReInviteClick(e, user)}>
                            {i18n.t("reInvite")}
                          </ReInviteButton>
                        )
                      ) : null}
                      {!user.parentUserId === false && (
                        <DeleteUserButton onClick={e => this.handleDeleteUserClick(e, user)}>
                          <DeleteIcon />
                        </DeleteUserButton>
                      )}
                    </MemberActions>
                  </MemberItem>
                );
              })}
              {this.shouldShowAddButton() === true && (
                <AddUserButton onClick={this.handleAddUserClick}>
                  {this.orderedUserList.length > 0 ? i18n.t("newUserClient") : i18n.t("inviteUsers")}
                </AddUserButton>
              )}
            </MemberListContainer>
            <ActionContainer>
              <SaveButton
                onClick={this.handleSaveButtonClick}
                title={i18n.t("save")}
                isLoading={this.state.isSaveButtonLoading}
                data-cy="saveButton"
              />
              <CancelButton onClick={this.handleOverlayDismiss} title={i18n.t("cancel")} data-cy="cancelButton" />
            </ActionContainer>
            <ArchiveClientAction onClick={this.handleArchiveClient}>
              {i18n.t("wlkManageUser.removeAndArchive")}
            </ArchiveClientAction>
            {this.state.showDeleteSubuserConfirmation === true && (
              <DeleteUserConfirmationDialog
                title={i18n.t("wlkDeleteSubuserDialog.title")}
                description={i18n.t("wlkDeleteSubuserDialog.description")}
                positiveButtonTitle={i18n.t("wlkDeleteSubuserDialog.action")}
                negativeButtonTitle={i18n.t("goBackLink")}
                handlePositiveButtonClick={this.handleDeleteUserConfirmationClick}
                handleNegativeButtonClick={this.handleDeleteUserConfirmationDismiss}
                canUserDismiss={this.state.isDeletingUser === false}
                isLoading={this.state.isDeletingUser}
                errorMessage={this.state.deleteUserErrorMessage}
                onDismiss={this.handleDeleteUserConfirmationDismiss}
              />
            )}
            {this.state.isArchiveClientDialogOpen === true && (
              <ArchiveClientDialog
                title={i18n.t("wlkArchiveClientDialog.title")}
                description={i18n.t("wlkArchiveClientDialog.desc")}
                positiveButtonTitle={i18n.t("wlkArchiveClientDialog.action")}
                negativeButtonTitle={i18n.t("goBackLink")}
                canUserDismiss={this.state.isArchivingClient === false}
                isLoading={this.state.isArchivingClient}
                errorMessage={this.state.deleteUserErrorMessage}
                handlePositiveButtonClick={this.handleClientArchiveConfirmationClick}
                handleNegativeButtonClick={this.handleClientArchiveDismiss}
                onDismiss={this.handleClientArchiveDismiss}
              />
            )}
            {this.isManageChangeDialogOpen === true && (
              <InviteClientDialogComponent
                userId={this.props.client.userId}
                subUserId={this.state.subUserId}
                isChangeClient={this.state.clientChangeDialog}
                isNewUserFlow={this.state.newUserDialog}
                isReinviteFlow={this.state.reInviteUserDialog}
                onDismiss={this.handleInviteDialogDismiss}
              />
            )}
            {this.state.isSomethingWentWrongDialogOpen === true && (
              <SomethingWentWrong
                title={i18n.t("wlkManageUser.somethingWentWrongTitle")}
                description={i18n.t("wlkManageUser.somethingWentWrongDesc")}
                positiveButtonTitle={i18n.t("retry")}
                handlePositiveButtonClick={this.handleSomethingWrongPositiveButtonClick}
                onDismiss={this.handleSomethingWrongDismiss}
                canUserDismiss
              />
            )}
          </Container>
        </MultiClientUsersDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  client: wlClientSelector(state, props.userId),
  userList: wlClientAndSubUserSelector(state, props.userId),
  siteConfig: siteConfigSelector(state)
});

const mapDispatchToProps = {
  deleteWlSubUser,
  updateWlClientInvite,
  archiveWlClient
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MultiuserManagementComponent));
