import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import LineChartComponent from "components/charts/LineChartComponent";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import {
  getTickerUsingId,
  convertCurrency,
  chartTimeRange,
  getMonthFromDate,
  getNetWorthChartStartDateForPortfolio,
  getNetWorthChartTimeRangeForPortfolio,
  updateUserPreferences,
  userPreferencesSelector,
  portfolioNetWorth,
  isAppInViewMode,
  currentPortfolioSelector,
  fetchNetWorthDataForPortfolio,
  fetchPortfolioChangeData,
  updatePortfolioStartDateTs,
  getDateInKuberaFormat,
  recapDataTotalInvestableAssetsValueSelector
} from "@kubera/common";
import { ReactComponent as DownArrowIcon } from "assets/images/menu_downarrow.svg";
import { ReactComponent as ChartPreferences } from "assets/images/cogwheel.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import ChangeLabel from "components/labels/ChangeLabel";
import ChartSettingsDialogComponent from "components/net_worth/ChartSettingsDialogComponent";
import Loader from "components/loader/Loader";
import emptyLineChartBackground from "assets/images/blank_line_charts.png";
import emptyLineChartBackgroundDark from "assets/images/blank_line_charts_dark.png";
import NetWorthTotalChangeDialog, { changeMode } from "./NetWorthTotalChangeDialog";
import NetWorthChartNotAvailableComponent from "./NetWorthChartNotAvailableComponent";
import ToolTip, { toolTipAlignment } from "components/tooltip/ToolTip";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.netWorthContainerBG};
  border: ${props => props.theme.netWorthContainerBR};
  box-sizing: border-box;
  margin-bottom: 6px;
`;

const EmptyLineChartContainer = styled.div`
  display: flex;
  position: relative;
  min-height: 336px;
  background-color: transparent;
  background-image: url(${props =>
    props.theme.mode === "default" ? emptyLineChartBackground : emptyLineChartBackgroundDark});
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 764px 174px;
  border: ${props => props.theme.emptyLineChartContainerBR};
`;

const EmptyChartTitle = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.emptyLineChartTitleCLR};
`;

const EmptyChartMessage = styled.div`
  display: flex;
  position: absolute;
  bottom: 70px;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${props => props.theme.emptyChartMessageCLR};
`;

const EmptyViewContainer = styled.div`
  display: flex;
  justify-content: center
  align-items: center;
  position: relative;
  top: 0px;
  right: 50%;
`;

const LoadingContentContainer = styled.div`
  position: relative;
  min-height: 336px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingChartTitle = styled.div`
  position: absolute;
  top: 30px;
  left: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  font-feature-settings: "ss01" on;
`;

const ValueAndSettingsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  margin: 25px 30px 16px 30px;
  font-size: 14px;
`;

const NetWorthDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${props => (props.takeFullWidth ? "1" : "")};
`;

const NetWorthInvestableDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${props => (!props.takeFullWidth ? "35px" : null)};
`;

const NetWorthDetailsTitle = styled.div`
  display: flex;
  flex: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
`;

const NetWorthDetailsDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
`;

const NetWorthValue = styled(CurrencyHeaderLabel)`
  min-width: 225px;
  margin-bottom: 6px;
`;

const NetWorthChange = styled(ChangeLabel)`
  position: relative;
  top: -4px;
  cursor: pointer;
  vertical-align: bottom;
  display: flex;
  align-items: flex-end;

  &:hover {
    text-decoration: underline};
  }

  .child-container {
    justify-content: flex-start;
  }
`;

const SelectTimeRangeButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  margin-left: 15px;
  padding-bottom: 3px;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  text-transform: uppercase;
  color: ${props => props.theme.selectTimeRangeButtonCLR};
`;

const SelectTimeRangeButtonIcon = styled(DownArrowIcon)`
  margin-left: 3px;
  margin-bottom: 1px;

  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const ChartPreferencesButton = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: -7px
  margin-right: -8px
  margin-left: 7px
  & > svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background-color: ${props => props.theme.focusBackgroundColor};
  }

  &:focus {
    background-color: ${props => props.theme.focusBackgroundColor};
  }
`;

const ChartPreferencesIcon = styled(ChartPreferences)`
  pointer-events: none;
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;
const ChartPreferencesButtonContainer = styled.div`
  position: relative;
`;

const LineChart = styled(LineChartComponent)``;

const ChartSettingsDialog = styled(ChartSettingsDialogComponent)``;

class NetWorthLineChartComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLastDataPointIndex: null,
      lineChartData: this.getLineChartData(),
      showChartStartDatePreferencesDialog: false,
      showNetWorthChangeDialogInMode: null,
      isHoveredOver: false
    };
    this.chartSettingsToolTip = React.createRef();

    this.handleTimeRangeSelectionButtonClick = this.handleTimeRangeSelectionButtonClick.bind(this);
    this.handleTimeRangeMenuSelection = this.handleTimeRangeMenuSelection.bind(this);
    this.handleLineChartHoverOverSection = this.handleLineChartHoverOverSection.bind(this);
    this.handleChartPreferencesButtonClick = this.handleChartPreferencesButtonClick.bind(this);
    this.handleChartPreferenceDialogDismiss = this.handleChartPreferenceDialogDismiss.bind(this);
    this.handleChartPreferenceDialogSave = this.handleChartPreferenceDialogSave.bind(this);
    this.handleTotalChangeDialogDismiss = this.handleTotalChangeDialogDismiss.bind(this);
    this.handleTotalChangeClick = this.handleTotalChangeClick.bind(this);
    this.contextMenuRef = React.createRef();
  }

  isReadOnly() {
    return isAppInViewMode() === true || this.props.portfolio.write === 0;
  }

  componentDidMount() {
    this.showChartSettingsToolTip();
  }

  componentDidUpdate(oldProps) {
    const pid = this.props.portfolio.id;
    if (
      oldProps.data !== this.props.data ||
      oldProps.selectedTimeRange !== this.props.selectedTimeRange ||
      oldProps.netWorthChartStartDate !== this.props.netWorthChartStartDate ||
      oldProps.portfolioNetWorth !== this.props.portfolioNetWorth ||
      oldProps.portfolioInvestableTotalForAssets !== this.props.portfolioInvestableTotalForAssets ||
      oldProps.userPreferences[`${pid}-showNetworthChart`] !== this.props.userPreferences[`${pid}-showNetworthChart`] ||
      oldProps.userPreferences[`${pid}-showInvestableAssetsChart`] !==
        this.props.userPreferences[`${pid}-showInvestableAssetsChart`]
    ) {
      this.setState({ lineChartData: this.getLineChartData() });
    }
    this.showChartSettingsToolTip();
  }

  showChartSettingsToolTip() {
    if (this.props.userPreferences && this.props.userPreferences.isChartSettingsTipShown !== true) {
      this.chartSettingsToolTip.current && this.chartSettingsToolTip.current.show(i18n.t("chartSettingsTip"), -77, 5);
    }
  }

  getLabelDateString(date) {
    return `${date.getDate()} ${getMonthFromDate(date)} ${date.getFullYear()}`.toUpperCase();
  }

  getAllTimeRangeEdgeDataPoints() {
    let allDataPoints = {};
    const menuItems = this.getTimeRangeMenuItems();
    for (const item of menuItems[0]) {
      allDataPoints[item.id] = {};

      const dataPoints = this.getDataPoints(item.id);
      if (dataPoints.length === 0) {
        continue;
      }
      allDataPoints[item.id].start = dataPoints[0];
      allDataPoints[item.id].end = dataPoints[dataPoints.length - 1];
    }
    return allDataPoints;
  }

  getDataPoints(timeRange) {
    const netWorthData = this.props.data[timeRange];
    const netWorthDataPoints = netWorthData.netWorth;

    if (netWorthDataPoints.length === 0) {
      return [];
    }

    const netWorthDataTicker = getTickerUsingId(netWorthData.tickerId);
    const portfolioTicker = this.props.portfolio.currency;
    netWorthDataPoints[netWorthDataPoints.length - 1].value = convertCurrency(
      this.props.portfolioNetWorth,
      portfolioTicker,
      netWorthDataTicker.shortName
    );
    netWorthDataPoints[netWorthDataPoints.length - 1].investibleTotal = convertCurrency(
      this.props.portfolioInvestableTotalForAssets,
      portfolioTicker,
      netWorthDataTicker.shortName
    );
    return netWorthDataPoints;
  }

  getNetWorthCurrency() {
    return this.props.data[this.props.selectedTimeRange].currency;
  }

  getLineChartData() {
    if (this.isDataEmpty() === true) {
      return null;
    }

    const pid = this.props.portfolio.id;
    const showNetworthChart =
      this.props.userPreferences[`${pid}-showNetworthChart`] === undefined
        ? true
        : this.props.userPreferences[`${pid}-showNetworthChart`];
    const showInvestableAssetsChart =
      this.props.userPreferences[`${pid}-showInvestableAssetsChart`] === undefined
        ? true
        : this.props.userPreferences[`${pid}-showInvestableAssetsChart`];

    const netWorthData = this.props.data[this.props.selectedTimeRange];
    const netWorthDataPoints = this.getDataPoints(this.props.selectedTimeRange);
    const portfolioTicker = this.props.portfolio.currency;
    //const firstDataPoint = netWorthDataPoints[0];

    const data = {
      labels: netWorthDataPoints.map((dataPoint, index) => {
        const dateString = this.getLabelDateString(getDateInKuberaFormat(dataPoint.date));
        var label = dateString;
        // const changeFromFirstValue = getPercentageChange(firstDataPoint.value, dataPoint.value);

        // // If the net worth has moved up by more than 1% from the base then
        // // find the top assets and show the percentage change for them
        // if (!changeFromFirstValue === false && Math.abs(changeFromFirstValue) >= 1) {
        //   const topAssets = dataPoint.asset.slice(0, 3);

        //   for (const asset of topAssets) {
        //     const assetInitialDataPoint = netWorthDataPoints
        //       .slice(0, index)
        //       .find(temp => !temp.asset.find(assetEntry => assetEntry.id === asset.id) === false);

        //     if (!assetInitialDataPoint === false) {
        //       const initialAssetValue = assetInitialDataPoint.asset.find(temp => temp.id === asset.id).value;
        //       const assetChangeFromFirstValue = getPercentageChange(initialAssetValue, asset.value);
        //       label = label + `<div><span>${asset.name}</span>`;
        //       if (!assetChangeFromFirstValue === false) {
        //         label =
        //           label +
        //           `<span style="color:${
        //             assetChangeFromFirstValue > 0
        //               ? props.theme.gridGreenColorForLightBackground
        //               : props.theme.gridRedColorForLightBackground
        //           }">  ${assetChangeFromFirstValue}%</span>`;
        //       }
        //       label = label + "</div>";
        //     } else {
        //       label = label + `<div>${asset.name}</div>`;
        //     }
        //   }
        // }
        return label;
      }),
      datasets: []
    };

    const networthData = {
      data: netWorthDataPoints.map(dataPoint =>
        convertCurrency(dataPoint.value, getTickerUsingId(netWorthData.tickerId).shortName, portfolioTicker)
      )
    };

    const investableData = {
      data: netWorthDataPoints.map(dataPoint =>
        convertCurrency(dataPoint.investibleTotal, getTickerUsingId(netWorthData.tickerId).shortName, portfolioTicker)
      )
    };

    if (showNetworthChart) {
      data.datasets.push(networthData);
    }
    if (showInvestableAssetsChart) {
      data.datasets.push(investableData);
    }
    return data;
  }

  getTimeRangeMenuItems() {
    return [
      [
        { id: chartTimeRange.WEEKLY, label: "1 Week" },
        { id: chartTimeRange.MONTHLY, label: "1 Month" },
        { id: chartTimeRange.QUARTERLY, label: "3 Months" },
        { id: chartTimeRange.YEARLY, label: "1 Year" },
        { id: chartTimeRange.YTD, label: "YTD" },
        { id: chartTimeRange.ALL, label: "All-time" }
      ]
    ];
  }

  handleTimeRangeSelectionButtonClick(event) {
    const targetPosition = event.target.getBoundingClientRect();
    const menuItems = this.getTimeRangeMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === this.props.selectedTimeRange) {
        item.selected = true;
      }
    }

    this.contextMenuRef.current.show(
      menuItems,
      targetPosition.left + targetPosition.width,
      targetPosition.top + targetPosition.height,
      false,
      event.target
    );
  }

  handleTimeRangeMenuSelection(item) {
    this.setState({ currentLastDataPointIndex: null }, () => {
      this.setState({
        isHoveredOver: false
      });
    });

    const map = this.props.userPreferences.portfolioNetWorthChartTimeRangeMap;
    map[this.props.portfolio.id] = item.id;
    this.props.updateUserPreferences({ portfolioNetWorthChartTimeRangeMap: map });
  }

  handleLineChartHoverOverSection(index) {
    this.setState({ currentLastDataPointIndex: index, isHoveredOver: true }, () => {
      this.setState({
        isHoveredOver: !!this.state.currentLastDataPointIndex
      });
    });
  }

  getCurrentlySelectedTimeRangeText() {
    const menuItems = this.getTimeRangeMenuItems();

    for (const item of menuItems[0]) {
      if (item.id === this.props.selectedTimeRange) {
        return item.label;
      }
    }
  }

  getAllRangeValuesForAttribute(key) {
    let timeRanges = {};
    const menuItems = this.getTimeRangeMenuItems();
    for (const item of menuItems[0]) {
      timeRanges[item.id] = this.getRangeValuesForAttribute(key, item.id);
    }
    return timeRanges;
  }

  getRangeValuesForAttribute(key, range, current = true) {
    const netWorthData = this.props.data[range];
    const netWorthDataPoints = this.getDataPoints(range);
    const netWorthTicker = getTickerUsingId(netWorthData.tickerId).shortName;
    const portfolioTicker = this.props.portfolio.currency;
    const netWorthValue = netWorthDataPoints[0] && netWorthDataPoints[0][key];
    const startValue = convertCurrency(netWorthValue, netWorthTicker, portfolioTicker);
    const endValue = convertCurrency(this.getCurrentLastDataPoint(current)[key], netWorthTicker, portfolioTicker);
    return {
      startValue: startValue,
      endValue: endValue
    };
  }

  getInvestableTotal(current = true) {
    const netWorthData = this.props.data[this.props.selectedTimeRange];
    const netWorthTicker = getTickerUsingId(netWorthData.tickerId).shortName;
    const portfolioTicker = this.props.portfolio.currency;
    const total = this.getCurrentLastDataPoint(current).investibleTotal;
    return convertCurrency(total, netWorthTicker, portfolioTicker);
  }

  getCurrentLastDataPoint(current = true) {
    const dataPoints = this.getDataPoints(this.props.selectedTimeRange);
    const indexToConsider =
      current === false || !this.state === true || this.state.currentLastDataPointIndex === null
        ? dataPoints.length - 1
        : this.state.currentLastDataPointIndex;
    return dataPoints[indexToConsider];
  }

  getNetWorth(current = true) {
    return convertCurrency(
      this.getCurrentLastDataPoint(current).value,
      getTickerUsingId(this.props.data[this.props.selectedTimeRange].tickerId).shortName,
      this.props.portfolio.currency
    );
  }

  handleChartPreferencesButtonClick(e) {
    if (!this.props.userPreferences.isChartSettingsTipShown) {
      this.chartSettingsToolTip.current.dismiss();
      this.props.updateUserPreferences({ isChartSettingsTipShown: true });
    }
    this.setState({ showChartStartDatePreferencesDialog: true });
  }

  handleChartPreferenceDialogDismiss() {
    this.setState({ showChartStartDatePreferencesDialog: false });
  }

  handleChartPreferenceDialogSave(date) {
    this.setState({ showChartStartDatePreferencesDialog: false });

    this.props.updatePortfolioStartDateTs(date.getTime());
    this.props.fetchNetWorthData(this.props.currentPortfolio.id);
    this.props.fetchPortfolioChangeData(this.props.currentPortfolio.id, false, true);
  }

  isDataEmpty() {
    if (!this.props.data === true) {
      return true;
    }
    const dataPoints = this.getDataPoints(this.props.selectedTimeRange);
    return dataPoints.length < 2;
  }

  handleTotalChangeDialogDismiss() {
    this.setState({ showNetWorthChangeDialogInMode: null });
  }

  handleTotalChangeClick(e, mode) {
    this.setState({ showNetWorthChangeDialogInMode: mode });
  }

  render() {
    const { className, portfolio, isLoading, isNetWorthChartEmpty } = this.props;
    const isEmpty = this.isDataEmpty();

    if (isLoading === true) {
      return (
        <Container className={className}>
          <LoadingContentContainer>
            <LoadingChartTitle>{i18n.t("networth")}</LoadingChartTitle>
            <Loader />
          </LoadingContentContainer>
        </Container>
      );
    }

    const pid = portfolio.id;
    const showNetworthChart =
      this.props.userPreferences[`${pid}-showNetworthChart`] === undefined
        ? true
        : this.props.userPreferences[`${pid}-showNetworthChart`];
    const showInvestableAssetsChart =
      this.props.userPreferences[`${pid}-showInvestableAssetsChart`] === undefined
        ? true
        : this.props.userPreferences[`${pid}-showInvestableAssetsChart`];

    const currentTimeRangeText = this.getCurrentlySelectedTimeRangeText();
    const chartStartDatePreference = this.props.netWorthChartStartDate;
    const showChartStartDatePreferencesDialog = this.state.showChartStartDatePreferencesDialog;

    if (showChartStartDatePreferencesDialog) {
      return (
        <ChartSettingsDialog
          title={i18n.t("chartStartDatePreferenceDialog.title")}
          description={i18n.t("chartStartDatePreferenceDialog.description")}
          date={chartStartDatePreference}
          onChange={this.handleChartPreferenceDialogSave}
          onDismiss={this.handleChartPreferenceDialogDismiss}
          showHideChartOption={isEmpty || isNetWorthChartEmpty ? false : true}
          portfolioId={portfolio.id}
          dashboardChartIds={this.props.dashboardChartIds}
          handleSuggestedChartsTabCheckBoxChange={this.props.handleSuggestedChartsTabCheckBoxChange}
          handleResetToDefaultLink={this.props.handleResetToDefaultLink}
        />
      );
    }

    if (isNetWorthChartEmpty === true) {
      return (
        <>
          <EmptyLineChartContainer className={className}>
            <ValueAndSettingsContainer>
              <EmptyChartTitle>{i18n.t("networth")}</EmptyChartTitle>
              {this.isReadOnly() === false && this.props.dashboardChartIds.length > 0 && (
                <ChartPreferencesButtonContainer>
                  <ChartPreferencesButton onClick={this.handleChartPreferencesButtonClick} id="chart_settings">
                    <ChartPreferencesIcon />
                  </ChartPreferencesButton>
                </ChartPreferencesButtonContainer>
              )}
            </ValueAndSettingsContainer>
            <EmptyViewContainer>
              <NetWorthChartNotAvailableComponent />
            </EmptyViewContainer>
            {/* <EmptyChartMessage>{i18n.t("detailsChartEmpty")}</EmptyChartMessage> */}
          </EmptyLineChartContainer>
          <ToolTip
            ref={this.chartSettingsToolTip}
            targetId={"chart_settings"}
            arrowOffset={20}
            align={toolTipAlignment.BOTTOM_RIGHT}
            onPositiveClick={() => {
              this.chartSettingsToolTip.current.dismiss();
              this.props.updateUserPreferences({ isChartSettingsTipShown: true });
            }}
          />
        </>
      );
    }

    if (isEmpty === true) {
      return (
        <>
          <EmptyLineChartContainer className={className}>
            <ValueAndSettingsContainer>
              <EmptyChartTitle>{i18n.t("networth")}</EmptyChartTitle>
              <SelectTimeRangeButton onClick={this.handleTimeRangeSelectionButtonClick}>
                {currentTimeRangeText}
                <SelectTimeRangeButtonIcon />
              </SelectTimeRangeButton>
              {this.isReadOnly() === false && (
                <ChartPreferencesButtonContainer>
                  <ChartPreferencesButton onClick={this.handleChartPreferencesButtonClick} id="chart_settings">
                    <ChartPreferencesIcon />
                  </ChartPreferencesButton>
                </ChartPreferencesButtonContainer>
              )}
            </ValueAndSettingsContainer>
            <EmptyChartMessage>{i18n.t("detailsChartEmpty")}</EmptyChartMessage>
            <ContextMenu width={132} ref={this.contextMenuRef} onSelection={this.handleTimeRangeMenuSelection} />
          </EmptyLineChartContainer>
          <ToolTip
            ref={this.chartSettingsToolTip}
            targetId={"chart_settings"}
            arrowOffset={20}
            align={toolTipAlignment.BOTTOM_RIGHT}
            onPositiveClick={() => {
              this.chartSettingsToolTip.current.dismiss();
              this.props.updateUserPreferences({ isChartSettingsTipShown: true });
            }}
          />
        </>
      );
    }

    const lineChartData = this.state.lineChartData;
    const networth = this.getNetWorth(showNetworthChart);
    const netWorthChange = this.getRangeValuesForAttribute("value", this.props.selectedTimeRange, showNetworthChart);
    const investableChange = this.getRangeValuesForAttribute(
      "investibleTotal",
      this.props.selectedTimeRange,
      showInvestableAssetsChart
    );
    const netWorthChangeAllTimeRange = this.getAllRangeValuesForAttribute("value");
    const investableChangeAllTimeRange = this.getAllRangeValuesForAttribute("investibleTotal");
    const investableTotal = this.getInvestableTotal(showInvestableAssetsChart);
    const shouldShowInvestableData = investableTotal !== 0;
    const showChange = this.state.currentLastDataPointIndex !== 0;
    const dataPoints = this.getDataPoints(this.props.selectedTimeRange);
    const allTimeRangeEdgeDataPoints = this.getAllTimeRangeEdgeDataPoints();
    const changeDialogMode = this.state.showNetWorthChangeDialogInMode;
    const showInvestablePreference =
      this.props.userPreferences[`${pid}-showInvestableAssetsChart`] === undefined
        ? true
        : this.props.userPreferences[`${pid}-showInvestableAssetsChart`];
    const showNetWorthPreference =
      this.props.userPreferences[`${pid}-showNetworthChart`] === undefined
        ? true
        : this.props.userPreferences[`${pid}-showNetworthChart`];

    return (
      <Container className={className}>
        <ValueAndSettingsContainer>
          {showNetWorthPreference && (
            <NetWorthDetailsContainer takeFullWidth={!showInvestablePreference || shouldShowInvestableData === false}>
              <NetWorthDetailsTitle>{i18n.t("networth")}</NetWorthDetailsTitle>
              <NetWorthDetailsDataContainer>
                <NetWorthValue
                  value={networth}
                  currency={portfolio.currency}
                  currencyFontSize={13}
                  valueFontSize={24}
                  height={"29px"}
                  isAnimationAllowed={
                    !this.state.isHoveredOver || this.state.currentLastDataPointIndex === dataPoints.length - 1
                  }
                />
                {showChange === true && (
                  <NetWorthChange
                    currency={portfolio.currency}
                    startValue={netWorthChange.startValue}
                    endValue={netWorthChange.endValue}
                    onClick={e => this.handleTotalChangeClick(e, changeMode.NETWORTH)}
                    alignPosition="left"
                    isAnimationAllowed={false}
                  />
                )}
              </NetWorthDetailsDataContainer>
            </NetWorthDetailsContainer>
          )}
          {showInvestablePreference && shouldShowInvestableData === true && (
            <NetWorthInvestableDetailsContainer takeFullWidth={!showNetWorthPreference}>
              <NetWorthDetailsTitle>{i18n.t("investable")}</NetWorthDetailsTitle>
              <NetWorthDetailsDataContainer>
                <NetWorthValue
                  value={investableTotal}
                  currency={portfolio.currency}
                  currencyFontSize={13}
                  valueFontSize={24}
                  height={"29px"}
                  showZero={true}
                  isAnimationAllowed={
                    !this.state.isHoveredOver || this.state.currentLastDataPointIndex === dataPoints.length - 1
                  }
                />
                {showChange === true && (
                  <NetWorthChange
                    currency={portfolio.currency}
                    startValue={investableChange.startValue}
                    endValue={investableChange.endValue}
                    onClick={e => this.handleTotalChangeClick(e, changeMode.INVESTABLE)}
                    alignPosition="left"
                    isAnimationAllowed={false}
                  />
                )}
              </NetWorthDetailsDataContainer>
            </NetWorthInvestableDetailsContainer>
          )}
          <SelectTimeRangeButton onClick={this.handleTimeRangeSelectionButtonClick}>
            {currentTimeRangeText}
            <SelectTimeRangeButtonIcon />
          </SelectTimeRangeButton>

          {this.isReadOnly() === false && (
            <ChartPreferencesButtonContainer>
              <ChartPreferencesButton onClick={this.handleChartPreferencesButtonClick} id="chart_settings">
                <ChartPreferencesIcon />
              </ChartPreferencesButton>
            </ChartPreferencesButtonContainer>
          )}
        </ValueAndSettingsContainer>
        <ToolTip
          ref={this.chartSettingsToolTip}
          targetId={"chart_settings"}
          arrowOffset={20}
          align={toolTipAlignment.BOTTOM_RIGHT}
          onPositiveClick={() => {
            this.chartSettingsToolTip.current.dismiss();
            this.props.updateUserPreferences({ isChartSettingsTipShown: true });
          }}
        />
        <LineChart
          data={lineChartData}
          chartHeight={240}
          adjustPageScroll={true}
          chartPadding={{
            left: 0,
            right: 0,
            top: 30,
            bottom: 0
          }}
          onHoverOverSection={this.handleLineChartHoverOverSection}
        />
        <ContextMenu width={132} ref={this.contextMenuRef} onSelection={this.handleTimeRangeMenuSelection} />
        {!changeDialogMode === false && dataPoints.length > 0 && (
          <NetWorthTotalChangeDialog
            mode={changeDialogMode}
            currency={portfolio.currency}
            total={changeDialogMode === changeMode.INVESTABLE ? investableTotal : networth}
            totalAllValues={
              changeDialogMode === changeMode.INVESTABLE ? investableChangeAllTimeRange : netWorthChangeAllTimeRange
            }
            startDataPointCurrency={this.getNetWorthCurrency()}
            allTimeRangeEdgeDataPoints={allTimeRangeEdgeDataPoints}
            startDataPoint={dataPoints[0]}
            endDataPoint={dataPoints[dataPoints.length - 1]}
            currentTimeRange={this.props.selectedTimeRange}
            onDismiss={this.handleTotalChangeDialogDismiss}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  portfolioNetWorth: portfolioNetWorth(state, props.portfolio),
  portfolioInvestableTotalForAssets: recapDataTotalInvestableAssetsValueSelector(state),
  netWorthChartStartDate: getNetWorthChartStartDateForPortfolio(state),
  selectedTimeRange: getNetWorthChartTimeRangeForPortfolio(state, props.portfolio),
  userPreferences: userPreferencesSelector(state),
  currentPortfolio: currentPortfolioSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences,
  fetchNetWorthData: fetchNetWorthDataForPortfolio,
  fetchPortfolioChangeData: fetchPortfolioChangeData,
  updatePortfolioStartDateTs: updatePortfolioStartDateTs
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetWorthLineChartComponent);
