import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import CurrencyLabel from "components/labels/CurrencyLabel";
import PrimaryButton from "components/button/PrimaryButton";
import {
  portfolioNetWorth,
  store,
  shortFormatNumberWithCurrency,
  currentPortfolioSelector,
  updatePortfolio,
  showCopiedToast,
  isAppInViewMode
} from "@kubera/common";
import { ReactComponent as TaxRateSetings } from "assets/images/cogwheel.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import { connect } from "react-redux";
import { routes } from "routes";
import { hashParams, modalValues } from "routes";
import { detailsTabs } from "components/custodian_details/CustodianDetailsComponent";
import { withRouter } from "@kubera/common";
const ListDialog = styled(Dialog)`
  width: 612px;
  min-height: 580px;
  display: flex;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TaxRateSetingsIcon = styled(TaxRateSetings)`
  width: 15px;
  cursor: pointer;
  height: 15px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const TotalValue = styled(CurrencyLabel)`
  margin-top: 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
  cursor: pointer;
`;

const Description = styled.div`
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const ListContainer = styled.div`
  margin-top: 20px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const ListRow = styled.div`
  display: flex;
  padding: 10px 18px 10px 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
  border-top: ${props => (props.isFirst === true ? "" : "1px solid rgba(0, 0, 0, 0.1)")};
  word-break: break-word;
  cursor: ${props => (props.showPointer ? "pointer" : "default")};
`;

const CustodianName = styled.div`
  flex: 1;
`;

const CustodianValue = styled(CurrencyLabel)`
  margin-left: 15px;
`;

const TipTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const TipDescription = styled.div`
  flex: 1;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const OkButton = styled(PrimaryButton)`
  margin-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  background: ${props => props.theme.emptyGridComponentOkBtnBG};
`;

const KnowMore = styled.a`
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-transform: capitalize;

  &:visited {
    color: #000000;
  }
`;

const AdjustNetWorthContainer = styled.div`
font-weight: 400;
font-size: 14px;
line-height: 20px
color: #000000;
  margin-top: 19px;
`;
const AdjustNetWorthDescription = styled.div`
  color: #000000;
`;
const AdjustNetWorthValue = styled.div`
  font-weight: 700;
  color: #000000;
`;

const TaxRatesContextMenu = styled(ContextMenu)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Taxrate = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-style: normal;
  font-weight: ${props => (props.isSelected === true ? "bold" : "normal")};
  font-size: 14px;
  line-height: 18px;
  color: "#000000";
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  text-transform: capitalize;
`;

const DefaultTaxRateMenuItem = styled.div`
  height: 40px;
  padding-right: 15px;
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.contextMenuItemSelectedBackgroundColor};
  }
`;

const DefaultTaxRateTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 18px;
  color: #000000;
  padding-left: 30px;
`;

const defaultTaxRateList = [0, 10, 15, 20, 23.8, 25, 30, 35, 40, 45, 50];

class CustodianListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.taxRateMenuRef = React.createRef();
    this.handleDialogDismiss = this.handleDialogDismiss.bind(this);
    this.handleTipDismiss = this.handleTipDismiss.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleTaxRateSettingsClick = this.handleTaxRateSettingsClick.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleTotalValueClick = this.handleTotalValueClick.bind(this);
    this.state = {
      isDragging: false,
      isMoving: false
    };
  }

  handleTotalValueClick() {
    navigator.clipboard.writeText(this.props.total).then(() => {
      this.props.showCopiedToast();
    });
  }

  handleMouseDown() {
    this.setState({ isDragging: true });
  }

  handleMouseMove() {
    if (this.state.isDragging === true) {
      this.setState({ isMoving: true });
    }
  }

  handleMouseUp() {
    this.setState({ isDragging: false });
  }

  handleDialogDismiss() {
    this.props.onDismiss();
  }

  handleTipDismiss() {
    if (this.props.showTip === true && !this.props.total === false) {
      this.props.onTipDismiss();
    } else {
      this.handleDialogDismiss();
    }
  }

  handleRowClick(item) {
    if (!isAppInViewMode() && !this.state.isDragging && !this.state.isMoving) {
      const location = { ...this.props.location, pathname: routes.ASSETS };
      const detailsHash = `${hashParams.MODAL}=${modalValues.CUSTODIAN_DETAILS}&${hashParams.ID}=${item.id}&${hashParams.DEFAULT_TAB}=${detailsTabs.ASSORTED}`;
      this.props.history.push({ ...location, hash: detailsHash });
    }
    this.setState({ isMoving: false });
  }

  handleTaxRateSettingsClick(event) {
    if (this.taxRateMenuRef.current.isVisible() === true) {
      this.taxRateMenuRef.current.dismiss();
      return;
    }
    const targetPosition = event.target.getBoundingClientRect();
    this.taxRateMenuRef.current.show(
      [],
      targetPosition.left + targetPosition.width - this.taxRateMenuRef.current.getWidth(),
      targetPosition.top + targetPosition.height + 10,
      true,
      event.target
    );
    this.taxRateMenuRef.current.show();
  }

  handleTaxRateSelection(taxRate) {
    this.taxRateMenuRef.current.dismiss();
    const currentPortfolio = currentPortfolioSelector(store.getState());
    currentPortfolio.taxRate = taxRate;
    this.props.updatePortfolio(currentPortfolio);
  }

  render() {
    const showTip = this.props.showTip === true || !this.props.total === true;
    const netWorthValue = portfolioNetWorth(store.getState());

    return (
      <DialogOverlay onDismiss={this.handleDialogDismiss}>
        <ListDialog>
          {showTip === true && (
            <Container>
              <TitleRow>
                <TipTitle>{this.props.title}</TipTitle>
                {this.props.isTaxOnUnrealizedGainsDialog && (
                  <TaxRateSetingsIcon onClick={this.handleTaxRateSettingsClick} />
                )}
              </TitleRow>
              <TipDescription
                dangerouslySetInnerHTML={{
                  __html: this.props.tip
                }}
              />
              <OkButton title={i18n.t("gotIt")} onClick={this.handleTipDismiss} />
            </Container>
          )}
          {showTip === false && (
            <Container>
              <TitleRow>
                <Title>{this.props.title}</Title>
                {this.props.isTaxOnUnrealizedGainsDialog && (
                  <TaxRateSetingsIcon onClick={this.handleTaxRateSettingsClick} />
                )}
              </TitleRow>
              <TotalValue
                value={this.props.total}
                roundDown={true}
                currency={this.props.currency}
                abbreviate={false}
                useScrollAnimation={false}
                onClick={this.handleTotalValueClick}
              />
              <Description>
                {this.props.description}{" "}
                <KnowMore target={"_blank"} href={this.props.knowMore}>
                  {i18n.t("knowMore")}
                </KnowMore>
                {this.props.isTaxOnUnrealizedGainsDialog === true && (
                  <AdjustNetWorthContainer>
                    <AdjustNetWorthDescription>{i18n.t("adjustedNetworth.description")}</AdjustNetWorthDescription>
                    <AdjustNetWorthValue>
                      {shortFormatNumberWithCurrency(
                        netWorthValue - this.props.total,
                        this.props.currency,
                        false,
                        true,
                        undefined,
                        undefined,
                        true
                      )}
                    </AdjustNetWorthValue>
                  </AdjustNetWorthContainer>
                )}
              </Description>
              <ListContainer>
                {this.props.listItems.map((item, index) => {
                  return (
                    <ListRow
                      key={index}
                      isFirst={index === 0}
                      onClick={() => this.handleRowClick(item)}
                      onMouseDown={this.handleMouseDown}
                      onMouseUp={this.handleMouseUp}
                      onMouseMove={this.handleMouseMove}
                      showPointer={!isAppInViewMode()}
                    >
                      <CustodianName>{item.name}</CustodianName>
                      <CustodianValue
                        value={item.value}
                        roundDown={true}
                        currency={this.props.currency}
                        abbreviate={false}
                        useScrollAnimation={false}
                      />
                    </ListRow>
                  );
                })}
              </ListContainer>
            </Container>
          )}
          <TaxRatesContextMenu ref={this.taxRateMenuRef}>
            <DefaultTaxRateTitle>{i18n.t("defaultTaxRate.contextMenu.title")}</DefaultTaxRateTitle>
            {defaultTaxRateList.map((taxRate, index) => {
              return (
                <DefaultTaxRateMenuItem key={index}>
                  <Taxrate
                    isSelected={taxRate === this.props.defaultTaxRateForCurrentPortfolio}
                    onClick={() => this.handleTaxRateSelection(taxRate)}
                  >{`${taxRate}%`}</Taxrate>{" "}
                </DefaultTaxRateMenuItem>
              );
            })}
          </TaxRatesContextMenu>
        </ListDialog>
      </DialogOverlay>
    );
  }
}
const mapDispatchToProps = {
  updatePortfolio: updatePortfolio,
  showCopiedToast: showCopiedToast
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(CustodianListComponent));
