import React, { useState, useEffect, Fragment } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  accountLinkingService,
  getExchangeRate,
  formatNumberWithCurrency,
  shortFormatNumberWithCurrency,
  updateCustodian,
  getTickerUsingId,
  getTickerUsingShortName,
  insertCustodianAtEndOfSection,
  getUuid,
  insertSheet,
  currentPortfolioSelector,
  tickerTypes,
  isCryptoCurrency,
  calcCustodianOwnershipValue,
  tickerSubTypes,
  getExchangeRateDetails,
  getHomeLTV,
  getSortKeyBetween,
  formatNumberWithKuberaNumberFormatSettings
} from "@kubera/common";

import { ReactComponent as ExitIcon } from "assets/images/exit.svg";
import { getEmptySheet, filterDataFromPortfolioDetails } from "components/grid/GridDataModel";
import { category } from "components/dashboard/DashboardComponent.js";
import { ReactComponent as RemoveIcon } from "assets/images/remove.svg";
import MortgageInputList from "components/link_account/MortgageInputList";
import Loader from "components/loader/Loader";

import StocksAndCryptoHomeComponent from "./StocksAndCryptoHomeComponent";

const Container = styled.div`
  padding: 20px 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  position: relative;
  flex-grow: 12;
`;

const HeaderLabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 25px;
`;

const HeaderLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;

const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const HeaderValue = styled.div`
  margin-top: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  font-feature-settings: "ss01" on;
`;

const MortgageTitle = styled.div`
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 6px;
`;

const AddMortgageButton = styled.div`
  width: fit-content;
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
`;

const ConnectedDebt = styled.div`
  position: relative;
  height: 50px;
  padding: 8px 10px 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  margin-top: 3px;
  margin-bottom: 15px;
`;

const RemoveIconComponent = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  padding: 10px;
  cursor: pointer;
  transform: translateY(-50%);
`;

const ConnectedDebtValue = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 2px;
`;

const ConnectedDebtTitle = styled.div`
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
`;

const ProviderLogo = styled.img`
  height: 15px;
  margin-bottom: 11px;
`;

const DomainProviderLogo = styled(ProviderLogo)`
  width: 70px;
  height: 25px;
  margin-bottom: 8px;
`;

const FooterAddress = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  margin-bottom: 8px;
`;

const SeeMoreLink = styled.a`
  display: block;
  font-size: 12px;
  line-height: 150%;
  text-decoration-line: underline;
  color: ${props => props.theme.linkColor};
  margin-bottom: 13px;
`;

const ExitComponent = styled(ExitIcon)`
  margin-left: 3px;
`;

const HomesLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  right: translateX(-50%);
  z-index: 100;
`;

const MortgageForm = styled.form`
  margin-bottom: 26px;
`;

const CustodianHomeComponent = props => {
  const dispatch = useDispatch();

  const portfolio = useSelector(currentPortfolioSelector);
  const isCrypto = isCryptoCurrency(portfolio.currency);

  const [inputVal, setInputVal] = useState("");
  const [tickerDetails, setTickerDetails] = useState(null);
  const [debtLinked, setDebtLinked] = useState({
    rows: []
  });
  const [isInsertingSheet, setIsInsertingSheet] = useState(false);
  const [showAddMortgageButton, setShowAddMortgageButton] = useState(false);
  const [showMortgageInput, setShowMortgageInput] = useState(false);

  const portfolioValueTickerId = getTickerUsingShortName(props.portfolio.currency).id;
  const debtLinkedRows = debtLinked.rows.length
    ? debtLinked.rows
    : [
        {
          value: 0,
          valueTickerId: portfolioValueTickerId
        }
      ];

  const cellExchangeRate = getExchangeRate(
    getTickerUsingId(props.detailsInfo.valueTickerId).shortName,
    props.portfolio.currency
  );

  const debtExchangeRates = debtLinkedRows.map(debtLinkedRow =>
    getExchangeRate(
      getTickerUsingId(debtLinkedRow.valueTickerId || portfolioValueTickerId).shortName,
      props.portfolio.currency
    )
  );

  const getEquityAndLTV = () => {
    const totalLoan = debtLinkedRows.reduce((acc, debtLinkedRow, index) => {
      return acc + calcCustodianOwnershipValue(debtLinkedRow.value * debtExchangeRates[index], debtLinkedRow.ownership);
    }, 0);
    const value = calcCustodianOwnershipValue(
      cellExchangeRate ? props.detailsInfo.value * cellExchangeRate : props.detailsInfo.value,
      props.detailsInfo.ownership
    );
    return [
      shortFormatNumberWithCurrency(value - totalLoan, props.portfolio.currency, false, true),
      getHomeLTV(totalLoan, value)
    ];
  };

  const onTickerQuantityChange = (value, index, tickerDetails) => {
    setInputVal(value);
    if (tickerDetails) {
      setTickerDetails(tickerDetails);
    }
  };

  const addRelatedId = async item => {
    if (!item) {
      return;
    }

    setShowMortgageInput(false);
    setShowAddMortgageButton(debtLinked.rows.length !== 3);

    const existingIds = debtLinked.rows.reduce((result, row) => {
      if (result !== "") {
        result += `,${row.id}`;
        return result;
      }

      result += row.id;

      return result;
    }, "");
    const ids = existingIds.length > 0 ? existingIds + "," + item.id : item.id;
    props.handleDetailsCustodianChange({ relatedId: ids }, false);

    setDebtLinked(prevDebtLinked => {
      return {
        rows: [...prevDebtLinked.rows, item]
      };
    });

    await dispatch(
      updateCustodian(
        false,
        props.detailsInfo.id,
        {
          relatedId: ids
        },
        true,
        () => {
          onTickerQuantityChange("");
        }
      )
    );
  };

  const removeRelatedId = async (index = 0) => {
    setShowMortgageInput(debtLinked.rows.length === 1);
    setShowAddMortgageButton(debtLinked.rows.length <= 3 && debtLinked.rows.length > 1);

    const currIds = props.detailsInfo.relatedId.split(",");
    currIds.splice(index, 1);
    props.handleDetailsCustodianChange({ relatedId: currIds.join() }, false);

    setDebtLinked(prevDebtLinked => {
      return {
        rows: prevDebtLinked.rows.filter((_, i) => i !== index)
      };
    });

    await dispatch(
      updateCustodian(
        false,
        props.detailsInfo.id,
        {
          relatedId: currIds.join()
        },
        true
      )
    );
  };

  const onAddAnotherMortgage = () => {
    setShowAddMortgageButton(false);
    setShowMortgageInput(true);
  };

  const onMortgageSubmit = async e => {
    e.preventDefault();

    const inputValue = parseFloat(inputVal.replace(/[^\d.]/g, ""), 10);

    if (isInsertingSheet || !inputValue) {
      return;
    }

    const debtSheets = filterDataFromPortfolioDetails(
      props.portfolio.details,
      row => !row.name && !row.value,
      void 0,
      sheet => sheet.category === category.DEBT
    );

    let debtLoanSection = filterDataFromPortfolioDetails(
      {
        sheet: debtSheets.sheets,
        section: debtSheets.sections,
        custodian: debtSheets.rows
      },
      row => !row.name && !row.value,
      void 0,
      sheet => sheet.name === "Loans"
    );

    if (debtLoanSection.sheets.length === 0) {
      const secondItemSortKey = getSortKeyBetween(
        debtSheets.sheets[0] ? debtSheets.sheets[0].sortKey : null,
        debtSheets.sheets[1] ? debtSheets.sheets[1].sortKey : null
      );
      const newSheet = getEmptySheet("Loans", secondItemSortKey, portfolio.currency, []);

      setIsInsertingSheet(true);
      await dispatch(insertSheet(category.DEBT, newSheet));
      setIsInsertingSheet(false);

      debtLoanSection = filterDataFromPortfolioDetails(
        portfolio.details,
        row => !row.name && !row.value,
        void 0,
        sheet => sheet.category === category.DEBT && sheet.name === "Loans"
      );
    }

    let relatedId = (debtLoanSection.rows || []).length > 0 ? debtLoanSection.rows[0].id : getUuid();
    dispatch(insertCustodianAtEndOfSection(props.portfolio.id, debtLoanSection.sections[0].id, relatedId));

    const tickerId = getTickerUsingShortName(props.portfolio.currency).id;
    const mortgageDebtCustodian = {
      name: i18n.t("mortgage.debtTitle").replace("%s%", props.detailsInfo.name),
      value: inputValue,
      valueTickerId: tickerDetails ? tickerDetails.tickerId : tickerId,
      isCompleted: 1,
      valueExchangeRate: tickerDetails
        ? tickerDetails.exchangeRate
        : getExchangeRateDetails(
            tickerId,
            getExchangeRate(getTickerUsingId(tickerId || portfolioValueTickerId).shortName, props.portfolio.currency)
          )
    };
    addRelatedId({
      id: relatedId,
      ...mortgageDebtCustodian
    });

    dispatch(updateCustodian(true, relatedId, mortgageDebtCustodian, true));
    setInputVal("");
  };

  useEffect(() => {
    const dl = filterDataFromPortfolioDetails(
      props.portfolio.details,
      custodian => props.detailsInfo.relatedId && props.detailsInfo.relatedId.includes(custodian.id),
      void 0,
      sheet => sheet.category === category.DEBT
    );
    setShowMortgageInput(dl.rows.length === 0);
    setShowAddMortgageButton(dl.rows.length > 0 && dl.rows.length < 3);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dl = filterDataFromPortfolioDetails(
      props.portfolio.details,
      custodian => props.detailsInfo.relatedId && props.detailsInfo.relatedId.includes(custodian.id),
      void 0,
      sheet => sheet.category === category.DEBT
    );
    if (props.detailsInfo.relatedId && dl.rows.length > 1) {
      const sortedRows = [...dl.rows].sort((a, b) => {
        const indexA = props.detailsInfo.relatedId.indexOf(a.id);
        const indexB = props.detailsInfo.relatedId.indexOf(b.id);
        return indexA - indexB;
      });
      setDebtLinked({ ...dl, rows: sortedRows });
    } else {
      setDebtLinked(dl);
    }
  }, [props.portfolio.details, props.detailsInfo]);
  const [equity, ltvValue] = getEquityAndLTV();
  const ltv = ltvValue ? formatNumberWithKuberaNumberFormatSettings(ltvValue) + "%" : null;
  const { linkType, valueTickerId, subType } = props.detailsInfo || {};
  const valueWithExchangeRate = isCrypto
    ? debtLinkedRows[0].value * debtExchangeRates[0]
    : Math.kuberaFloor(debtLinkedRows[0].value * debtExchangeRates[0]);
  const value = calcCustodianOwnershipValue(valueWithExchangeRate, debtLinkedRows[0].ownership);

  if (linkType === accountLinkingService.CARS || subType === tickerSubTypes.CARS) {
    return (
      <Container>
        <Header>
          {!props.isLoading ? (
            <Fragment>
              {props.detailsInfo.linkAccountName && <HeaderValue>{props.detailsInfo.linkAccountName}</HeaderValue>}
              <HeaderLabelsContainer>
                <HeaderLabelContainer>
                  <HeaderTitle>{i18n.t("equity")}</HeaderTitle>
                  <HeaderValue>{equity}</HeaderValue>
                </HeaderLabelContainer>
              </HeaderLabelsContainer>
              {!props.isReadOnly === true && <MortgageTitle>{i18n.t("linkAccount.carsQuantity")}</MortgageTitle>}

              {debtLinked.rows.length > 0 && (
                <ConnectedDebt>
                  <ConnectedDebtValue>
                    {formatNumberWithCurrency(value, props.portfolio.currency, undefined, isCrypto ? 4 : undefined)}
                  </ConnectedDebtValue>
                  <ConnectedDebtTitle>{debtLinkedRows[0].name}</ConnectedDebtTitle>
                  {props.isReadOnly === false && (
                    <RemoveIconComponent onClick={removeRelatedId}>
                      <RemoveIcon />
                    </RemoveIconComponent>
                  )}
                </ConnectedDebt>
              )}

              {debtLinked.rows.length === 0 && !props.isReadOnly === true && (
                <MortgageForm onSubmit={onMortgageSubmit}>
                  <MortgageInputList
                    disabled={props.isReadOnly === true}
                    index={0}
                    ticker={{
                      ...props.detailsInfo,
                      currency: props.portfolio.currency
                    }}
                    isValueLoading={false}
                    inputPlaceholder={i18n.t("linkAccount.carsQuantity2")}
                    inputVal={inputVal}
                    onTickerQuantityChange={onTickerQuantityChange}
                    onMortgageSelect={addRelatedId}
                    isLoading={isInsertingSheet}
                    onBlur={onMortgageSubmit}
                  />
                </MortgageForm>
              )}
            </Fragment>
          ) : (
            <HomesLoader />
          )}
        </Header>
      </Container>
    );
  }

  if (linkType === accountLinkingService.ZILLOW || subType === tickerSubTypes.HOME) {
    return (
      <Container>
        <Header>
          {!props.isLoading ? (
            <Fragment>
              <HeaderLabelsContainer>
                <HeaderLabelContainer>
                  <HeaderTitle>{i18n.t("mortgage.detailsHomeTitle")}</HeaderTitle>
                  <HeaderValue>{equity}</HeaderValue>
                </HeaderLabelContainer>

                {debtLinked.rows.length > 0 && ltv && (
                  <HeaderLabelContainer>
                    <HeaderTitle>{i18n.t("mortgage.loanToValue")}</HeaderTitle>
                    <HeaderValue>{ltv}</HeaderValue>
                  </HeaderLabelContainer>
                )}
              </HeaderLabelsContainer>

              {!props.isReadOnly === true && <MortgageTitle>{i18n.t("linkAccount.homesQuantity")}</MortgageTitle>}

              {debtLinked.rows.map((debtLinkedRow, index) => {
                const valueWithExchangeRate = isCrypto
                  ? debtLinkedRow.value * debtExchangeRates[index]
                  : Math.kuberaFloor(debtLinkedRow.value * debtExchangeRates[index]);
                const value = calcCustodianOwnershipValue(valueWithExchangeRate, debtLinkedRow.ownership);

                return (
                  <Fragment key={debtLinkedRow.id}>
                    <ConnectedDebt>
                      <ConnectedDebtValue>
                        {formatNumberWithCurrency(value, props.portfolio.currency, undefined, isCrypto ? 4 : undefined)}
                      </ConnectedDebtValue>
                      <ConnectedDebtTitle>{debtLinkedRow.name}</ConnectedDebtTitle>
                      {props.isReadOnly === false && (
                        <RemoveIconComponent onClick={() => removeRelatedId(index)}>
                          <RemoveIcon />
                        </RemoveIconComponent>
                      )}
                    </ConnectedDebt>
                  </Fragment>
                );
              })}

              {debtLinked.rows.length < 3 && !props.isReadOnly === true && (
                <MortgageForm onSubmit={onMortgageSubmit}>
                  {showMortgageInput && (
                    <MortgageInputList
                      disabled={props.isReadOnly === true}
                      index={0}
                      ticker={{
                        ...props.detailsInfo,
                        currency: props.portfolio.currency
                      }}
                      isValueLoading={false}
                      inputPlaceholder={i18n.t("linkAccount.homesQuantity2")}
                      inputVal={inputVal}
                      onTickerQuantityChange={onTickerQuantityChange}
                      onMortgageSelect={addRelatedId}
                      isLoading={isInsertingSheet}
                      onBlur={onMortgageSubmit}
                      excludeIds={props.detailsInfo?.relatedId}
                    />
                  )}
                  {showAddMortgageButton && (
                    <AddMortgageButton onClick={onAddAnotherMortgage}>{i18n.t("addAnotherMortgage")}</AddMortgageButton>
                  )}
                </MortgageForm>
              )}
            </Fragment>
          ) : (
            <HomesLoader />
          )}
        </Header>
      </Container>
    );
  }

  if (linkType === accountLinkingService.DOMAINS || subType === tickerSubTypes.DOMAINS) {
    const url = `https://www.estibot.com/go?u=21162&appraise=${props.detailsInfo.linkAccountName}`;

    return (
      <Container>
        <DomainProviderLogo src={process.env.PUBLIC_URL + "/images/provider_logo_icons/estibot.png"} alt="estibot" />
        <FooterAddress>{props.detailsInfo.linkAccountName}</FooterAddress>
        <SeeMoreLink href={url} target="_blank" rel="noopener noreferrer">
          {i18n.t("domainSeeMoreDetails")}
          <ExitComponent />
        </SeeMoreLink>
      </Container>
    );
  }

  const ticker = getTickerUsingId(valueTickerId);
  if (
    [
      tickerTypes.STOCK,
      tickerTypes.FUND,
      tickerTypes.CRYPTO,
      tickerTypes.BOND,
      tickerTypes.DERIVATIVE,
      tickerTypes.INDEX
    ].includes(ticker.type) ||
    ticker.subType === tickerSubTypes.PRECIOUS_METALS
  ) {
    return (
      <StocksAndCryptoHomeComponent
        detailsInfo={props.detailsInfo}
        tickerInfo={ticker}
        handleDetailsCustodianChange={props.handleDetailsCustodianChange}
        isQuantityField={[tickerTypes.CRYPTO].includes(ticker.type)}
        isReadOnly={props.isReadOnly}
        portfolio={portfolio}
      />
    );
  }

  return null;
};

export default CustodianHomeComponent;
