import { combineReducers } from "redux";
import { portfolioReducer, portfolioPersistTransform, portfolioActions } from "./PortfolioReducer";
import { beneficiaryReducer, beneficiaryPersistTransform } from "./BeneficiaryReducer";
import { pluginsReducer, pluginsPersistTransform } from "./PluginReducer";
import { authReducer, authPersistTransform, userSelector, getPortfolioSessionUserId } from "./AuthReducer";
import { syncReducer, syncPersistTransform } from "./SyncReducer";
import { tickerReducer, tickersPersistTransform } from "./TickerReducer";
import { custodianDetailsReducer, custodianDetailsPersistTransform } from "./CustodianDetailsReducer";
import { toastReducer, toastPersistTransform } from "./ToastReducer";
import { linkAccountReducer, linkAccountPersistTransform } from "./LinkAccountReducer";
import { archivedPortfolioReducer, archivedPortfolioPersistTransform } from "./ArchivedPortfolioReducer";
import { whiteLabelReducer, whiteLabelPersistTransform } from "./WhiteLabelReducer";

export var asyncReducers = {};
export var asyncTransforms = [];

var appReducer = null;
export const createAppReducer = () => {
  appReducer = combineReducers({
    auth: authReducer,
    portfolios: portfolioReducer,
    beneficiaries: beneficiaryReducer,
    plugins: pluginsReducer,
    sync: syncReducer,
    tickers: tickerReducer,
    custodian_details: custodianDetailsReducer,
    toast: toastReducer,
    link_account: linkAccountReducer,
    archived_portfolio: archivedPortfolioReducer,
    white_label: whiteLabelReducer,
    ...asyncReducers
  });
};
createAppReducer();

export const persistTransforms = () => {
  return [
    authPersistTransform(),
    portfolioPersistTransform(),
    syncPersistTransform(),
    tickersPersistTransform(),
    custodianDetailsPersistTransform(),
    toastPersistTransform(),
    linkAccountPersistTransform(),
    archivedPortfolioPersistTransform(),
    beneficiaryPersistTransform(),
    whiteLabelPersistTransform(),
    pluginsPersistTransform(),
    ...asyncTransforms
  ];
};

export const rootReducer = (state, action) => {
  if (state !== undefined) {
    const currentUser = userSelector(state);
    const portfolioUserId = getPortfolioSessionUserId();
    if (
      !currentUser === false &&
      !portfolioUserId === false &&
      portfolioUserId !== currentUser.id &&
      portfolioActions.includes(action.type)
    ) {
      const suffix = `-${portfolioUserId}`;
      action.type = `${action.type}${suffix}`;
    }
  }
  return appReducer(state, action);
};
