import React from "react";
import styled from "styled-components";

import { cellMode } from "components/grid/GridCell";
import LinkedIcon from "./LinkedIcon";
import { accountLinkingService } from "@kubera/common";

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const Input = styled.input`
  background-color: ${props => (props.disabled === true ? "rgba(0, 0, 0, 0.05)" : "#ffffff")};
`;

const Description = styled.div`
  font-style: ${props => (props.updated === true ? "normal" : "italic")};
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.textCellDescriptionCLR};
  z-index: 100;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  margin-top: 1px;
`;

const LinkedContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
`;

const LinkedText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const LinkedHoldingsCount = styled.div`
  display: none;
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
  flex: 1;
  margin-top: 2px;
  margin-left: 3px;
  margin-bottom: ${props => (props.showDescription === true ? "10px" : "")};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on, "calt" off;
  color: ${props => props.theme.textCellLinkedHoldingsCount};
`;

const LinkedTextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

class TextCell extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();

    this.handleLinkFocus = this.handleLinkFocus.bind(this);
    this.handleLinkDoubleClick = this.handleLinkDoubleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.inputRef.current.value =
      this.props.value === null || this.props.value === undefined
        ? this.props.showDottedLineIfEmpty
          ? "--"
          : ""
        : this.props.value;
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.inputRef.current.value =
        this.props.value === null || this.props.value === undefined
          ? this.props.showDottedLineIfEmpty
            ? "--"
            : ""
          : this.props.value;
    }
  }

  handleLinkFocus(e) {
    this.inputRef.current.focus();
  }

  handleLinkDoubleClick(e) {
    e.target = this.inputRef.current;
    this.props.onDoubleClick(e);
  }

  handleChange(e) {
    e.persist();
    this.inputRef.current.value = e.target.value;
    this.props.onUpdate(e);
  }

  render() {
    const {
      className,
      linkedHoldingsCount,
      linkedIconTitle,
      value,
      accessoryView,
      onUpdate,
      isEditable,
      ...other
    } = this.props;
    const showDescription =
      this.props.mode === cellMode.SELECT &&
      this.props.description !== undefined &&
      this.props.description !== null &&
      this.props.description.trim().length > 0;
    const showLinkedIcon =
      this.props.isCellLinked ||
      (this.props.subType && this.props.relatedId) ||
      !this.props.unfundedCapital === false ||
      (this.props.linkType === accountLinkingService.IN_HOUSE_OAUTH && !!this.props.availableCredit);
    const showLinkedText = showLinkedIcon && this.props.mode === cellMode.SELECT && !this.props.value === false;

    return (
      <Container>
        <Input
          {...other}
          ref={this.inputRef}
          className={className}
          type="text"
          size="1"
          spellCheck="false"
          autoComplete="off"
          showDescription={showDescription}
          style={showDescription || showLinkedText === true ? { color: "transparent" } : { color: "" }}
          onChange={this.handleChange}
          readOnly={!isEditable}
          data-1p-ignore
        />
        <LinkedContainer
          visible={showDescription || showLinkedText}
          className={className}
          tabIndex={"-1"}
          onFocus={this.handleLinkFocus}
          onDoubleClick={this.handleLinkDoubleClick}
        >
          <LinkedText>
            <LinkedTextContainer>
              <LinkedText>{this.props.value}</LinkedText>
              {showLinkedIcon &&
                !this.props.unfundedCapital === true &&
                this.props.linkType !== accountLinkingService.KUBERA_PORTFOLIO && (
                  <LinkedIcon
                    showDescription={showDescription}
                    title={linkedIconTitle}
                    type={this.props.linkType}
                    url={this.props.url}
                    isBesideText={!this.props.isCellVerified === true}
                    subType={this.props.subType}
                    isEditable={isEditable}
                  />
                )}
            </LinkedTextContainer>
            {showDescription === true && (
              <Description updated={this.props.updated}>{this.props.description}</Description>
            )}
          </LinkedText>
          {showLinkedIcon && (
            <LinkedIcon
              showDescription={showDescription}
              title={linkedIconTitle}
              type={this.props.linkType}
              url={this.props.url}
              currencyCellValue={this.props.currencyCellValue}
              relatedId={this.props.relatedId}
              currency={this.props.currency}
              linkContainer={this.props.linkContainer}
              linkProviderAccountId={this.props.linkProviderAccountId}
              availableCredit={this.props.availableCredit}
              isVerified={this.props.isCellVerified === true}
              subType={this.props.subType}
              unfundedCapital={this.props.unfundedCapital}
            />
          )}
          <LinkedHoldingsCount
            visible={showLinkedText === true && !linkedHoldingsCount === false}
            showDescription={showDescription}
          >
            {linkedHoldingsCount}
          </LinkedHoldingsCount>
        </LinkedContainer>
        {accessoryView}
      </Container>
    );
  }
}

export default TextCell;
