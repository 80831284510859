import React, { useState, useRef, useMemo } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  getTickerUsingId,
  getSymbolForTickerUsingShortName,
  shortFormatNumberWithCurrency,
  updateSection,
  sanitizeIrr,
  convertCurrency,
  accountLinkingService
} from "@kubera/common";
import { useTheme } from "theme";

import { category as CATEGORY } from "components/dashboard/DashboardComponent";
import PercentageLabel from "components/labels/PercentageLabel";

import { ReactComponent as CollapseIcon } from "assets/images/collapse.svg";

import MobileRowComponent from "./MobileRowComponent";

const Container = styled.div`
  border-top: ${props => (!props.hasMoreThanOneSection ? `1px solid ${props.theme.mobileSeperatorColor}` : null)};
`;

const SectionContainer = styled.div`
  position: relative;
  padding: 11px 20px;
  border-top: ${props => `1px solid ${props.theme.mobileSeperatorColor}`};
  border-bottom: ${props => `1px solid ${props.theme.mobileSeperatorColor}`};
  color: ${props => props.theme.mobileTxtColorSection};
  background: ${props => props.theme.mobileBackgroundSection};
  contain: layout, style;
`;

const SectionName = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 20px);
  font-feature-settings: "ss01" on;
`;

const SectionTotal = styled.div`
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.mobileTxtColorSectionTotal};
`;

const CollapseSectionIcon = styled(CollapseIcon)`
  position: absolute;
  top: 50%;
  right: 23px;
  transform: translateY(-50%) rotate(${props => props.collapsedeg});

  path {
    fill: ${props => props.theme.mobileTxtColorSectionCollapseIcon};
  }
`;

const PercentageValue = styled(PercentageLabel)`
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  max-width: 70px;
  margin-left: 6px;
  font-weight: 400;
  transform: translateY(6px);
`;

const MobileSectionComponent = ({
  index,
  sheet: thisSheet,
  section: thisSection,
  filteredPortfolioDetails,
  portfolio,
  category = CATEGORY.ASSET,
  hasMoreThanOneSection = false,
  shouldRender = true
}) => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const element = useRef(null);

  const [isSectionCollapsed, setIsSectionCollapsed] = useState(!thisSection.expanded);

  const collapsedeg = isSectionCollapsed ? "-90deg" : "0";

  const percentageValue = useMemo(() => {
    try {
      const irrDetails = JSON.parse(thisSection.irr);
      const sanitizedIrr = sanitizeIrr(irrDetails.all.value);
      return Math.abs(sanitizedIrr) > 0 && Math.abs(sanitizedIrr) < 1 ? sanitizedIrr : Math.kuberaFloor(sanitizedIrr);
    } catch (e) {
      return null;
    }
  }, [thisSection.irr]);

  if (!shouldRender) {
    return null;
  }

  const toggleCollapse = () => {
    dispatch(
      updateSection(portfolio.id, thisSheet.id, thisSection.id, {
        expanded: !isSectionCollapsed === true ? 0 : 1
      })
    );

    setIsSectionCollapsed(!isSectionCollapsed);

    if (element.current && isSectionCollapsed === true) {
      element.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  };

  const sectionTotalFormatted = `${getSymbolForTickerUsingShortName(portfolio.currency)}${shortFormatNumberWithCurrency(
    thisSection.total,
    portfolio.currency
  )}`;

  const getIrrDetails = irr => {
    try {
      return JSON.parse(irr);
    } catch (e) {
      return null;
    }
  };

  const getIrr = irr => {
    const irrDetails = getIrrDetails(irr);
    if (!irrDetails === true) {
      return null;
    }
    const sanitizedIrr = sanitizeIrr(irrDetails.all.value);
    return Math.abs(sanitizedIrr) > 0 && Math.abs(sanitizedIrr) < 1 ? sanitizedIrr : Math.kuberaFloor(sanitizedIrr);
  };

  const sortRowsFn = (a, b) => {
    let valueA;
    let valueB;
    switch (thisSection.columnSortKey) {
      case "Value":
      case "Balance":
        valueA = convertCurrency(a.value, getTickerUsingId(a.valueTickerId).shortName, portfolio.currency);
        valueB = convertCurrency(b.value, getTickerUsingId(b.valueTickerId).shortName, portfolio.currency);
        break;
      case "IRR":
        valueA = getIrr(a.irr);
        valueB = getIrr(b.irr);
        break;
      case "Asset":
      case "Debt":
        valueA = (a.name || "").toLowerCase();
        valueB = (b.name || "").toLowerCase();
        break;
      default:
        return true;
    }

    if (!valueA || !valueB) {
      return true;
    }

    if (thisSection.columnSortOrder === "descending") {
      return valueB - valueA;
    }
    if (thisSection.columnSortOrder === "ascending") {
      return valueA - valueB;
    }

    return true;
  };

  const sectionRows = Object.values(thisSection.rows);

  if (thisSection.hasRowWithValue) {
    return (
      <Container ref={element} hasMoreThanOneSection={hasMoreThanOneSection}>
        {hasMoreThanOneSection && (
          <SectionContainer onClick={toggleCollapse}>
            <SectionName>{thisSection.name}</SectionName>
            <SectionTotal>
              {sectionTotalFormatted}
              <PercentageValue value={percentageValue} darkBackground={theme.mode === "dark"} />
            </SectionTotal>
            <CollapseSectionIcon collapsedeg={collapsedeg} />
          </SectionContainer>
        )}
        {!isSectionCollapsed &&
          sectionRows
            .filter(row => (row.linkType === accountLinkingService.KUBERA_PORTFOLIO ? row.value !== 0 : true))
            .sort(sortRowsFn)
            .map((eachRow, index) => (
              <MobileRowComponent
                key={eachRow.id}
                row={eachRow}
                isLastRow={index === sectionRows.length - 1 && (index !== 0 || sectionRows.length === 1)}
                category={category}
                ownership={eachRow.ownership}
              />
            ))}
      </Container>
    );
  }

  return null;
};

export default React.memo(MobileSectionComponent);
