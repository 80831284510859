import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  planningRules,
  searchTickersForText,
  currentPortfolioSelector,
  tickerTypes,
  getTickerDescription,
  convertCurrency,
  getTickerUsingId,
  planningVariables,
  getPrivateCustodians
} from "@kubera/common";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import CurrencyLabel from "components/labels/CurrencyLabel";
import PrimaryButton from "components/button/PrimaryButton";
import SearchInput from "components/inputs/SearchInput";
import { ReactComponent as ClearIcon } from "assets/images/close.svg";

const tickerCategory = {
  STOCKS_AND_FUNDS: "Stocks & funds",
  METALS: "Metals",
  CRYPTO: "Crypto",
  OTHER: "Other"
};

const PickerDialog = styled(Dialog)`
  position: relative;
  width: 612px;
  min-height: 633px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 50px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const TickerCategoryContainer = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  font-feature-settings: "ss01" on;
`;

const TickerCategory = styled.div`
  margin-right: 20px;
  color: ${props => (props.isSelected === true ? "#000000" : "rgba(0, 0, 0, 0.5)")};
  cursor: pointer;
`;

const SearchBox = styled(SearchInput)`
  height: 45px;
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  line-height: 17px;
  background-color: #ffffff;
`;

const ContentContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ResultsContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background: #ffffff;
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 18px 12px 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: ${props => (props.isFocused === true ? props.theme.focusBackgroundColor : "#ffffff")};
  cursor: pointer;
`;

const EmptyResult = styled(Result)`
  cursor: auto;
  font-size: 14px;
  padding-left: 10px;
`;

const ResultDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ResultName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
`;

const ResultDescription = styled.div`
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const ResultValue = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  font-feature-settings: "ss01" on, "calt" off;
  color: #000000;
`;

const SelectedItemsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 27px;
  opacity: ${props => (props.inSearchMode === true ? "0.4" : "1")};
  pointer-events: ${props => (props.inSearchMode === true ? "none" : "auto")};
`;

const SelectedItemsList = styled.div`
  visibility: ${props => (props.isEmpty === true ? "hidden" : "visible")};
  box-shadow: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;

const SelectedItem = styled(Result)`
  background: rgba(0, 0, 0, 0.05);
  align-items: center;
  cursor: auto;
`;

const SelectedItemValue = styled(ResultValue)`
  justify-content: center;
`;

const DeleteSelectedItemIcon = styled(ClearIcon)`
  margin-left: 35px;
  width: 8px;
  height: 8px;
  cursor: pointer;

  path {
    fill: #aaaaaa;
  }
`;

const DoneButton = styled(PrimaryButton)`
  width: 124px;
  min-width: 124px;
  height: 44px;
`;

const PVSTRow = styled.div`
  background-color: white;
  height: 59px;
  padding-left: 17px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${props => (props.isFirst ? "14px" : "-1px")};
  cursor: pointer;
`;

class TickersPickerDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: "",
      searchResults: null,
      searchLoading: false,
      currentlySelectedResultIndex: null,
      selectedItems: this.getDefaultItems(),
      selectedTickerCategory: tickerCategory.STOCKS_AND_FUNDS
    };

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleResultInteraction = this.handleResultInteraction.bind(this);
    this.handleRemoveSelectedItemClick = this.handleRemoveSelectedItemClick.bind(this);
    this.handleCategoryTitleClick = this.handleCategoryTitleClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);

    this.searchInputRef = React.createRef();
  }

  getDefaultItems() {
    if (!this.props.data === true || !this.props.data.items === true) {
      return [];
    }
    return this.props.data.items
      .map(obj => {
        if (obj?.id !== 171) return getTickerUsingId(obj.id);
        const myCust = this.props.privateCustodians.get(obj.pvstCustodianId);
        return myCust ? { ...obj, code: myCust.name, pvstRate: myCust.pvstRate } : null;
      })
      .filter(Boolean);
  }

  componentDidMount() {
    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown, true);
  }

  handleKeyDown(e) {
    if (e.key === "ArrowUp") {
      this.selectPreviousResult();
      e.preventDefault();
      return true;
    } else if (e.key === "ArrowDown") {
      this.selectNextResult();
      e.preventDefault();
      return true;
    } else if (e.key === "Enter") {
      if (this.state.searchResults) {
        const result = this.state.searchResults[this.state.currentlySelectedResultIndex];
        this.handleResultInteraction(result);
      } else if (this.state.selectedItems?.length) {
        this.handleSaveClick();
      }
      e.preventDefault();
      return true;
    }
    return false;
  }

  allowUnusedTickers() {
    if (!this.props.data === false) {
      if (!this.props.data.props === false) {
        return this.props.data.props.allowUnusedTickers;
      } else if (!this.props.rule === false) {
        const ruleObject = planningRules.find(item => item.type === this.props.rule.type);
        if (
          !ruleObject.data === false &&
          !ruleObject.data[planningVariables.TICKER_ID_OBJ] === false &&
          !ruleObject.data[planningVariables.TICKER_ID_OBJ].props === false
        ) {
          return ruleObject.data[planningVariables.TICKER_ID_OBJ].props.allowUnusedTickers;
        }
      }
    }
    return undefined;
  }

  handleSearchInputChange(searchText) {
    this.setState({ searchText: searchText });

    if (!this.searchTimer === false) {
      clearTimeout(this.searchTimer);
    }

    if (!searchText === true) {
      this.setState({ searchText: searchText, searchResults: null, searchLoading: false });
      return;
    } else {
      this.setState({ searchText: searchText });
    }

    var tickerTypesAllowed = tickerTypes.FIAT;
    if (this.state.selectedTickerCategory === tickerCategory.STOCKS_AND_FUNDS) {
      tickerTypesAllowed = tickerTypes.STOCK;
    } else if (this.state.selectedTickerCategory === tickerCategory.CRYPTO) {
      tickerTypesAllowed = tickerTypes.CRYPTO;
    }

    this.setState({ searchLoading: true });

    this.searchTimer = setTimeout(() => {
      this.props.searchTickersForText(searchText, tickerTypesAllowed, this.allowUnusedTickers(), data => {
        if (data.searchText !== searchText) {
          return;
        }
        this.setState({ searchResults: data.results, searchLoading: false });
      });
      this.searchTimer = null;
    }, 300);
  }

  handleResultInteraction(result) {
    if (result && !this.state.selectedItems.find(item => item.id === result.id) === true) {
      var selectedItems = this.props.allowMultiple === true ? this.state.selectedItems : [];
      selectedItems.push(result);
      this.setState({ selectedItems: selectedItems });
    }

    this.handleSearchInputChange("");
  }

  handleRemoveSelectedItemClick(result) {
    var selectedItems = this.state.selectedItems;
    selectedItems = selectedItems.filter(item => item.id !== result.id);
    this.setState({ selectedItems: selectedItems });
  }

  handleCategoryTitleClick(e, category) {
    this.setState({ selectedTickerCategory: category });
    this.handleSearchInputChange("");
    if (category === tickerCategory.OTHER) return;

    setTimeout(() => {
      if (!this.searchInputRef.current === false) {
        this.searchInputRef.current.focus();
      }
    }, 10);
  }

  handleSaveClick(e) {
    const items = this.state.selectedItems.map(item => ({ id: item.id, pvstCustodianId: item.pvstCustodianId }));
    this.props.onVariableUpdate({ items: items });
    this.props.onDismiss();
  }

  getResultDescription(result) {
    return getTickerDescription(result);
  }

  getResultValue(result) {
    if (result.id === 171 && (result.pvstRate || result.pvstRate === 0)) return result.pvstRate;
    return convertCurrency(1, result.shortName, this.props.currentPortfolio.currency);
  }

  selectNextResult() {
    const currentIndex = this.state.currentlySelectedResultIndex;

    if (this.state.searchResults && currentIndex === this.state.searchResults.length - 1) {
      return;
    }
    this.setState({ currentlySelectedResultIndex: currentIndex === null ? 0 : currentIndex + 1 });
  }

  selectPreviousResult() {
    const currentIndex = this.state.currentlySelectedResultIndex;
    if (currentIndex === null || currentIndex === 0) {
      return;
    }
    this.setState({ currentlySelectedResultIndex: currentIndex - 1 });
  }

  selectResult(index) {
    this.setState({ currentlySelectedResultIndex: index });
  }

  render() {
    const privateCustodiansArray =
      (this.props.privateCustodians && Array.from(this.props.privateCustodians.values())) || [];
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <PickerDialog>
          <Container>
            <TickerCategoryContainer>
              <TickerCategory
                isSelected={this.state.selectedTickerCategory === tickerCategory.STOCKS_AND_FUNDS}
                onClick={e => this.handleCategoryTitleClick(e, tickerCategory.STOCKS_AND_FUNDS)}
              >
                {i18n.t("stocks")}
              </TickerCategory>
              <TickerCategory
                isSelected={this.state.selectedTickerCategory === tickerCategory.METALS}
                onClick={e => this.handleCategoryTitleClick(e, tickerCategory.METALS)}
              >
                {i18n.t("metals")}
              </TickerCategory>
              <TickerCategory
                isSelected={this.state.selectedTickerCategory === tickerCategory.CRYPTO}
                onClick={e => this.handleCategoryTitleClick(e, tickerCategory.CRYPTO)}
              >
                {i18n.t("crypto")}
              </TickerCategory>
              {privateCustodiansArray?.length > 0 && (
                <TickerCategory
                  isSelected={this.state.selectedTickerCategory === tickerCategory.OTHER}
                  onClick={e => this.handleCategoryTitleClick(e, tickerCategory.OTHER)}
                >
                  {i18n.t("other")}
                </TickerCategory>
              )}
            </TickerCategoryContainer>
            {this.state.selectedTickerCategory !== tickerCategory.OTHER ? (
              <SearchBox
                outerRef={this.searchInputRef}
                autoFocus={true}
                isLoading={this.state.searchLoading}
                value={this.state.searchText}
                onChange={this.handleSearchInputChange}
                placeholder={this.state.selectedTickerCategory}
              />
            ) : (
              privateCustodiansArray.map((custodian, index) => (
                <PVSTRow
                  key={custodian.id}
                  isFirst={index === 0}
                  onClick={() => {
                    this.setState({ selectedItems: [{ id: 171, pvstCustodianId: custodian.id }] }, () => {
                      this.handleSaveClick();
                    });
                  }}
                >
                  {custodian.name}
                </PVSTRow>
              ))
            )}
            <ContentContainer>
              {!this.state.searchResults === false && (
                <ResultsContainer>
                  {this.state.searchResults.map((result, index) => {
                    return (
                      <div key={index}>
                        <Result
                          isFocused={this.state.currentlySelectedResultIndex === index}
                          onClick={e => this.handleResultInteraction(result)}
                          onMouseEnter={() => {
                            this.selectResult(index);
                          }}
                        >
                          <ResultDetailsContainer>
                            <ResultName>{result.code}</ResultName>
                            <ResultDescription>{this.getResultDescription(result)}</ResultDescription>
                          </ResultDetailsContainer>
                          <ResultValue
                            currency={this.props.currentPortfolio.currency}
                            value={this.getResultValue(result)}
                            abbreviate={false}
                            roundDown={true}
                          />
                        </Result>
                      </div>
                    );
                  })}
                  {this.state.searchResults.length === 0 && (
                    <EmptyResult>
                      {this.allowUnusedTickers() ? i18n.t("nothingFound") : i18n.t("assetNotFound")}
                    </EmptyResult>
                  )}
                </ResultsContainer>
              )}

              {this.state.selectedTickerCategory !== tickerCategory.OTHER && (
                <SelectedItemsContainer inSearchMode={!this.state.searchText === false}>
                  <SelectedItemsList isEmpty={this.state.selectedItems.length === 0}>
                    {this.state.selectedItems.map((result, index) => {
                      return (
                        <div key={index}>
                          <SelectedItem>
                            <ResultDetailsContainer>
                              <ResultName>{result.code}</ResultName>
                              <ResultDescription>{this.getResultDescription(result)}</ResultDescription>
                            </ResultDetailsContainer>
                            <SelectedItemValue
                              currency={this.props.currentPortfolio.currency}
                              value={this.getResultValue(result)}
                              abbreviate={false}
                              roundDown={true}
                            />
                            <DeleteSelectedItemIcon onClick={e => this.handleRemoveSelectedItemClick(result)} />
                          </SelectedItem>
                        </div>
                      );
                    })}
                  </SelectedItemsList>
                  <DoneButton title={i18n.t("done")} onClick={this.handleSaveClick} />
                </SelectedItemsContainer>
              )}
            </ContentContainer>
          </Container>
        </PickerDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state),
  privateCustodians: getPrivateCustodians(state)
});

const mapDispatchToProps = {
  searchTickersForText: searchTickersForText
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TickersPickerDialog);
