import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import {
  currentPortfolioSelector,
  portfoliosSelector,
  moveCustodian,
  moveSection,
  moveSheet,
  custodiansWithSameParentIdSelector,
  store,
  custodianSelector,
  sectionCustodiansSelector,
  sheetSectionsSelector,
  sheetCustodiansSelector,
  createNewLocalSheet,
  getSortKeyBetween,
  getUuid,
  insertSectionAction,
  accountLinkingService
} from "@kubera/common";
import { category } from "components/dashboard/DashboardComponent";
import SearchInput from "components/inputs/SearchInput";
import { ReactComponent as DownArrowIcon } from "assets/images/menu_downarrow.svg";
import ContextMenu from "components/contextmenu/ContextMenu";
import { addKeyboardEventListener, removeKeyboardEventListener } from "utilities/EventManager";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

export const moveItemType = {
  CUSTODIAN: "custodian",
  SECTION: "section",
  SHEET: "sheet"
};

const ITEM_ID_CREATE = "create";

const MoveDialog = styled(Dialog)`
  position: relative;
  width: 400px;
  min-height: 600px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 50px 80px 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const ItemName = styled.div`
  font-weight: 400;
  font-size: 13px;
`;

const Title = styled.div`
  margin-top: 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const FilterOptionsContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`;

const PortfolioButtonContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

const PortfolioButton = styled.button`
  background-color: transparent;
  height: 16px;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 13px;
  text-align: right;
`;

const PortfolioButtonIcon = styled(DownArrowIcon)`
  margin-left: 3px;
  margin-bottom: 1px;
`;

const CategoryFilter = styled.div`
  margin-left: 12px;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  opacity: ${props => (props.isSelected === true ? 1 : 0.35)};
  cursor: pointer;
`;

const SearchBox = styled(SearchInput)`
  height: 35px;
  margin-top: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  line-height: 17px;
  background-color: #ffffff;
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  max-height: 400px;
  overflow-y: scroll;
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 15px 12px 12px;
  background: ${props => (props.isFocused === true ? props.theme.focusBackgroundColor : "transparent")};
  cursor: pointer;
`;

class MoveItemDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCategory: props.defaultCategory,
      selectedPortfolio: props.currentPortfolio,
      searchText: "",
      searchResults: this.getMoveDestinationsForItem(props.currentPortfolio, props.defaultCategory, ""),
      selectedResultIndex: null,
      showConfirmationDialogForResult: null
    };

    this.handlePortfolioButtonClick = this.handlePortfolioButtonClick.bind(this);
    this.handlePortfolioSelection = this.handlePortfolioSelection.bind(this);
    this.handleCategorySelection = this.handleCategorySelection.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.scrollHighlightedElemInView = this.scrollHighlightedElemInView.bind(this);
    this.handleResultSelection = this.handleResultSelection.bind(this);
    this.handleConfirmationDismiss = this.handleConfirmationDismiss.bind(this);
    this.handlePositiveConfirmation = this.handlePositiveConfirmation.bind(this);

    this.portfolioMenuRef = React.createRef();
  }

  componentDidMount() {
    addKeyboardEventListener(this.handleKeyDown);
  }

  componentWillUnmount() {
    removeKeyboardEventListener(this.handleKeyDown, true);
  }

  handleConfirmationDismiss() {
    this.setState({ showConfirmationDialogForResult: null });
  }

  handlePositiveConfirmation() {
    this.completeMove(this.state.showConfirmationDialogForResult);
  }

  handleKeyDown(e) {
    if (e.key === "ArrowUp") {
      this.selectPreviousResult();
      e.preventDefault();
      return true;
    } else if (e.key === "ArrowDown") {
      this.selectNextResult();
      e.preventDefault();
      return true;
    } else if (e.key === "Enter") {
      this.handleResultSelection(this.state.searchResults[this.state.selectedResultIndex]);
      e.preventDefault();
      return true;
    }
    return false;
  }

  selectNextResult() {
    const currentIndex = this.state.selectedResultIndex;
    if (currentIndex === this.state.searchResults.length - 1) {
      return;
    }
    this.setState({ selectedResultIndex: currentIndex === null ? 0 : currentIndex + 1 }, () =>
      this.scrollHighlightedElemInView()
    );
  }

  selectPreviousResult() {
    const currentIndex = this.state.selectedResultIndex;
    if (currentIndex === null || currentIndex === 0) {
      return;
    }
    this.setState({ selectedResultIndex: currentIndex - 1 }, () => this.scrollHighlightedElemInView());
  }

  selectResult(index) {
    this.setState({ selectedResultIndex: index });
  }

  handleResultSelection(item) {
    if (this.props.itemType === moveItemType.CUSTODIAN) {
      if (this.state.selectedPortfolio.id !== this.props.currentPortfolio.id && !this.props.item.parentId === false) {
        this.setState({ showConfirmationDialogForResult: item });
        return;
      }
    } else if (this.props.itemType === moveItemType.SECTION) {
      if (this.state.selectedPortfolio.id !== this.props.currentPortfolio.id) {
        const custodians = sectionCustodiansSelector(
          store.getState(),
          this.props.currentPortfolio.id,
          this.props.item.id,
          true
        );
        const custodiansOutsideSection = custodians.filter(item => item.sectionId !== this.props.item.id);
        if (custodiansOutsideSection.length > 0) {
          this.setState({ showConfirmationDialogForResult: item });
          return;
        }
      }
    } else if (this.props.itemType === moveItemType.SHEET) {
      const sheetSectionIds = sheetSectionsSelector(
        store.getState(),
        this.props.currentPortfolio.id,
        this.props.item.id
      ).map(item => item.id);
      const custodiansOutsideSheet = sheetCustodiansSelector(
        store.getState(),
        this.props.currentPortfolio.id,
        this.props.item.id,
        true
      ).filter(item => sheetSectionIds.includes(item.sectionId) === false);
      if (custodiansOutsideSheet.length > 0) {
        this.setState({ showConfirmationDialogForResult: item });
        return;
      }
    }

    this.completeMove(item);
  }

  completeMove(item) {
    if (!item === true) {
      return;
    }

    var isCreateSheetFlow = false;
    var isCreateSectionFlow = false;

    if (item.id === ITEM_ID_CREATE) {
      const sectionSeparatorIndex = this.state.searchText.lastIndexOf("/");
      const sectionName =
        sectionSeparatorIndex === -1
          ? null
          : this.state.searchText.substring(sectionSeparatorIndex + 1, this.state.searchText.length).trim();
      const sheetName =
        sectionSeparatorIndex === -1
          ? this.state.searchText
          : this.state.searchText.substring(0, sectionSeparatorIndex).trim();
      const sheets = this.state.selectedPortfolio.details.sheet;
      const existingSheet = sheets.find(item => item.name.toLowerCase().trim() === sheetName.toLowerCase());
      const sheet = existingSheet || {
        id: getUuid(),
        category: this.state.selectedCategory,
        name: sheetName,
        sortKey: getSortKeyBetween(sheets[sheets.length - 1].sortKey, null)
      };

      if (this.props.itemType === moveItemType.CUSTODIAN) {
        var section = {
          id: getUuid(),
          name: !sectionName === true ? "Section 1" : sectionName,
          sheetId: sheet.id,
          sortKey: "1"
        };

        if (!existingSheet === false) {
          const sections = this.state.selectedPortfolio.details.section.filter(
            item => item.sheetId === existingSheet.id
          );
          section = {
            id: getUuid(),
            name: sectionName,
            sheetId: sheet.id,
            sortKey: getSortKeyBetween(sections[sections.length - 1].sortKey, null)
          };
          this.props.insertSectionAction(this.state.selectedPortfolio.id, section);
          isCreateSectionFlow = true;
        } else {
          this.props.createNewLocalSheet(this.state.selectedPortfolio.id, sheet, section);
          isCreateSheetFlow = true;
        }

        item.id = section.id;
      } else if (this.props.itemType === moveItemType.SECTION) {
        this.props.createNewLocalSheet(this.state.selectedPortfolio.id, sheet, this.props.item);

        item.id = sheet.id;
      }
    }

    if (this.props.itemType === moveItemType.CUSTODIAN) {
      this.props.moveCustodian(
        this.props.item.sectionId,
        item.id,
        this.props.item.id,
        "01",
        true,
        isCreateSheetFlow,
        isCreateSectionFlow
      );
    } else if (this.props.itemType === moveItemType.SECTION) {
      this.props.moveSection(this.props.item.sheetId, this.props.item.id, item.id, isCreateSheetFlow);
    } else if (this.props.itemType === moveItemType.SHEET) {
      this.props.moveSheet(this.props.item.id, item.id);
    }
    this.props.onDismiss();
  }

  handlePortfolioSelection(item) {
    this.setState({ selectedPortfolio: item.portfolio }, () => {
      this.setState({
        searchResults: this.getMoveDestinationsForItem(
          this.state.selectedPortfolio,
          this.state.selectedCategory,
          this.state.searchText
        )
      });
    });
  }

  handleCategorySelection(category) {
    this.setState({ selectedCategory: category }, () =>
      this.setState({
        searchResults: this.getMoveDestinationsForItem(
          this.state.selectedPortfolio,
          this.state.selectedCategory,
          this.state.searchText
        )
      })
    );
  }

  handlePortfolioButtonClick(e) {
    const moveAcrossPortfoliosAllowed = this.canMoveAcrossPortfolios();
    const portfolios = moveAcrossPortfoliosAllowed
      ? this.props.portfolios
      : this.props.portfolios.filter(item => item.id === this.props.currentPortfolio.id);

    const menuItems = [
      portfolios.map(item => {
        return { id: item.id, label: item.name, portfolio: item };
      })
    ];
    for (const item of menuItems[0]) {
      if (item.id === this.state.selectedPortfolio.id) {
        item.selected = true;
      }
    }

    const targetPosition = e.target.getBoundingClientRect();
    this.portfolioMenuRef.current.show(
      menuItems,
      targetPosition.left,
      targetPosition.top + targetPosition.height + 5,
      true,
      e.target
    );
  }

  handleSearchInputChange(searchText) {
    this.setState({ searchText }, () =>
      this.setState({
        searchResults: this.getMoveDestinationsForItem(
          this.state.selectedPortfolio,
          this.state.selectedCategory,
          this.state.searchText
        )
      })
    );
  }

  scrollHighlightedElemInView() {
    const elementId = `moveitemdialog-${this.state.searchResults[this.state.selectedResultIndex].id}`;
    document.scrollToElement(elementId, { block: "nearest" });
  }

  canMoveAcrossPortfolios() {
    if (this.props.itemType === moveItemType.CUSTODIAN) {
      if (this.props.item.linkType === accountLinkingService.KUBERA_PORTFOLIO) {
        return false;
      }
    } else if (this.props.itemType === moveItemType.SECTION) {
      const custodians = sectionCustodiansSelector(
        store.getState(),
        this.props.currentPortfolio.id,
        this.props.item.id
      );
      return custodians.filter(item => item.linkType === accountLinkingService.KUBERA_PORTFOLIO).length === 0;
    } else if (this.props.itemType === moveItemType.SHEET) {
      const custodians = sheetCustodiansSelector(store.getState(), this.props.currentPortfolio.id, this.props.item.id);
      return custodians.filter(item => item.linkType === accountLinkingService.KUBERA_PORTFOLIO).length === 0;
    }
    return true;
  }

  getMoveDestinationsForItem(portfolio, category, searchText) {
    var destinations = [];
    const sheets = portfolio.details.sheet.filter(item => item.category === category);

    if (this.props.itemType === moveItemType.CUSTODIAN) {
      for (const sheet of sheets) {
        const sections = portfolio.details.section.filter(item => item.sheetId === sheet.id);
        destinations.push(
          ...sections
            .filter(item => item.id !== this.props.item.sectionId)
            .map(item => {
              return { id: item.id, name: sections.length === 1 ? sheet.name : `${sheet.name} / ${item.name}` };
            })
        );
      }
    } else if (this.props.itemType === moveItemType.SECTION) {
      destinations = sheets
        .filter(item => item.id !== this.props.item.sheetId)
        .map(item => {
          return { id: item.id, name: item.name };
        });
    } else if (this.props.itemType === moveItemType.SHEET) {
      if (this.canMoveAcrossPortfolios() === false) {
        return [];
      }

      destinations = this.props.portfolios
        .filter(item => item.id !== this.props.currentPortfolio.id)
        .map(item => {
          return { id: item.id, name: item.name };
        });
    }

    if (!searchText === false) {
      destinations = destinations.filter(item => item.name.toLowerCase().includes(searchText.trim().toLowerCase()));

      if (this.props.itemType === moveItemType.CUSTODIAN || this.props.itemType === moveItemType.SECTION) {
        const foundExactMatch =
          destinations.filter(item => item.name.toLowerCase() === searchText.trim().toLowerCase()).length > 0;
        if (foundExactMatch === false) {
          destinations.push({ id: ITEM_ID_CREATE, name: `${i18n.t("create")} "${searchText.trim()}"` });
        }
      }
    }
    return destinations;
  }

  getSearchBoxPlaceholder() {
    if (this.props.itemType === moveItemType.CUSTODIAN) {
      return i18n.t("movemodal.sheetorsection");
    } else if (this.props.itemType === moveItemType.SECTION) {
      return i18n.t("movemodal.sheet");
    } else {
      return i18n.t("movemodal.portfolio");
    }
  }

  getConfirmationDialogTitle(rowsToMove) {
    return i18n.t("movemodal.confirmation.title").replace("%s1%", rowsToMove);
  }

  getConfirmationDialogDescription(rowsToMove) {
    if (this.props.itemType === moveItemType.CUSTODIAN) {
      const parentCustodian = custodianSelector(store.getState(), this.props.item.parentId);
      return i18n
        .t("movemodal.custodian.description")
        .replace("%s1%", rowsToMove)
        .replace("%s2%", parentCustodian.name);
    } else if (this.props.itemType === moveItemType.SECTION) {
      return i18n.t("movemodal.section.description");
    } else if (this.props.itemType === moveItemType.SHEET) {
      return i18n.t("movemodal.sheet.description");
    }
    return null;
  }

  getRowsToMove() {
    if (this.props.itemType === moveItemType.CUSTODIAN) {
      return custodiansWithSameParentIdSelector(
        store.getState(),
        this.props.currentPortfolio.id,
        this.props.item.parentId
      ).length;
    } else if (this.props.itemType === moveItemType.SECTION) {
      return sectionCustodiansSelector(store.getState(), this.props.currentPortfolio.id, this.props.item.id, true, true)
        .length;
    } else if (this.props.itemType === moveItemType.SHEET) {
      return sheetCustodiansSelector(store.getState(), this.props.currentPortfolio.id, this.props.item.id, true, true)
        .length;
    }
    return 0;
  }

  render() {
    if (!this.state.showConfirmationDialogForResult === false) {
      const rowsTomove = this.getRowsToMove();

      return (
        <ConfirmationDialog
          canUserDismiss={true}
          title={this.getConfirmationDialogTitle(rowsTomove)}
          description={this.getConfirmationDialogDescription(rowsTomove)}
          positiveButtonTitle={i18n.t("movemodal.button").replace("%s1%", rowsTomove)}
          negativeButtonTitle={i18n.t("cancel")}
          onDismiss={this.handleConfirmationDismiss}
          handlePositiveButtonClick={this.handlePositiveConfirmation}
          handleNegativeButtonClick={this.handleConfirmationDismiss}
        />
      );
    }

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <MoveDialog>
          <Container>
            <ItemName>{this.props.item.name}</ItemName>
            <Title>{i18n.t("movemodal.heading")}</Title>
            {this.props.itemType !== moveItemType.SHEET && (
              <FilterOptionsContainer>
                <PortfolioButtonContainer>
                  <PortfolioButton onClick={this.handlePortfolioButtonClick}>
                    {this.state.selectedPortfolio.name}
                    <PortfolioButtonIcon />
                  </PortfolioButton>
                </PortfolioButtonContainer>
                <CategoryFilter
                  isSelected={this.state.selectedCategory === category.ASSET}
                  onClick={e => this.handleCategorySelection(category.ASSET)}
                >
                  {i18n.t("assets")}
                </CategoryFilter>
                <CategoryFilter
                  isSelected={this.state.selectedCategory === category.DEBT}
                  onClick={e => this.handleCategorySelection(category.DEBT)}
                >
                  {i18n.t("debts")}
                </CategoryFilter>
              </FilterOptionsContainer>
            )}
            <SearchBox
              autoFocus={true}
              value={this.state.searchText}
              onChange={this.handleSearchInputChange}
              placeholder={this.getSearchBoxPlaceholder(this.props.itemType)}
            />
            <ResultsContainer>
              {this.state.searchResults.map((item, index) => (
                <Result
                  key={item.id}
                  id={`moveitemdialog-${item.id}`}
                  isFocused={index === this.state.selectedResultIndex}
                  onMouseEnter={() => {
                    this.selectResult(index);
                  }}
                  onClick={e => this.handleResultSelection(item)}
                >
                  {item.name}
                </Result>
              ))}
            </ResultsContainer>
          </Container>
          <ContextMenu ref={this.portfolioMenuRef} width={300} onSelection={this.handlePortfolioSelection} />
        </MoveDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  currentPortfolio: currentPortfolioSelector(state),
  portfolios: portfoliosSelector(state)
});

const mapDispatchToProps = {
  moveCustodian: moveCustodian,
  moveSection: moveSection,
  moveSheet: moveSheet,
  createNewLocalSheet: createNewLocalSheet,
  insertSectionAction: insertSectionAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveItemDialog);
