import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import FileUploadInput from "components/inputs/FileUploadInput";
import { connect } from "react-redux";
import {
  uploadDocument,
  renameDocument,
  deleteDocuments,
  ApiClient,
  exportCustodianDocuments,
  showToastTip,
  isAmcUser,
  userMaskAllValuesSelector,
  custodianDocumentsSelector
} from "@kubera/common";
import DragDropInput from "components/inputs/DragDropInput";
import DocumentsComponent from "components/documents/DocumentsComponent";
import { generateThumbnailForFile } from "utilities/FileUtils";
import { downloadFile } from "utilities/FileUtils";
import DocumentsDownloadDialog from "components/safe_deposit_box/DocumentsDownloadDialog";
import { withRouter } from "@kubera/common";
import { category } from "components/dashboard/DashboardComponent";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

const Container = styled.div`
  width: 100%;
`;

const NoDocumentsContainer = styled(DragDropInput)`
  width: 100%;
`;

const CommandArea = styled.div`
  position: absolute;
  width: 100%;
  height: ${props => (props.isDragging === false ? "90%" : "0px")};
  background: ${props => props.theme.documentsDetailsCommandAreaBG};
  display: flex;
  justify-content: center;
  visibility: ${props => (props.isDragging === false ? "visible" : "hidden")};
`;

const NoDocumentsMessage = styled.div`
  position: absolute;
  top: 23px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  display: flex;
  justify-content: flex-start;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
`;

const UploadButton = styled(FileUploadInput)`
  position: absolute;
  top: 168px;
  left: 0;
  display: flex;
  justify-content: center;
  width: 134px;
  height: 44px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  font-feature-settings: "ss01" on;
  color: #ffffff;
  background: #000000;
  cursor: pointer;
  outline: 0;
  border: 0;
`;

const UploadHint = styled.div`
  position: absolute;
  top: 220px;
  left: 0;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const Documents = styled(DocumentsComponent)`
  margin-top: 14px;
`;

const DropAreaIndicator = styled.div`
  position: absolute;
  margin-top: 20px;
  width: 100%;
  height: ${props => (props.isDragging === true ? "90%" : "0px")};
  background: rgba(252, 252, 252, 0.97);
  border: 2px dashed rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  visibility: ${props => (props.isDragging === true ? "visible" : "hidden")};
`;

const DropAreaHint = styled.div`
  height: 15px;
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  white-space: pre-wrap;
  margin-top: 144px;
`;

class CustodianDocumentsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleRemoveDocumentDialogNegativeButtonClick = this.handleRemoveDocumentDialogNegativeButtonClick.bind(this);
    this.handleRemoveDocumentDialogPositiveButtonClick = this.handleRemoveDocumentDialogPositiveButtonClick.bind(this);

    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleDocumentUpload = this.handleDocumentUpload.bind(this);
    this.handleDocumentRename = this.handleDocumentRename.bind(this);
    this.handleOnDeleteDocuments = this.handleOnDeleteDocuments.bind(this);
    this.handleOnDownloadDocuments = this.handleOnDownloadDocuments.bind(this);
    this.handleRetryDocumentUpload = this.handleRetryDocumentUpload.bind(this);
    this.handleDownloadAllDocuments = this.handleDownloadAllDocuments.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragStop = this.handleDragStop.bind(this);

    this.state = {
      ...this.state,
      isDragging: false,
      showRemoveDocumentDialog: false,
      deldocs: []
    };
  }

  handleDragStart() {
    this.setState({ isDragging: true });
  }

  handleDragStop() {
    this.setState({ isDragging: false });
  }

  handleFileUpload(files) {
    for (const file of files) {
      generateThumbnailForFile(file, thumbnail => {
        this.handleDocumentUpload(file, thumbnail);
      });
    }
  }

  handleDocumentUpload(file, fileThumbnail) {
    this.props.uploadDocument(file, fileThumbnail, { custodianId: this.props.custodian.id });
  }

  handleDocumentRename(updatedDocument) {
    this.props.renameDocument(updatedDocument);
  }

  handleOnDeleteDocuments(documents) {
    if (documents.length === 1) {
      this.setState({ showRemoveDocumentDialog: true, deldocs: documents });
    }
  }

  handleRemoveDocumentDialogNegativeButtonClick(e) {
    this.setState({ showRemoveDocumentDialog: false });
  }

  handleRemoveDocumentDialogPositiveButtonClick(e) {
    this.setState({ showRemoveDocumentDialog: false });
    this.props.deleteDocuments(this.state.deldocs);
    this.props.showToastTip(
      "TIP",
      i18n.t("documentDeleteToast.deleted") + this.state.deldocs[0].name,
      didShow => {},
      4000
    );
  }

  handleOnDownloadDocuments(documents) {
    for (const doc of documents) {
      ApiClient.getFileDownloadUrl(doc, false, false)
        .then(url => {
          downloadFile(url, doc.name);
        })
        .catch(apiError => {});
    }
  }

  handleRetryDocumentUpload(doc) {
    this.props.uploadDocument(doc.file, doc.thumbnail, { custodianId: this.props.custodian.id, retryDocument: doc });
  }

  handleDownloadAllDocuments() {
    this.props.exportDocuments(this.props.custodian.sectionId, this.props.custodian.id, this.props.custodian.name);
    DocumentsDownloadDialog.show(this.props.history, this.props.location);
  }

  getNoDocumentsMessage() {
    const custodianCategory = this.props.category;

    if (custodianCategory === category.ASSET) {
      return i18n.t("custodianDetails.assetsDocumentsPanelMessage");
    } else if (custodianCategory === category.DEBT) {
      return i18n.t("custodianDetails.debtsDocumentsPanelMessage");
    } else if (custodianCategory === category.INSURANCE) {
      return i18n.t("custodianDetails.insuranceDocumentsPanelMessage");
    }
    return "";
  }

  render() {
    const documents = this.props.documents;
    const isEmpty = this.props.documents && this.props.documents.length === 0;
    const isDragging = this.state.isDragging;
    const showRemoveDocumentDialog = this.state.showRemoveDocumentDialog;
    const isAmcUser = this.props.isAmcUser;

    return (
      <Container className={this.props.className}>
        {isEmpty === true && (
          <NoDocumentsContainer
            onDragStart={this.handleDragStart}
            onDragStop={this.handleDragStop}
            onFileDrop={this.handleFileUpload}
            disabled={this.props.isReadOnly === true}
          >
            <CommandArea isDragging={isDragging}>
              <NoDocumentsMessage>{this.getNoDocumentsMessage()}</NoDocumentsMessage>
              {this.props.isReadOnly === false && (
                <>
                  <UploadButton
                    data-cy="detailsUpload"
                    label={i18n.t("upload")}
                    multiple={true}
                    onChange={this.handleFileUpload}
                    disabled={this.props.isReadOnly === true}
                  />
                  <UploadHint>{i18n.t("custodianDetails.documentsPanelUploadHint")}</UploadHint>
                </>
              )}
            </CommandArea>
            <DropAreaIndicator isDragging={isDragging}>
              <DropAreaHint>{i18n.t("documentsComponent.dropFileHint")}</DropAreaHint>
            </DropAreaIndicator>
          </NoDocumentsContainer>
        )}
        {isEmpty === false && (
          <Documents
            isReadOnly={this.props.isReadOnly === true}
            maxItemsPerRow={4}
            documentCellSize={111}
            compactMode={true}
            hideDownloadAll={isAmcUser === true}
            onDocumentUpload={this.handleDocumentUpload}
            documents={documents}
            onDocumentRename={this.handleDocumentRename}
            onDeleteDocuments={this.handleOnDeleteDocuments}
            onDownloadDocuments={this.handleOnDownloadDocuments}
            onRetryDocumentUpload={this.handleRetryDocumentUpload}
            onDownloadAllDocuments={this.handleDownloadAllDocuments}
            maskThumbnail={this.props.userMaskAllValues}
          />
        )}
        {showRemoveDocumentDialog === true && (
          <ConfirmationDialog
            title={i18n.t("documentDeleteDialog.title")}
            description={this.state.deldocs[0].name}
            positiveButtonTitle={i18n.t("remove")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleRemoveDocumentDialogNegativeButtonClick}
            handlePositiveButtonClick={this.handleRemoveDocumentDialogPositiveButtonClick}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isAmcUser: isAmcUser(state),
  userMaskAllValues: userMaskAllValuesSelector(state),
  documents: custodianDocumentsSelector(state, props.custodian.id)
});

const mapDispatchToProps = {
  uploadDocument: uploadDocument,
  renameDocument: renameDocument,
  deleteDocuments: deleteDocuments,
  exportDocuments: exportCustodianDocuments,
  showToastTip: showToastTip
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustodianDocumentsComponent));
