import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import {
  custodianSelector,
  getTickerUsingId,
  convertCurrency,
  collapseHoldingsForCustodian,
  calcCustodianOwnershipValue,
  tickerTypes,
  tickerSubTypes
} from "@kubera/common";
import CurrencyLabel from "components/labels/CurrencyLabel";
import { category } from "components/dashboard/DashboardComponent";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import GlowingBadgeTip from "components/tooltip/GlowingBadgeTip";
import CustodianOwnershipComponent from "./CustodianOwnershipComponent";
import StocksAndCryptoHomeComponent from "./StocksAndCryptoHomeComponent";
import { GridRowData } from "components/grid/GridDataModel";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const AccountDetailsTitle = styled.div`
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  font-color: rgba(0, 0, 0, 1);
`;

const WalletAddress = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-feature-settings: "ss01" on;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 6px;
`;

const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
`;

const Value = styled(CurrencyLabel)`
  margin-top: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const HoldingsCount = styled.div`
  margin-top: 23px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
`;

const RevertContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
`;

const HoldingsRevertMessage = styled.div``;

const HoldingsRevertButton = styled.div`
  margin-left: 5px;
  color: ${props => props.theme.linkColor};
  text-decoration: underline;
  cursor: pointer;
`;

const HoldingsBadge = styled(GlowingBadgeTip)`
  margin-left: 5px;
  margin-top: 2px;
`;

class CustodianParentDetailsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showConfirmationDialog: false };

    this.handleHoldingsCollapse = this.handleHoldingsCollapse.bind(this);
    this.handleConfirmationDialogPositiveButtonClick = this.handleConfirmationDialogPositiveButtonClick.bind(this);
    this.handleConfirmationDialogNegativeButtonClick = this.handleConfirmationDialogNegativeButtonClick.bind(this);
  }

  handleHoldingsCollapse() {
    this.setState({ showConfirmationDialog: true });
  }

  handleConfirmationDialogPositiveButtonClick(e) {
    this.props.collapseHoldingsForCustodian(
      this.props.custodianId,
      parentSectionId => {
        this.props.onHoldingsCollapsed();
        document.scrollToElement(parentSectionId);
      },
      apiError => {}
    );
  }

  handleConfirmationDialogNegativeButtonClick(e) {
    this.setState({ showConfirmationDialog: false });
  }

  getValue() {
    const { value, ownership } = this.props.parentCustodian;
    return convertCurrency(
      calcCustodianOwnershipValue(value, ownership),
      this.getParentCurrency(),
      this.props.portfolioCurrency
    );
  }

  getParentCurrency() {
    return getTickerUsingId(this.props.parentCustodian.valueTickerId).shortName;
  }

  showTickerDetails() {
    const ticker = getTickerUsingId(this.props.detailsInfo.valueTickerId);
    if (
      [
        tickerTypes.STOCK,
        tickerTypes.FUND,
        tickerTypes.CRYPTO,
        tickerTypes.BOND,
        tickerTypes.DERIVATIVE,
        tickerTypes.INDEX
      ].includes(ticker.type) ||
      ticker.subType === tickerSubTypes.PRECIOUS_METALS
    ) {
      return (
        <StocksAndCryptoHomeComponent
          detailsInfo={this.props.detailsInfo}
          tickerInfo={ticker}
          handleDetailsCustodianChange={this.props.handleDetailsCustodianChange}
          isQuantityField={[tickerTypes.CRYPTO].includes(ticker.type)}
          isReadOnly={true}
          portfolio={this.props.portfolio}
        />
      );
    }
  }

  render() {
    const custodianCategory = this.props.category;
    const portfolioCurrency = this.props.portfolioCurrency;
    const value = (this.props.isAssetMovedToDebt ? -1 : 1) * this.getValue();
    const parentCustodian = this.props.parentCustodian;
    const showBadge = this.props.showBadgeTip;

    if (this.props.detailsInfo?.hidden === 1) {
      return <Container className={this.props.className}>{this.showTickerDetails()}</Container>;
    }

    return (
      <Container className={this.props.className}>
        {this.showTickerDetails()}
        <AccountDetailsTitle>{i18n.t("accountDetails")}</AccountDetailsTitle>
        <WalletAddress>{GridRowData.linkedAccountDescription(parentCustodian)}</WalletAddress>
        <Name>{parentCustodian.name}</Name>
        <Value
          value={value}
          roundDown={true}
          currency={portfolioCurrency}
          maxLongFormatValue={99999}
          abbreviate={false}
        />
        <HoldingsCount>
          {i18n
            .t(parentCustodian.holdingsCount > 1 ? "holdingsCount" : "holdingCount")
            .replace("%s1%", parentCustodian.holdingsCount)}
        </HoldingsCount>

        <RevertContainer>
          <HoldingsRevertMessage>
            {i18n.t("holdingsRevertMessage").replace("%s1%", custodianCategory === category.ASSET ? "assets" : "debts")}
          </HoldingsRevertMessage>
          {!this.props.isReadOnly === true && (
            <HoldingsRevertButton onClick={this.handleHoldingsCollapse}>{i18n.t("revert")}</HoldingsRevertButton>
          )}
          {showBadge === true && <HoldingsBadge />}
        </RevertContainer>
        <CustodianOwnershipComponent
          details={this.props.details}
          custodianId={this.props.detailsInfo.id}
          detailsInfo={this.props.detailsInfo}
          ownershipValue={this.props.detailsInfo.ownership}
          isReadOnly={this.props.isReadOnly}
          category={custodianCategory}
          chartStartDate={this.props.chartStartDate}
        />
        {this.state.showConfirmationDialog && (
          <ConfirmationDialog
            canUserDismiss={true}
            title={i18n.t("collapseHoldingsDialog.title")}
            description={i18n
              .t("collapseHoldingsDialog.description")
              .replace("%s1%", custodianCategory === category.ASSET ? "asset" : "debt")}
            positiveButtonTitle={i18n.t("yes")}
            negativeButtonTitle={i18n.t("cancel")}
            onDismiss={this.handleUnlinkAllConnectionsDialogOnDismiss}
            handlePositiveButtonClick={this.handleConfirmationDialogPositiveButtonClick}
            handleNegativeButtonClick={this.handleConfirmationDialogNegativeButtonClick}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  parentCustodian: custodianSelector(state, props.parentId)
});

const mapDispatchToProps = {
  collapseHoldingsForCustodian: collapseHoldingsForCustodian
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustodianParentDetailsComponent);
