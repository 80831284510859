import { getUuid } from "../../utilities/Number";
import { isAppInViewMode } from "../../utilities/Auth";
import { store } from "../store";
import { currentPortfolioSelector } from "../reducers/PortfolioReducer";

export const ENQUEUE_ITEM = "ENQUEUE_ITEM";
export const DEQUEUE_ITEM = "DEQUEUE_ITEM";
export const START_SYNC = "START_SYNC";
export const STOP_SYNC = "STOP_SYNC";
export const REMOVE_PREVIOUS_UPDATES = "REMOVE_PREVIOUS_UPDATES";
export const ADD_PENDING_DOCUMENT_UPLOAD = "ADD_PENDING_DOCUMENT_UPLOAD";
export const UPDATE_PENDING_DOCUMENT_UPLOAD = "UPDATE_PENDING_DOCUMENT_UPLOAD";
export const REMOVE_PENDING_DOCUMENT_UPLOAD = "REMOVE_PENDING_DOCUMENT_UPLOAD";
export const MARK_SYNC_QUEUE_AS_STUCK = "MARK_SYNC_QUEUE_AS_STUCK";

export const SyncItemType = {
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  BULK_UPDATE: "bulk_update",
  DOWNLOAD: "download"
};

export class SyncItem {
  constructor(itemType, itemId, requestInstance, delay, removePreviousUpdates, onSuccess) {
    this.itemType = itemType;
    this.itemId = itemId;
    this.requestInstance = requestInstance;
    this.removePreviousUpdates = removePreviousUpdates;
    this.onSuccess = onSuccess;
    this.onError = null;
    this.delay = delay;
    this.requestTimestamp = Date.now();
    this.retryCount = 0;
    this.idempotentId = getUuid();
    this.requestPayload = null;
  }
}

export const enqueueItemAction = syncItem => ({
  type: ENQUEUE_ITEM,
  syncItem
});

export const dequeueItemAction = syncItem => ({
  type: DEQUEUE_ITEM,
  syncItem
});

export const startSyncAction = () => ({
  type: START_SYNC
});

export const stopSyncAction = () => ({
  type: STOP_SYNC
});

export const removePreviousUpdatesAction = itemId => ({
  type: REMOVE_PREVIOUS_UPDATES,
  itemId
});

export const enqueueItem = syncItem => {
  return dispatch => {
    const currentPortfolio = currentPortfolioSelector(store.getState());
    if (isAppInViewMode() || (currentPortfolio && currentPortfolio.write === 0)) {
      return;
    }

    if (syncItem.removePreviousUpdates === true) {
      dispatch(removePreviousUpdatesAction(syncItem.itemId));
    }
    dispatch(enqueueItemAction(syncItem));
  };
};

export const addPendingDocumentUploadAction = document => ({
  type: ADD_PENDING_DOCUMENT_UPLOAD,
  document
});

export const updatePendingDocumentUploadAction = document => ({
  type: UPDATE_PENDING_DOCUMENT_UPLOAD,
  document
});

export const removePendingDocumentUploadAction = document => ({
  type: REMOVE_PENDING_DOCUMENT_UPLOAD,
  document
});

export const markSyncQueueAsStuckAction = () => ({
  type: MARK_SYNC_QUEUE_AS_STUCK
});
