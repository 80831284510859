import { store } from "../redux";
export function getState(thisRef, throwErrorIfNotValidState = false) {
  // eslint-disable-next-line no-prototype-builtins
  if (thisRef && thisRef.hasOwnProperty("_persist")) {
    return thisRef;
  }

  if (throwErrorIfNotValidState) {
    throw new Error("getState() should only be used if a state cannot be passed in as a parameter.");
  }

  return store.getState();
}
