import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import defaultTheme from "theme";
import * as Sentry from "@sentry/browser";
import { Provider } from "react-redux";

import { signOut, store, getAppVersion, userSelector, isAppInWhiteLabelMode } from "@kubera/common";
import { ReactComponent as MoneyBag } from "assets/images/moneybag.svg";
import kuberaLogo from "assets/images/kubera_logo.svg";
import i18n from "locale/i18n";
import PrimaryButton from "components/button/PrimaryButton";

// Capture global error and send to sentry
export const captureGlobalError = (error, { componentStack }) => {
  Sentry.withScope(scope => {
    scope.setTag("global_error", "GlobalOnError");
    scope.setExtras({
      userId: userSelector(store.getState()) ? userSelector(store.getState()).id : "",
      appVersion: getAppVersion()
    });
    Sentry.captureException(error, {
      extra: {
        componentStack
      }
    });
  });
};

// Troubleshoot component
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  padding: 0 10px;
`;

const KuberaLogo = styled.img`
  width: 90px;
  margin-top: 35px;
  margin-bottom: 15px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  margin-bottom: 10px;
  text-align: center;
`;

const Desc = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  margin-bottom: 30px;
  color: ${props => props.theme.headsUpDesktopBannerCLR};

  a {
    color: inherit;
  }
`;

const isWhiteLabelSite = isAppInWhiteLabelMode();
function TroubleShoot() {
  const [isLoading, setIsLoading] = useState(false);

  const clearSiteData = async () => {
    setIsLoading(true);
    signOut();
  };

  if (isWhiteLabelSite) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Container id="troubleshoot-app">
          <MoneyBag />
          <Title>{i18n.t("troubleshoot.title")}</Title>
          <Desc
            dangerouslySetInnerHTML={{
              __html: i18n.t("troubleshoot.wlkdesc")
            }}
          />
          <PrimaryButton title={i18n.t("signOut")} isLoading={isLoading} onClick={clearSiteData} />
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container id="troubleshoot-app">
        <MoneyBag />
        <KuberaLogo src={kuberaLogo} />
        <Title>{i18n.t("troubleshoot.title")}</Title>
        <Desc
          dangerouslySetInnerHTML={{
            __html: i18n.t("troubleshoot.desc")
          }}
        />
        <PrimaryButton title={i18n.t("signOut")} isLoading={isLoading} onClick={clearSiteData} />
      </Container>
    </ThemeProvider>
  );
}

function TroubleShootWrapper() {
  return (
    <Provider store={store}>
      <TroubleShoot />
    </Provider>
  );
}

export default TroubleShootWrapper;
