import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import TextInput from "components/inputs/TextInput";
import Checkbox from "components/inputs/Checkbox";
import {
  shareCapabilities,
  createPortfolioShareLink,
  getShareLinkFormattedDateString,
  updatePortfolioShareLink,
  capitalizeStringWithSpaces,
  kuberaNumberFormatSelector,
  getAccountOwnerNameSelector
} from "@kubera/common";
import DropDown from "components/inputs/DropDown";
import PrimaryButton from "components/button/PrimaryButton";
import { ReactComponent as DeleteIcon } from "assets/images/delete_user_icon.svg";

const expiryPeriods = {
  NEVER: "Never Expires",
  WEEK1: "1 Week",
  WEEK2: "2 Weeks",
  WEEK3: "3 Weeks",
  MONTH1: "1 Month",
  MONTH3: "3 Months",
  MONTH6: "6 Months",
  YEAR1: "1 Year"
};

const LinkDialog = styled(Dialog)`
  width: 420px;
  min-height: 600px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const PortfolioName = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  font-feature-settings: "ss01" on;
`;

const Title = styled.div`
  margin-top: 3px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const FieldTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: ${props => (props.hasError === true ? "red" : "black")};
`;

const NameField = styled(TextInput)`
  height: 45px;
  width: 100%
  padding: 13px;
  background: #FFFFFF;
  border: ${props => (props.hasError === true ? "1px solid red" : "1px solid rgba(0, 0, 0, 0.4)")} ;
  box-sizing: border-box;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ss01' on;
`;

const CapabilityCheckboxContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
`;

const CapabilityCheckbox = styled(Checkbox)`
  margin-top: 10px;
`;

const CurrentExpiryDateContainer = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  padding: 13px;
  margin-bottom: 15px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
`;

const CurrentExpiryDate = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;
const ClearCurrentExpiryDate = styled(DeleteIcon)`
  cursor: pointer;
  padding: 10px;
  margin-right: -10px;
`;

const ExpiryDropDown = styled(DropDown)`
  margin-bottom: 15px;
`;

const PasscodeField = styled(NameField)``;

const SaveErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
  margin-top: 7px;
  margin-bottom: 7px;
`;

const SaveButton = styled(PrimaryButton)`
  height: 45px;
  margin-top: 30px;
`;

const WarningText = styled.div`
  margin-top: 15px;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

class PortfolioLinkDetailsDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linkDetails: props.linkDetails || {
        capability: JSON.stringify([shareCapabilities.ASSET, shareCapabilities.DEBT]),
        name: this.getDefaultLinkName(),
        option: JSON.stringify({
          kuberaNumberFormat: this.props.kuberaNumberFormat
        })
      },
      selectedExpiryPeriod: expiryPeriods.NEVER,
      nameErrorMessage: null,
      isSaving: false,
      saveErrorMessage: null
    };

    this.handleDimiss = this.handleDimiss.bind(this);
    this.handleNameInputChange = this.handleNameInputChange.bind(this);
    this.handleCapabilityCheckboxChange = this.handleCapabilityCheckboxChange.bind(this);
    this.handleExpiryDropDownSelection = this.handleExpiryDropDownSelection.bind(this);
    this.handlePasscodeInputChange = this.handlePasscodeInputChange.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleClearCurrentExpiryClick = this.handleClearCurrentExpiryClick.bind(this);
  }

  getDefaultLinkName() {
    const userName = capitalizeStringWithSpaces(this.props.accountOwnerName);
    var defaultName = !userName === false ? `${userName}'s Portfolio` : "Link 1";
    var counter = 1;

    while (
      !this.props.sharedLinks === false &&
      !this.props.sharedLinks.find(item => item.name === defaultName) === false // eslint-disable-line no-loop-func
    ) {
      if (!userName === false && defaultName.includes(userName)) {
        if (counter === 1) {
          defaultName = `${userName}'s Portfolio ${counter}`;
        } else {
          defaultName = defaultName.replace(`${counter - 1}`, `${counter}`);
        }
      } else {
        defaultName = defaultName.replace(`${counter}`, `${counter + 1}`);
      }
      counter++;
    }
    return defaultName;
  }

  handleNameInputChange(e) {
    const newLinkDetails = this.state.linkDetails;
    newLinkDetails.name = e.target.value;
    this.setState({ linkDetails: newLinkDetails, nameErrorMessage: null, saveErrorMessage: null });
  }

  handleCapabilityCheckboxChange(checked, capability) {
    const newLinkDetails = this.state.linkDetails;
    var capabilities = JSON.parse(newLinkDetails.capability);

    if (checked === true) {
      capabilities.push(capability);
    } else {
      capabilities = capabilities.filter(item => item !== capability);
    }
    newLinkDetails.capability = JSON.stringify(capabilities);
    this.setState({ linkDetails: newLinkDetails });
  }

  handleExpiryDropDownSelection(item) {
    this.setState({ selectedExpiryPeriod: item.id });
  }

  handleClearCurrentExpiryClick(e) {
    const newLinkDetails = this.state.linkDetails;
    newLinkDetails.tsExpiry = null;
    this.setState({ linkDetails: newLinkDetails });
  }

  getExpiryTimestampFromExpiryPeriod(period) {
    switch (period) {
      case expiryPeriods.WEEK1:
        return Math.floor(new Date().getTime() / 1000) + 7 * 24 * 60 * 60;
      case expiryPeriods.WEEK2:
        return Math.floor(new Date().getTime() / 1000) + 2 * 7 * 24 * 60 * 60;
      case expiryPeriods.WEEK3:
        return Math.floor(new Date().getTime() / 1000) + 3 * 7 * 24 * 60 * 60;
      case expiryPeriods.MONTH1:
        return Math.floor(new Date().getTime() / 1000) + 30 * 24 * 60 * 60;
      case expiryPeriods.MONTH3:
        return Math.floor(new Date().getTime() / 1000) + 3 * 30 * 24 * 60 * 60;
      case expiryPeriods.MONTH6:
        return Math.floor(new Date().getTime() / 1000) + 6 * 30 * 24 * 60 * 60;
      case expiryPeriods.YEAR1:
        return Math.floor(new Date().getTime() / 1000) + 365 * 24 * 60 * 60;
      default:
        return null;
    }
  }

  handlePasscodeInputChange(e) {
    const newLinkDetails = this.state.linkDetails;
    const newPasscode = e.target.value;

    if (!newPasscode === true || newPasscode.trim().length === 0) {
      newLinkDetails.passcode = null;
    } else {
      newLinkDetails.passcode = newPasscode;
    }
    this.setState({ linkDetails: newLinkDetails });
  }

  handleSaveButtonClick(e) {
    if (!this.state.linkDetails.name === true || this.state.linkDetails.name.trim().length === 0) {
      this.setState({ nameErrorMessage: i18n.t("genericExceptionTitle"), saveErrorMessage: i18n.t("genericError") });
      return;
    }

    this.setState({ isSaving: true });

    const linkDetails = JSON.parse(JSON.stringify(this.state.linkDetails));
    linkDetails.isEnabled = 1;
    linkDetails.tsExpiry = this.getExpiryTimestampFromExpiryPeriod(this.state.selectedExpiryPeriod);

    if (!this.props.linkDetails === true) {
      if (!linkDetails.passcode === true) {
        delete linkDetails.passcode;
      }
    }

    const apiCall =
      !this.props.linkDetails === true ? this.props.createPortfolioShareLink : this.props.updatePortfolioShareLink;

    apiCall(
      this.props.portfolio,
      linkDetails,
      () => {
        this.setState({ isSaving: false }, () => {
          this.handleDimiss();
        });
      },
      apiError => {
        this.setState({ isSaving: false, saveErrorMessage: apiError.errorMessage });
      }
    );
  }

  handleDimiss() {
    if (this.state.isSaving === true) {
      return;
    }
    this.props.onDismiss();
  }

  getCapabilitiesCheckboxData() {
    const capabilities = JSON.parse(this.state.linkDetails.capability);

    return [
      { id: shareCapabilities.ASSET, title: i18n.t("assets"), disabled: true, checked: true },
      { id: shareCapabilities.DEBT, title: i18n.t("debts"), disabled: true, checked: true },
      {
        id: shareCapabilities.NETWORTH,
        title: i18n.t("portfolioLinkDetailsDialog.networth"),
        checked: capabilities.includes(shareCapabilities.NETWORTH)
      },
      {
        id: shareCapabilities.RECAP,
        title: i18n.t("portfolioLinkDetailsDialog.recap"),
        checked: capabilities.includes(shareCapabilities.RECAP)
      },
      {
        id: shareCapabilities.PLANNING,
        title: i18n.t("portfolioLinkDetailsDialog.fastforward"),
        checked: capabilities.includes(shareCapabilities.PLANNING)
      },
      {
        id: shareCapabilities.INSURANCE,
        title: i18n.t("insurance"),
        disabled: false,
        checked: capabilities.includes(shareCapabilities.INSURANCE)
      },
      {
        id: shareCapabilities.DOCUMENT,
        title: i18n.t("portfolioLinkDetailsDialog.documents"),
        disabled: false,
        checked: capabilities.includes(shareCapabilities.DOCUMENT)
      }
    ];
  }

  getExpiryDropDownData() {
    return Object.keys(expiryPeriods).map(key => {
      return {
        id: expiryPeriods[key],
        label:
          expiryPeriods[key] === expiryPeriods.NEVER
            ? expiryPeriods[key]
            : `${expiryPeriods[key]} (${getShareLinkFormattedDateString(
                this.getExpiryTimestampFromExpiryPeriod(expiryPeriods[key]) * 1000
              )})`,
        selected: this.state.selectedExpiryPeriod === expiryPeriods[key]
      };
    });
  }

  render() {
    const portfolio = this.props.portfolio;
    const linkDetails = this.state.linkDetails;
    const nameErrorMessage = this.state.nameErrorMessage;

    return (
      <DialogOverlay onDismiss={this.handleDimiss}>
        <LinkDialog>
          <Container>
            <PortfolioName>{portfolio.name}</PortfolioName>
            <Title>{i18n.t("portfolioLinkDetailsDialog.title")}</Title>
            <FieldTitle hasError={!nameErrorMessage === false}>
              {i18n.t("portfolioLinkDetailsDialog.nameLabel")}
            </FieldTitle>
            <NameField
              value={linkDetails.name || ""}
              placeholder={i18n.t("name")}
              hasError={!nameErrorMessage === false}
              onChange={this.handleNameInputChange}
              autoFocus
            />

            <CapabilityCheckboxContainer>
              {this.getCapabilitiesCheckboxData().map((data, index) => (
                <CapabilityCheckbox
                  key={index}
                  label={data.title}
                  checked={data.checked}
                  disabled={data.disabled}
                  onChange={(checked, e) => {
                    this.handleCapabilityCheckboxChange(checked, data.id);
                  }}
                />
              ))}
            </CapabilityCheckboxContainer>
            <FieldTitle>{i18n.t("portfolioLinkDetailsDialog.expiresLabel")}</FieldTitle>
            {!linkDetails.tsExpiry === true && (
              <ExpiryDropDown items={this.getExpiryDropDownData()} onSelection={this.handleExpiryDropDownSelection} />
            )}
            {!linkDetails.tsExpiry === false && (
              <CurrentExpiryDateContainer>
                <CurrentExpiryDate>{getShareLinkFormattedDateString(linkDetails.tsExpiry * 1000)}</CurrentExpiryDate>
                <ClearCurrentExpiryDate onClick={this.handleClearCurrentExpiryClick} />
              </CurrentExpiryDateContainer>
            )}
            <FieldTitle>{i18n.t("portfolioLinkDetailsDialog.passcodeLabel")}</FieldTitle>
            <PasscodeField value={linkDetails.passcode || ""} onChange={this.handlePasscodeInputChange} />
            {!this.state.saveErrorMessage === false && (
              <SaveErrorMessage>{this.state.saveErrorMessage}</SaveErrorMessage>
            )}
            <SaveButton title={i18n.t("save")} onClick={this.handleSaveButtonClick} isLoading={this.state.isSaving} />
            <WarningText>{i18n.t("portfolioLinkDetailsDialog.warning")}</WarningText>
          </Container>
        </LinkDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  accountOwnerName: getAccountOwnerNameSelector(state),
  kuberaNumberFormat: kuberaNumberFormatSelector(state)
});

const mapDispatchToProps = {
  createPortfolioShareLink: createPortfolioShareLink,
  updatePortfolioShareLink: updatePortfolioShareLink
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortfolioLinkDetailsDialog);
