import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  cleanUpSeparatorFromNumberString,
  parseNumberStringToFloat,
  getCharacterForDecimal,
  getCharacterForNumberSeparator,
  formatNumberWithKuberaNumberFormatSettings
} from "@kubera/common";

const Input = styled.input`
  font-feature-settings: "ss01", "calt" 0;
  font-size: 14px;

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
`;

const NumberInput = (
  { className, type, value: defaultValue, onChange = () => null, onBlur = () => null, ...other },
  ref
) => {
  const [value, setValue] = useState();

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    const value =
      !defaultValue === false
        ? formatNumberWithKuberaNumberFormatSettings(parseFloat(defaultValue), {
            maximumFractionDigits: 8
          })
        : defaultValue;

    setValue(value);
  }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (e, isBlur = false) => {
    if (!e.target.value === false && e.target.value.length >= 1) {
      const lastChar = e.target.value.charAt(e.target.value.length - 1);
      if (
        isNaN(parseFloat(lastChar)) &&
        lastChar !== getCharacterForDecimal() &&
        lastChar !== getCharacterForNumberSeparator() &&
        lastChar !== "-"
      ) {
        return;
      }
    }

    setValue(e.target.value);

    const sanitizedNumber = cleanUpSeparatorFromNumberString(e.target.value, false);
    const parsedNumber = parseNumberStringToFloat(sanitizedNumber);

    if (sanitizedNumber !== "" && parsedNumber === null) {
      return;
    }
    if (sanitizedNumber === value) {
      return;
    }

    e.target.value = parsedNumber;

    if (!isBlur) {
      onChange(e, parsedNumber);
    } else {
      onBlur(e, parsedNumber);
    }
  };

  const handleInputBlur = e => {
    handleInputChange.call(this, e, true);
  };

  const getValueDisplayString = () => {
    if (value === null || value === undefined || value === "") {
      return "";
    }
    return value;
  };

  const inputValue = getValueDisplayString();

  return (
    <Input
      {...other}
      ref={ref}
      className={className}
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
      data-1p-ignore
    />
  );
};

export default React.forwardRef(NumberInput);
