import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import i18n from "locale/i18n";
import { hashParams, modalValues } from "routes";

import {
  parseParams,
  store,
  recapChartOptions,
  chartIdsForSelectedCustodianSelector,
  getRecapChartOptionFromId,
  RECAP_CATEGORY_TYPE_ASSET,
  RECAP_CATEGORY_TYPE_DEBT,
  RECAP_CATEGORY_TYPE_NETWORTH,
  recapReportNodeSelector,
  chartTimeRange,
  chartContent,
  recapDataSelector,
  chartKeyParams,
  createChartIdUsingReportId,
  updateDashboardCharts,
  shouldShowLoaderOnPortfolioAndRecapCurrencyMismatch,
  shouldShowLoaderOnPortfolioAndNeworthCurrencyMismatch,
  useHistory
} from "@kubera/common";

import ColumnsComponent from "./ColumnsComponent";
import ChartsRearrangementComponent from "./ChartsRearrangementComponent";
import DashboardChartsNotAvailableComponent from "./DashboardChartsNotAvailableComponent";
import { accountSettingsTabs } from "components/account_settings/AccountSettingsComponent";
import Loader from "components/loader/Loader";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const ChartsLoader = styled(Loader)`
  margin-top: 30px;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.popoverBackgroundColor};
  z-index: 10000;
`;

const RefreshLoader = styled(Loader)`
  height: auto;
  margin-top: 100px;
`;

const DiyDashboardRenderCharts = ({ portfolioId, chartIds, diyDashboardCharts }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const recapData = useSelector(recapDataSelector);
  const showLoaderOnPortfolioAndNeworthCurrencyMismatch = useSelector(
    shouldShowLoaderOnPortfolioAndNeworthCurrencyMismatch
  );
  const showLoaderOnPortfolioAndRecapCurrencyMismatch = useSelector(
    shouldShowLoaderOnPortfolioAndRecapCurrencyMismatch
  );

  const [showRemoveChartDialog, setShowRemoveChartDialog] = useState(false);
  const [chartIdToRemove, setChartIdToRemove] = useState(null);
  const [showReArrangeView, setShowRearrangeView] = useState(null);

  const hideRearrangeOption = useMemo(
    () =>
      [...chartIds.columns["column1"].chartIds, ...chartIds.columns["column2"].chartIds].filter(
        item => parseParams(item).is_checked === "true"
      ).length < 2,
    [chartIds.columns]
  );
  const areDashboardChartsEmpty = useMemo(
    () => [...chartIds.columns["column1"].chartIds, ...chartIds.columns["column2"].chartIds].length === 0,
    [chartIds.columns]
  );

  const filteredChartIdsMap = useMemo(() => {
    let map = {};
    diyDashboardCharts.columnOrder.forEach((column, index) => {
      if (!map[column]) {
        map[column] = new Map();
      }
      const unfilteredChartIds = diyDashboardCharts.columns[column].chartIds.filter(
        (id, index) => diyDashboardCharts.columns[column].chartIds.indexOf(id) === index
      );
      const chartIds =
        index === 1
          ? unfilteredChartIds.filter(
              id => !diyDashboardCharts.columns[diyDashboardCharts.columnOrder[0]].chartIds.includes(id)
            )
          : unfilteredChartIds;
      map[column].set(index, chartIds);
    });

    return map;
  }, [diyDashboardCharts]);

  const handleRemoveChartSelection = chartId => {
    setShowRemoveChartDialog(true);
    setChartIdToRemove(chartId);
  };

  const handleRearrangeChartSelection = () => {
    setShowRearrangeView(true);
  };

  const handleChartClick = chartId => {
    const chartParams = parseParams(chartId);
    // add keys like should compare with sheet if not present since older chart id's before the change was made might not have the keys
    if (!chartParams.should_compare_with_investable_assets) {
      chartParams.should_compare_with_investable_assets = "undefined";
    }
    if (!chartParams.should_compare_with_sheet) {
      chartParams.should_compare_with_sheet = "undefined";
    }
    if (!chartParams.should_compare_with_total_assets_or_debts) {
      chartParams.should_compare_with_total_assets_or_debts = "undefined";
    }
    if (!chartParams.should_compare_with_investable_assets_without_cash) {
      chartParams.should_compare_with_investable_assets_without_cash = "undefined";
    }
    const reportId = chartParams.report_id;
    const reportParams = parseParams(reportId);
    const selectedChartOptions = reportParams[hashParams.CHART_OPTION];
    const selectedTimeRange = reportParams[hashParams.CHART_TIMERANGE];
    const selectedChartType = reportParams[hashParams.CHART_TYPE];
    const reportPath = reportParams[hashParams.REPORT_PATH];
    const reportNodeId = reportParams[hashParams.REPORT_NODE_ID];
    const chartName = chartParams.chart_name;
    const chartStyle = chartParams.chart_style;
    const chartContent = chartParams.chart_content;
    const shouldShowContentsTab = () => {
      return selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id
        ? !reportParams[hashParams.REPORT_PATH] === true ||
            reportParams[hashParams.REPORT_PATH].endsWith("/rows") === false
        : !reportParams[hashParams.REPORT_PATH] === true;
    };

    const shouldShowReportsTab = () => {
      const reportId = reportParams[hashParams.REPORT_NODE_ID];
      const selectedRecapOption = getRecapChartOptionFromId(selectedChartOptions);
      if (
        reportId !== RECAP_CATEGORY_TYPE_ASSET &&
        reportId !== RECAP_CATEGORY_TYPE_DEBT &&
        reportId !== RECAP_CATEGORY_TYPE_NETWORTH
      ) {
        if (reportId === "Stocks" || reportId === "Stocks & Funds") {
          if (
            selectedRecapOption.id === recapChartOptions.INVESTABLE.id ||
            selectedRecapOption.id === recapChartOptions.ASSET_CLASSES.id ||
            selectedRecapOption.id === recapChartOptions.INVESTABLE_WITHOUT_CASH.id
          ) {
            return true;
          }
          return false;
        }
        if (reportId === "Crypto" || reportId === "Cash on hand" || reportId === "Investable Assets") {
          return true;
        }
        if (
          reportId.toLowerCase() !== selectedRecapOption.label.toLowerCase() &&
          selectedRecapOption.id !== recapChartOptions.STOCKS_AND_SECTOR.id &&
          selectedRecapOption.id !== recapChartOptions.STOCKS_AND_GEOGRAPHY.id &&
          selectedRecapOption.id !== recapChartOptions.STOCKS_AND_MARKETCAP.id
        ) {
          return true;
        }
        return true;
      }
      return false;
    };

    const shouldShowInvestableAssetsBySheetsTab = () => {
      return (
        selectedChartOptions === recapChartOptions.INVESTABLE.id && reportParams.report_node_id === "Investable Assets"
      );
    };

    const shouldShowInvestableAssetsBySectionsTab = () => {
      if (
        selectedChartOptions === recapChartOptions.INVESTABLE.id &&
        reportParams.report_node_id === "Investable Assets"
      ) {
        const node = recapReportNodeSelector(store.getState(), reportId, true, chartTimeRange.TODAY, false, true);
        const isAllSheetsHaveOneSection = node && node.sheets.every(sheet => sheet.sections.length === 1);
        // do not show investable assets by sections report if all sheets have only one section
        return !isAllSheetsHaveOneSection;
      } else {
        return false;
      }
    };

    const shouldShowInvestableAssetsWithoutcashBySectionsTab = () => {
      if (
        (selectedChartOptions === recapChartOptions.INVESTABLE_WITHOUT_CASH.id &&
          reportParams.report_node_id === "Investable Assets ex Cash") ||
        (selectedChartOptions === recapChartOptions.INVESTABLE_WITHOUT_CASH.id &&
          reportParams.report_node_id === "Investable Assets ex Cash")
      ) {
        const node = recapReportNodeSelector(
          store.getState(),
          reportId,
          true,
          chartTimeRange.TODAY,
          false,
          false,
          true
        );
        const isAllSheetsHaveOneSection = node && node.sheets.every(sheet => sheet.sections.length === 1);
        // do not show investable assets by sections report if all sheets have only one section
        return !isAllSheetsHaveOneSection;
      } else {
        return false;
      }
    };

    const shouldShowInvestableAssetsWithoutCashBySheetsTab = () => {
      return (
        selectedChartOptions === recapChartOptions.INVESTABLE_WITHOUT_CASH.id &&
        reportParams.report_node_id === "Investable Assets ex Cash"
      );
    };

    const shouldShowAssetsBySectionsTab = () => {
      if (
        selectedChartOptions === recapChartOptions.SHEETS_AND_SECTIONS.id &&
        reportParams.report_node_id === "Assets"
      ) {
        const node = recapReportNodeSelector(
          store.getState(),
          reportId,
          true,
          chartTimeRange.TODAY,
          false,
          false,
          false,
          false,
          true
        );
        const isAllSheetsHaveOneSection = node && node.sheets.every(sheet => sheet.sections.length === 1);
        // do not show assets by sections report if all sheets have only one section
        return !isAllSheetsHaveOneSection;
      }
      return false;
    };

    const chartIds = chartIdsForSelectedCustodianSelector(
      selectedChartOptions,
      selectedTimeRange,
      selectedChartType,
      reportPath,
      reportNodeId,
      reportId,
      shouldShowContentsTab(),
      shouldShowReportsTab(),
      shouldShowInvestableAssetsBySheetsTab(),
      shouldShowInvestableAssetsBySectionsTab(),
      shouldShowInvestableAssetsWithoutCashBySheetsTab(),
      shouldShowInvestableAssetsWithoutcashBySectionsTab(),
      shouldShowAssetsBySectionsTab()
    );
    const chartIndex = chartIds.findIndex(id => {
      const parsedId = parseParams(id);
      const parsedChartParams = parseParams(parsedId.report_id);
      if (parsedChartParams.chart_option === recapChartOptions.ASSETS_AND_CURRENCY.id) {
        return (
          parsedChartParams.report_node_id === reportParams.report_node_id &&
          parsedId.chart_content === chartContent &&
          parsedId.chart_style === chartStyle
        );
      } else if (parsedChartParams.chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) {
        return (
          parsedId.chart_style === chartParams.chart_style &&
          parsedId.chart_content === chartParams.chart_content &&
          parsedId.should_compare_with_investable_assets === chartParams.should_compare_with_investable_assets &&
          parsedId.should_compare_with_sheet === chartParams.should_compare_with_sheet &&
          parsedId.should_compare_with_total_assets_or_debts ===
            chartParams.should_compare_with_total_assets_or_debts &&
          parsedId.should_compare_with_investable_assets_without_cash ===
            chartParams.should_compare_with_investable_assets_without_cash &&
          parsedChartParams.report_node_id === reportParams.report_node_id
        );
      } else {
        return (
          parsedId.chart_name === chartName &&
          parsedId.chart_content === chartContent &&
          parsedId.chart_style === chartStyle
        );
      }
    });
    const tabIndex = chartIndex === 0 ? chartIndex : chartIndex - 1;
    const chartsHash = `${hashParams.MODAL}=${modalValues.RECAP_CHARTS}&${hashParams.REPORT_ID}=${encodeURIComponent(
      reportId
    )}&${hashParams.TAB_INDEX}=${tabIndex}`;
    history.push({ ...location, hash: chartsHash });
  };

  const handleConnectivityWidgetClick = () => {
    const connectivityTabHash = `${hashParams.MODAL}=${modalValues.ACCOUNT_SETTINGS}&${hashParams.TAB}=${accountSettingsTabs.CONNECTION_STATUS}&${hashParams.SELECTED_PORTFOLIO_ID}=${portfolioId}`;
    history.push({ ...location, hash: connectivityTabHash });
  };

  const handleRemoveChartConfirmationPositiveClick = () => {
    const dashboardCharts = { ...diyDashboardCharts };
    diyDashboardCharts.columnOrder.every(column => {
      const chartIndex = diyDashboardCharts.columns[column].chartIds.findIndex(id => {
        const chartParams = parseParams(chartIdToRemove);
        const chartName = chartParams.chart_name;
        const diyChartParams = parseParams(id);
        const shouldCompareAgainstInvestableAssetsChartParams =
          chartParams.should_compare_with_investable_assets === "undefined"
            ? undefined
            : chartParams.should_compare_with_investable_assets;
        const shouldCompareWithTotalAssetsOrDebtsChartParams =
          chartParams.should_compare_with_total_assets_or_debts === "undefined"
            ? undefined
            : chartParams.should_compare_with_total_assets_or_debts;
        const shouldCompareWithSheetChartParams =
          chartParams.should_compare_with_sheet === "undefined" ? undefined : chartParams.should_compare_with_sheet;
        const shouldCompareAgainstInvestableAssetsWithOutCashChartParams =
          chartParams.should_compare_with_investable_assets_without_cash === "undefined"
            ? undefined
            : chartParams.should_compare_with_investable_assets_without_cash;

        const shouldCompareAgainstInvestableAssetsDiyChartParams =
          diyChartParams.should_compare_with_investable_assets === "undefined"
            ? undefined
            : diyChartParams.should_compare_with_investable_assets;
        const shouldCompareWithTotalAssetsOrDebtsDiyChartParams =
          diyChartParams.should_compare_with_total_assets_or_debts === "undefined"
            ? undefined
            : diyChartParams.should_compare_with_total_assets_or_debts;
        const shouldCompareWithSheetDiyChartParams =
          diyChartParams.should_compare_with_sheet === "undefined"
            ? undefined
            : diyChartParams.should_compare_with_sheet;
        const shouldCompareAgainstInvestableAssetsWithOutCashDiyChartParams =
          diyChartParams.should_compare_with_investable_assets_without_cash === "undefined"
            ? undefined
            : diyChartParams.should_compare_with_investable_assets_without_cash;

        if (
          (parseParams(chartParams.report_id).chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id ||
            parseParams(diyChartParams.report_id).chart_option === recapChartOptions.SHEETS_AND_SECTIONS.id) &&
          chartParams.chart_name !== "Assets"
        ) {
          if (chartName === "Assets x Class" || chartName === "Assets x Sheets") {
            return (
              parseParams(chartParams.report_id).report_node_id ===
                parseParams(diyChartParams.report_id).report_node_id &&
              chartParams.chart_style === diyChartParams.chart_style &&
              chartParams.chart_content === diyChartParams.chart_content &&
              chartParams.chart_name === diyChartParams.chart_name &&
              shouldCompareAgainstInvestableAssetsChartParams === shouldCompareAgainstInvestableAssetsDiyChartParams &&
              shouldCompareWithTotalAssetsOrDebtsChartParams === shouldCompareWithTotalAssetsOrDebtsDiyChartParams &&
              shouldCompareWithSheetChartParams === shouldCompareWithSheetDiyChartParams &&
              shouldCompareAgainstInvestableAssetsWithOutCashChartParams ===
                shouldCompareAgainstInvestableAssetsWithOutCashDiyChartParams
            );
          }
          return (
            parseParams(chartParams.report_id).report_node_id ===
              parseParams(diyChartParams.report_id).report_node_id &&
            chartParams.chart_style === diyChartParams.chart_style &&
            chartParams.chart_content === diyChartParams.chart_content &&
            shouldCompareAgainstInvestableAssetsChartParams === shouldCompareAgainstInvestableAssetsDiyChartParams &&
            shouldCompareWithTotalAssetsOrDebtsChartParams === shouldCompareWithTotalAssetsOrDebtsDiyChartParams &&
            shouldCompareWithSheetChartParams === shouldCompareWithSheetDiyChartParams &&
            shouldCompareAgainstInvestableAssetsWithOutCashChartParams ===
              shouldCompareAgainstInvestableAssetsWithOutCashDiyChartParams
          );
        } else {
          return (
            chartParams.chart_style === diyChartParams.chart_style &&
            chartParams.chart_content === diyChartParams.chart_content &&
            chartParams.chart_name === diyChartParams.chart_name
          );
        }
      });
      if (chartIndex !== -1) {
        const chartParams = parseParams(chartIdToRemove);
        chartParams.is_checked = false;
        const reportId = encodeURIComponent(chartParams.report_id);
        const chartContent = chartParams.chart_content;
        const chartStyle = chartParams.chart_style;
        const isDefaultChart = chartParams[chartKeyParams.IS_DEFAULT_CHART];
        const chartName = chartParams.chart_name;
        const shouldBeCheckedByDefault = chartParams.should_be_checked_by_default;
        const shouldCompareWithInvestableAsset = chartParams.should_compare_with_investable_assets;
        const shouldCompareWithTotalAssetsOrDebts = chartParams.should_compare_with_total_assets_or_debts;
        const shouldCompareWithSheet = chartParams.should_compare_with_sheet;
        const shouldCompareWithInvestableAssetsWithoutCash =
          chartParams.should_compare_with_investable_assets_without_cash;
        const updatedChartId = createChartIdUsingReportId(
          reportId,
          chartStyle,
          chartContent,
          false,
          isDefaultChart,
          chartName,
          shouldBeCheckedByDefault,
          shouldCompareWithInvestableAsset,
          shouldCompareWithTotalAssetsOrDebts,
          shouldCompareWithSheet,
          shouldCompareWithInvestableAssetsWithoutCash
        );
        dashboardCharts.columns[column].chartIds[chartIndex] = updatedChartId;
        setShowRemoveChartDialog(false);
        dispatch(updateDashboardCharts({ ...diyDashboardCharts }));
        return false;
      }
      return true;
    });
  };

  const handleRemoveChartConfirmationDismiss = () => {
    setShowRemoveChartDialog(false);
  };

  const shouldRemoveWidget =
    parseParams(chartIdToRemove) && parseParams(chartIdToRemove).chart_content === chartContent.CONNECTIVITY_WIDGET;

  return (
    <>
      {!recapData === true ? (
        <ChartsLoader />
      ) : !areDashboardChartsEmpty ? (
        <>
          <ColumnContainer>
            {showLoaderOnPortfolioAndRecapCurrencyMismatch && !showLoaderOnPortfolioAndNeworthCurrencyMismatch && (
              <LoaderOverlay>
                <RefreshLoader />
              </LoaderOverlay>
            )}
            {diyDashboardCharts.columnOrder.map((column, index) => {
              return (
                <ColumnsComponent
                  index={index}
                  chartIds={filteredChartIdsMap[column].get(index)}
                  handleRemoveChartSelection={handleRemoveChartSelection}
                  handleRearrangeChartSelection={handleRearrangeChartSelection}
                  handleChartClick={handleChartClick}
                  key={index}
                  setShowRearrangeView={setShowRearrangeView}
                  column={column}
                  handleConnectivityWidgetClick={handleConnectivityWidgetClick}
                  hideRearrangeOption={hideRearrangeOption}
                  portfolioId={portfolioId}
                />
              );
            })}
          </ColumnContainer>
          {showReArrangeView && (
            <ChartsRearrangementComponent
              diyDashboardCharts={diyDashboardCharts}
              setShowRearrangeView={setShowRearrangeView}
              filteredChartIdsMap={filteredChartIdsMap}
            />
          )}
        </>
      ) : (
        <DashboardChartsNotAvailableComponent />
      )}
      {showRemoveChartDialog && (
        <ConfirmationDialog
          canUserDismiss={true}
          title={
            shouldRemoveWidget ? i18n.t("removeWidget.confirmation.title") : i18n.t("removeChart.confirmation.title")
          }
          description={i18n.t("removeChart.confirmation.description")}
          positiveButtonTitle={
            shouldRemoveWidget
              ? i18n.t("removeWidget.confirmation.positiveButton.title")
              : i18n.t("removeChart.confirmation.positiveButton.title")
          }
          negativeButtonTitle={i18n.t("goBackLink")}
          onDismiss={handleRemoveChartConfirmationDismiss}
          handlePositiveButtonClick={handleRemoveChartConfirmationPositiveClick}
          handleNegativeButtonClick={handleRemoveChartConfirmationDismiss}
        />
      )}
    </>
  );
};

export default React.memo(DiyDashboardRenderCharts);
