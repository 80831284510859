import { isMobile } from "@kubera/common";

const isMobileDevice = isMobile();
// eslint-disable-next-line no-unused-vars
const loadTimeStamp = Math.floor(Date.now() / 1000);
const lrConditionalCall = (fn, isWhiteListedUser = false) => {
  if (isMobileDevice) {
    // if (loadTimeStamp % 10 === 0 || isWhiteListedUser) {
    //   fn();
    //   return true;
    // }
    return false;
  }

  fn();
  return true;
};

export default lrConditionalCall;
