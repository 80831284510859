import React from "react";
import i18n from "i18next";
import styled, { css } from "styled-components";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { withRouter } from "@kubera/common";
import { hashParams, modalValues } from "routes";
import MultiEmailInput from "components/inputs/MultiEmailInput";
import TextArea from "components/inputs/TextArea";
import PrimaryButton from "components/button/PrimaryButton";
import { connect } from "react-redux";
import {
  whiteLabelUserInfoSelector,
  inviteWlClient,
  createWlSubUser,
  inviteWlSubUser,
  isEmailValid,
  wlClientSelector,
  wlClientAndSubUserSelector,
  updateWlClientInvite,
  siteConfigSelector,
  wlSetupType,
  apiErrorCodes,
  wlOtherClientAndSubUserEmailMapSelector,
  isMobile
} from "@kubera/common";
import TextInput from "components/inputs/TextInput";
import Checkbox from "components/inputs/Checkbox";
import Spinner from "components/loader/Spinner";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";

const isMobileDevice = isMobile();

const InviteClientDialog = styled(Dialog)`
  position: relative;
  width: 612px;
  display: flex;
  align-items: stretch;
  margin-top: 74px;
  min-height: 451px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 55px 60px 67px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
  position: relative;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => (isMobileDevice ? props.theme.mobileBackgroundOverlay : props.theme.dialogContentContainerBG)};
  white-space: pre-wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  font-feature-settings: "ss01" on;
  color: #000000;
  flex-direction: column;
`;

const Loader = styled(Spinner)`
  margin-bottom: 15px;
  width: 30px;
  height: 30px;
`;

const Title = styled.div`
  margin-bottom: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
`;

const FieldTitle = styled.div`
  margin-top: 16px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const NameField = styled(TextInput)`
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding-left: 13px;
  padding-right: 13px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;

  ${({ readOnly }) =>
    readOnly &&
    css`
      background: rgba(0, 0, 0, 0.05);
      outline: 0;
    `}
`;

const MultiEmailField = styled(MultiEmailInput)`
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;

  .multiemail-input {
    min-height: 45px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
  }

  .exists-error,
  .invalid-error {
    color: #ff0000;
  }

  &:focus .invalid-error {
    color: inherit;
  }
`;

const PasscodeField = styled(NameField)``;

const InviteCheckbox = styled(Checkbox)`
  margin-top: 24px;
  margin-bottom: 12px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
      user-select: none;
    `};
`;

const MessageTemplate = styled.div`
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
  white-space: pre-wrap;
  padding: 20px 17px 30px 17px;
`;

const MessageTextArea = styled(TextArea)`
  min-height: 88px;
  margin-top: -1px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding: 21px 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const SubmitErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.errorCLR};
  margin-top: 7px;
  margin-bottom: 7px;
`;

const InviteButtonContainer = styled.div`
  flex: 1;
`;

const InviteButton = styled(PrimaryButton)`
  margin-top: 40px;
  height: 40px;
`;

const EmailErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: #ff0000;
`;

const EmailNote = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.5);
`;

const SomethingWentWrong = styled(ConfirmationDialog)`
  min-width: 655px;
`;

class InviteClientDialogComponent extends React.Component {
  static show = (history, location, userId = null) => {
    var hash = `${hashParams.MODAL}=${modalValues.WL_INVITE_CLIENT}`;
    if (!userId === false) {
      hash += `&${hashParams.ID}=${userId}`;
    }

    history.push({
      ...location,
      hash: hash
    });
  };

  static scrollDialogToTop = () => {
    document.getElementById("kubera-container-overlay").scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  static showWentWrongDialogDependsOnErrorCode(errorCode) {
    return [apiErrorCodes.DUPLICATE_ENTRY, apiErrorCodes.LIMIT_REACHED].includes(errorCode) ? false : true;
  }

  get isManageClientFlow() {
    return (
      this.props.isChangeClient === true || this.props.isNewUserFlow === true || this.props.isReinviteFlow === true
    );
  }

  get multiEmailLimitTo() {
    const isReadOnlyMode = this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO;
    if (isReadOnlyMode || this.props.isChangeClient === true || this.props.isReinviteFlow === true) {
      return 1;
    }

    if (this.props.isNewUserFlow === true) {
      return this.props.siteConfig.subUserLimit - this.props.client.subUser.length;
    }

    return this.props.siteConfig.subUserLimit;
  }

  constructor(props) {
    super(props);

    const getEmails = () => {
      if (props.isNewUserFlow === true || props.isChangeClient === true) {
        return [];
      }

      if (props.isReinviteFlow === true) {
        const subUser = props.clientAndSubUser.find(user => user.userId === props.subUserId);
        return [subUser.email];
      }

      return props.clientAndSubUser.map(user => user.email) || [];
    };

    this.state = {
      name: !props.client === false ? props.client.clientName : "",
      nameErrorMessage: null,
      emailErrorMessage: null,
      emailRequiredErrorMessage: null,
      emails: getEmails(),
      inviteClientIsChecked: false,
      message: (this.props.siteConfig.inviteCustomMessage || "").replace(/\\r?\\n/g, "\r\n"),
      isSubmitting: false,
      isSubmittingEmail: null,
      submitErrorMessage: null,
      passcode: !props.client === false && !props.client.share === false ? props.client.share.passcode : "",
      isSomethingWentWrongDialogOpen: false
    };

    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleMessageInputChange = this.handleMessageInputChange.bind(this);
    this.handleNameInputChange = this.handleNameInputChange.bind(this);
    this.handleInviteCheckboxChange = this.handleInviteCheckboxChange.bind(this);
    this.handleInviteButtonClick = this.handleInviteButtonClick.bind(this);
    this.handlePasscodeInputChange = this.handlePasscodeInputChange.bind(this);

    this.multiInputEmailRef = React.createRef();
  }

  defaultInviteClientOption(props) {
    if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
      return !props.client === false;
    } else {
      return true;
    }
  }

  handleOverlayDismiss() {
    if (this.props.onDismiss) {
      this.props.onDismiss(this.props.history, this.props.location);
      return;
    }
    DialogOverlay.forceDismiss(this.props.history, this.props.location);
  }

  handleNameInputChange(e) {
    this.setState({ name: e.target.value, nameErrorMessage: null });
  }

  handleMessageInputChange(value) {
    this.setState({ message: value });
  }

  handlePasscodeInputChange(e) {
    this.setState({ passcode: e.target.value });
  }

  handleInviteCheckboxChange(selected, e) {
    this.setState({ inviteClientIsChecked: selected });
  }

  handleSubUserInvite = async (clientUser, subUserEmails, inviteClient) => {
    for (let email of subUserEmails) {
      this.setState({
        isSubmittingEmail: email
      });
      if (inviteClient) {
        await this.props
          .inviteWlSubUser(clientUser, {
            parentUserId: clientUser.userId,
            email: email.toLowerCase()
          })
          .catch(err => {
            this.setState({
              submitErrorMessage: err.errorMessage,
              isSomethingWentWrongDialogOpen: InviteClientDialogComponent.showWentWrongDialogDependsOnErrorCode(
                err.errorCode
              )
            });
          });
      } else {
        await this.props
          .createWlSubUser(clientUser, {
            parentUserId: clientUser.userId,
            email: email.toLowerCase()
          })
          .catch(err => {
            this.setState({
              submitErrorMessage: err.errorMessage,
              isSomethingWentWrongDialogOpen: InviteClientDialogComponent.showWentWrongDialogDependsOnErrorCode(
                err.errorCode
              )
            });
          });
      }
    }

    return true;
  };

  async handleInviteButtonClick(e) {
    const emails = this.state.emails;

    if (this.state.nameErrorMessage || this.state.emailErrorMessage) {
      InviteClientDialogComponent.scrollDialogToTop();
      return;
    }

    if (!this.state.name === true) {
      this.setState({ nameErrorMessage: i18n.t("emptyClientName") });
      InviteClientDialogComponent.scrollDialogToTop();
      return;
    }
    if (this.isEmailOptional() === false) {
      if (emails.length === 0) {
        this.setState({ emailRequiredErrorMessage: i18n.t("emailRequired").toUpperCase() });
        InviteClientDialogComponent.scrollDialogToTop();
        return;
      }

      if (emails.some(email => isEmailValid(email) === false)) {
        this.setState({
          emailErrorMessage: i18n.t("invalidEmailErrorMsg")
        });
        InviteClientDialogComponent.scrollDialogToTop();
        return;
      }
    }

    this.setState({ isSubmitting: true, submitErrorMessage: null });

    const customMessage = this.state.message.replaceAll(/\r?\n/g, "<br/>");
    const isEmailOptionalAndEmpty = this.isEmailOptional() && emails.length === 0;
    const isInviteClient = this.canShowInviteClientCheckbox()
      ? this.state.inviteClientIsChecked
      : this.defaultInviteClientOption(this.props);

    this.setState({
      isSubmittingEmail: emails[0]
    });
    if (this.props.isNewUserFlow === true || this.props.isReinviteFlow === true) {
      await this.handleSubUserInvite(this.props.client, emails, true);
      this.handleOverlayDismiss();
    } else if (!this.props.client === true) {
      this.props.inviteWlClient(
        this.state.name,
        emails[0],
        customMessage,
        isEmailOptionalAndEmpty ? 0 : isInviteClient,
        async user => {
          await this.handleSubUserInvite(user, emails.slice(1), isInviteClient);
          this.handleOverlayDismiss();
        },
        apiError => {
          this.setState({
            isSubmitting: false,
            submitErrorMessage: apiError.errorMessage,
            isSomethingWentWrongDialogOpen: InviteClientDialogComponent.showWentWrongDialogDependsOnErrorCode(
              apiError.errorCode
            )
          });
        }
      );
    } else {
      this.props.updateWlClientInvite(
        this.state.name,
        this.props.client.email,
        emails[0],
        customMessage,
        isEmailOptionalAndEmpty ? 0 : isInviteClient,
        this.state.passcode,
        async user => {
          await this.handleSubUserInvite(user, emails.slice(1), isInviteClient);
          this.handleOverlayDismiss();
        },
        apiError => {
          if (apiError.errorCode === apiErrorCodes.EMAIL_IN_USE) {
            this.setState({ isSubmitting: false, emailErrorMessage: i18n.t("emailInUse") });
          } else {
            this.setState({
              isSubmitting: false,
              submitErrorMessage: apiError.errorMessage,
              isSomethingWentWrongDialogOpen: InviteClientDialogComponent.showWentWrongDialogDependsOnErrorCode(
                apiError.errorCode
              )
            });
          }
        }
      );
    }
  }

  getTemplateMessage() {
    if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
      return i18n.t("roInviteClientMessageTemplate");
    }
    return i18n.t("inviteClientMessageTemplate");
  }

  getTitle() {
    if (this.props.isNewUserFlow === true) {
      return i18n.t("newUserClient");
    }
    if (!this.props.client === false) {
      if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
        return i18n.t("inviteRoClient");
      }
      return i18n.t("inviteClient");
    }
    return i18n.t("addNewClient");
  }

  getInviteButtonTitle() {
    if (!this.props.client === false) {
      return i18n.t("invite");
    }
    return i18n.t("createClient");
  }

  canShowInviteClientCheckbox() {
    if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
      return false;
    }
    return !this.props.client === true;
  }

  canShowInviteClientMessage() {
    if (
      this.canShowInviteClientCheckbox()
        ? !this.state.inviteClientIsChecked
        : !this.defaultInviteClientOption(this.props)
    ) {
      return false;
    }
    if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
      return !this.props.client === false;
    }
    return true;
  }

  isEmailOptional() {
    if (
      this.props.isNewUserFlow ||
      this.props.isChangeClient ||
      this.props.isReinviteFlow ||
      !this.props.client === false
    ) {
      return false;
    }
    return true;
  }

  canShowPasscodeField() {
    return this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO && !this.props.client === false;
  }

  renderEmails = (email, isFocused) => {
    const trimmedEmail = email.trim();
    if (this.props.otherClientAndSubUserEmailMap.get(trimmedEmail) && !isFocused) {
      return `<span class="exists-error">${email}</span>`;
    }

    if (!isEmailValid(trimmedEmail) && !isFocused) {
      return `<span class="invalid-error">${email}</span>`;
    }

    return `<span>${email}</span>`;
  };

  handleMultiEmailOnChange = multiEmails => {
    this.setState({
      emails: multiEmails.list,
      emailErrorMessage: "",
      emailRequiredErrorMessage: ""
    });
  };

  handleMultiEmailOnBlur = multiEmails => {
    const hasExistingEmail = multiEmails.list.some(email => this.props.otherClientAndSubUserEmailMap.get(email));

    if (multiEmails.list.some(email => isEmailValid(email) === false)) {
      this.setState({
        emailErrorMessage: i18n.t("invalidEmailErrorMsg")
      });
    } else if (hasExistingEmail) {
      this.setState({
        emailErrorMessage: i18n.t("emailExistsErrorMsg")
      });
    }
  };

  handleSomethingWrongDismiss = () => {
    this.setState({
      isSomethingWentWrongDialogOpen: false
    });
  };

  handleSomethingWrongPositiveButtonClick = () => {
    this.handleSomethingWrongDismiss();
    this.handleInviteButtonClick();
  };

  handleTextAreaAdjust = ref => {
    ref.style.height = "1px";
    ref.style.height = 5 + ref.scrollHeight + "px";
  };

  isInviteClientIsChecked() {
    if (this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO) {
      return !this.props.client === false;
    }
    return this.state.inviteClientIsChecked;
  }

  render() {
    const templateMessage = this.getTemplateMessage();
    const name = this.state.name;
    const nameErrorMessage = this.state.nameErrorMessage;
    const emails = this.state.emails;
    const emailErrorMessage = this.state.emailErrorMessage;
    const emailRequiredErrorMessage = this.state.emailRequiredErrorMessage;
    const inviteClientIsChecked = this.isInviteClientIsChecked();
    const message = this.state.message;
    const isSubmitting = this.state.isSubmitting;
    const submitErrorMessage = this.state.submitErrorMessage;
    const isInviteClient = this.canShowInviteClientCheckbox()
      ? inviteClientIsChecked
      : this.defaultInviteClientOption(this.props);
    const isInviteFlow = !this.props.client === false || (isInviteClient && emails.length > 0);
    const invitingOrCreating = isInviteFlow ? "inviting" : "creating";

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <InviteClientDialog>
          <Container>
            <Title>{this.getTitle()}</Title>
            <FormContainer>
              <FieldTitle>{i18n.t("clientsName")}</FieldTitle>
              <NameField
                placeholder={i18n.t("fullname")}
                value={name}
                errorMessage={nameErrorMessage}
                onChange={this.handleNameInputChange}
                readOnly={this.isManageClientFlow}
                autoFocus
              />
              <FieldTitle>
                {!emailRequiredErrorMessage ? (
                  i18n.t("email") + (this.isEmailOptional() ? " " + i18n.t("optional") : "")
                ) : (
                  <EmailErrorMessage>{emailRequiredErrorMessage}</EmailErrorMessage>
                )}
              </FieldTitle>
              <MultiEmailField
                ref={this.multiInputEmailRef}
                placeholder={i18n.t("inviteClientEmailPlaceholder")}
                emails={emails}
                limitTo={this.multiEmailLimitTo}
                renderEmails={this.renderEmails}
                onChange={this.handleMultiEmailOnChange}
                onBlur={this.handleMultiEmailOnBlur}
                disabled={this.props.isReinviteFlow === true}
              />
              {emailErrorMessage ? (
                <EmailErrorMessage>{emailErrorMessage}</EmailErrorMessage>
              ) : this.multiEmailLimitTo > 1 ? (
                <EmailNote>{i18n.t("wlkMultiEmailInput.note")}</EmailNote>
              ) : null}
              {this.canShowPasscodeField() && <FieldTitle>{i18n.t("passcode") + " " + i18n.t("optional")}</FieldTitle>}
              {this.canShowPasscodeField() && (
                <PasscodeField
                  placeholder={i18n.t("passcode")}
                  value={this.state.passcode}
                  onChange={this.handlePasscodeInputChange}
                />
              )}
              {this.canShowInviteClientCheckbox() && (
                <InviteCheckbox
                  checked={inviteClientIsChecked}
                  disabled={emails.length === 0}
                  label={i18n.t("inviteClientCheckboxLabel")}
                  onChange={this.handleInviteCheckboxChange}
                />
              )}
              <MessageContainer isDisabled={emails.length === 0}>
                {this.canShowInviteClientMessage() && <FieldTitle>{i18n.t("message")}</FieldTitle>}
                {this.canShowInviteClientMessage() && (
                  <MessageTemplate
                    dangerouslySetInnerHTML={{
                      __html: templateMessage
                    }}
                  />
                )}
                {this.canShowInviteClientMessage() && (
                  <MessageTextArea
                    placeholder={i18n.t("inviteClientPersonalMessagePlaceholder")}
                    value={message}
                    onChange={this.handleMessageInputChange}
                    disabled={inviteClientIsChecked === false}
                    onMount={this.handleTextAreaAdjust}
                  />
                )}
                {!submitErrorMessage === false && (
                  <SubmitErrorMessage
                    dangerouslySetInnerHTML={{
                      __html: submitErrorMessage
                    }}
                  />
                )}
              </MessageContainer>
              <InviteButtonContainer>
                <InviteButton
                  title={this.getInviteButtonTitle()}
                  onClick={this.handleInviteButtonClick}
                  isLoading={isSubmitting}
                />
              </InviteButtonContainer>
              {isSubmitting && (
                <LoadingContainer>
                  <Loader />
                  {this.state.isSubmittingEmail
                    ? i18n
                        .t(`wlkInviteDialog.${invitingOrCreating}ClientWithEmail`)
                        .replace("{email}", this.state.isSubmittingEmail)
                    : i18n.t(`wlkInviteDialog.${invitingOrCreating}ClientWithoutEmail`)}
                </LoadingContainer>
              )}
              {this.state.isSomethingWentWrongDialogOpen === true && (
                <SomethingWentWrong
                  title={i18n.t("wlkManageUser.somethingWentWrongTitle")}
                  description={i18n.t("wlkManageUser.somethingWentWrongDesc")}
                  positiveButtonTitle={i18n.t("retry")}
                  handlePositiveButtonClick={this.handleSomethingWrongPositiveButtonClick}
                  onDismiss={this.handleSomethingWrongDismiss}
                  canUserDismiss
                />
              )}
            </FormContainer>
          </Container>
        </InviteClientDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => {
  const isNewUserFlow = () => {
    return props.isNewUserFlow === true;
  };
  const includeSubUsers = () => {
    return props.isChangeClient === true || props.isNewUserFlow === true;
  };
  return {
    wlUserInfo: whiteLabelUserInfoSelector(state),
    client: wlClientSelector(state, props.userId),
    clientAndSubUser: wlClientAndSubUserSelector(state, props.userId),
    otherClientAndSubUserEmailMap: wlOtherClientAndSubUserEmailMapSelector(
      state,
      isNewUserFlow() === false ? props.userId : null,
      includeSubUsers() ? props.userId : null
    ),
    siteConfig: siteConfigSelector(state)
  };
};

const mapDispatchToProps = {
  inviteWlClient: inviteWlClient,
  updateWlClientInvite: updateWlClientInvite,
  inviteWlSubUser,
  createWlSubUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InviteClientDialogComponent));
