import { ApiClient } from "../../api/ApiClient";
import { getUuid } from "../../utilities/Number";

import { batch } from "react-redux";

export const SET_PLUGIN_CURRENCY = "SET_PLUGIN_CURRENCY";
export const SET_LONG_EQUITY_DATA = "SET_LONG_EQUITY_DATA";
export const SAVE_PLUGIN_DATA = "SAVE_PLUGIN_DATA";

export const setPluginCurrencyAction = (portfolioId, pluginId, currency) => ({
  type: SET_PLUGIN_CURRENCY,
  portfolioId,
  pluginId,
  currency
});

export const setLongEquityDataAction = (portfolioId, pluginId, longEquityData) => ({
  type: SET_LONG_EQUITY_DATA,
  portfolioId,
  pluginId,
  longEquityData
});

export const savePluginDataAction = (portfolioId, pluginId, pluginData) => ({
  type: SAVE_PLUGIN_DATA,
  portfolioId,
  pluginId,
  pluginData
});

export const getLongEquityData = (portfolioId, pluginId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.getLongEquityData(getUuid(), portfolioId)
        .then(apiData => {
          batch(() => {
            dispatch(setLongEquityDataAction(portfolioId, pluginId, apiData.payload.fundMatchInfo));
            const pluginFormValue = apiData.payload.data.find(
              formValue => formValue.pluginId === pluginId && formValue.portfolioId === portfolioId
            );
            if (pluginFormValue) {
              dispatch(savePluginDataAction(portfolioId, pluginId, pluginFormValue.value));
            }
          });
          resolve(apiData.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const savePluginDataForPluginId = (portfolioId, pluginId, data) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.savePluginDataForPluginId(getUuid(), portfolioId, pluginId, data)
        .then(apiData => {
          dispatch(savePluginDataAction(portfolioId, pluginId, data));
          resolve(apiData.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};
