import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import i18n from "i18next";
import { hashParams } from "routes";
import {
  irrTypes,
  updateCustodianIrrType,
  withRouter,
  fetchCustodianDetails,
  fetchCustodianDetailsSuccessAction
} from "@kubera/common";
import { DialogOverlay } from "components/dialog/DialogOverlay";
import CustodianDetailsComponent, { detailsTabs } from "./CustodianDetailsComponent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const EnterOtherReturnsContainer = styled.div`
  display: flex;
  column-gap: 10px;
  margin-top: 20px;
`;

const EnterOtherReturnsButton = styled.div`
  width: fit-content;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor};
  cursor: pointer;
`;

const HoldingsReturnDesc = styled.div`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 30px;

  u {
    cursor: pointer;
  }
`;

class AccountReturnsComponent extends React.Component {
  handleEnterHoldingOnClick = async e => {
    this.props.fetchCustodianDetailsSuccessAction();
    await this.props.updateCustodianIrrType(this.props.parentId, irrTypes.HOLDING);
    this.props.fetchDetails(this.props.custodianId, 1);
  };

  handleGoToAccounts = event => {
    if (event.target.tagName.toLowerCase() === "u") {
      DialogOverlay.forceDismiss(this.props.history, this.props.location);
      CustodianDetailsComponent.show(this.props.history, this.props.location, this.props.parentId, false, undefined, {
        hash: `${hashParams.TAB_INDEX}=${detailsTabs.RETURNS}`
      });
    }
  };

  render() {
    const { className } = this.props;

    return (
      <Container className={className}>
        <HoldingsReturnDesc
          dangerouslySetInnerHTML={{
            __html: i18n.t("accountReturnsDescription")
          }}
          onClick={this.handleGoToAccounts}
        />
        {this.props.isReadOnly === false && (
          <EnterOtherReturnsContainer>
            <EnterOtherReturnsButton onClick={this.handleEnterHoldingOnClick}>
              {i18n.t("accountReturnsButton")}
            </EnterOtherReturnsButton>
          </EnterOtherReturnsContainer>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  updateCustodianIrrType: updateCustodianIrrType,
  fetchDetails: fetchCustodianDetails,
  fetchCustodianDetailsSuccessAction: fetchCustodianDetailsSuccessAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountReturnsComponent));
