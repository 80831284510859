import createWhitelistFilter from "redux-persist-transform-filter";
import {
  FETCH_CUSTODIAN_DETAILS,
  FETCH_CUSTODIAN_DETAILS_SUCCESS,
  FETCH_CUSTODIAN_DETAILS_HISTORY_SUCCESS,
  FETCH_CUSTODIAN_DETAILS_ERROR,
  RESET_CUSTODIAN_DETAILS,
  UPDATE_CUSTODIAN_DETAILS,
  DELETE_CUSTODIAN_HISTORY,
  UPDATE_CUSTODIAN_HISTORY,
  REFRESH_CUSTODIAN_HISTORY,
  UPDATE_CUSTODIAN_CASHFLOW,
  DELETE_CUSTODIAN_CASHFLOW,
  ADD_PENDING_IRR_REQUEST,
  REMOVE_PENDING_IRR_REQUEST,
  SET_INNER_DETAILS_STATE,
  SET_CURRENT_STATE,
  GET_CUSTODIAN_CHART,
  GET_CUSTODIAN_CHART_SUCCESS,
  GET_CUSTODIAN_CHART_ERROR,
  REFRESH_CUSTODIAN_CHART
} from "../actions/CustodianDetailsActions";
import { custodianTaxTypes, UPDATE_CUSTODIAN_BULK } from "../actions";

const initialState = {
  details: null,
  fetchDetailsPending: false,
  fetchDetailsError: false,
  pendingIrrRequests: [],
  previousState: null,
  scrollTop: 0,
  custodianChart: null,
  fetchCustodianChartPending: false,
  fetchCustodianChartError: null
};

export const getNewInitialState = () => {
  return window.kbStructuredClone(initialState);
};

export const custodianDetailsPersistTransform = () => {
  return createWhitelistFilter("custodian_details", []);
};

export function custodianDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_STATE:
      return {
        ...action.state
      };
    case SET_INNER_DETAILS_STATE:
      state.scrollTop = action.scrollTop;
      return {
        ...getNewInitialState(),
        previousState: state
      };
    case FETCH_CUSTODIAN_DETAILS:
      return {
        ...state,
        details: state.details || null,
        fetchDetailsPending: true,
        fetchDetailsError: null
      };
    case FETCH_CUSTODIAN_DETAILS_SUCCESS:
      return {
        ...state,
        fetchDetailsPending: false,
        details: action.details,
        fetchDetailsError: null
      };
    case GET_CUSTODIAN_CHART:
      return {
        ...state,
        custodianChart: state.custodianChart || null,
        fetchCustodianChartPending: true,
        fetchCustodianChartError: null
      };
    case GET_CUSTODIAN_CHART_SUCCESS:
      return {
        ...state,
        custodianChart: action.chartData,
        fetchCustodianChartPending: false,
        fetchCustodianChartError: null
      };
    case GET_CUSTODIAN_CHART_ERROR:
      return {
        ...state,
        fetchCustodianChartPending: false,
        fetchCustodianChartError: action.error
      };
    case FETCH_CUSTODIAN_DETAILS_HISTORY_SUCCESS:
      return {
        ...state,
        fetchDetailsPending: false,
        details: {
          ...state.details,
          history: action.isCostHistory
            ? state.details.history
            : action.clearPreviousHistory === true
            ? action.history
            : [...state.details.history, ...action.history],
          costHistory: action.isCostHistory
            ? action.clearPreviousHistory === true
              ? action.history
              : [...state.details.costHistory, ...action.history]
            : state.details.costHistory,
          isHistoryLoaded: action.isCostHistory ? state.details.isHistoryLoaded : action.history.length === 0,
          isCostHistoryLoaded: action.isCostHistory ? action.history.length === 0 : state.details.isCostHistoryLoaded
        },
        fetchDetailsError: null
      };
    case FETCH_CUSTODIAN_DETAILS_ERROR:
      return {
        ...state,
        fetchDetailsPending: false,
        details: null,
        fetchDetailsError: action.error
      };
    case UPDATE_CUSTODIAN_DETAILS:
      return {
        ...state,
        details: Object.assign({}, action.updatedDetails)
      };
    case ADD_PENDING_IRR_REQUEST:
      return {
        ...state,
        pendingIrrRequests: [...state.pendingIrrRequests, action.requestId]
      };
    case REMOVE_PENDING_IRR_REQUEST:
      return {
        ...state,
        pendingIrrRequests: state.pendingIrrRequests.filter(
          item => !action.requestId === false && item !== action.requestId
        )
      };
    case DELETE_CUSTODIAN_HISTORY: {
      const newState = { ...state };
      const details = newState.details || {};
      const { history = [] } = details;
      const historyItems = action.isCostHistory ? details.costHistory : history;
      const historyIndex = (historyItems || []).findIndex(entry => entry.id === action.historyId);
      if (historyIndex !== -1) {
        historyItems.splice(historyIndex, 1);
      }
      if (!action.isCostHistory) {
        details.history = [...historyItems];
      }
      return newState;
    }
    case UPDATE_CUSTODIAN_HISTORY: {
      const newState = { ...state };
      const details = newState.details || {};
      const { history = [] } = details;
      const historyItems = action.isCostHistory ? details.costHistory : history;
      const historyIndex = (historyItems || []).findIndex(entry => entry.id === action.history.id);
      if (historyIndex !== -1) {
        historyItems[historyIndex] = action.history;
      } else {
        historyItems.push(action.history);
      }
      if (!action.isCostHistory) {
        details.history = [...historyItems];
      }
      return newState;
    }
    case REFRESH_CUSTODIAN_HISTORY:
      return {
        ...state,
        details: {
          ...state.details,
          isHistoryRefreshing: action.isRefreshing
        }
      };

    case UPDATE_CUSTODIAN_CASHFLOW: {
      const newState = { ...state };
      const details = newState.details;

      if (!details === true) {
        return newState;
      }

      if (!details.cashFlow === true) {
        details.cashFlow = [];
      }

      const cashflowIndex = details.cashFlow.findIndex(entry => entry.id === action.cashflow.id);
      if (cashflowIndex !== -1) {
        details.cashFlow[cashflowIndex] = action.cashflow;
      } else {
        details.cashFlow.push(action.cashflow);
      }

      details.cashFlow = [...details.cashFlow];
      return newState;
    }
    case UPDATE_CUSTODIAN_BULK: {
      const newState = { ...state };
      const details = newState.details;

      if (!details === true) {
        return newState;
      }

      for (const custodian of action.custodians) {
        const custodianIndex = details.holdings.findIndex(item => item.id === custodian.id);

        if (custodianIndex !== -1) {
          details.holdings[custodianIndex] = {
            ...details.holdings[custodianIndex],
            ...custodian
          };
        }
      }
      details.holdings.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
      details.holdings = [...details.holdings];
      return newState;
    }
    case DELETE_CUSTODIAN_CASHFLOW: {
      const newState = { ...state };
      const details = newState.details;
      const cashflowIndex = details.cashFlow.findIndex(entry => entry.id === action.cashflowId);
      if (cashflowIndex !== -1) {
        details.cashFlow.splice(cashflowIndex, 1);
      }
      return newState;
    }
    case RESET_CUSTODIAN_DETAILS:
      return initialState;
    default:
      return state;
  }
}

export const custodianDetailsIsPendingSelector = state => state.custodian_details.fetchDetailsPending;

export const isCustodianHistoryRefreshingSelector = state => state.custodian_details.details?.isHistoryRefreshing;

export const custodianDetailsErrorSelector = state => state.custodian_details?.fetchDetailsError;

export const custodianDetailsSelector = state => state.custodian_details.details;

export const custodianChartDataSelector = state => state.custodian_details.custodianChart;

export const custodianChartIsPendingSelector = state => state.custodian_details.fetchCustodianChartPending;

export const previousDetailsStateSelector = state => state.custodian_details.previousState;

export const custodianDetailsScrollTopSelector = state => state.custodian_details.scrollTop;

export const isIrrUpdatingSelector = state => state.custodian_details.pendingIrrRequests.length !== 0;

export const taxOnUnrealizedGainsSelector = state => {
  const custodianDetails = custodianDetailsSelector(state);
  const detailsInfo = custodianDetails && custodianDetails.info;
  return detailsInfo && detailsInfo.taxDetails ? JSON.parse(detailsInfo.taxDetails) : null;
};

export const getTaxableTypeForCustodian = state => {
  const taxOnUnrealizedGains = taxOnUnrealizedGainsSelector(state);
  return taxOnUnrealizedGains ? taxOnUnrealizedGains.taxableAssetType : custodianTaxTypes.TAXABLE;
};
