import React from "react";
import styled from "styled-components";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import i18n from "i18next";
import { ReactComponent as LoansIcon } from "assets/images/loans.svg";
import { ReactComponent as CreditCardsIcon } from "assets/images/creditcards.svg";
import { ReactComponent as PortfolioLinkingIcon } from "assets/images/portfolio_linking.svg";

const EmptyGridView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 17px;
`;

const EmptyGridViewConnectAccountView = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ isColumnLayout }) => (!isColumnLayout ? "row" : "column")};
  height: ${props => props.height || "120px"};
  width: ${props => props.width || "271px"};
  margin: 6px;
  background: ${props => props.theme.emptyGridViewTitleBG};
  border: ${props => props.theme.emptyGridViewTitleBR};
  box-sizing: border-box;
  cursor: pointer;
  justify-content: space-between;
  &: hover {
    background: ${props => props.theme.emptyGridViewTitleBGHover};
  }
`;

const EmptyGridViewConnectAccountTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  font-feature-settings: "ss01" on;
  white-space: pre-wrap;
  padding: ${props => props.padding};
  align-self: ${props => (props.position === "top" ? "flex-start" : "flex-end")};
`;

const EmptyGridViewConnectAccountIcon = styled.div`
  outline: 0;
  padding: ${props => props.padding};
  cursor: pointer;
  align-self: ${props => (props.position === "top" ? "flex-start" : "flex-end")};
  text-align: ${props => (props.align === "left" ? "left" : "right")};
`;

const EmptyGridViewEnterManually = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15.6px;
  font-feature-settings: "ss01" on;
  padding: 0 11px 12px;
  white-space: pre;
  word-wrap: break-word;
  align-self: flex-end;
  width: 100%;
  box-sizing: border-box;
`;

const EmptyGridViewColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LoansIconComponent = styled(LoansIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const CreditCardsIconComponent = styled(CreditCardsIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const AddViewEnterManuallyTip = styled.div`
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  padding: 3px 6px 0;
  color: rgba(0, 0, 0, 0.5);

  a {
    color: rgba(0, 0, 0, 0.5);
    text-decoration: underline;
  }
`;

const EmptyGridViewLinkPortfoliosContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px
  margin-right: ${props => (props.isAddFlow ? "0px" : "34px")};
`;

const EmptyGridViewLinkPortfoliosView = styled(EmptyGridViewConnectAccountView)`
  flex: 1;
  margin: 6px;
  padding: 16px;
  background: ${props => props.theme.emptyGridViewTitleBG};
  border: ${props => props.theme.emptyGridViewTitleBR};
  box-sizing: border-box;
  cursor: pointer;
  justify-content: space-between;
  &: hover {
    background: ${props => props.theme.emptyGridViewTitleBGHover};
  }
`;

const PortfolioLinkingIconComponent = styled(PortfolioLinkingIcon)`
  path {
    fill: ${props => props.theme.svgDefaultColor};
  }
`;

const EmptyGridViewTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12.1px;
  font-feature-settings: "ss01" on;
  padding: 13px 11px 0;
  white-space: pre;
  align-self: flex-start;
  color: ${props => props.theme.emptyGridViewTitleColor};
`;

function EmptyGridViewDebtsComponent(props) {
  return (
    <EmptyGridView className={props.className}>
      {props.isReadOnlyWlClient === false && (
        <EmptyGridViewColumnContainer>
          <EmptyGridViewConnectAccountView
            onClick={e => props.handleInitialTipClick(contextMenuItemType.LINK)}
            height="240px"
          >
            <EmptyGridViewConnectAccountTitle position="top" padding="18px">
              {i18n.t("debtsComponent.emptyViewConnectLoansTitle")}
            </EmptyGridViewConnectAccountTitle>
            <EmptyGridViewConnectAccountIcon padding="15px">
              <LoansIconComponent />
            </EmptyGridViewConnectAccountIcon>
          </EmptyGridViewConnectAccountView>
          <EmptyGridViewConnectAccountView
            onClick={e => props.handleInitialTipClick(contextMenuItemType.LINK)}
            height="240px"
          >
            <EmptyGridViewConnectAccountIcon position="top" padding="16px">
              <CreditCardsIconComponent />
            </EmptyGridViewConnectAccountIcon>
            <EmptyGridViewConnectAccountTitle position="bottom" padding="20px">
              {i18n.t("debtsComponent.emptyViewConnectCreditCardsTitle")}
            </EmptyGridViewConnectAccountTitle>
          </EmptyGridViewConnectAccountView>
        </EmptyGridViewColumnContainer>
      )}
      <EmptyGridViewLinkPortfoliosContainer isAddFlow={props.isAddNewFlow}>
        <EmptyGridViewLinkPortfoliosView
          onClick={e => props.handleInitialTipClick(contextMenuItemType.CONNECT_PORTFOLIOS)}
          height="100%"
          width="272px"
        >
          <EmptyGridViewConnectAccountTitle position="top">
            {i18n.t("assetsComponent.emptyViewConnectPortfolios")}
          </EmptyGridViewConnectAccountTitle>
          <EmptyGridViewConnectAccountIcon>
            <PortfolioLinkingIconComponent />
          </EmptyGridViewConnectAccountIcon>
        </EmptyGridViewLinkPortfoliosView>
        <EmptyGridViewConnectAccountView
          onClick={e => props.handleInitialTipClick(null)}
          height="100%"
          width={"270px"}
          isColumnLayout
        >
          <EmptyGridViewTitle>{i18n.t("generic")}</EmptyGridViewTitle>
          <EmptyGridViewEnterManually>{i18n.t("debtsComponent.addViewManuallyDesc1")}</EmptyGridViewEnterManually>
        </EmptyGridViewConnectAccountView>
      </EmptyGridViewLinkPortfoliosContainer>

      {props.isAddNewFlow && (
        <AddViewEnterManuallyTip
          dangerouslySetInnerHTML={{
            __html: i18n.t("debtsComponent.addViewManuallyDesc2")
          }}
        />
      )}
    </EmptyGridView>
  );
}
export default EmptyGridViewDebtsComponent;
