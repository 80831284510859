import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import styled, { keyframes } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import i18n from "i18next";
import { ThemeSwitchComponent, useDarkModeActive } from "theme";
import {
  getUserProfilePhotoUrl,
  portfoliosSelector,
  userNameSelector,
  userEmailSelector,
  updateUserPreferences,
  updateDashboardAction,
  signOut,
  currentThemeMode,
  hasValidPortfolio,
  showRefreshingSelector,
  fetchPortfolios,
  currentPortfolioSelector,
  isAppInWhiteLabelMode,
  isSharedPortfolioUserSelector,
  userMaskAllValuesSelector,
  isAmcUser as isAmcUserSelector,
  useHistory,
  sharedPortfolioUserSelector,
  sharedPortfolioAccountTitleSelector,
  sharedPortfolioAccountSubtitleSelector,
  sharedPortfolioUsersSelector,
  userSelector,
  isPortfolioReadySelector
} from "@kubera/common";
import { routes } from "routes";
import UserProfileImage from "components/inputs/UserProfileImage";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import Logo from "components/labels/AppLogo";
import { ReactComponent as RefreshIcon } from "assets/images/refresh_icon.svg";
import AccountStatusMessage from "components/account_settings/AccountStatusMessage";
import DashboardComponent from "components/dashboard/DashboardComponent";
import RadioButton from "components/inputs/RadioButton";
import AccountSettingsComponent, { accountSettingsTabs } from "components/account_settings/AccountSettingsComponent";
import Spinner from "components/loader/Spinner";
import ClickableLink from "components/labels/DelayLink";
import { ReactComponent as BackArrow } from "assets/images/back_arrow.svg";
import { ReactComponent as OptionsIcon } from "assets/images/options.svg";
import SwitchAccountComponent from "components/multiuser/SwitchAccountComponent";

const Container = styled.div`
  z-index: 2;
  background: ${props => props.theme.mobileBackground};
  min-height: 60px;
  margin-bottom: 4px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-right: 20px;
`;

const LeftGroupContainer = styled.div`
  flex: 1;
`;

const PortfolioName = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${props => props.theme.mobileTxtColor};
  padding-top: 9px;
  margin-left: 20px;
  letter-spacing: -0.055em;
`;

const HistoryBackContainer = styled(ClickableLink)`
  height: 40px;
  width: 48px;
  margin-left: 5px;
  margin-top: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const HistoryBackBtn = styled(BackArrow)`
  height: 16px;
  margin-top: 5px;

  path {
    fill: ${props => props.theme.mobileTxtColorHistoryBackBtn};
  }
`;

const RefreshButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px;
`;

const RefreshButtonIcon = styled(RefreshIcon)`
  width: 20px;
  height: 20px;

  path {
    fill: ${props => props.theme.mobileRefreshButtonIconColor};
  }
`;
const SpinIt = styled(Spinner)`
  width: 18px;
  height: 18px;
`;

const ProfileImage = styled(UserProfileImage)`
  margin-left: 26px;
`;

const SideHeaderOverlay = styled(DialogOverlay)`
  align-items: flex-end;

  & #kubera-container-overlay {
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
  }
`;

const sideHeaderSlideAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  } `;

const SideHeaderDialog = styled(Dialog)`
  position: relative;
  overflow-y: auto;
  height: 100%;
  width: calc(100vw - 107px);
  min-width: 304px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
  margin: 0;
  box-shadow: 0px -6px 8px rgba(0, 0, 0, 0.25);
  border: 0;
  animation: ${sideHeaderSlideAnimation} 0.2s ease;
  background: ${props => props.theme.mobileBackgroundSidebar};
`;

const AppContainer = styled.div`
  width: 100%;
  margin-bottom: 18px;
`;

const SettingsSection = styled.div`
  width: 100%;
  padding-right: 20px;
  padding-bottom: 30px;
  padding-top: 24px;
  border-bottom: ${props => `1px solid ${props.theme.mobileSidebarSeperator}`};
`;

const MaskValesContainer = styled.div`
display : flex
flex-direction : column`;

const Notifications = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.theme.mobileTxtColor};
  margin-bottom: 24px;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const MaskValues = styled.div`
flex : 1
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.theme.mobileTxtColor};
  margin-bottom: 4px;
`;

const MaskValuesDescription = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.mobileTxtHeaderColorSemiLight};
  margin-bottom: 4px;
`;

const AppLogo = styled(Logo)`
  height: 40px;
  margin-top: 28px;
`;

const PortfolioMenuWrapper = styled.div`
  width: 100%;
`;

const PortfolioMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding-top: 30px;
  border-bottom: ${props => `1px solid ${props.theme.mobileSidebarSeperator}`};
`;

const PortfolioMenuItem = styled.div`
  font-weight: ${props => (props.isSelected ? "bold" : null)};
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.theme.mobileTxtColor};
  margin-bottom: 30px;
`;

const SideHeaderFooter = styled.div`
  width: 100%;
`;

const UserDetailsContainer = styled.div`
  display: flex;
  width: 100%;
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  border-bottom: ${props => `1px solid ${props.theme.mobileSidebarSeperator}`};
`;

const UserDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 30px;
`;

const SwitchAccountContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-right: -40px;
`;

const SwitchAccountButton = styled(OptionsIcon)`
  margin-top: 12px;
  path {
    fill: ${props => props.theme.mobileTxtColor};
  }
`;

const UserName = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.theme.mobileTxtColor};
  margin-bottom: 4px;
`;

const UserEmail = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.mobileTxtHeaderColorSemiLight};
  margin-bottom: 4px;
`;

const AccountStatusContainer = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  color: ${props => props.theme.mobileTxtHeaderColorSemiLight};
`;

const ThemeSelector = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.theme.mobileTxtColor};
  padding-bottom: 6px;
  padding-top: 6px;
  margin-bottom: 24px;
`;

const SignOutAction = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.theme.mobileTxtColor};
  padding: 3px 0;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ThemeSelectOverlay = styled(DialogOverlay)`
  & #kubera-container-overlay {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 30px;
  }
`;

const ThemeSelectDialog = styled(Dialog)`
  position: relative;
  overflow-y: auto;
  padding: 40px 30px;
  width: 100%;
  color: ${props => props.theme.mobileTxtColorOverlay};
`;

const ThemeSelectTitle = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  margin-bottom: 17px;
`;

const RadioOption = styled(RadioButton)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  border: ${props => `1px solid ${props.theme.mobileBorderSeperatorColor}`};
  border-bottom: ${props => (!props.isLastItem ? 0 : null)}
  height: 55px;
  padding: 15px 15px 15px 0;

  div {
    font-size: 16px;
    line-height: 150%;
  }

  path {
    fill: ${props => props.theme.mobileTxtColorOverlay};
  }
`;

const MaskValuesBadge = styled.div`
  width: 7px;
  height: 7px;
  margin-top: ${props => (props.marginTop ? props.marginTop : "none")};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "none")};
  //border: 3px solid ${props => props.theme.badgeBorderCLR};
  border-radius: 50%;
  background-color: #2f8fff;'
`;

const MaskLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

let profileImageUrlUserMapStored = {};

const isWhiteLabelSite = isAppInWhiteLabelMode();

const MobileHeader = (props, ref) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isDarkModeActive = useDarkModeActive();

  const useMaskedValues = useSelector(userMaskAllValuesSelector);
  const isRefreshing = useSelector(showRefreshingSelector);
  const portfolio = useSelector(currentPortfolioSelector);
  const allPortfolios = useSelector(portfoliosSelector);
  const userName = useSelector(userNameSelector);
  const userEmail = useSelector(userEmailSelector);
  const themeMode = useSelector(currentThemeMode);
  const isSharedPortfolioAccountUser = useSelector(isSharedPortfolioUserSelector);
  const isAmcUser = useSelector(isAmcUserSelector);

  const [profileImageUrlUserMap, setProfileImageUrlUserMap] = useState(profileImageUrlUserMapStored);
  const [isSideHeaderOpen, setIsSideHeaderOpen] = useState();
  const [isThemeSelectOpen, setIsThemeSelectOpen] = useState();
  const [filteredPortfolios, setFilteredPortfolios] = useState([]);

  const user = useSelector(userSelector);
  const sharedPortfolioUsers = useSelector(sharedPortfolioUsersSelector);
  const sharedPortfolioUser = useSelector(sharedPortfolioUserSelector);
  const sharedPortfolioAccountTitle = useSelector(sharedPortfolioAccountTitleSelector);
  const sharedPortfolioAccountSubtitle = useSelector(state =>
    sharedPortfolioAccountSubtitleSelector(state, sharedPortfolioUser)
  );
  const [showSwitchAccount, setShowSwitchAccount] = useState(false);

  const isPortfolioReady = useSelector(isPortfolioReadySelector);

  const onOpenSideHeader = () => {
    setIsSideHeaderOpen(true);
  };

  const onDismissSideHeader = () => {
    setIsSideHeaderOpen(false);
  };

  const getSortedPortfolios = () => {
    if (!allPortfolios) return [];

    allPortfolios.sort((a, b) => a.tsCreated - b.tsCreated);
    return allPortfolios;
  };

  const onSignOut = () => {
    signOut();
  };

  const onPortfolioSelection = portfolio => {
    onDismissSideHeader();
    DashboardComponent.showPortfolio(history, location, portfolio.id);
  };

  const toggleUserMaskValues = () => {
    const toggleMaskAllValues = !useMaskedValues;
    dispatch(updateUserPreferences({ maskAllValuesMobile: toggleMaskAllValues }, undefined, undefined, true));
    dispatch(updateDashboardAction(null));
    onDismissSideHeader();
  };

  const onThemeSelectOpen = () => {
    setIsThemeSelectOpen(true);
    onDismissSideHeader();
  };

  const onThemeSelectDismiss = () => {
    setIsThemeSelectOpen(false);
  };

  const onSubscriptionOpen = () => {
    if (isSharedPortfolioAccountUser === true || isAmcUser === true) {
      return;
    }

    onDismissSideHeader();
    AccountSettingsComponent.show(history, location, accountSettingsTabs.SUBSCRIPTION);
  };

  const onNotificationsOpen = () => {
    onDismissSideHeader();
    AccountSettingsComponent.show(history, location, accountSettingsTabs.NOTIFICATIONS);
  };

  const onRefreshButtonClick = e => {
    if (isRefreshing === false) {
      dispatch(fetchPortfolios(false, true));
    }
  };

  const portfolios = getSortedPortfolios();
  const hasMultiplePortfolios = filteredPortfolios && filteredPortfolios.length > 1;

  useEffect(() => {
    if (!user === true) {
      return;
    }

    dispatch(
      getUserProfilePhotoUrl(
        url => {
          profileImageUrlUserMapStored[user.id] = url;
          setProfileImageUrlUserMap(profileImageUrlUserMapStored);
        },
        apiError => {}
      )
    );
  }, [dispatch, user]);

  useEffect(() => {
    sharedPortfolioUsers.forEach(user => {
      dispatch(
        getUserProfilePhotoUrl(
          url => {
            profileImageUrlUserMapStored[user.id] = url;
            setProfileImageUrlUserMap(profileImageUrlUserMapStored);
          },
          apiError => {},
          user.picture
        )
      );
    });
  }, [dispatch, sharedPortfolioUsers]);

  useEffect(() => {
    const filteredPortfolios = portfolios.filter((portfolio, index) => hasValidPortfolio(portfolio));

    setFilteredPortfolios(filteredPortfolios);
  }, [portfolios]);

  useImperativeHandle(ref, () => ({
    handleDismissSideHeader: onDismissSideHeader
  }));

  return (
    <Container>
      <HeaderContainer>
        <LeftGroupContainer>
          {location.pathname === routes.MOBILENAV && !portfolio === false && (
            <PortfolioName>{portfolio.name}</PortfolioName>
          )}
          {location.pathname !== routes.MOBILENAV && (
            <HistoryBackContainer to={routes.MOBILENAV} direction="right">
              <HistoryBackBtn />
            </HistoryBackContainer>
          )}
        </LeftGroupContainer>
        <RefreshButton onClick={onRefreshButtonClick}>
          {isRefreshing === false && <RefreshButtonIcon />}
          {isRefreshing === true && <SpinIt thickness={3} darkTheme={isDarkModeActive === false} />}
        </RefreshButton>
        {!props.isInViewMode && (
          <ProfileImage
            data-private
            onClick={onOpenSideHeader}
            data-cy="profileImage"
            imageSize={36}
            userName={userName}
            imageUrl={!user === false ? profileImageUrlUserMap[user.id] : null}
            secondaryUserName={sharedPortfolioUser ? sharedPortfolioUser.name : null}
            secondaryImageUrl={sharedPortfolioUser ? profileImageUrlUserMap[sharedPortfolioUser.id] : null}
          />
        )}
        {useMaskedValues && <MaskValuesBadge marginLeft="2px" />}
      </HeaderContainer>
      {isSideHeaderOpen && !isThemeSelectOpen && (
        <SideHeaderOverlay onDismiss={onDismissSideHeader}>
          <SideHeaderDialog>
            <AppContainer>
              <AppLogo hasNoLink />
            </AppContainer>
            <UserDetailsContainer
              disabled={sharedPortfolioUsers.length === 0}
              onClick={e => {
                setShowSwitchAccount(true);
              }}
            >
              <UserDetails>
                <UserName>{isSharedPortfolioAccountUser ? sharedPortfolioAccountTitle : userName}</UserName>
                <UserEmail>{isSharedPortfolioAccountUser ? sharedPortfolioAccountSubtitle : userEmail}</UserEmail>
              </UserDetails>
              {sharedPortfolioUsers.length > 0 && (
                <SwitchAccountContainer
                  onClick={e => {
                    setShowSwitchAccount(true);
                  }}
                >
                  <SwitchAccountButton />
                </SwitchAccountContainer>
              )}
            </UserDetailsContainer>
            <PortfolioMenuWrapper>
              {hasMultiplePortfolios && (
                <PortfolioMenu>
                  {filteredPortfolios.map((portfolioItem, index) => (
                    <PortfolioMenuItem
                      key={index}
                      isSelected={!portfolio === false && portfolioItem.id === portfolio.id}
                      onClick={() => {
                        onPortfolioSelection(portfolioItem);
                      }}
                    >
                      {portfolioItem.name}
                    </PortfolioMenuItem>
                  ))}
                </PortfolioMenu>
              )}
            </PortfolioMenuWrapper>
            <SideHeaderFooter>
              {isPortfolioReady && (
                <SettingsSection>
                  {!isWhiteLabelSite && !isSharedPortfolioAccountUser && (
                    <Notifications>
                      {i18n.t("accountSettings.subscriptionTab")}
                      <AccountStatusContainer onClick={onSubscriptionOpen}>
                        <AccountStatusMessage isPriceOptionHidden />
                      </AccountStatusContainer>
                    </Notifications>
                  )}
                  <ThemeSelector onClick={onThemeSelectOpen}>Dark Mode</ThemeSelector>
                  <Notifications onClick={onNotificationsOpen}>
                    {i18n.t("accountSettings.notificationsTab")}
                  </Notifications>
                  <MaskValesContainer onClick={toggleUserMaskValues}>
                    <MaskLabelContainer>
                      <MaskValues>
                        {useMaskedValues ? i18n.t("unMaskValuesLabel") : i18n.t("maskValuesLabel")}
                      </MaskValues>
                      {useMaskedValues && <MaskValuesBadge />}
                    </MaskLabelContainer>
                    <MaskValuesDescription>{i18n.t("maskValuesDescription")}</MaskValuesDescription>
                  </MaskValesContainer>
                </SettingsSection>
              )}
              <SignOutAction onClick={onSignOut}>{i18n.t("signOut")}</SignOutAction>
            </SideHeaderFooter>
          </SideHeaderDialog>
        </SideHeaderOverlay>
      )}
      {isThemeSelectOpen && (
        <ThemeSelectOverlay onDismiss={onThemeSelectDismiss}>
          <ThemeSelectDialog>
            <ThemeSelectTitle>Dark Mode</ThemeSelectTitle>
            <ThemeSwitchComponent mode="default">
              <RadioOption
                label={i18n.t("off")}
                value="default"
                checked={themeMode === "default"}
                onChange={() => {}}
              />
            </ThemeSwitchComponent>
            <ThemeSwitchComponent mode="dark">
              <RadioOption label={i18n.t("on")} value="dark" checked={themeMode === "dark"} onChange={() => {}} />
            </ThemeSwitchComponent>
            <ThemeSwitchComponent mode="sts">
              <RadioOption
                label={i18n.t("mobileHeader.sts")}
                value="sts"
                checked={themeMode === "sts"}
                onChange={() => {}}
              />
            </ThemeSwitchComponent>
            <ThemeSwitchComponent mode="sp">
              <RadioOption
                label={i18n.t("mobileHeader.sp")}
                value="sp"
                checked={themeMode === "sp"}
                onChange={() => {}}
                isLastItem
              />
            </ThemeSwitchComponent>
          </ThemeSelectDialog>
        </ThemeSelectOverlay>
      )}
      {showSwitchAccount === true && <SwitchAccountComponent onDismiss={() => setShowSwitchAccount(false)} />}
    </Container>
  );
};

export default forwardRef(MobileHeader);
