import React from "react";
import styled from "styled-components";
import searchIcon from "assets/images/search_icon.svg";
import Spinner from "components/loader/Spinner";

const Container = styled.label`
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  align-items: center;
  background-color: #fff;
  cursor: text;
`;

const Icon = styled.div`
  width: 12px;
  height: 12px;
  outline: 0;
  padding: 0;
  border: 0;
  margin-left: 10px;
  background-color: transparent;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
`;

const Input = styled.input`
  flex: 1;
  height: 45px;
  margin: 0;
  margin-left: 6px;
  padding: 0;
  outline: 0;
  border: 0;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;

  ::placeholder {
    color: ${props => props.theme.searchInputPlaceholderColor};
    font-size: 14px;
  }
`;

const SearchSpinner = styled(Spinner)`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  visibility: ${props => (props.isLoading ? null : "hidden")};
`;

class SearchInput extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = props.outerRef || React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    this.handleSearchTermChange(e.target.value);
  }

  handleSearchTermChange(value) {
    this.props.onChange(value);
  }

  render() {
    const { className, type, onChange, value, isLoading, loaderThickness, ...other } = this.props;

    return (
      <Container className={className}>
        <Icon />
        <Input
          {...other}
          value={value === null ? "" : value}
          ref={this.inputRef}
          type="text"
          onChange={this.handleInputChange}
          autoComplete="new-password"
          spellCheck="false"
          data-1p-ignore
        />
        <SearchSpinner thickness={loaderThickness} isLoading={!isLoading === false} />
      </Container>
    );
  }
}

export default SearchInput;
