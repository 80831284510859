import React from "react";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { hashParams, modalValues } from "routes";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import i18n from "i18next";
import CurrencyLabel from "components/labels/CurrencyLabel";
import ChangeLabel from "components/labels/ChangeLabel";
import {
  currentPortfolioSelector,
  pastValueInterval,
  userPreferencesSelector,
  getChangeTotalsWithContributorsForCurrentPortfolio,
  portfolioTotalForCategory,
  isMobile,
  updateUserPreferences
} from "@kubera/common";
import { category } from "components/dashboard/DashboardComponent";
import ValueChangeTable from "components/table/ValueChangeTable";
import { connect } from "react-redux";
import Loader from "components/loader/Loader";
import MobilePortfolioTotalChangeDialog from "components/portfolio/MobilePortfolioTotalChangeDialog";

const ChangeDialog = styled(Dialog)`
  width: 627px;
  min-height: 633px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 44px 50px 80px 50px;
  width: 100%;
  position: relative;
`;

const CurrentTotal = styled(CurrencyLabel)`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.015em;
  font-feature-settings: "ss01" on, "calt" off;

  .child-container {
    justify-content: flex-start;
  }
`;

const ChangeLoader = styled(Loader)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
`;

const ChangeTabs = styled(Tabs)`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
`;

const ChangeTabList = styled(TabList)`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding-inline-start: 0;
  padding-bottom: 8px;
`;

const ChangeTab = styled(Tab)`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.35);
  padding-bottom: 8px;
  margin-right: 25px;
  border: 0;
  cursor: pointer;
  z-index: 100;

  &.is-selected {
    color: black;
    border-bottom: 2px solid rgba(0, 0, 0, 1);
  }
`;

const ChangeTabPanel = styled(TabPanel)`
  margin-top: -10px;
  display: none;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex: 1;

  &.is-selected {
    display: flex;
  }
`;

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TotalChange = styled(ChangeLabel)`
  margin-top: 24px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;

  .child-container {
    justify-content: flex-start;
  }
`;

const Updating = styled.div`
  display: flex;
  visibility: ${props => (props.hidden === true ? "hidden" : "visible")};
  margin-bottom: 5px;
  font-size: 10px;
  line-height: 12px;
  font-feature-settings: "ss01" on;
  color: rgba(0, 0, 0, 0.4);
`;

class PortfolioTotalChangeDialog extends React.Component {
  static show = (history, location, interval, category) => {
    history.push({
      ...location,
      hash: `${hashParams.MODAL}=${modalValues.GRID_CHANGE}&${hashParams.TAB}=${interval}&${hashParams.CATEGORY}=${category}`
    });
  };

  constructor(props) {
    super(props);
    this.tabs = this.getTabs();
    const selectedIndex = this.tabs.findIndex(item => item.interval === props.interval);
    this.state = { tabIndex: selectedIndex };

    this.setTab = this.setTab.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
  }

  handleOverlayDismiss() {
    if (this.props.onDismiss) {
      this.props.onDismiss();
    } else {
      DialogOverlay.dismiss(this.props.history, this.props.location);
    }

    if (
      !this.props.userPreferences === false &&
      this.props.userPreferences.isContributingCustodiansTipShown === false
    ) {
      this.props.updateUserPreferences({ isContributingCustodiansTipShown: true });
    }
  }

  setTab(tabIndex) {
    this.setState({
      tabIndex
    });
  }

  getTabs() {
    var tabs = [];

    for (const key of [
      pastValueInterval.DAY,
      pastValueInterval.WEEK,
      pastValueInterval.MONTH,
      pastValueInterval.YEAR,
      pastValueInterval.YTD
    ]) {
      const total = this.props.changeTotalsAndContributors.changes[this.getChangeDataKey(key)].total;

      if (
        this.props.changeTotalsAndContributors.changes[this.getChangeDataKey(key)].isInsidePortfolioStartDate &&
        (!total === false || !this.props.changeTotalsAndContributors.currentTotal === false || this.isUpdating(key))
      ) {
        tabs.push({
          interval: key,
          panelComponent: this.getTabPanel(key)
        });
      }
    }
    return tabs;
  }

  isUpdating(forInterval) {
    if (forInterval === pastValueInterval.DAY) {
      return this.props.changeData.isDayChangeFetching;
    }
    return this.props.changeData.isAllChangeFetching;
  }

  getChangeDataKey(forPastInterval) {
    switch (forPastInterval) {
      case pastValueInterval.DAY:
        return "day";
      case pastValueInterval.WEEK:
        return "week";
      case pastValueInterval.MONTH:
        return "month";
      case pastValueInterval.YEAR:
        return "year";
      case pastValueInterval.YTD:
        return "ytd";
      default:
        return null;
    }
  }

  getTabPanel(forInterval) {
    const currency = this.props.currency;
    const changeDataKey = this.getChangeDataKey(forInterval);
    const totalStartValue = this.props.changeTotalsAndContributors.changes[changeDataKey].total;

    if (!this.props.changeData.data === true || (this.isUpdating(forInterval) === true && !totalStartValue === true)) {
      return (
        <PanelContainer>
          <ChangeLoader />
        </PanelContainer>
      );
    }

    const valueChangeContributions = this.props.changeTotalsAndContributors.changes[changeDataKey].contributors;
    const totalEndValue = this.props.changeTotalsAndContributors.currentTotal;

    return (
      <PanelContainer>
        <TotalChange
          currency={this.props.currency}
          startValue={totalStartValue}
          endValue={totalEndValue}
          disableShortFormat={true}
          disableColor={this.props.category === category.DEBT}
          alignPosition="left"
        />
        <Updating hidden={this.isUpdating(forInterval) === false}>{i18n.t("updating")}</Updating>
        <ValueChangeTable
          rows={valueChangeContributions}
          currency={currency}
          disableColor={this.props.category === category.DEBT}
          disableRoundDown={true}
        />
      </PanelContainer>
    );
  }

  getTabTitle(forInterval) {
    switch (forInterval) {
      case pastValueInterval.DAY:
        return i18n.t("dayChange");
      case pastValueInterval.WEEK:
        return i18n.t("weekChange");
      case pastValueInterval.MONTH:
        return i18n.t("monthChange");
      case pastValueInterval.YEAR:
        return i18n.t("yearChange");
      case pastValueInterval.YTD:
        return i18n.t("ytdChange");
      default:
        return forInterval;
    }
  }

  render() {
    const currency = this.props.currency;
    const tabs = this.getTabs();

    return (
      <DialogOverlay onDismiss={this.handleOverlayDismiss}>
        <ChangeDialog className={this.props.className}>
          <Container>
            <CurrentTotal
              currency={currency}
              value={this.props.totalValue}
              maxLongFormatValue={99999}
              abbreviate={false}
              roundDown={true}
              isAnimationAllowed
              useScrollAnimation
            />
            <ChangeTabs
              selectedTabClassName="is-selected"
              selectedTabPanelClassName="is-selected"
              selectedIndex={this.state.tabIndex}
              onSelect={this.setTab}
            >
              <ChangeTabList>
                {tabs.map((tab, index) => (
                  <ChangeTab data-cy={"changeTab" + index} key={index}>
                    {this.getTabTitle(tab.interval)}
                  </ChangeTab>
                ))}
              </ChangeTabList>
              {tabs.map((tab, index) => (
                <ChangeTabPanel key={index}>{tab.panelComponent}</ChangeTabPanel>
              ))}
            </ChangeTabs>
          </Container>
        </ChangeDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentPortfolio: currentPortfolioSelector(state),
  userPreferences: userPreferencesSelector(state),
  totalValue: portfolioTotalForCategory(state, undefined, props.category),
  changeTotalsAndContributors: getChangeTotalsWithContributorsForCurrentPortfolio(state, props.category)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences
};

export default isMobile()
  ? MobilePortfolioTotalChangeDialog
  : connect(
      mapStateToProps,
      mapDispatchToProps
    )(withRouter(PortfolioTotalChangeDialog));
