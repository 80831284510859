import React from "react";
import styled from "styled-components";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import i18n from "i18next";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  CurrencyCellData,
  PercentageCellData,
  cellType
} from "components/grid/GridDataModel";
import {
  getUuid,
  unarchiveCustodian,
  deleteCustodianPermanently,
  getExchangeRate,
  getTickerUsingId,
  irrTypes,
  tickerTypes,
  accountCurrentTsSelector,
  accountEndTsSelector,
  accountGraceTsSelector,
  accountStartTsSelector,
  accountSubscriptionStatusSelector,
  accountSubscriptionIsActiveSelector,
  isAppInViewMode
} from "@kubera/common";
import { connect } from "react-redux";
import { withRouter } from "@kubera/common";
import { category } from "components/dashboard/DashboardComponent";
import { contextMenuItemType, getRestoreArchivedItemsContextMenuItem } from "components/contextmenu/ContextMenu";
import CustodianDetailsWrapperComponent from "components/custodian_details/CustodianDetailsWrapperComponent";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import DeferredPromise from "utilities/DeferredPromise";
import CurrencyLabel from "components/labels/CurrencyLabel";

const Container = styled.div``;

const Grid = styled(GridComponentWrapper)``;

const EmptyGrid = styled.div`
  margin-top: 5px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  white-space: pre-wrap;
`;

const IrrSummaryCell = styled.div`
  display: flex;
  align-items: center;
  z-index: 100;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  text-align: right;
  font-feature-settings: "ss01" on;
`;

const IrrCellLabel = styled.div`
  display: flex;
  margin-left: ${props => (props.applyMargin === true ? "5px" : "")};
  color: ${props => props.theme.irrCellLabelCLR};
  font-style: ${props => (props.updated === true ? "normal" : "italic")};
`;

const IrrCellValue = styled(CurrencyLabel)`
  display: flex;
  color: ${props => props.theme.irrCellValueCLR};
  margin-left: 5px;
  font-style: ${props => (props.updated === true ? "normal" : "italic")};
`;

class ArchivedCustodiansComponent extends React.Component {
  static dismiss = (history, location) => {
    history.push({
      ...location,
      hash: ""
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      gridData: this.getGridData(this.props.portfolio.currency, this.props.custodians),
      isDeleteConfirmModalVisible: false
    };

    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
    this.handleDetailsClick = this.handleDetailsClick.bind(this);
    this.handleDialogPositiveButtonClick = this.handleDialogPositiveButtonClick.bind(this);
    this.handleDialogNegativeButtonClick = this.handleDialogNegativeButtonClick.bind(this);

    this.CustodianDetailsWrapperRef = React.createRef();
    this.deleteConfirmationPromise = new DeferredPromise();
  }

  isReadOnly() {
    return isAppInViewMode() === true || this.props.portfolio.write === 0;
  }

  getGridData(currency, custodians) {
    var rows = [];

    for (const [index, custodian] of custodians.entries()) {
      const currentRow = this.getEmptyRow(`${index + 1}`);

      currentRow.id = custodian.id;
      currentRow.isLinked = this.props.accountSubscriptionIsActive && !custodian.linkType === false;
      currentRow.tsArchive = custodian.tsArchive;
      if (this.props.category === category.ASSET) {
        currentRow.cells[0].value = custodian.name;
        currentRow.cells[0].description = custodian.description;
        currentRow.cells[2].value = custodian.value;
        currentRow.irrType = custodian.irrType;
        currentRow.irr = custodian.irr;

        currentRow.cells[1].value = currentRow.getIrr();
        currentRow.cells[2].cost = custodian.cost;

        if (custodian.rate && custodian.valueTickerId === 171) {
          const rateParsed = JSON.parse(custodian.rate);
          currentRow.cells[2].useRateFromExchangeRateDetails = true;
          currentRow.cells[2].exchangeRateDetails = `{"tickerId":${rateParsed.t},"rate":${rateParsed.p}}`;
        }

        if (custodian.valueTickerId) {
          currentRow.cells[2].currency = getTickerUsingId(custodian.valueTickerId).shortName;
        }
        if (custodian.valueExchangeRate) {
          currentRow.cells[2].exchangeRateDetails = custodian.valueExchangeRate;
        }

        if (custodian.costTickerId) {
          currentRow.cells[2].costTickerId = custodian.costTickerId;
        }
        if (custodian.costExchangeRate) {
          currentRow.cells[2].costExchangeRate = custodian.costExchangeRate;
        }
      } else if (this.props.category === category.DEBT) {
        currentRow.cells[0].value = custodian.name;
        currentRow.cells[0].description = custodian.description;
        currentRow.cells[1].value = custodian.value;
        if (custodian.rate && custodian.valueTickerId === 171) {
          const rateParsed = JSON.parse(custodian.rate);
          currentRow.cells[1].useRateFromExchangeRateDetails = true;
          currentRow.cells[1].exchangeRateDetails = `{"tickerId":${rateParsed.t},"rate":${rateParsed.p}}`;
        }
        if (custodian.valueTickerId) {
          currentRow.cells[1].currency = getTickerUsingId(custodian.valueTickerId).shortName;
        }
        if (custodian.valueExchangeRate) {
          currentRow.cells[1].exchangeRateDetails = custodian.valueExchangeRate;
        }
      } else if (this.props.category === category.INSURANCE) {
        currentRow.cells[0].value = custodian.name;
        currentRow.cells[0].description = custodian.description;
        currentRow.cells[1].value = custodian.accountNumber;
        currentRow.cells[2].value = custodian.cost;
        currentRow.cells[3].value = custodian.value;
        currentRow.cells[4].value = custodian.star;

        if (custodian.costTickerId) {
          currentRow.cells[2].currency = getTickerUsingId(custodian.costTickerId).shortName;
        }
        if (custodian.costExchangeRate) {
          currentRow.cells[2].exchangeRateDetails = custodian.costExchangeRate;
        }
        if (custodian.valueTickerId) {
          currentRow.cells[3].currency = getTickerUsingId(custodian.valueTickerId).shortName;
        }
        if (custodian.valueExchangeRate) {
          currentRow.cells[3].exchangeRateDetails = custodian.valueExchangeRate;
        }
      }
      rows.push(currentRow);
    }

    const section = this.getEmptySection(0, "1");
    section.rows = rows.filter(row => row.isComplete() === true);

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection(forIndex, sortKey) {
    var sectionData = null;

    if (this.props.category === category.ASSET) {
      const nameColumn = new GridColumnData("", true, false, false);
      const irrColumn = new GridColumnData("IRR", false, false, false);
      irrColumn.hideTitleIfEmpty = true;
      const valueColumn = new GridColumnData("Value", true, false, false);
      const optionsColumn = new GridColumnData(null, true, false, true);

      let columns = [nameColumn, irrColumn, valueColumn];
      if (this.isReadOnly() === false) {
        columns.push(optionsColumn);
      }

      sectionData = new GridSectionData(
        getUuid(),
        sortKey,
        "Section " + (forIndex + 1),
        [this.getEmptyRow("1"), this.getEmptyRow("2"), this.getEmptyRow("3")],
        columns,
        undefined,
        2,
        false
      );
    } else if (this.props.category === category.DEBT) {
      const nameColumn = new GridColumnData("", true, false, false);
      const balanceColumn = new GridColumnData("Balance", true, false, false);
      const optionsColumn = new GridColumnData(null, true, false, true);

      let columns = [nameColumn, balanceColumn];
      if (this.isReadOnly() === false) {
        columns.push(optionsColumn);
      }

      sectionData = new GridSectionData(
        getUuid(),
        sortKey,
        "Section " + (forIndex + 1),
        [this.getEmptyRow("1"), this.getEmptyRow("2"), this.getEmptyRow("3")],
        columns,
        1,
        1,
        false
      );
    } else if (this.props.category === category.INSURANCE) {
      const nameColumn = new GridColumnData("", true, false, false);
      const policyNumberColumn = new GridColumnData("Policy Number", true, false, false);
      const premiumColumn = new GridColumnData("Annual Premium", true, false, false);
      const insuredValueColumn = new GridColumnData("Insured Value", true, false, false);
      const optionsColumn = new GridColumnData(null, true, false, true);

      let columns = [nameColumn, policyNumberColumn, premiumColumn, insuredValueColumn];
      if (this.isReadOnly() === false) {
        columns.push(optionsColumn);
      }

      sectionData = new GridSectionData(
        getUuid(),
        sortKey,
        "Section " + (forIndex + 1),
        [this.getEmptyRow("1"), this.getEmptyRow("2"), this.getEmptyRow("3")],
        columns,
        2,
        3,
        false
      );
    }

    if (sectionData) {
      sectionData.showFooter = false;
    }
    return sectionData;
  }

  getEmptyRow(sortKey) {
    var rowData = null;

    if (this.props.category === category.ASSET) {
      const nameCell = new GridCellData(cellType.TEXT, "Asset", null);
      const irrCell = new PercentageCellData(cellType.PERCENTAGE, "IRR", null, undefined, undefined);
      irrCell.getAccessoryView = (sheetIndex, sectionIndex, rowIndex, cellIndex) => {
        //copied almost straight from AssetsComponent
        const row = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex];

        if (row.irrType === irrTypes.COSTBASIS) {
          const cost = row.cells[2].getCostInCurrency(this.props.portfolio.currency); //only difference
          if (!cost === true) {
            return null;
          }
          return (
            <IrrSummaryCell>
              <IrrCellLabel updated={row.isUpdated}>{i18n.t("cost")}</IrrCellLabel>
              <IrrCellValue
                updated={row.isUpdated}
                value={cost}
                currency={this.props.portfolio.currency}
                roundDown={true}
                maxLongFormatValue={999999}
              />
            </IrrSummaryCell>
          );
        } else if (row.irrType === irrTypes.CASHFLOW) {
          const irrDetails = row.getIrrDetails();
          if (!irrDetails === true) {
            return null;
          }

          const cashTicker = getTickerUsingId(irrDetails.all.cashTickerId);
          const cashExchangeRate = getExchangeRate(cashTicker.shortName, this.props.portfolio.currency);
          const totalCashIn = irrDetails.all.cashIn * cashExchangeRate;
          const totalCashOut = irrDetails.all.cashOut * cashExchangeRate;

          if (!totalCashIn === true && !totalCashOut === true) {
            return null;
          }
          return (
            <IrrSummaryCell>
              {!totalCashIn === false && (
                <>
                  <IrrCellLabel updated={row.isUpdated}>{i18n.t("in")}</IrrCellLabel>
                  <IrrCellValue
                    updated={row.isUpdated}
                    value={totalCashIn}
                    currency={this.props.portfolio.currency}
                    roundDown={true}
                    maxLongFormatValue={999999}
                  />
                </>
              )}
              {!totalCashOut === false && (
                <>
                  <IrrCellLabel updated={row.isUpdated} applyMargin={!totalCashIn === false}>
                    {i18n.t("out")}
                  </IrrCellLabel>
                  <IrrCellValue
                    updated={row.isUpdated}
                    value={totalCashOut}
                    currency={this.props.portfolio.currency}
                    roundDown={true}
                    maxLongFormatValue={999999}
                  />
                </>
              )}
            </IrrSummaryCell>
          );
        }
      };

      const valueCell = new CurrencyCellData(cellType.CURRENCY, "Value", null, this.props.portfolio.currency);
      valueCell.supportedTickerTypes = [
        tickerTypes.FIAT,
        tickerTypes.CRYPTO,
        tickerTypes.STOCK,
        tickerTypes.FUND,
        tickerTypes.BOND,
        tickerTypes.DERIVATIVE,
        tickerTypes.INDEX
      ];
      const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
      const cells = [nameCell, irrCell, valueCell, optionsCell];

      rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
        const value = cells[2].value;

        if (value !== null && value !== undefined) {
          return true;
        }
        return false;
      });
    } else if (this.props.category === category.DEBT) {
      const nameCell = new GridCellData(cellType.TEXT, "Debt", null);
      const balanceCell = new CurrencyCellData(cellType.CURRENCY, "Balance", null, this.props.portfolio.currency);
      const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
      const cells = [nameCell, balanceCell, optionsCell];
      rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
        const balance = cells[1].value;

        if (balance !== null && balance !== undefined) {
          return true;
        }
        return false;
      });
    } else if (this.props.category === category.INSURANCE) {
      const nameCell = new GridCellData(cellType.TEXT, "Insurance Policy Name", null);
      const policyNumberCell = new GridCellData(cellType.TEXT, "Number", null);
      const premiumCell = new CurrencyCellData(cellType.CURRENCY, "Premium", null, this.props.portfolio.currency);
      const insuredValueCell = new CurrencyCellData(cellType.CURRENCY, "Value", null, this.props.portfolio.currency);
      const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
      const cells = [nameCell, policyNumberCell, premiumCell, insuredValueCell, optionsCell];
      rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
        const policyNumber = cells[1].value;
        const insuredValue = cells[3].value;

        if (policyNumber && insuredValue !== null && insuredValue !== undefined) {
          return true;
        }
        return false;
      });
    }

    if (rowData) {
      rowData.getContextMenuItems = row => {
        if (this.isReadOnly()) {
          return [];
        }
        return [[getRestoreArchivedItemsContextMenuItem(rowData), contextMenuItemType.DELETE_PERMANENTLY]];
      };
    }

    return rowData;
  }

  handleChange(newGridData) {
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (menuItem.id === contextMenuItemType.UNARCHIVE.id) {
      this.props.unarchiveCustodian(this.props.portfolio.id, row.id);
    } else if (menuItem.id === contextMenuItemType.DELETE_PERMANENTLY.id) {
      setTimeout(async () => {
        this.setState({
          isDeleteConfirmModalVisible: true
        });
        const isConfirm = await this.deleteConfirmationPromise.catch(err => null);
        if (isConfirm) {
          this.props.deleteCustodianPermanently(row.id);
        }
      });

      this.deleteConfirmationPromise = new DeferredPromise();
      return this.deleteConfirmationPromise;
    }
  }

  handleDetailsClick(sheetIndex, sectionIndex, rowIndex, row) {
    this.CustodianDetailsWrapperRef.current.show(sheetIndex, sectionIndex, rowIndex, row);
  }

  getEmptyListMessage() {
    if (this.props.category === category.ASSET) {
      return i18n.t("archivedComponent.emptyListAssets");
    } else if (this.props.category === category.DEBT) {
      return i18n.t("archivedComponent.emptyListDebts");
    }
    return "";
  }

  handleDialogPositiveButtonClick() {
    this.setState({
      isDeleteConfirmModalVisible: false
    });
    this.deleteConfirmationPromise.resolve(true);
  }

  handleDialogNegativeButtonClick() {
    this.setState({
      isDeleteConfirmModalVisible: false
    });
    this.deleteConfirmationPromise.resolve(false);
  }

  render() {
    const isCustodianListEmpty = this.state.gridData.sheets[0].sections[0].rows.length === 0;
    const { gridData, isDeleteConfirmModalVisible } = this.state;

    return (
      <Container className={this.props.className}>
        <CustodianDetailsWrapperComponent
          gridData={gridData}
          handleRowContextMenuSelection={this.handleRowContextMenuSelection}
          history={this.props.history}
          location={this.props.location}
          ref={this.CustodianDetailsWrapperRef}
          category={this.props.category}
        />
        {isCustodianListEmpty === true && <EmptyGrid>{this.getEmptyListMessage()}</EmptyGrid>}
        {isCustodianListEmpty === false && (
          <Grid
            gridData={gridData}
            onChange={this.handleChange.bind(this)}
            onRowContextMenuSelection={this.handleRowContextMenuSelection}
            onDetailsClick={this.handleDetailsClick}
          />
        )}
        {isDeleteConfirmModalVisible && (
          <ConfirmationDialog
            title={i18n.t("archivedComponent.deleteModalTitle")}
            description={i18n.t("archivedComponent.deleteModalDesc")}
            positiveButtonTitle={i18n.t("delete")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleDialogNegativeButtonClick}
            handlePositiveButtonClick={this.handleDialogPositiveButtonClick}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  accountCurrentTs: accountCurrentTsSelector(state),
  accountEndTs: accountEndTsSelector(state),
  accountGraceTs: accountGraceTsSelector(state),
  accountStartTs: accountStartTsSelector(state),
  accountSubscriptionStatus: accountSubscriptionStatusSelector(state),
  accountSubscriptionIsActive: accountSubscriptionIsActiveSelector(state)
});

const mapDispatchToProps = {
  unarchiveCustodian: unarchiveCustodian,
  deleteCustodianPermanently: deleteCustodianPermanently
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArchivedCustodiansComponent));
