import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import { hashParams } from "routes";
import styled from "styled-components";
import i18n from "i18next";
import { connect, useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import DocumentsComponent from "components/documents/DocumentsComponent";
import {
  uploadDocument as uploadDocumentDispatch,
  currentPortfolioDocumentsSelector,
  folderPortfolioDocumentsSelector,
  renameDocument,
  renameFolder,
  deleteDocuments,
  deleteDocumentsFolder,
  deleteDocumentsCustodian,
  ApiClient,
  exportPortfolioSafeDeposit,
  currentPortfolioSelector,
  showToastTip,
  isAmcUser,
  userMaskAllValuesSelector,
  isAppInViewMode,
  exportCustodianDocuments,
  getHashParams,
  userPreferencesSelector,
  updateUserPreferences,
  siteConfigSelector,
  wlSetupType,
  withRouter,
  useHistory
} from "@kubera/common";
import { downloadFile } from "utilities/FileUtils";
import EmptySafeDepositComponent from "components/safe_deposit_box/EmptySafeDepositComponent";
import DocumentsDownloadDialog from "components/safe_deposit_box/DocumentsDownloadDialog";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import CreateFolderComponent from "components/documents/CreateFolder";
import { detailsTabs } from "components/custodian_details/CustodianDetailsComponent";
import AssetsComponent from "components/assets/AssetsComponent";
import DebtsComponent from "components/debts/DebtsComponent";
import InsuranceComponent from "components/insurance/InsuranceComponent";
import { category } from "components/dashboard/DashboardComponent";
import EditableLabel from "components/inputs/EditableLabel";

const Container = styled.div`
  width: 100%;
`;

const Documents = styled(DocumentsComponent)`
  margin-top: 22px;
  flex: 1;
`;

class SafeDepositBoxComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleRemoveDocumentDialogNegativeButtonClick = this.handleRemoveDocumentDialogNegativeButtonClick.bind(this);
    this.handleRemoveDocumentDialogPositiveButtonClick = this.handleRemoveDocumentDialogPositiveButtonClick.bind(this);

    this.handleDocumentUpload = this.handleDocumentUpload.bind(this);
    this.handleDocumentRename = this.handleDocumentRename.bind(this);
    this.handleOnDeleteDocuments = this.handleOnDeleteDocuments.bind(this);

    this.handleOnDownloadDocuments = this.handleOnDownloadDocuments.bind(this);
    this.handleRetryDocumentUpload = this.handleRetryDocumentUpload.bind(this);
    this.handleDownloadAllDocuments = this.handleDownloadAllDocuments.bind(this);
    this.state = {
      ...this.state,
      showRemoveDocumentDialog: false,
      showCreateFolderDialog: false,
      deldocs: []
    };
  }

  handleDocumentUpload(file, fileThumbnail) {
    this.props.uploadDocument(file, fileThumbnail);
  }

  handleDocumentRename(updatedDocument) {
    if (updatedDocument.fileType === "folder") {
      this.props.renameFolder(updatedDocument);
    } else {
      this.props.renameDocument(updatedDocument);
    }
  }

  handleOnDeleteDocuments(documents) {
    if (documents.length === 1) {
      this.setState({ showRemoveDocumentDialog: true, deldocs: documents });
    }
  }

  handleRemoveDocumentDialogNegativeButtonClick(e) {
    this.setState({ showRemoveDocumentDialog: false });
  }

  handleRemoveDocumentDialogPositiveButtonClick(e) {
    this.setState({ showRemoveDocumentDialog: false });

    if (!this.state.deldocs[0]) {
      return;
    }

    if (this.state.deldocs[0].fileType === "folder") {
      this.props.deleteDocumentsFolder(this.state.deldocs);
    } else if (this.state.deldocs[0].fileType === "custodian") {
      this.props.deleteDocumentsCustodian(this.state.deldocs);
    } else {
      this.props.deleteDocuments(this.state.deldocs);
    }

    this.props.showToastTip(
      "TIP",
      i18n.t("documentDeleteToast.deleted") + this.state.deldocs[0].name,
      didShow => {},
      4000
    );
  }

  handleOnDownloadDocuments(documents) {
    for (const doc of documents) {
      if (doc.fileType === "custodian") {
        this.props.exportDocuments(doc.custodian.sectionId, doc.custodian.id, doc.custodian.name);
        DocumentsDownloadDialog.show(this.props.history, this.props.location);
      } else if (doc.fileType === "folder") {
        ApiClient.downloadFolder(doc.id);
        DocumentsDownloadDialog.show(this.props.history, this.props.location);
      } else {
        ApiClient.getFileDownloadUrl(doc, false, false)
          .then(url => {
            downloadFile(url, doc.name);
          })
          .catch(apiError => {});
      }
    }
  }

  handleRetryDocumentUpload(doc) {
    this.props.uploadDocument(doc.file, doc.thumbnail, { retryDocument: doc });
  }

  handleDownloadAllDocuments() {
    if (this.props.folderDetails) {
      this.handleOnDownloadDocuments([this.props.folderDetails]);
    } else {
      this.props.exportPortfolioSafeDeposit(this.props.currentPortfolio.id, this.props.currentPortfolio.name);
      DocumentsDownloadDialog.show(this.props.history, this.props.location);
    }
  }

  handleUploadNewFolder = () => {
    this.setState({
      showCreateFolderDialog: true
    });
  };

  createFolder = name => {
    this.props.uploadDocument(
      {
        name,
        type: "folder"
      },
      null
    );
  };

  handleUploadNewFolderDismiss = () => {
    this.setState({
      showCreateFolderDialog: false
    });
  };

  render() {
    const { showCreateFolderDialog, showRemoveDocumentDialog } = this.state;
    const { forceShowEmptyState, isAmcUser, handleOpenDocument = () => null, folderDetails } = this.props;

    const documents = this.props.documents;
    const isEmpty = documents && documents.length === 0;

    return (
      <Container>
        {!folderDetails && (forceShowEmptyState || isEmpty === true) ? (
          <EmptySafeDepositComponent
            isReadOnly={isAppInViewMode() || this.props.currentPortfolio.write === 0}
            onDocumentUpload={this.handleDocumentUpload}
            showFilesBtn={forceShowEmptyState}
            handleOpenDocument={handleOpenDocument}
          />
        ) : (
          <>
            <Documents
              isReadOnly={isAppInViewMode() || this.props.currentPortfolio.write === 0}
              isWlkReadOnly={this.props.siteConfig.type === wlSetupType.ADMIN_WR_CLIENT_RO}
              maxItemsPerRow={5}
              documentCellSize={this.props.documentCellSize}
              onDocumentUpload={this.handleDocumentUpload}
              documents={documents}
              hideDownloadAll={isAmcUser === true}
              onDocumentRename={this.handleDocumentRename}
              onDeleteDocuments={this.handleOnDeleteDocuments}
              onDownloadDocuments={this.handleOnDownloadDocuments}
              onRetryDocumentUpload={this.handleRetryDocumentUpload}
              onDownloadAllDocuments={this.handleDownloadAllDocuments}
              isFolderUploadAllowed={this.props.isFolderUploadAllowed}
              onUploadNewFolder={this.handleUploadNewFolder}
              maskThumbnail={this.props.userMaskAllValues}
              handleFolderClick={this.props.handleFolderClick}
              compactMode={this.props.compactMode}
              isModal={!!this.props.folderDetails}
              selectedSortOption={this.props.selectedSortOption}
              handleSortPreference={this.props.handleSortPreference}
            />
          </>
        )}
        {showRemoveDocumentDialog === true && (
          <ConfirmationDialog
            title={i18n.t("documentDeleteDialog.title")}
            description={this.state.deldocs[0].name}
            positiveButtonTitle={i18n.t("remove")}
            negativeButtonTitle={i18n.t("cancel")}
            handleNegativeButtonClick={this.handleRemoveDocumentDialogNegativeButtonClick}
            handlePositiveButtonClick={this.handleRemoveDocumentDialogPositiveButtonClick}
          />
        )}
        {showCreateFolderDialog === true && (
          <CreateFolderComponent onDismiss={this.handleUploadNewFolderDismiss} onChange={this.createFolder} />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  userMaskAllValues: userMaskAllValuesSelector(state),
  isAmcUser: isAmcUser(state),
  siteConfig: siteConfigSelector(state)
});

const mapDispatchToProps = {
  renameDocument,
  renameFolder,
  deleteDocuments,
  deleteDocumentsFolder,
  deleteDocumentsCustodian,
  exportPortfolioSafeDeposit,
  exportDocuments: exportCustodianDocuments,
  showToastTip
};

export const SafeDepositBoxComponentConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SafeDepositBoxComponent));

const FolderContainer = styled(DialogOverlay)``;

const FolderDialog = styled(Dialog)`
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 866px;
  margin-top: 80px;
  padding: 60px;
  min-height: 648px;
  box-sizing: border-box;
`;

const FolderName = styled(EditableLabel)`
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const SafeDepositBoxWrapper = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const detailsRef = useRef();
  const nameRef = useRef();

  const [folderDetails, setFolderDetails] = useState(null);
  const [custodianDetails, setCustodianDetails] = useState(null);

  const currentPortfolio = useSelector(currentPortfolioSelector);
  const documents = useSelector(currentPortfolioDocumentsSelector);
  const folderDocuments = useSelector(folderPortfolioDocumentsSelector((folderDetails || {}).id));
  const userPreferences = useSelector(userPreferencesSelector);

  const isModalOpen = getHashParams(location)[hashParams.MODAL];
  const custodianCategory = (custodianDetails || {}).category;
  const custodianId = custodianDetails && custodianDetails.custodian.id;

  const isDocumentOpened = userPreferences.isDocumentOpened && userPreferences.isDocumentOpened[currentPortfolio.id];

  const uploadDocument = (file, thumbnail, options) => {
    dispatch(uploadDocumentDispatch(file, thumbnail, options));
  };

  const uploadDocumentToFolder = (file, thumbnail, options = {}) => {
    options.folderId = folderDetails.id;
    dispatch(uploadDocumentDispatch(file, thumbnail, options));
  };

  const onFolderClick = document => {
    if (document.fileType === "custodian") {
      setCustodianDetails(document);
    } else {
      setFolderDetails(document);
    }
  };

  const handleOverlayDismiss = () => {
    setFolderDetails(null);
  };

  const hasOtherDocuments = useMemo(
    () =>
      documents.some(function(document) {
        return document.fileType !== "custodian";
      }),
    [documents]
  );

  const handleOpenDocument = useCallback(() => {
    dispatch(
      updateUserPreferences({
        isDocumentOpened: {
          ...userPreferences.isDocumentOpened,
          [currentPortfolio.id]: true
        }
      })
    );
  }, [dispatch, currentPortfolio.id, userPreferences.isDocumentOpened]);

  const handleFolderRename = value => {
    dispatch(renameFolder({ ...folderDetails, name: value }));
  };

  const handleFolderNameClick = () => {
    if (nameRef.current !== null) {
      nameRef.current.edit();
    }
  };

  const handleSortPreference = selectedSort => {
    dispatch(
      updateUserPreferences({
        selectedSort
      })
    );
  };

  const safeDepositProps = {
    ...props,
    currentPortfolio,
    documents,
    uploadDocument,
    handleFolderClick: onFolderClick,
    isFolderUploadAllowed: true,
    documentCellSize: 150,
    forceShowEmptyState: !isDocumentOpened && !hasOtherDocuments && documents.length > 0,
    handleOpenDocument,
    handleSortPreference,
    selectedSortOption: userPreferences.selectedSort
  };

  const folderDialogProps = {
    ...props,
    currentPortfolio,
    documents: folderDocuments,
    uploadDocument: uploadDocumentToFolder,
    handleFolderClick: onFolderClick,
    isFolderUploadAllowed: false,
    documentCellSize: 111,
    compactMode: true,
    folderDetails
  };

  useEffect(() => {
    if (custodianCategory && detailsRef.current) {
      detailsRef.current.show(null, null, null, custodianDetails.custodian, detailsTabs.DOCUMENTS);
    }
  }, [custodianId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (custodianCategory && !isModalOpen) {
      setCustodianDetails(null);
    }

    if (!custodianCategory && isModalOpen && !document.querySelector("#kubera-container-overlay div")) {
      DialogOverlay.forceDismiss(history, location);
    }
  }, [isModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isDocumentOpened && hasOtherDocuments) {
      handleOpenDocument();
    }
  }, [dispatch, isDocumentOpened, hasOtherDocuments, handleOpenDocument]);

  return (
    <>
      <SafeDepositBoxComponentConnect {...safeDepositProps} />
      {folderDetails && (
        <FolderContainer onDismiss={handleOverlayDismiss}>
          <FolderDialog>
            <FolderName
              type="text"
              ref={nameRef}
              value={folderDetails.name}
              onChange={handleFolderRename}
              disabled={isAppInViewMode() || currentPortfolio.write === 0}
              onClick={handleFolderNameClick}
            />
            <SafeDepositBoxComponentConnect {...folderDialogProps} />
          </FolderDialog>
        </FolderContainer>
      )}
      {custodianCategory === category.ASSET && (
        <AssetsComponent portfolio={currentPortfolio} detailsOnlyRef={detailsRef} />
      )}
      {custodianCategory === category.DEBT && (
        <DebtsComponent portfolio={currentPortfolio} detailsOnlyRef={detailsRef} />
      )}
      {custodianCategory === category.INSURANCE && (
        <InsuranceComponent portfolio={currentPortfolio} detailsOnlyRef={detailsRef} />
      )}
    </>
  );
};

export default React.memo(SafeDepositBoxWrapper);
