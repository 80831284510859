import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setSlideDirection, useHistory } from "@kubera/common";

import requestIdleCallback from "utilities/requestIdleCallback";

const ClickableDiv = styled.div`
  display: inline-block;

  :active {
    background: ${props => props.theme.mobileBackgroundOnTap};
  }
`;

const DelayLink = props => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const timeoutId = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  /**
   * Called when the link is clicked
   *
   * @param {Event} e
   */
  const handleClick = e => {
    const {
      replace,
      to,
      delay = null,
      direction = "left",
      onDelayStart = () => null,
      onDelayEnd = () => null,
      onClick = () => null
    } = props;

    const changeRoute = () => {
      dispatch(setSlideDirection(direction));

      if (to && replace) {
        if (to.pathname) {
          history.replace({
            ...location,
            ...to
          });
        } else {
          history.replace({
            ...location,
            pathname: to
          });
        }
      } else if (to) {
        if (to.pathname) {
          history.push({
            ...location,
            ...to
          });
        } else {
          history.push({
            ...location,
            pathname: to
          });
        }
      }
      onDelayEnd(e, to);
      onClick(e);
    };

    onDelayStart(e, to);
    if (e.defaultPrevented) {
      return;
    }
    e.preventDefault();

    if (delay === null) {
      requestIdleCallback(changeRoute);
      return;
    }

    timeoutId.current = setTimeout(changeRoute, delay);
  };

  const allProps = Object.assign({}, props);
  delete allProps.delay;
  delete allProps.onDelayStart;
  delete allProps.onDelayEnd;

  return <ClickableDiv {...allProps} onClick={handleClick} />;
};

export default DelayLink;
